import React from 'react'

// Containers
import Header from '../containers/Header'
import ChatsContent from '../containers/Content/ChatsContent'

function Chats(props) {
    var status = null
    var thread = null
    if (props.location.state) {
        if (props.location.state.status) {
            status = props.location.state
        } else if (props.location.state.channel) {
            thread = props.location.state
        }
    }

    return (
        <React.Fragment>
            <div className="max-screen">
                <Header />
                <ChatsContent statusChange={status} viewThread={thread} />
            </div>
        </React.Fragment>
    )
}

export default Chats