import React, {useState} from 'react'

// Components
import Button from '../../Elements/Button'
import Dropdown from '../../Elements/Dropdown'
import InputField from '../../Elements/InputField'
import useOrgId from '../../../hooks/useOrgId'

// API
import { projectService } from '../../../api'

function CloseProjectForm(props) {
    const [fillableText, setFillableText] = useState(null)

    const orgId = useOrgId()

    function handleCloseReason(e) {
        e.preventDefault()

        var form_element = document.getElementById('close-project-form')
        var form_data = new FormData(form_element)

        form_data.append("status", "CLOSE")

        projectService.update(form_data, orgId, props.project)
        .then(response => {
            // props.onSubmit(response.close_other_reason ? response.close_other_reason : response.proper_close_options)
            props.onSubmit(response.close_other_reason && response.close_options ==='OTHER' ? response.close_other_reason : response.proper_close_options)
        })
        .catch(error => {
            console.error("Unable to update reason", error)
        })
    }

    function handleOtherOption(e) {
        if (e.target.value === "OTHER") {
            setFillableText(<InputField label="Reason" name="close_other_reason" />)
        }
        else {
            setFillableText(null)
        }
    }

    return (
        <React.Fragment>
            <form id="close-project-form" onSubmit={handleCloseReason}>
                <h1 className="title is-dark mb-30">Close project</h1>
                <Dropdown label="Closed reason" name="close_options" onChange={handleOtherOption}>
                    <option value="IDENTIFIED">Identified</option>
                    <option value="FOUND">Found</option>
                    <option value="RESCUED">Rescued/Recovered</option>
                    <option value="DECEASED">Deceased</option>
                    <option value="ARRESTED">Arrested/Detained</option>
                    <option value="COMPLETE">Passed to customer</option>
                    <option value="OTHER">Other</option>
                </Dropdown>
                {fillableText}
                <div className="right center-mobile">
                    <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose}/>
                    <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit"/>
                </div>
            </form>
        </React.Fragment>
    )
}

export default CloseProjectForm