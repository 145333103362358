import {useContext} from 'react'
import {Link} from 'react-router-dom'

import {PermissionWrapper} from '../'
import {Hyperlink} from '../Elements'
import {projectService} from '../../api'
import {useOrgId, UserContext} from '../../hooks'

import '../../assets/styles/dashboard.scss'
import styled from 'styled-components'
import CustomTooltip from '../molecules/ProjectTable/ProjectTableItem'

const ProjectTable = ( { projects, tableView, columnsList, defaultColumns, title, to, custom, onSearchAnalyst, analystWanted, loading, events } ) => {
	const orgId = useOrgId()
	const { currentUser } = useContext( UserContext )

	const handleDelete = ( project_id ) => {
		document.getElementById( `project-${ project_id }` ).remove()

		projectService.remove( orgId, project_id ).catch( ( error ) => {
			console.log( 'Error deleting project', error )
		} )
	}
	const showID = !columnsList.includes( defaultColumns[ 0 ] )
		? 'hidden'
		: ''
	const showName = !columnsList.includes( defaultColumns[ 1 ] )
		? 'hidden'
		: ''
	const showDate = !columnsList.includes( defaultColumns[ 2 ] )
		? 'hidden'
		: ''
	const showAnalyst = !columnsList.includes( defaultColumns[ 3 ] )
		? 'hidden'
		: ''


	return (
		<div className={ tableView
			? 'wcc-projects wcc-table'
			: 'wcc-projects wcc-cards' }>
			<div>
				<h1 className="title-3 is-dark">{ title }</h1>
				{ !custom && (
					<Hyperlink
						text="View All"
						className="inline"
						link={ `/organization/${ orgId }/projects/${ to }` }
					/>
				) }
			</div>

			<table className="table" cellSpacing="0">
				<thead>
				<tr className="table-header">
					{ defaultColumns.map( ( column, idx ) => {
						const shown = !columnsList.includes( defaultColumns[ idx ] )
							? 'hidden'
							: ''
						return (
							<th key={ column } className={ `text is-light left ${ shown }` }>
								{ column }
							</th>
						)
					} ) }
					<th/>
				</tr>
				</thead>

				<tbody>
				{projects.map((project) => {
					const {
						id,
						case_id,
						proper_start_date,
						subject: { display_name },
						priority,
						analyst,
						collaborative,
						collaborators
					} = project

					let collaborator = collaborators.map(c => c.cognito_id)
					return (
						<tr className='table-rows' id={`project-${id}`} key={id}>
							<td className={`text is-dark left wcc-pid ${showID}`} width={150}>
								<PermissionWrapper
									requiredPermissions={[
										'torch::manage_projects',
										'torch::assign_claim_projects',
									]}
									checkAll={false}
									placeholder={!collaborative && !collaborator.includes(currentUser.cognito_id) ? <p className='text is-dark left'>{case_id}</p> : <Link to={`/organization/${orgId}/project/${id}`}>{case_id}</Link>}
								>
									<Link to={`/organization/${orgId}/project/${id}`}>{case_id}</Link>
								</PermissionWrapper>
							</td>


							<td className={ `text is-dark left wcc-pname ${ showName }` }>{ display_name }</td>

							<td className={ `text is-dark left wcc-pdate ${ showDate }` }>{ proper_start_date }</td>

							<td className={ `text is-dark left wcc-panalyst ${ showAnalyst }` }>
								{ onSearchAnalyst
									? <>
										{ analyst && <CollaboratorNameStyled itsMe={ analyst.cognito_id === analystWanted.cognito_id }>{ analyst?.public_name }</CollaboratorNameStyled> }
										{ collaborators && <>
											{ collaborators.length === 1 && <CollaboratorNameStyled itsMe={ collaborators[ 0 ].cognito_id === analystWanted.cognito_id }>{ collaborators[ 0 ].public_name }</CollaboratorNameStyled> }
											{ collaborators.length > 1 && <CustomTooltip onLoading={loading} collaborators={ collaborators } currentUser={ analystWanted } /> }
										</>
										}
									</>
									: <>
										{ analyst && <CollaboratorNameStyled itsMe={ analyst.cognito_id === currentUser.cognito_id  && events.length > 0 ? events.includes(id) : false}>{ analyst?.public_name }</CollaboratorNameStyled> }
										{ collaborators && <>
											{ collaborators.length === 1 && <CollaboratorNameStyled itsMe={ collaborators[ 0 ].cognito_id === currentUser.cognito_id && events.length > 0 ? events.includes(id) : false}>{ collaborators[ 0 ].public_name }</CollaboratorNameStyled> }
											{ collaborators.length > 1 && <CustomTooltip onLoading={loading} collaborators={ collaborators } currentUser={ currentUser } case_id={id} events={events}/> }
										</>
										}
									</>
								}

							</td>

							<td className="wcc-pfooter" width="60">
								{ !tableView && <div className="wcc-priority"> { priority } </div> }

									<div className='wcc-picons'>
										<PermissionWrapper
											requiredPermissions={[
												'torch::manage_projects',
												'torch::assign_claim_projects',
											]}
											checkAll={false}
											placeholder={!collaborative && !collaborator.includes(currentUser.cognito_id) ? null : <Link to={`/organization/${orgId}/project/${id}`}><i className='fa fa-pencil mx-5 cursor is-light' /></Link>}
										>
											<Link to={`/organization/${orgId}/project/${id}`}>
												<i className='fa fa-pencil mx-5 cursor is-light' />
											</Link>
										</PermissionWrapper>

									<PermissionWrapper requiredPermissions={ [ 'torch::manage_projects' ] } checkAll>
										<i
											className="fa fa-trash-o mx-5 cursor is-light"
											onClick={ () => {
												handleDelete( id )
											} }
										/>
									</PermissionWrapper>
								</div>
							</td>
						</tr>
					)
				} ) }
				</tbody>
			</table>
		</div>
	)
}

const CollaboratorNameStyled = styled.span`
  color: ${ ( props ) => props.itsMe && 'var(--error)' };
  font-weight: ${ ( props ) => props.itsMe && '700' };
`

export default ProjectTable
