import { useParams } from 'react-router-dom'

import Dropdown from '../../Elements/Dropdown'
import Button from '../../Elements/Button'
import useOrgId from '../../../hooks/useOrgId'

// API
import { projectService } from '../../../api'

const PriorityOfProjectForm = (props) => {
	let { project_id } = useParams()

	const orgId = useOrgId()

	function handleSubmit(e) {
		e.preventDefault()

		var form_element = document.getElementById('update-project-form')
		var form_data = new FormData(form_element)

		form_data.append('additional_content', props.data.additional_content)
		form_data.append('lea_supported', props.data.lea_supported)
		form_data.append('status', 'UNPUBLISHED')

		projectService
			.update(form_data, orgId, project_id)
			.then((response) => {
				props.onNext()
			})
			.catch((error) => {
				console.error('Unable to convert project', error)
			})
	}

	return (
		<div className='half-width center'>
			<form id='update-project-form' className='left mt-60' onSubmit={handleSubmit}>
				<div className='flexer'>
					<i className='form-icon fas fa-user-alt' />
					<Dropdown name='case_type' class='full-width line-margin-xs' label='Project type'>
						<option value='MIS' selected>
							Missing
						</option>
						<option value='ESC'>Escort</option>
						<option value='POI'>Person Of Interest</option>
						<option value='UNK'>Unknown</option>
					</Dropdown>
				</div>
				<div className='flexer'>
					<i className='form-icon fa fa-level-up' />
					<Dropdown
						name='priority'
						class='full-width line-margin-xs'
						label='Priority of the project'
					>
						<option value='high' selected>
							High Priority
						</option>
						<option value='standard'>Standard Priority</option>
					</Dropdown>
				</div>
				<div className='flexer flexer-spaced center-mobile'>
					<Button
						text='&larr; Previous step'
						class='mt-15 white-btn is-orange subheading is-light'
						type='button'
						onClick={props.onPrevious}
					/>
					<Button text='Create Project &#43;' class='mt-15 orange-btn subheading is-light' />
				</div>
			</form>
		</div>
	)
}

export default PriorityOfProjectForm
