import React, {useState} from 'react'
import { PermissionWrapper } from 'components'

//Components
import Button from '../Elements/Button'
import Modal from '../Elements/Modal'
import EducationForm from '../../components/Profile/Forms/EducationForm'
import ExperienceForm from '../../components/Profile/Forms/ExperienceForm'

function ExperienceSection(props) {
    const [isOpen, setIsOpen] = useState(false)
    
    function openModal() {
        setIsOpen(true)
    }
    function closeModal(refresh=false) {
        if (refresh){
            props.onUpdate()
        }
        setIsOpen(false)
    }

    var form
    if (props.type === "education"){
        form = <EducationForm onClose={closeModal}/>
    }
    else if(props.type === "experience"){
        form = <ExperienceForm onClose={closeModal}/>
    }
    return (
        <div className="exp-rev-section left center-mobile">
          <div className="flexer flexer-spaced flexer-mobile">
            <h1 className="title is-dark">{props.title}</h1>
              { props.allow_edit 
                ? <Button text={props.button} class={props.btnClass} icon={props.btnIcon} onClick={openModal} />
                : <PermissionWrapper 
                    requiredPermissions={['users::manage_users']} 
                    checkAll={false} >
                    <Button text={props.button} class={props.btnClass} icon={props.btnIcon} onClick={openModal} />
                  </PermissionWrapper>
							}
          </div>
            {props.children}
          <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
            {form}
          </Modal>
        </div>
    )
}

export default ExperienceSection
