import React, {useState} from 'react'
import { PermissionWrapper } from 'components'

//Components
import Button from '../Elements/Button'
import Modal from '../Elements/Modal'
import ReviewForm from '../Profile/Forms/ReviewForm'

function ReviewSection(props) {
    const [isOpen, setIsOpen] = useState(false)
    
    function openModal() {
        setIsOpen(true)
    }
    function closeModal(refresh=false) {
        if (refresh){
            props.onUpdate()
        }
        setIsOpen(false)
    }

    return (
        <div className="exp-rev-section left center-mobile">
            <div className="flexer flexer-spaced flexer-mobile mb-15">
                <h1 className="title is-dark">{props.title}</h1>
                {props.allow_edit ? null : <Button text={props.button} class={props.btnClass} icon={props.btnIcon} onClick={openModal}/>}
                {/* <PermissionWrapper requiredPermissions={''}>
                    <Button text={props.button} class={props.btnClass} icon={props.btnIcon} onClick={openModal}/>
                </PermissionWrapper> */}
            </div>
            {props.children}
            <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
                <ReviewForm user={props.user} onClose={closeModal}/>
            </Modal>
        </div>
    )
}

export default ReviewSection