import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons'
import {ClickAwayListener} from '@mui/material'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import {projectService} from 'api'
import {
	AccountCard,
	AddAccountForm,
	AdditionalInfoSection,
	AssociatedMessagesSection,
	AttachedDocsSection,
	Button,
	LinksSection,
	MenuTabItem,
	Modal,
	PermissionWrapper,
	ProfileSection,
	ProjectAssigner,
	ProjectInfoSection,
	RecentActivitySection,
	WebScraperSection,
} from 'components'
import {useOrgId} from 'hooks'
import AddLocationModal from '../../components/Project/Forms/Location/AddLocationModal'
import LocationCard from '../../components/Project/LocationCard'
import EditLocationModal from '../../components/Project/Forms/Location/EditLocationModal'

const ProjectContent = ( props ) => {
	const [ Project, setProject ] = useState( {} )
	const [ accountsList, setAccounts ] = useState( [] )
	const [ isOpenAddAccount, setOpenAddAccount ] = useState( false )
	const [ isOpenAddLocation, setOpenAddLocation ] = useState( false )
	const [ isOpenEditLocation, setOpenEditLocation ] = useState( false )
	const [ editedLocation, setEditedLocation ] = useState( {} )
	const [ isOpenUpdateAccount, setOpenUpdateAccount ] = useState( false )
	const [ isOpenUnpublished, setOpenUnpublished ] = useState( false )
	const [ modalAccount, setModalAccount ] = useState()
	const [ refresh, setRefresh ] = useState( 0 )
	const [ social, setSocial ] = useState( true )
	const [ activity, setActivity ] = useState( false )
	const [ scraper, setScraper ] = useState( false )
	const [ messages, setMessages ] = useState( false )
	const [ closed, setClosed ] = useState( false )
	const [ showFindings, setShowFindings ] = useState( false )
	const [ subject, setSubject ] = useState( { } )

	const orgId = useOrgId()
	const { project_id } = useParams()

	useEffect( () => {
		if ( props.first ) {
			setOpenUnpublished( true )
		}
	}, [ props.first ] )

	useEffect( () => {
		projectService
			.retrieve( project_id, orgId )
			.then( ( response ) => {
				const filteredPhones = response.subject.phones.filter(x => x.deleted == 0);
				response.subject.phones = filteredPhones;
				setProject( response )
				setAccounts( response.subject.findings )
				setSubject( { id: response.subject.id, type: response.subject.type })
			} )
			.catch( ( error ) => {
				console.log( 'Error loading project', error )
			} )
	}, [ orgId, project_id, refresh, social, isOpenAddLocation, isOpenEditLocation ] )

	function addDoc( doc ) {
		var project_ = Project
		project_.docs.push( doc )
		setProject( { ...project_ } )
	}

	function closeAndRefresh() {
		setOpenUpdateAccount( false )
		setOpenAddAccount( false )
		setOpenUnpublished( false )
		setRefresh( refresh + 1 )
	}

	const hdlShowFindingOptions = () => {
		setShowFindings( false )
	}

	function openAccountModal( account = null ) {
		setModalAccount( <AddAccountForm account={ account } onClose={ closeAndRefresh }/> )
		setOpenUpdateAccount( true )
	}

	function handleAssociatedMessages() {
		setActivity( false )
		setSocial( false )
		setScraper( false )
		setMessages( true )
	}

	function handleRecentActivity() {
		setActivity( true )
		setSocial( false )
		setScraper( false )
		setMessages( false )
	}

	function handleSocialAccounts() {
		setActivity( false )
		setSocial( true )
		setScraper( false )
		setMessages( false )
	}

	function handleScraper() {
		setActivity( false )
		setSocial( false )
		setScraper( true )
		setMessages( false )
	}

	if ( !closed && Project.proper_status === 'Closed' ) {
		setClosed( true )
	}

	function toggleCollaborative() {
		setProject( { ...Project, collaborative: !Project.collaborative } )
	}

	return (
		<>
			<div className="flexer flexer-spaced max-screen__bottom flexer-mobile">
				<div className="flexer-3 project-sides wcc-pleft">
					<ProfileSection
						project={ Project }
						onClose={ closeAndRefresh }
						closed={ closed }
						orgId={ orgId }
					/>
					<LinksSection project={ Project } onClose={ closeAndRefresh } closed={ closed } orgId={ orgId }/>
					<ProjectAssigner
						AssignedGroups={ Project.groups_organization }
						AssignedUsers={ Project.collaborators }
						projectId={ Project.id }
						// collaborative={Project.collaborative}
						setRefresh={ setRefresh }
					/>
				</div>

				<div className="flexer-6 wcc-project-container">
					<ProjectInfoSection
						project={ Project }
						accounts={ accountsList }
						closed={ closed }
						onClose={ closeAndRefresh }
						onChange={ setOpenUnpublished }
						onChangeCollaborative={ toggleCollaborative }
					/>
					<div className="advanced-profile flexer flexer-spaced">
						<div className="flexer center-mobile">
							<MenuTabItem
								text="Findings"
								onClick={ handleSocialAccounts }
								active={ social }
								project={ true }
							/>
							<PermissionWrapper
								requiredPermissions={ [ 'torch::read_recent_activity' ] }
								checkAll={ false }
							>
								<MenuTabItem
									text="Recent Activity"
									onClick={ handleRecentActivity }
									active={ activity }
									project={ true }
								/>
							</PermissionWrapper>
							{/* <PermissionWrapper
							 requiredPermissions={'torch::read_scraper_results'}
							 checkAll={false}
							 >
							 <MenuTabItem
							 text='Scraper Results'
							 onClick={handleScraper}
							 active={scraper}
							 project={true}
							 />
							 </PermissionWrapper> */ }
							<MenuTabItem
								text="Associated Messages"
								onClick={ handleAssociatedMessages }
								active={ messages }
								project={ true }
							/>
						</div>
						{ social && !closed && (
							// <PermissionWrapper
							//   requiredPermissions={['torch::edit_project_data']}
							//   checkAll={false}
							// >
							<ClickAwayListener onClickAway={ hdlShowFindingOptions }>
								<nav
									style={ {
										position: 'relative',
										height: 'fit-content',
									} }
								>
									<ButtonStyled onClick={ () => setShowFindings( true ) }>
										+ Add Finding
										<FontAwesomeIcon className={ 'ml-10' } icon={ faEllipsisV }/>
									</ButtonStyled>
									<ButtonList isOpen={ showFindings }>
										<li
											style={ {
												padding: '.8rem 1rem',
												cursor: 'pointer',
												fontWeight: 'lighter',
												borderBottom: '1px solid var(--border)',
											} }
											onClick={ () => {
												setOpenAddAccount( true )
											} }
										>
											Add Account
										</li>
										<li
											style={ {
												padding: '.8rem 1rem',
												cursor: 'pointer',
												fontWeight: 'lighter',
												borderBottom: '1px solid var(--border)',
											} }
											onClick={ () =>
												setOpenAddLocation( true )
											}
										>
											Add Location
										</li>
									</ButtonList>
								</nav>
							</ClickAwayListener>
							// </PermissionWrapper>
						) }
					</div>
					{ social && accountsList.map( ( account ) => {
						if ( account.finding_type === 'account' ) {
							return <AccountCard
								closed={ closed }
								key={ account.id }
								account={ account }
								onEdit={ openAccountModal }
								onClose={ closeAndRefresh }
								onDelete={ closeAndRefresh }
							/>
						}
						if ( account.finding_type === 'location' ) {
							return <LocationCard
								orgId={ orgId }
								key={ account.id }
								account={ account }
								setRefresh={ setRefresh }
								setEditedLocation={ setEditedLocation }
								setOpenEditLocation={ setOpenEditLocation }
							/>
						}
					} ) }
					{ activity && <RecentActivitySection project={ Project }/> }
					{ scraper && <WebScraperSection project={ Project } orgId={ orgId }/> }
					{ !social && !activity && !scraper && <AssociatedMessagesSection project={ Project }/> }
				</div>
				<div className="flexer-3 project-sides">
					<AdditionalInfoSection project={ Project } onClose={ closeAndRefresh } closed={ closed }/>
					<AttachedDocsSection project={ Project } onNew={ addDoc } closed={ closed }/>
				</div>
			</div>

			<Modal
				isOpen={ isOpenAddAccount }
				handleClose={ () => {
					setOpenAddAccount( false )
				} }
			>
				<AddAccountForm subject={ subject.id } account={ {} } onClose={ closeAndRefresh }/>
			</Modal>

			<AddLocationModal
				orgId={ orgId }
				subject={ subject }
				isOpenAddLocation={ isOpenAddLocation }
				setOpenAddLocation={ setOpenAddLocation }
			/>

			<EditLocationModal
				orgId={ orgId }
				subject={ subject }
				editedLocation={ editedLocation }
				isOpenEditLocation={isOpenEditLocation}
				setEditedLocation={ setEditedLocation }
				setOpenEditLocation={ setOpenEditLocation }
			/>

			<Modal
				isOpen={ isOpenUpdateAccount }
				handleClose={ () => {
					setOpenUpdateAccount( false )
				} }
			>
				{ modalAccount }
			</Modal>

			<Modal
				isOpen={ isOpenUnpublished }
				handleClose={ () => {
					setOpenUnpublished( false )
				} }
			>
				<div className="center">
					<h1 className="title is-dark mb-30">Project Created</h1>
					<p className="text is-dark mx-30">The project with ID <strong className="is-dark">{ Project.case_id }</strong> has been created successfully and its
						current status is "Unpublished".</p>
					<p className="text is-dark mx-30">Please review the information and change its status to "Available" to make it visible to the analysts.</p>
					<Button
						text="OK"
						type="button"
						onClick={ closeAndRefresh }
						class="mt-30 one-third-width orange-btn subheading is-light"
					/>
				</div>
			</Modal>
		</>
	)
}

const ButtonStyled = styled.button`
  border: none;
  padding: 1rem;
  cursor: pointer;
  color: var(--secondary-btn-text);
  background-color: var(--naranja-btn);

  svg {
    path {
      color: var(--secondary-btn-text);
    }
  }
`

const ButtonList = styled.ul`
  right: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  border-radius: .25rem;
  list-style-type: none;
  background-color: var(--main-bg);
  transition: all 250ms ease-in-out;
  height: ${ props => props.isOpen
          ? 'calc(200% - 2px)'
          : '0' };
  border: ${ props => props.isOpen
          ? '1px'
          : '0' } solid var(--border);
`

export default ProjectContent
