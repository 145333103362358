import { AiOutlineRightCircle } from 'react-icons/ai'
import { FaPencilAlt, FaUser } from 'react-icons/fa'

import styles from './userRowSkt.module.sass'

const UserRowSkt = () => (
	<article className={styles.skeleton}>
		<span className={`${styles.skeleton_icon} skeleton-animation`}>
			<AiOutlineRightCircle />
		</span>
		<figure className={`${styles.skeleton_figure} skeleton-animation`}>
			<FaUser className={styles.skeleton_figure_img} />
			<span className={styles.skeleton_figure_item} style={{ gridColumn: 2 / 3, gridRow: 1 / 2 }} />
			<span className={styles.skeleton_figure_item} style={{ gridColumn: 2 / 3, gridRow: 2 / 3 }} />
		</figure>
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_icon} skeleton-animation`}>
			<FaPencilAlt />
		</span>
	</article>
)
export default UserRowSkt
