import React from 'react'

function RadioButton(props) {

    var className = props.class ? props.class : ''

    var input
    if(props.value){
        input = <input className="radio-btn__input" type="radio" name={props.name} required={props.required? "required" : false} value={props.value} checked={props.checked}/>
    }
    else{
        input = <input className="radio-btn__input" type="radio" name={props.name} required={props.required? "required" : false} checked={props.checked}/>
    }

    return (
        <div className={`${className} radio-btn`} onClick={props.onClick}>
            <label className="radio-btn__label">
                {input}{props.label}
            </label>
        </div>
    )
}

export default RadioButton