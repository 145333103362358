import RFIContent from 'containers/Content/RFIContent'
import Header from '../containers/Header'

function RFI() {
	return (
		<>
			<Header />
			<div className='rfi-wrapper col-8'>
				<RFIContent />
			</div>
		</>
	)
}

export default RFI
