import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const accountImageService = {
  create,
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body: body }
  
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/account-images/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
