import { Button } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Modal } from '../../../Elements/index'

const ProjectTypesModalCreate = ({
	showAddModal,
	setShowAddModal,
	hdlCreate,
	categories,
	reportTemplates,
	onErr,
}) => {
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm()

	useEffect(() => {
		showAddModal && reset({ short_name: '', project_name: '', template: '' })
	}, [showAddModal])

	return (
		<Modal
			isOpen={showAddModal}
			handleClose={() => {
				setShowAddModal(false)
			}}
		>
			<h1 className='title is-dark mb-30'>Add Project Type</h1>
			<form
				id='types-form'
				onSubmit={handleSubmit(hdlCreate)}
				style={{
					display: 'grid',
					rowGap: '.75rem',
				}}
			>
				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Project type name </small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{onErr && 'This field is required and must be unique.'}
							{errors.project_name && 'This field is required'}
						</span>
					</label>
					<input
						style={{
							border: `1px solid var(${errors.project_name ? '--error' : '--border'})`,
							padding: '.5rem',
							transition: 'all .5rem ease-in-out',
						}}
						placeholder={'Example: Research'}
						type={'text'}
						{...register('project_name', { required: true })}
					/>
				</fieldset>
				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>
							Short name (3 Uppercase letters)
						</small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{onErr && 'This field is required and must be unique.'}
							{errors.short_name && 'This field is required'}
						</span>
					</label>
					<input
						style={{
							border: `1px solid var(${errors.short_name ? '--error' : '--border'})`,
							padding: '.5rem',
							transition: 'all .5rem ease-in-out',
						}}
						maxLength={3}
						placeholder={'Example: RES'}
						type='text'
						{...register('short_name', { required: true })}
					/>
				</fieldset>

				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Custom report template</small>
					</label>
					<select
						style={{ border: '1px solid var(--border)', padding: '.5rem' }}
						{...register('template')}
					>
						<option disabled selected value={''}>
							Select template
						</option>
						{reportTemplates.length > 0 ? (
							reportTemplates.map(({ name, id }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))
						) : (
							<option disabled>No report templates found </option>
						)}
					</select>
				</fieldset>
				<input type='hidden' value={[]} {...register('category')} />
				{/* <fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Category</small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{errors.category && 'This field is required'}
						</span>
					</label>
					<select
						style={{
							border: '1px solid var(--border)',
							padding: '.5rem',
						}}
						{...register('category', { required: true })}
					>
						{categories.length > 0 ? (
							<>
								<option disabled selected hidden>
									Select category
								</option>
								{categories.map(({ name, id }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</>
						) : (
							<option disabled selected hidden>
								No categories found
							</option>
						)}
					</select>
				</fieldset> */}
				<footer className='right center-mobile'>
					<Button
						type={'button'}
						className={'mr-10'}
						variant={'outlined'}
						onClick={() => {
							setShowAddModal(false)
						}}
					>
						Cancel
					</Button>
					<Button variant={'contained'} type={'submit'}>
						Save changes
					</Button>
				</footer>
			</form>
		</Modal>
	)
}
export default ProjectTypesModalCreate
