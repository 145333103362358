import config from "./config";
import { handleResponse } from "./handle-response";


export const authenticationService = {
	login,
	twoFA,
	cleanAut,
	logout,
	refreshToken,
	updateBasicInfo,
	updateOrgMember,
}

function updateBasicInfo() {}

function updateOrgMember() {}

function login(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/initiate_auth/`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response
		})
}

function twoFA(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/respond_to_auth_challenge/`, requestOptions)
		.then(handleResponse)
		.then((tokens) => {
			// organizationService.listByUser(tokens.user_id)  // org are now in global context
			return tokens
		})
}

function cleanAut(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/respond_clean_auth/`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			// organizationService.listByUser(tokens.user_id)  // org are now in global context
			return response
		})
}


async function refreshToken() {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return await fetch(`${config.apiUrl}/auth/refresh_token/`, requestOptions)
		.then((response) => {
			return handleResponse(response)
		})
		.then((user) => {
			return user
		})
		.catch((error) => {
			console.log('failing refreshing token', error)
			logout()
		})
}

async function logout() {
	 
	await fetch(`${config.apiUrl}/auth/logout/`).then((res) => {
		// eslint-disable-next-line no-console
		console.log(res, 'Logging out')
	})
}
