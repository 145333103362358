import { IconButton } from '@mui/material'
import { BiEditAlt, FiTrash } from 'react-icons/all'
import styles from './projectTypesTableItem.module.sass'

const ProjectTypesTableItem = ({
	id,
	name,
	code,
	setToEdit,
	categories,
	setToDelete,
	reportTemplate,
}) => {
	
	return (
	<article className={styles.item}>
		<span className={styles.item_detail}>{name}</span>
		<span className={styles.item_detail}>{code}</span>
		<span className={styles.item_detail}>{reportTemplate?.name ?? '-'}</span>
		<span className={styles.item_detail}></span>
		{/*<span className={styles.item_detail}>{categories.toString()}</span>*/}
		<nav className={styles.item_actions}>
			<IconButton
				onClick={() =>
					setToEdit({
						showModal: true,
						name: name,
						code: code,
						// category: categories[0].id,
						id: id,
						// template: report_template?.id,
					})
				}
				className={`${styles.item_actions_ic} ${styles.item_actions_ic__outline}`}
			>
				<BiEditAlt size={15} />
			</IconButton>
			<IconButton
				onClick={() =>
					setToDelete({
						code: code,
						id: id,
						name: name,
						showModal: true,
					})
				}
				className={`${styles.item_actions_ic} ${styles.item_actions_ic__solid}`}
			>
				<FiTrash size={15} />
			</IconButton>
		</nav>
	</article>
)
}
export default ProjectTypesTableItem
