import React, {useState} from 'react'

import {toggleService} from './Elements/ThemeToggle'

function ThemeMode(props) {

    const [mode, setMode] = useState(toggleService.currentModeValue)
    toggleService.currentModeSubject.subscribe((value)=>{
        if(value !== mode && value){
            setMode(value)
        }
    })

    var modeClass = mode ? mode : "light"

    var body = document.getElementById('body-wrapper')
    if (modeClass === "light") {
        body.classList.add('light')
    } else {
        body.classList.add('dark')
    }

    return (
        <div id="theme-wrapper" className={`${modeClass}`}>
            {props.children}
        </div>
    )

}

export default ThemeMode