import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const projectTemplateService = {
  list,
  listByUrl,
  create,
  update,
  remove,
}

async function list(org_id, limit, offset) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type_template/?limit=${limit}&offset=${offset}`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body }
  
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type_template/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
	}
  
async function update( body, org_id, template_id) {
  const fixedBody = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fixedBody) }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type_template/${template_id}/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function remove(org_id, template_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type_template/${template_id}/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })

}
