import PriorityOfProjectForm from '../../components/CreateProject/Forms/PriorityOfProjectForm'
import MultiSteps from '../../components/CreateProject/MultiSteps'

const PriorityOfProject = ({ onNext, onPrevious, onUpdate, data }) => {
	return (
		<>
			<h1 className='title-2 is-dark line-margin-0'>Project Generation</h1>
			<MultiSteps current={5} />
			<PriorityOfProjectForm
				onNext={onNext}
				onPrevious={onPrevious}
				onUpdate={onUpdate}
				data={data}
			/>
		</>
	)
}

export default PriorityOfProject
