import styles from './projectTypesTableItemSkt.module.sass'

const ProjectTypesTableItemSkt = ({}) => (
	<article className={styles.skeleton}>
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
	</article>
)
export default ProjectTypesTableItemSkt
