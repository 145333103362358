import React, { useState, useEffect } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'

// Components
import useOrgId from '../../hooks/useOrgId'
import { Button, Dropdown, InputField, Textarea } from 'components'

// API
import { ticketService, projectService, memberService } from '../../api'

const CreateTicket = ({ onClose, organizations }) => {
	const [errors, setErrors] = useState({})
	const [selectedIssue, setSelectedIssue] = useState('')
	const [selectedOption, setSelectedOption] = useState([])
	const [selectedOrgOption, setSelectedOrgOption] = useState([])
	const [options, setOptions] = useState([])
	const [orgOptions, setOrgOptions] = useState([])

	const orgId = useOrgId()

	const handleSubmit = (e) => {
		e.preventDefault()

		let form_element = document.getElementById('ticket-form')
		let form_data = new FormData(form_element)

		let body = {}
		form_data.forEach((value, key) => {
			if (value) {
				body[key] = value
			}
		})

		if (selectedOption) {
			body['organization'] = selectedOption[0]
		}

		if (selectedIssue && selectedIssue === 'ORGANIZATION_MEMBER') {
			body['member'] = selectedOrgOption[0]
		}

		if (selectedIssue && selectedIssue === 'PROJECT') {
			body['project'] = selectedOrgOption[0]
		}

		ticketService
			.create(body, orgId)
			.then((response) => onClose())
			.catch((error) => {
				if (Array.isArray(error)) {
					error = { non_field_errors: error[0] }
				}
				onClose()
				setErrors({ ...error })
			})
	}

	const handleChange = (e) => {
		// Dropdown to select Issue type
		let selected_issue = e.target.value
		setSelectedIssue(selected_issue)
	}

	const handleSelect = (selected) => {
		// Multiselect to select one org_id
		let selected_option = selected.map((e) => e.id)
		setSelectedOption(selected_option)
	}
	const handleSelectOrg = (selected) => {
		// Multiselect to select one member_id or project_id
		let selected_org_option = selected.map((e) => e.id)
		setSelectedOrgOption(selected_org_option)
	}

	useEffect(() => {
		if (
			selectedIssue === 'ORGANIZATION' ||
			selectedIssue === 'ORGANIZATION_MEMBER' ||
			selectedIssue === 'PROJECT'
		) {
			setOptions(organizations)
		}
	}, [selectedIssue])

	useEffect(() => {
		if (selectedIssue === 'ORGANIZATION_MEMBER') {
			memberService
				.list(selectedOption)
				.then(({ results }) => {
					console.log(
						results.map(({ id, user: { public_name } }) => {
							return { id, name: public_name }
						})
					)
					setOrgOptions(
						results.map(({ id, user: { public_name } }) => {
							return { id, name: public_name }
						})
					)
				})
				.catch(({ message }) => console.error('Member not found', message))
		}

		if (selectedIssue === 'PROJECT') {
			projectService
				.list({}, selectedOption)
				.then(({ results }) => {
					setOrgOptions(
						results.map(({ id, subject: { display_name } }) => {
							return { id, name: display_name }
						})
					)
				})
				.catch(({ message }) => console.error('Project not found', message))
		}
	}, [selectedOption])

	const handleSearch = (keyword) => {
		if (selectedIssue === 'ORGANIZATION_MEMBER') {
			keyword.length >= 1
				? memberService
						.search(keyword, selectedOption)
						.then((resp) =>
							setOrgOptions(
								resp.map(({ id, user: { public_name } }) => {
									return { id: id, name: public_name }
								})
							)
						)
						.catch((error) => console.error('Member not found', error))
				: memberService
						.list(selectedOption)
						.then(({ results }) =>
							setOrgOptions(
								results.map(({ id, user: { public_name } }) => {
									return { id, name: public_name }
								})
							)
						)
						.catch((error) => console.error('Member not found', error))
		}
		if (selectedIssue === 'PROJECT') {
			keyword.length >= 1
				? projectService
						.search(keyword, selectedOption)
						.then((response) => {
							setOrgOptions(
								response.map(({ id, subject: { display_name } }) => {
									return { id, name: display_name }
								})
							)
						})
						.catch(({ message }) => {
							console.error('Project not found', message)
						})
				: projectService
						.list(selectedOption)
						.then(({ results }) => {
							setOrgOptions(
								results.map(({ id, subject: { display_name } }) => {
									return { id, name: display_name }
								})
							)
						})
						.catch(({ message }) => {
							console.error('Project not found', message)
						})
		}
	}

	let placeholder = selectedIssue && selectedIssue === 'ORGANIZATION_MEMBER' ? 'member' : 'project'

	return (
		<>
			<form id='ticket-form' onSubmit={handleSubmit}>
				<h1 className='title is-dark mb-30'>Create ticket</h1>
				<InputField
					name='title'
					label='Ticket title'
					type='text'
					required={true}
					error={errors.title}
				/>
				<Dropdown
					label='Issue'
					class='is-light line-margin-s my-20'
					name='type'
					onChange={handleChange}
					required={true}
					error={errors.type}
				>
					<option selected disabled>
						Select an option
					</option>
					<option value='ACCOUNT'>Account Issue</option>
					<option value='ORGANIZATION'>Organization Issue</option>
					<option value='ORGANIZATION_MEMBER'>Organization Member Issue</option>
					<option value='PROJECT'>Organization Project Issue</option>
					<option value='OTHER'>Other</option>
				</Dropdown>
				{selectedIssue &&
					(selectedIssue === 'ORGANIZATION' ||
						selectedIssue === 'ORGANIZATION_MEMBER' ||
						selectedIssue === 'PROJECT') && (
						<div className='filters-multi my-30'>
							<Multiselect
								options={options}
								displayValue='name'
								showCheckbox={true}
								avoidHighlightFirstOption={true}
								placeholder='Select an organization'
								hidePlaceholder={true}
								name='selected_org'
								onSelect={handleSelect}
								onRemove={handleSelect}
								selectionLimit={1}
							/>
						</div>
					)}
				{selectedIssue && (selectedIssue === 'ORGANIZATION_MEMBER' || selectedIssue === 'PROJECT') && (
					<div className='filters-multi my-30'>
						<Multiselect
							options={orgOptions}
							onSearch={(keyword) => handleSearch(keyword)}
							displayValue='name'
							showCheckbox={true}
							avoidHighlightFirstOption={true}
							placeholder={`Search by ${placeholder} name`}
							hidePlaceholder={true}
							name='selected_org_option'
							onSelect={handleSelectOrg}
							onRemove={handleSelectOrg}
							selectionLimit={1}
						/>
					</div>
				)}

				<Textarea
					name='message'
					class='full-width line-margin-xs'
					label='Message'
					placeholder='Enter your message...'
					required={true}
					error={errors.message}
				/>
				<div className='right center-mobile'>
					<Button
						text='Cancel'
						class='mt-30 mx-15 white-btn subheading is-dark'
						onClick={onClose}
					/>
					<Button text='Save changes' class='orange-btn mt-30 subheading is-white' type='submit' />
				</div>
			</form>
		</>
	)
}

export default CreateTicket
