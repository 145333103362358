import ProjectContent from '../containers/Content/ProjectContent'

// Containers
import Header from '../containers/Header'

const Project = ( props ) =>{
	return (
		<>
			<div className="max-screen">
				<Header/>
				<ProjectContent first={ props.location.state
					? props.location.state.first
					: null }/>
			</div>
		</>
	)
}

export default Project
