import { useState, useContext } from 'react'

import SuperAdminSettingsMenu from '../../components/SuperadminSettings/SuperAdminSettingsMenu'
import UsersDashboard from '../../components/SuperadminSettings/UsersDashboard'
import OrganizationsDashboard from '../../components/SuperadminSettings/OrganizationsDashboard'

import { UserContext } from '../../hooks/UserContext'

const SuperAdminSettingsContent = () => {
	const { currentUser } = useContext(UserContext)
	const [org, setOrg] = useState([])
	const [isActive, setIsActive] = useState('users')
	const [refresh, setRefresh] = useState(1)

	const handleActive = (active_menu) => {
		setIsActive(active_menu)
		setRefresh(refresh + 1)
	}

	let section =
		isActive === 'users' ? (
			<UsersDashboard />
		) : isActive === 'organizations' ? (
			<OrganizationsDashboard />
		) : null

	return (
		<>
			<div className='flexer flexer-mobile'>
				<div className='flexer-3 center'>
					<SuperAdminSettingsMenu isActive={isActive} handleActive={handleActive} />
				</div>
				<div className='flexer-9 center'>
					<div className=''>{section}</div>
				</div>
			</div>
		</>
	)
}

export default SuperAdminSettingsContent
