import React from 'react'

function RecentActivityDetail(props) {
    if(props.details) {
        var changes = props.details.map( change => {
            return(
            change.old_value
            ?
                (<div className="flexer flexer-vcenter">
                    <i className="fa fa-check-circle-o is-orange mx-10" />
                    <p className="text is-light"><span className="is-bold">{change.field}</span> was changed from <span className="is-orange is-bold">{change.old_value}</span> to <span className="is-orange is-bold">{change.new_value}</span> </p>
                </div>)
            :
                (<div className="flexer flexer-vcenter">
                    <i className="fa fa-check-circle-o is-orange mx-10" />
                    <p className="text is-light"><span className="is-bold">{change.field}</span> was added <span className="is-orange is-bold">{change.new_value}</span></p>
                </div>)
            )
        })
    }
    return(
        <div className="content-wrapper line-margin-0">
            {changes}
        </div>
    )
}

export default RecentActivityDetail

