import React from 'react'
import confidenceService from '../../api/confidence.service'
import AddScore from './AddScore'
import PermissionWrapper from '../Permissions/PermissionWrapper'

const DataConfidence = (props) => {
	const { confidence, color, orgId, dataId, onClose } = props

	return (
		<>
			<div className={`confidence-card ${props.class}`}>
				<div className='confidence-frame'>
					<i className={`fa fa-tachometer ${color} confidence-icon`} />
					<p className='text is-dark is-bold'>{confidence}</p>
				</div>
				<PermissionWrapper requiredPermissions={'torch::manage_datapoint'}>
					<div className='confidence-frame'>
						<AddScore
							orgId={orgId}
							dataId={dataId}
							confidenceLevel={confidence}
							onClose={onClose}
						/>
					</div>
				</PermissionWrapper>
			</div>
		</>
	)
}

export default DataConfidence
