import config from './config';
import { handleResponse } from './handle-response';


export const subscriptionService = {
  cancelSubscription,
}

async function cancelSubscription(subscription_id) {
  const requestOptions = { method: 'POST', credentials: 'include' }

  return fetch(`${config.apiUrl}/subscription/${subscription_id}/cancel/`, requestOptions)
    .then(handleResponse)
    .then(org => {
      return org
    })
}
