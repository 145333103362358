import { CountDown } from 'components'
import  { useState } from 'react'

// Components
import Modal from '../Elements/Modal'
import EditContentForm from './Forms/EditContentForm'
import EditCustomerForm from './Forms/EditCustomerForm'
import EditLastKnownForm from './Forms/EditLastKnownForm'

function AdditionalInfoSection(props) {
	const [editForm, setEditForm] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	function handleEditContent() {
		setIsOpen(true)
		setEditForm(
			<EditContentForm
				onComplete={props.onClose}
				onClose={() => {
					setIsOpen(false)
				}}
				projectID={props.project.id}
				placeholder={props.project.additional_content}
			/>
		)
	}

	function handleEditCustomer() {
		setIsOpen(true)
		setEditForm(
			<EditCustomerForm
				onComplete={props.onClose}
				onClose={() => {
					setIsOpen(false)
				}}
				projectID={props.project.id}
				placeholder={props.project.lea_supported}
			/>
		)
	}

	function handleEditLastKnown() {
		setIsOpen(true)
		setEditForm(
			<EditLastKnownForm
				onComplete={props.onClose}
				onClose={() => {
					setIsOpen(false)
				}}
				projectID={props.project.id}
				placeholder={props.project.last_known}
			/>
		)
	}

	return (
		<div className='simple-profile__sections center'>
			{/* <PermissionWrapper requiredPermissions={['torch::use_timetracker']} checkAll={false} > */}
				<CountDown projectId={props.project.id} overTime={props.project.time_limit} />
			{/* </PermissionWrapper> */}
			<div className='left'>
				<p className='text is-dark is-bold'>
					Additional content
					{!props.closed && (
						<i className='mx-5 fa fa-pencil is-light cursor' onClick={handleEditContent} />
					)}
					{/* <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false} > */}
					{/* </PermissionWrapper> */}
				</p>
				<p className='text is-light'>{props.project.additional_content}</p>
			</div>
			<div className='left'>
				<p className='text is-dark is-bold breakable'>
					What customer will be supported with this project
					{!props.closed && (
						<i className='mx-5 fa fa-pencil is-light cursor' onClick={handleEditCustomer} />
					)}
					{/* <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false} > */}
					{/* </PermissionWrapper> */}
				</p>
				<p className='text is-light'>{props.project.lea_supported}</p>
			</div>
			<div className='left'>
				<p className='text is-dark is-bold'>
					Last known
					{!props.closed && (
						<i className='mx-5 fa fa-pencil is-light cursor' onClick={handleEditLastKnown} />
					)}
					{/* <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false} > */}
					{/* </PermissionWrapper> */}
				</p>
				<p className='text is-light'>{props.project.last_known}</p>
			</div>
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				{editForm}
			</Modal>
		</div>
	)
}

export default AdditionalInfoSection
