import React, { useState } from 'react'

import { FullSize, Modal } from 'components'
import AddNoteScraperForm from './Forms/AddNoteScraperForm'
import EditNoteScraperForm from './Forms/EditNoteScraperForm'
import EditRelevanceScraperForm from './Forms/EditRelevanceScraperForm'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const WebscraperCard = (props) => {
  const { data, onRefresh } = props
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenRelevance, setIsOpenRelevance] = useState(false)
  const [toEditNote, setToEditNote] = useState(null)
  const [relevance, setRelevance] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddNote = () => {
    setIsOpenAdd(true)
  }

  const handleClickEditNote = (note_id, note_content) => {
    setIsOpenEdit(true)
    setToEditNote({ id: note_id, content: note_content })
  }

  const handleClickRelevance = (relevance) => {
    setIsOpenRelevance(true)
    setRelevance(relevance)
    handleClose()
  }

  const handleCloseAddNote = () => {
    setIsOpenAdd(false)
    onRefresh()
  }

  const handleCloseEditNote = () => {
    setIsOpenEdit(false)
    onRefresh()
  }

  const handleCloseRelevance = () => {
    setIsOpenRelevance(false)
    onRefresh()
  }

  let three_dots = <>
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls="long-menu"
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      onClick={handleClick}
      className='MuiButtonBase-root-MuiIconButton-root'
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    >
      <MenuItem disabled >Mark as... </MenuItem>
      {data?.relevance !== 'RELEVANT' && <MenuItem className='MenuItem' onClick={() => handleClickRelevance('RELEVANT')}>Relevant result</MenuItem>}
      {data?.relevance !== 'IRRELEVANT' && <MenuItem className='MenuItem' onClick={() => handleClickRelevance('IRRELEVANT')}>Irrelevant result</MenuItem>}
      {data?.relevance !== 'REVIEW' && <MenuItem className='MenuItem' onClick={() => handleClickRelevance('REVIEW')}>For review</MenuItem>}
    </Menu>
  </>

  let content = 'No data available' // TODO: check the response format in  torch_datapoint when content_name === 'subject_profile_pic'
  if (data && data?.result_type === 'torch_alias') {
    content = data?.first_name && data.display_alias
  }
  if (data && data?.result_type === 'torch_link') {
    content = <>{data?.linked_subject && data.linked_subject.first_name.content} <span className='text is-light is-bold mx-10'> (Relationship:</span>{data?.relationship && data.relationship.content})</>
  }

  if (data && data?.result_type === 'torch_phone') {
    content = data?.phone_number && data.phone_number.content
  }
  if (data && data?.result_type === 'torch_account_image') {
    content = data?.image && <FullSize image={`/media/${data.image.content}`} />  
  }

  if (data && data?.result_type === 'torch_datapoint') {
    content = (data?.content_name === 'subject_profile_pic' || data?.content_name === 'account_image') ?
    <FullSize image={`/media/${data.content}`} />  
    : data.content 
  }

  let images = []
  let shortProfilePage = null
  if (data && data?.result_type === 'torch_accounts') {
    images = data.images.length && data.images.map( e => <FullSize key={e.id} image={e.image} />)

    if (data?.profile_page.content) {
      if (data.profile_page.content.length > 50) {
        shortProfilePage = `${data.profile_page.content.substring(0, 50)}...`
      } else {
        shortProfilePage = data.profile_page.content
      }
    }
  }

  let datapoint_type = data?.content_name === 'subject_profile_pic' ? 'Profile photo' 
  : data?.content_name === 'phonenumber_phone_number' ? 'Phone number' 
  : data?.content_name && data?.content_name.split('_').map(e => e.charAt(0).toUpperCase() + e.slice(1) + ' ')


  let date = data?.proper_created ? data.proper_created : 'No date available'

  let relevance_ = data?.relevance === 'RELEVANT' ? 'Relevant result'
    : data?.relevance === 'IRRELEVANT' ? 'Irrelevant result'
      : data?.relevance === 'REVIEW' ? 'For review'
        : 'For review MV'

  let tag = data?.result_type === 'torch_alias' ? 'Alias'
    : data?.result_type === 'torch_accounts' ? 'Account'
      : data?.result_type === 'torch_link' ? 'Link'
        : data?.result_type === 'torch_phone' ? 'Phone'
          : data?.result_type === 'torch_subject' ? 'Subject'
            : data?.result_type === 'torch_location' ? 'Location'
              : data?.result_type === 'torch_datapoint' ? 'Data point'
                : data?.result_type === 'torch_account_image' ? 'Account image'
                  : 'No data available'

  let notes_ = data?.result_notes && data.result_notes.map(e =>
    <div className='flexer'>
      <p className='text is-dark is-bold flexer-3 line-margin-xs'>Note <i className='mx-10 is-dark fa fa-pen-alt cursor' onClick={() => handleClickEditNote(e.id, e.content)} /></p>
      <p key={e.id} className='text is-dark flexer-9 line-margin-xs'>{e.content}</p>
    </div>
  )

  return (
    <>
      <div className='scraper-card my-40 mx-30'>
        <div className='flexer flexer-spaced flexer-vcenter'>
          <div className=''>
            <p className='subtitle is-dark'>{`Scraper result # ${data && data.id}`}</p>
            <p className='text is-light'>{`Created ${date}`} </p>
          </div>
          <div className='flexer flexer-vcenter'>
            <div className='relevance mr-20'><p className='text is-dark line-margin-0'>{relevance_}</p></div>
            {three_dots}
          </div>

        </div>
        <div className='flexer'>
          <p className='text is-dark is-bold flexer-3 line-margin-xs'>Tag</p>
          <p className='text is-dark flexer-9 line-margin-xs'>{tag}</p>
        </div>
        {
          data?.result_type === 'torch_datapoint' && 
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">Type</p>
              <p className="text is-dark flexer-9 line-margin-xs">{datapoint_type}</p>
            </div>
        }
        { 
          data?.result_type !== 'torch_accounts' ? 
            <div className='flexer'>
              <p className='text is-dark is-bold flexer-3 line-margin-xs'>Content</p>
              <p className='text is-dark flexer-9 line-margin-xs'>{content}</p>
            </div>
            : <>
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">Type</p>
              <p className="text is-dark flexer-9 line-margin-xs">{data.proper_type}</p>
            </div>
            {data.type !== 'streetview' && <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">User ID</p>
              <p className="text is-dark flexer-9 line-margin-xs">{data.user_id.content}</p>
            </div>}
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">{data.type !== 'streetview' ? 'Display name' : 'Location'}</p>
              <div className='flexer flexer-vcenter flexer-9 wcc-hasconfidence'>
                <p className="text is-dark line-margin-xs">{data.display_name.content }</p>
              </div>
            </div>
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">{data.type !== 'streetview' ? 'Profile page' : 'Street View URL'}</p>
              <div className='flexer flexer-vcenter flexer-9 wcc-hasconfidence'>
                <a href={data.profile_page.content} className="text is-orange line-margin-xs" target="_blank" rel="noreferrer">{shortProfilePage}</a>
              </div>
            </div>
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">Last active</p>
              <div className='flexer flexer-vcenter flexer-9 wcc-hasconfidence'>
                <p className="text is-dark  line-margin-xs">{data.last_active?.content}</p>
              </div>
            </div>
            { data.type === 'linkedin' && (
              <>
                <div className="flexer">
                  <p className="text is-dark is-bold flexer-3 line-margin-xs">Job Title</p>
                  <div className='flexer  flexer-9 flexer-vcenter wcc-hasconfidence'>
                    <p className="text is-dark  line-margin-xs">{data.job_title.content}</p>
                  </div>
                </div>
                <div className="flexer">
                  <p className="text is-dark is-bold flexer-3 line-margin-xs">Employer</p>
                  <div className='flexer  flexer-9 flexer-vcenter wcc-hasconfidence'>
                    <p className="text is-dark  line-margin-xs">{data.employer.content}</p>
                  </div>
                </div>
              </>)}
            { data.type === 'twitter' && (
              <>
                <div className="flexer">
                  <p className="text is-dark is-bold flexer-3 line-margin-xs">Handle</p>
                  <div className='flexer  flexer-9 flexer-vcenter wcc-hasconfidence'>
                    <p className="text is-dark  line-margin-xs">{data.handle.content}</p>
                  </div>
                </div>
              </>)}
            <div className="flexer">
              <p className="text is-dark is-bold flexer-3 line-margin-xs">Uploaded images</p>
              <div className="flexer x-scroll line-margin-xs">
                {images.length && images}
              </div>
          </div>        
          </>
        }

        <div className=''>
          {notes_}
          <div className='flexer'>
            <p className='text is-dark is-bold flexer-3 line-margin-xs'> {(data?.result_notes && data.result_notes.length) ? `` : `Notes`}</p>
            <p className="subheading is-orange flexer-9 cursor" onClick={handleAddNote}>+ Add note</p>
          </div>
        </div>

        <Modal isOpen={isOpenAdd} handleClose={() => { setIsOpenAdd(false) }}>
          <AddNoteScraperForm id={data?.id && data.id} type={data?.result_type && data.result_type} onClose={handleCloseAddNote} />
        </Modal>
        <Modal isOpen={isOpenEdit} handleClose={() => { setIsOpenEdit(false) }}>
          <EditNoteScraperForm data={toEditNote && toEditNote} onClose={handleCloseEditNote} />
        </Modal>
        <Modal isOpen={isOpenRelevance} handleClose={() => { setIsOpenRelevance(false) }}>
          <EditRelevanceScraperForm
            id={data?.id && data.id}
            type={data?.result_type && data.result_type}
            oldRelevance={data?.relevance && data.relevance}
            newRelevance={relevance && relevance}
            onClose={handleCloseRelevance} />
        </Modal>
      </div>
    </>
  )
}

export default WebscraperCard
