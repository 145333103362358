import { useState } from 'react'
import { useUserRole } from 'hooks'
// import {  Button  } from '@mui/material'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import Tooltip from '../Elements/Tooltip'

// API
import { organizationService } from '../../api'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'
import { toast } from 'react-toastify'

function CreateOrganizationForm(props) {
	const [errors, setErrors] = useState({})
	const [onLoading, setOnLoading] = useState(false)
	const [created, setCreated] = useState(false)
	const profilePic = OrganizationLogo

	const currentRole = useUserRole()
	const showEagIdField = currentRole === 'SUPER_ADMIN'

	function handleSubmit(e) {
		setOnLoading(true)
		e.preventDefault()

		var form_element = document.getElementById('create-org-form')
		var form_data = new FormData(form_element)

		let uploadedProfilePic = form_data.get("profile_pic");
		if (uploadedProfilePic.size > 0) {
			var ext =  uploadedProfilePic.name.split('.').pop().toLowerCase();
             const allowedExtensions = ["png", "jpeg", "gif", "jpg"];
             if(allowedExtensions.includes(ext)){
			const uniqueName = crypto.randomUUID() + '-' + uploadedProfilePic.name;
			form_data.set("profile_pic", uploadedProfilePic, uniqueName);}
		}

		organizationService
			.create(form_data)
			.then((response) => {
				setCreated(true);
				toast.success('Your organization has been created');
				props.onClose();
			})
			.catch((error) => {
				toast.error(error?.message)
				if (Array.isArray(error)) {
					error = { non_field_errors: error[0] }
				}
				setErrors({ ...error });
			})
			.finally(() => {
				setOnLoading(false);
			});
	}

	function handleFileLoader() {
		var element = document.getElementById('file-loader')
		element.click()
	}

	// disabling for future refactoring
	// if (created) {
	// 	return (
	// 		<>
	// 			<h1 className='title is-dark mb-30'>Create an Organization</h1>
	// 			<div className='flexer flexer-vcenter'>
	// 				<p>The organization was created but it is missing an Stripe plan to work properly. To do it, go to the new organization and then to "Organization Settings > Your Plan > See Other Plans" and select a plan</p>
	// 			</div>
	// 			<div className='right center-mobile'>
	// 				<Button
	// 					text='Ok'
	// 					class='orange-btn mt-30 mx-15 subheading is-white'
	// 					type='button'
	// 					onClick={() => { props.onClose(true) }}
	// 				/>
	// 			</div>
	// 		</>
	// 	)
	// }
	return (
		<form id='create-org-form' onSubmit={handleSubmit}>
			<h1 className='title is-dark mb-30'>Create an Organization</h1>
			<div className='flexer flexer-vcenter'>
				<img className='edit-org-profile__picture' src={profilePic} alt='profile' />
				<input id='file-loader' name='profile_pic' accept='.png, .jpeg, .jpg, .gif' type='file' className='file-input' />
				<Button
					type='button'
					text='Upload photo'
					class=' orange-btn mx-15 subheading is-white'
					onClick={handleFileLoader}
				/>
			</div>

			{showEagIdField && (
				<InputField
					name='eag_id'
					label='EAG ID'
					type='text'
					error={errors.eag_id}
				/>
			)}

			<InputField
				name='name'
				label='Organization Name'
				type='text'
				required={true}
				error={errors.name}
			/>
			<InputField
				name='description'
				label='Description'
				type='text'
				required={true}
				error={errors.description}
			/>
			<Tooltip content='example@example.com' direction='bottom' class='full-width'>
				<InputField
					name='email'
					label='Administrator'
					type='email'
					required={true}
					error={errors.email}
				/>
			</Tooltip>
			<div className='right center-mobile'>
				<Button
					text='Cancel'
					class='mt-30 mx-15 white-btn subheading is-dark'
					onClick={props.onClose}
				/>
				<Button
					text='Create'
					class='orange-btn mt-30 mx-15 subheading is-white'
					type='submit'
					disabled={onLoading}
				/>
			</div>
		</form>
	)
}

export default CreateOrganizationForm