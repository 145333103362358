import {eventService, organizationService, projectService} from 'api'
import {MenuTabItem, PermissionWrapper, ProfileMenu} from 'components'
import {CidTorchMenu, SearchBar, ThemeToggle} from 'components/Elements'

import {CreateProject} from 'containers'
import {UserContext, useWebSocket} from 'hooks'
import {useUserRole} from '../../hooks/index'
import {useContext, useEffect, useState} from 'react'

import {Link, useHistory} from 'react-router-dom'

import torchLogo from '../../assets/images/logo__torch.png'

import torchLogoWhite from '../../assets/images/logo__torch--white.png'

import Notifications from './Notifications'

const OrganizationHeader = ( { orgId } ) => {
	const history = useHistory()
	const [createProjectIsOpen, setCreateProjectIsOpen] = useState(false)
	const [unseenMessages, setUnseenMessages] = useState({
		dashboard: 0,
		chat: 0,
		orgSettings: 0,
		rfi: 0,
		bell: 0,
	})
	const [searchResults, setSearchResults] = useState(null)
	const [theme, setTheme] = useState('')

	const [events, setEvents] = useState([])
	const [showMsg, setShowMsg] = useState(false)
	const [refresh, setRefresh] = useState(0)

	const memberRole = useUserRole(true)
	const currentRole = useUserRole()

	const { currentOrganizations, setCurrentOrganizations } = useContext(UserContext)
	const currentOrganization = currentOrganizations[orgId]

	useEffect(() => {
		if (!currentOrganization && currentRole === 'SUPER_ADMIN') {
			organizationService
				.retrieve(orgId)
				.then((response) => {
					setCurrentOrganizations({ ...currentOrganizations, [orgId]: response })
				})
				.catch((error) => {
					console.error('It was not possible to retrieve organization', error)
				})
		}
	}, [currentOrganization, currentRole])

	useEffect(() => {
		if (orgId) {
			eventService
				.list(orgId, 999)
				.then((resp) => {
					setEvents(resp.results)
				})
				.catch((error) => {
					console.log('Error loading events')
				})
		}
	}, [orgId, refresh])

	useEffect(() => {
		if (events?.length) {
			let unseen_dashboard = events?.filter(
				(item) => item.type === 'ChangeStatusProjectAvailable' || item.type === 'ProjectAssigned'
			).length
			let unseen_chat = events?.filter(
				(item) =>
					item.type === 'DirectMessage' ||
					item.type === 'GroupMessage' ||
					item.type === 'BroadcastMessage'
			).length
			let unseen_orgSettings = events?.filter((item) => item.type === 'InvitationAccepted').length
			let unseen_rfi = events?.filter(
				(item) => item.type === 'RFICommentMention' || item.type === 'RFIMention'
			).length
			let bell = unseen_chat + unseen_rfi + unseen_dashboard + unseen_orgSettings
			setUnseenMessages({
				dashboard: unseen_dashboard,
				chat: unseen_chat,
				orgSettings: unseen_orgSettings,
				rfi: unseen_rfi,
				bell: bell,
			})
		}
	}, [events, refresh])

	var path = window.location.pathname

	const { WebSocketOpen, WebSocketClient } = useWebSocket('events')
	if (WebSocketOpen) {
		WebSocketClient.current.onmessage = (e) => {
			const data = JSON.parse(e.data)
			console.log(data, 'WS')
			setEvents((prevState) => [...prevState, data.payload])
			setRefresh(refresh + 1)
		}
	}

	const handleExpandable = () => {
		let element = document.getElementById('expandable-menu')
		element.classList.toggle('mobile-hidden')
		element.classList.toggle('mobile-shown')
	}

	const handleSearch = (search) => {
		let results = []
		if ( search.length >= 2 ) {
			projectService
				.search( search, orgId )
				.then( ( response ) => {
					for ( var r of response ) {
						results.push(
							<div className="search-results cursor">
								<a href={ `/organization/${ orgId }/project/${ r.id }` } className="flexer">
									<i className="fa fa-angle-right line-margin-0 mr-10 is-light"/>
									<p className="text is-light line-margin-0">{ `${ r.case_id } (${ r.subject.display_name })` }</p>
								</a>
							</div>,
						)
					}
					setSearchResults( results )
				} )
				.catch( ( error ) => {
					setSearchResults( [] )
				} )
		} else {
			setSearchResults([])
		}
	}

	useEffect(() => {
		if (ThemeToggle.currentModeValue && ThemeToggle.currentModeValue !== '') {
			setTheme(ThemeToggle.currentModeValue)
		} else {
			setTheme(localStorage.getItem('themeMode'))
		}
	}, [])

	const handleShowMsg = () => {
		setShowMsg(!showMsg)
	}

	const handleAllRead = () => {
		events?.forEach( item =>

			eventService.mark_as_read( orgId, item.id )
				.then( () => {
					setUnseenMessages( {
						dashboard: 0,
						chat: 0,
						orgSettings: 0,
						rfi: 0,
						bell: 0,
					} )
					setShowMsg( !showMsg )
					setRefresh( refresh + 1 )
				} )
				.catch( () => {
					console.log( 'Error trying to mark all as read' )
				} )

		)
	}

	const handleMarkAsRead = (event_id, path, tab_name) => {
		eventService.mark_as_read( orgId, event_id ).then( () => {
			history.push( `/organization/${ orgId }/${ path }`, { isActive: 'active-members' } )
			let new_value = unseenMessages[ tab_name ] - 1
			setUnseenMessages( ( prevState ) => ( { ...prevState, [ tab_name ]: new_value } ) )
			setRefresh( refresh + 1 )
		} )
	}

	return (
		<>
			<header className='header'>
				{currentOrganization?.subscriptions?.[0]?.is_active_or_new ? null : (
					<div className='warning-message center'>
						<p
							className='subheading line-margin-0 cursor'
							onClick={() => {
								history.push(`./${orgId}/organization-settings`)
							}}
						>
							This organization does not have an active plan. Please go to Organization Settings
							&gt; Your Plan &gt; See other Plans to subscribe to one.
						</p>
					</div>
				)}
				<div className='row flexer flexer-vcenter'>
					{/*Commented out until both have subscriptions 2/18/2022 TD*/ }
					<div className="ml-10">
						<CidTorchMenu/>
					</div>

					<div className="col-3 center-mobile">
						{ theme === 'light' && <img className="logo" src={ torchLogo } alt={ 'Logo Torch' }/> }
						{ theme === 'dark' && <img className="logo" src={ torchLogoWhite } alt={ 'Logo Torch' }/> }
					</div>

					<div className="col-6 relative">
						<SearchBar placeholder={ 'Find a project...' } onChange={ handleSearch }/>
						<div className="absolute full-width on-top">{ searchResults }</div>
					</div>

					<div className="col-3 flexer flexer-spaced flexer-vcenter">
						<ThemeToggle onChange={ setTheme }/>

						<div style={ { position: 'relative' } }>
							<i className="fa fa-bell is-light ml-20 cursor" onClick={ handleShowMsg }>
								{ unseenMessages.bell > 0 && (
									<span className="title is-red is-bold  line-margin-0 ">•</span>
								) }
							</i>
							{showMsg && (
								<Notifications
									handleShowMsg={handleShowMsg}
									orgId={orgId}
									events={events}
									handleMarkAsRead={handleMarkAsRead}
									handleAllRead={handleAllRead}
								/>
							)}
						</div>

						<div className='hidden mobile-shown center-mobile' onClick={handleExpandable}>
							<p className='mx-10'>
								Menu
								<i className='fa fa-bars mx-10' />
							</p>
						</div>
						<ProfileMenu />
					</div>
				</div>
				<div className='wcc-org'>
					<b>{currentOrganization?.name}</b>
				</div>
				<div className='flexer flexer-spaced header-tabs flexer-mobile'>
					<div id='expandable-menu' className='wcc-menu flexer flexer-mobile mobile-hidden'>
						<Link to={`/organization/${orgId}`} className='line-margin-0'>
							<MenuTabItem
								text='Dashboard'
								alert={unseenMessages.dashboard}
								icon='fas fa-grip-horizontal'
								active={path === `/organization/${orgId}`}
							/>
						</Link>
						{memberRole && memberRole !== 'SUPER_ADMIN' && (
							<Link to={`/organization/${orgId}/chats`} className='line-margin-0'>
								<MenuTabItem
									text='Chats'
									alert={unseenMessages.chat}
									icon='far fa-comment-dots'
									active={path === `/organization/${orgId}/chats`}
								/>
							</Link>
						)}

						<PermissionWrapper
							requiredPermissions={[
								'torch::manage_analytics',
								'torch::general_stats',
								'torch::analysts_stats',
								'torch::read_analytics',
							]}
							checkAll={false}
						>
							<Link to={`/organization/${orgId}/analytics`} className='line-margin-0'>
								<MenuTabItem
									text='Analytics'
									icon='fa fa-bar-chart'
									active={path === `/organization/${orgId}/analytics`}
								/>
							</Link>
						</PermissionWrapper>

						<Link to={`/organization/${orgId}/user-resources`}>
							<MenuTabItem
								text='User Resources'
								icon='fa fa-file-o'
								active={path === `/organization/${orgId}/user-resources`}
							/>
						</Link>
						<Link to={`/organization/${orgId}/case-manager-resources`}>
							<MenuTabItem
								text='Case Manager Resources'
								icon='fa fa-file-o'
								active={path === `/organization/${orgId}/case-manager-resources`}
							/>
						</Link>
						<PermissionWrapper
							requiredPermissions={[
								'users::manage_users',
								'organizations::add_user',
								'organizations::manage_roles',
								'organizations::manage_organizations',
								'torch::manage_subscription',
								'torch::manage_project_types',
							]}
							checkAll={false}
						>
							<Link to={`/organization/${orgId}/organization-settings`}>
								<MenuTabItem
									text='Organization Settings'
									alert={unseenMessages.orgSettings}
									icon='fas fa-cog'
									active={path === `/organization/${orgId}/organization-settings`}
								/>
							</Link>
						</PermissionWrapper>

						{/* <Link to={`/organization/${orgId}/rfi`}>
							<MenuTabItem
								text='RFI'
								alert={unseenMessages.rfi}
								icon='fa fa-info'
								active={path === `/organization/${orgId}/rfi`}
							/>
						</Link> */}

					</div>

					{orgId && (
						<PermissionWrapper requiredPermissions={['torch::manage_projects']} checkAll={true}>
							<div className='right center-mobile'>
								<div
									className='project-btn full-width-mobile'
									onClick={() => {
										setCreateProjectIsOpen(true)
									}}
								>
									<p className='subheading text-white cursor'>
										<i className='fas fa-plus' />
										Create Project
									</p>
								</div>
							</div>
						</PermissionWrapper>
					)}
				</div>
			</header>
			<CreateProject
				isOpen={createProjectIsOpen}
				handleClose={() => {
					setCreateProjectIsOpen(false)
				}}
			/>
		</>
	)
}

export default OrganizationHeader
