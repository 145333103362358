import React, { useState, useEffect } from 'react'
import { webscraperService } from 'api'
import { Multiselect } from 'multiselect-react-dropdown'
import { Button } from 'components'

const AddNewInformationForm = (props) => {
  const { accounts, projectId, onClose } = props
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [selectedSources, setSelectedSources] = useState([])


  const handleAddInfo = () => {
    let body = { start_point: 'account', item_id: selectedAccounts}

    webscraperService.addNewInfo(body)
    .then(response => {
      onClose()
    })
    .catch(error => {
      console.log("It was not possible to add new information", error)
    })
  }

  const handleSelectedSource = (selected) => {
    let selected_sources = selected.map( e => e.id)
    setSelectedSources(selected_sources)
  }
  const handleSelectedAccounts = (selected) => {
    let selected_accounts = selected.map( e => e.id)
    setSelectedAccounts(selected_accounts)
  }

  const accounts_options = accounts && accounts.map(e => { 
    let name = e.proper_type + ' - ' + e.user_id.content
    return { name: name, id: e.id }} )

  const fake_source = [
    {name:'YouTube', id: 1},
  ]

  return (
    <>
      <div className=''>
        <h1 className="title is-dark mb-30">Add New Information</h1>
        {/* <form id='add-info' onSubmit={handleSubmit}> */}
          <div className="center-mobile">
            <div className='filters-multi my-20'>
            <p className="text is-light is-bold my-10">Accounts</p>

                <Multiselect
                  options={accounts_options}
                  displayValue="name"
                  showCheckbox={true}
                  avoidHighlightFirstOption={true}
                  placeholder="Select accounts"
                  hidePlaceholder={true}
                  name="selected_accounts"
                  onSelect={handleSelectedAccounts}
                  onRemove={handleSelectedAccounts}
                />
              </div>
            <div className='filters-multi my-20'>
            <p className="text is-light is-bold my-10">Sources</p>
                <Multiselect
                  options={fake_source}
                  displayValue="name"
                  showCheckbox={true}
                  avoidHighlightFirstOption={true}
                  placeholder="Select sources"
                  hidePlaceholder={true}
                  name="selected_sources"
                  onSelect={handleSelectedSource}
                  onRemove={handleSelectedSource}
                />
              </div>
              <div className='right center-mobile'>
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={onClose} />
                <Button text="Run scrapers" class="orange-btn mt-30 subheading is-white" type="button" onClick={handleAddInfo} />
            </div>
          </div>
        {/* </form> */}
      </div>
    </>
  )
}

export default AddNewInformationForm
