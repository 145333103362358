import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'

// Hooks
import { UserContext } from '../../hooks/UserContext'
import Button from '../Elements/Button'

//Api
import { organizationService } from '../../api'

// Components
import Modal from '../Elements/Modal'
import AddOrganizationForm from './AddOrganizationForm'

function Organizations(props) {
	const { currentUser, currentOrganizations, setCurrentOrganizations } = useContext(UserContext) // global context

	const [orgList, setOrgList] = useState([])
	const [isAddOpen, setIsAddOpen] = useState(false)
	const [userRole, setUserRole] = useState('')
	const profilePic = OrganizationLogo

	useEffect(() => {
		setUserRole(currentUser.role)
	}, [currentOrganizations, currentUser.role])

	useEffect(() => {
		organizationService.listByUser(currentUser.id)
		.then(res => {
			let organ = {}
			res.map(org => organ[org.id] = org)
			setCurrentOrganizations(organ)
			setOrgList(res)
		})
	
		return () => {
			setOrgList([])
		}
	}, [])
	

	let organizations
	organizations = orgList?.map((org) => (
		<tr className='table-rows flexer full-width' key={org.id}>
			<td className='text is-light flexer-1 left side-margin-0'>
				<img
					className='org-icon__picture'
					src={org.profile_pic ? org.profile_pic : profilePic}
					alt='Logo'
				/>
			</td>

			<td className='text is-light flexer-4 left side-margin-0 mt-5'>
				<Link to={`/organization/${org.id}`} className='text is-dark'>
					{org.name}
				</Link>
			</td>
			<td className='text is-light remaining-width left side-margin-0 mt-5'>{org.description}</td>
		</tr>
	))

	return (
		<div className='dashboard-user-section'>
			<div className='right my-20 '>
				<Button
					text={'Request Access to Organization'}
					class={'black-btn mx-10 '}
					icon={'fas fa-plus'}
					onClick={() => setIsAddOpen(true)}
				/>
			</div>
			<Modal
				isOpen={isAddOpen}
				handleClose={() => {
					setIsAddOpen(false)
				}}
			>
				<AddOrganizationForm onClose={() => setIsAddOpen(false)} />
			</Modal>

			<table className='table ' cellSpacing='0'>
				<thead>
					<tr className='table-header  flexer full-width mt-20'>
						<th className='text is-light flexer-5 left side-margin-0'>Organization</th>
						<th className='text is-light remaining-width left side-margin-0'>Description</th>
					</tr>
				</thead>
				<tbody>{organizations}</tbody>
			</table>
		</div>
	)
}
export default Organizations
