/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const timeService = {
  list,
  create,
  getSpendTime,
}


async function getSpendTime(org_id, project_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/time_entries/${project_id}/time_spent/`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return Math.round(res.seconds_spent)
    })
}

async function list(org_id, project_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/time_entries/${project_id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

async function create(orgId, body) {
  body = fixBody(body)
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }

  return fetch(`${config.apiUrl}/torch/organization/${orgId}/time_entries/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
