/* eslint-disable max-len */
import config from './config'
import { handleResponse } from './handle-response'
import { fixBody } from './helpers'

export const projectService = {
	create,
	update,
	retrieve,
	remove,
	list,
	listByUrl,
	search,
	updateAssigned,
	generate_report,
	claim
}

async function create (body, org_id) {
	const bodyFixed      = fixBody(body)
	const requestOptions = {
		method     : 'POST',
		credentials: 'include',
		headers    : { 'Content-Type': 'application/json' },
		body       : JSON.stringify(bodyFixed)
	}
	return fetch(`${ config.apiUrl }/torch/organization/${ org_id }/projects/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			// console.log('user', user)
			return user
		})
}

async function update (body, org_id, project_id) {
	const bodyFixed      = fixBody(body)
	const requestOptions = {
		method     : 'PATCH',
		credentials: 'include',
		body       : bodyFixed
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function updateAssigned (body, org_id, project_id) {

	const bodyFixed      = fixBody(body)
	const requestOptions = {
		method     : 'PATCH',
		credentials: 'include',
		headers    : { 'Content-Type': 'application/json' },
		body       : JSON.stringify(bodyFixed)
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function retrieve (project_id, org_id) {
	const requestOptions = {
		method     : 'GET',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function remove (org_id, project_id) {
	const requestOptions = {
		method     : 'DELETE',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function list (body, org_id, offset = 0, limit = 9) {
	const bodyFixed = fixBody(body)

	const requestOptions = {
		method     : 'POST',
		credentials: 'include',
		headers    : { 'Content-Type': 'application/json' },
		body       : JSON.stringify(bodyFixed)
	}
	return fetch(
		`${config.apiUrl}/torch/organization/${org_id}/projects/filters/?limit=${limit}&offset=${offset}`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function listByUrl (url, body) {
	const bodyFixed      = fixBody(body)
	const requestOptions = {
		method     : 'POST',
		credentials: 'include',
		headers    : { 'Content-Type': 'application/json' },
		body       : JSON.stringify(bodyFixed)
	}

	return fetch(url, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function search (query, org_id) {
	const requestOptions = {
		method     : 'GET',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/search/?q=${ query }`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function generate_report (org_id, project_id, file_type) {
	const urlencoded = new URLSearchParams()
	urlencoded.append('file_type', file_type)

	const requestOptions = {
		method     : 'POST',
		credentials: 'include',
		headers    : {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body       : urlencoded
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/generate_report/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

/**
 * claim
 * @param {Array} body user_id that is claiming the project
 * @param {string} org_id the organization ID
 * @param {string} project_id The project ID
 * @param {string} type add_collaborator or delete_collaborator
 * @returns
 '*/
async function claim (org_id, project_id, type = 'claim_project') {
	// const bodyFixed = fixBody(body)

	const requestOptions = {
		method     : 'POST',
		credentials: 'include',
		headers    : { 'Content-Type': 'application/json' }
	}

	return fetch(
		`${ config.apiUrl }/torch/organization/${ org_id }/projects/${ project_id }/${ type }/`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
