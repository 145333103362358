import React, { useState, useEffect } from 'react'

// API
import { subjectService, projectService, linkService } from '../../../api'

// Components
import Button from '../../Elements/Button'
import { Multiselect } from 'multiselect-react-dropdown'
import Dropdown from '../../Elements/Dropdown'
import InputField from '../../Elements/InputField'
import ReactFlagsSelect from 'react-flags-select'
import useOrgId from '../../../hooks/useOrgId'
import Tooltip from '../../Elements/Tooltip'

function AddLinkForm(props) {
  const [createNew, setNew] = useState(true)
  const [calling_code, setCallingCode] = useState("+1")
  const [options, setOptions] = useState([])
  const [linkedSubject, setLinkedSubject] = useState(null)
  const [projectType, setProjectType] = useState('PERSON')
  const orgId = useOrgId()

  useEffect(() => {
    subjectService.list(orgId)
      .then(response => {
        setOptions(response)
      })
      .catch(error => {
        console.log("Error loading subjects", error)
      })
  }, [orgId])

  const country_calling_codes = { "US": { "primary": "+1" } }
  function changeCountryCallingCode(value) {
    setCallingCode(country_calling_codes[value]['primary'])
  }

  const handleChange = (e) => {
    let selected_type = e.target.value
    setProjectType(selected_type)
  }

  function handleNewLink(e) {
    e.preventDefault()

    var form_element = document.getElementById('new-link')
    var form_data = new FormData(form_element)

    var body = { "alias": [], "phones": [] }

    form_data.forEach((value, key) => {
        if(value) {
            if (key.includes('phones')){
                value = calling_code + value
            }

            if(key.includes('__')) {
                key = key.split('__')
                var subelement = {}
                // subelement[key[1]] = value
                subelement[key[1]] = {content:value}
                body[key[0]].push(subelement)
            }
            else if (key === 'type') {
              body[key] = value
            }
            else{
                body[key] = {content:value}
            }
        }
    })

    subjectService.create(body, orgId)
      .then(response => {
        var projectBody = { "subject": response.id, "priority": "standard", "status": "TEMPORARY", "case_type": "LNK" }
        projectService.create(projectBody, orgId)
          .then(response => {
            var linkBody = { "relationship": body.relationship, "linked_subject": response.subject, "main_subject": props.mainSubject }
            linkService.create(linkBody, orgId)
              .then(response => {
                props.onComplete()
                props.onClose()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  let input_email = 
  <>
      <div className="flexer">
          <i className="form-icon fa fa-envelope"></i>
          <Tooltip content='example@example.com' direction='top' class='full-width'>
              <InputField class="full-width line-margin-xs" type='email' label='Email' name='email'/>
          </Tooltip>
      </div>
  </>

let input_website = 
  <>
      <div className="flexer">
          <i className="form-icon fa fa-globe"></i>
          <Tooltip content='http://www.example.com or https://www.example.com' direction='top' class='full-width'>
              <InputField class='full-width line-margin-xs' type='url' label='Website' name='website'/>
          </Tooltip>
      </div>
  </>

let input_phone = 
  <>
      <div className="flexer">
          <i className="form-icon fa fa-phone"></i>
          <ReactFlagsSelect selected={"US"} countries={["US"]} customLabels={country_calling_codes} onSelect={changeCountryCallingCode} />
          <Tooltip content='55555555' direction='top' class='full-width'>
              <InputField type="tel" class="full-width line-margin-xs" name="phones__phone_number"/>
          </Tooltip>
      </div>
  </>

let input_fields
if (projectType === 'PERSON') {
    input_fields =
    <>
        <div className="flexer mt-30">
            <i className="form-icon fas fa-user-alt"></i>
            <InputField class="full-width line-margin-xs" label="First name" name="first_name"/>
        </div>
        <div className="flexer">
            <i className="form-icon fas fa-user-alt"></i>
            <InputField class="full-width line-margin-xs" label="Middle name" name="middle_name"/>
        </div>
        <div className="flexer">
            <i className="form-icon fas fa-user-alt"></i>
            <InputField class="full-width line-margin-xs" label="Last name" name="last_name"/>
        </div>
        <div className="flexer">
            <i className="form-icon fas fa-user-alt"></i>
            <InputField class="full-width line-margin-xs" label="Alias" name="alias__first_name" />
        </div>
        { input_email}
        { input_phone }
        <div className="flexer">
            <i className="form-icon fa fa-globe"></i>
            <Tooltip content='http://www.example.com or https://www.example.com' direction='top' class='full-width'>
                <InputField class="full-width line-margin-xs" type="url" label="Profile URL" name="profile_page"/>
            </Tooltip>
        </div>
        {props.mainSubjectType === 'ORGANIZATION' && <input type="hidden" name="relationship" value={'key-personnel'} />}
        {props.mainSubjectType === 'EVENT' && <input type="hidden" name="relationship" value={'affiliate'} />}
        {props.mainSubjectType === 'PERSON' &&
        <Dropdown label="Relationship" class="line-margin-xs" name="relationship">
          <option value="" disabled>Select an option</option>
          <option value="mother">Mother</option>
          <option value="father">Father</option>
          <option value="relative">Relative</option>
          <option value="family-friend">Family Friend</option>
          <option value="associate">Associate</option>
          <option value="person-of-interest">Person of Interest</option>
        </Dropdown>}
    </>
} else if (projectType === 'ORGANIZATION') {
    input_fields =
    <>
        <div className="flexer mt-30">
            <i className="form-icon fa fa-building"></i>
            <InputField class="full-width line-margin-xs" label='Organization name' name="organization_name"/>
        </div>
        { input_email }
        { input_phone }
        { input_website }
        {props.mainSubjectType === 'PERSON' && <input type="hidden" name="relationship" value={'key-personnel'} />}
        {(props.mainSubjectType === 'EVENT' || props.mainSubjectType === 'ORGANIZATION') && <input type="hidden" name="relationship" value={'associate'} />}
    </>
} else if (projectType === 'EVENT'){
    input_fields =
    <>
        <div className="flexer mt-30">
            <i className="form-icon fa fa-calendar-check-o"></i>
            <InputField class="full-width line-margin-xs" label='Event name' name="event_name"/>
        </div>
        <div className="flexer mt-30">
            <i className="form-icon fas fa-user-alt"></i>
            <InputField class="full-width line-margin-xs" label='Organizer name' name="organizer_name"/>
        </div>
        { input_email }
        { input_phone }
        { input_website }
        {props.mainSubjectType === 'PERSON' && <input type="hidden" name="relationship" value={'affiliate'} />}
        {(props.mainSubjectType === 'EVENT' || props.mainSubjectType === 'ORGANIZATION') && <input type="hidden" name="relationship" value={'associate'} />}

    </>
}

  var link_form
  if (createNew) {
    link_form =
    <form id="new-link" onSubmit={handleNewLink}>
      <Dropdown name="type" label="Project Type" type="text" class="is-light line-margin-xs mb-30" onChange={handleChange}>
        <option value='PERSON'>Person</option>
        <option value='ORGANIZATION'>Organization</option>
        <option value='EVENT'>Event</option>
      </Dropdown>
      { input_fields }
      <div className="right center-mobile">
        <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose} />
        <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
      </div>
    </form>
  }
  else {

    function handleOldLink(e) {
      e.preventDefault()

      var form_element = document.getElementById('old-subject-form')
      var form_data = new FormData(form_element)

      var body = {}
      form_data.forEach((value, key) => {
        if (value) {
          if (key.includes('relationship')) {
            body[key] = { content: value}
          } else {
            body[key] = value
          }
        }
      })

      linkService.create(body, orgId)
        .then(response => {
          props.onComplete()
          props.onClose()
          form_element.reset()
        })
        .catch(error => {
          console.log("It was not possible to link subjects", error)
        })
    }

    let relationship 
    if (props.mainSubjectType === 'EVENT') {
      relationship = 
        <div className='my-20'>
          {(linkedSubject && linkedSubject.type === 'PERSON') &&
          <>
            <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Affiliate</p>
            <input type="hidden" name="relationship" value={'affiliate'} />
          </>}
          {((linkedSubject && linkedSubject.type === 'EVENT' )|| (linkedSubject && linkedSubject.type === 'ORGANIZATION')) &&
          <>
          <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Associate</p>
            <input type="hidden" name="relationship" value={'associate'} />
          </>}
          
        </div>
    }
    if (props.mainSubjectType === 'ORGANIZATION') {
      relationship = 
        <div className='my-20'>
          {(linkedSubject && linkedSubject.type === 'PERSON') ?
            <>
              <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Key Personnel</p>
              <input type="hidden" name="relationship" value={'key-personnel'} /></>
          : ((linkedSubject && linkedSubject.type === 'EVENT') || (linkedSubject && linkedSubject.type === 'ORGANIZATION')) ?
            <>
              <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Associate</p>
              <input type="hidden" name="relationship" value={'associate'} />
            </>
          : null
          }
        </div>
    }
    if (props.mainSubjectType === 'PERSON') {
      relationship = 
        <div className='my-20'>
          {(linkedSubject && linkedSubject.type === 'ORGANIZATION') ?
            <>
              <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Key Personnel</p>
              <input type="hidden" name="relationship" value={'key-personnel'} />
            </>
            : (linkedSubject && linkedSubject.type === 'EVENT') ?
              <>
                <p className="subtitle is-dark line-margin-0"><span className='text is-light line-margin-0 mr-10'>Relationship:</span> Affiliate</p>
                <input type="hidden" name="relationship" value={'affiliate'} />
              </>
            : (linkedSubject && linkedSubject.type === 'PERSON') ?
              <Dropdown label="Relationship" name="relationship">
                <option value="" disabled>Select an option</option>
                <option value="mother">Mother</option>
                <option value="father">Father</option>
                <option value="relative">Relative</option>
                <option value="family-friend">Family Friend</option>
                <option value="associate">Associate</option>
                <option value="person-of-interest">Person of Interest</option>
              </Dropdown>
            : null
          }
        </div>
    }

    let linkable_subjects = options && options
    .filter(e => e.id !== props.mainSubject)

    link_form =
    <form id="old-subject-form" onSubmit={handleOldLink}>
      <Multiselect 
        // options={options} 
        options={linkable_subjects}
        avoidHighlightFirstOption={true}
        displayValue="display_name" 
        selectionLimit={1} 
        placeholder="Select a subject" 
        name="linked_subject" 
        onSelect={(selected) => { setLinkedSubject({id:selected[0]['id'], type: selected[0]['type']}) }} 
        onRemove={() => { setLinkedSubject(null) }} 
      />
      { relationship }
        <input type="hidden" name="main_subject" value={props.mainSubject} />
        <input type="hidden" name="linked_subject" value={linkedSubject && linkedSubject.id} />
        <div className="right my-30 center-mobile">
          <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose} />
          <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
        </div>
      </form>
  }

  return (
    <React.Fragment>
      <div className="flexer flexer-spaced">
        <h1 className="title is-dark mb-30">Add Link</h1>
        {createNew ? 
        <p className="link right cursor" onClick={() => { setNew(false) }}><i className="fa fa-plus is-orange mx-10"></i>Add existing link</p> 
        : <p className="link right cursor" onClick={() => { setNew(true) }}><i className="fa fa-plus is-orange mx-10"></i>Add new link</p>}
      </div>
      {link_form}
    </React.Fragment>
  )
}

export default AddLinkForm
