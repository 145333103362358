import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineRightCircle, AiOutlineUpCircle } from 'react-icons/ai'
import { TiUser } from 'react-icons/ti'
import { IconButton, FormControlLabel, Switch } from '@mui/material'
import { memberService, accessService } from 'api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import Button from '../Elements/Button'

const defaultRoles = [
	{
		name: 'Project manager',
		value: 'CASE_MANAGER',
	},
	{
		name: 'Analyst',
		value: 'ANALYST',
	},
]

const OrganizationMembersRow = (props) => {
	const { member, orgId, currentRole, canDrop, setToDelete, accessList, onUpdate } = props

	const [errors, setErrors] = useState({})
	const [showPermissions, setShowPermissions] = useState(false)
	const [roles, setRoles] = useState(defaultRoles)
	const [access, setAccess] = useState([])
	const [data, setData] = useState({})
	const [hasChanged, setHasChanged] = useState(false)
	const dataLoaded = useRef(false)

	useEffect(() => {
		if (currentRole === 'ADMIN' || currentRole === 'SUPER_ADMIN') {
			setRoles([...defaultRoles, { name: 'Admin', value: 'ADMIN' }])
		}

		if (currentRole === 'CASE_MANAGER' && member.role === 'ADMIN') {
			setRoles([{ name: 'Admin', value: 'ADMIN' }])
		}

		// Set initial permissions
		setData(member?.permission_policy?.policy)
	}, [member, currentRole])

	useEffect(() => {
		if (accessList) {
			setAccess(accessList)
		}
	}, [accessList])

	// When execute the endpoint to update the permissions
	useEffect(() => {
		if (dataLoaded.current && showPermissions) {
			// accessService.update({ policy: data }, orgId, member.id)   ////
		} else if (showPermissions) {
			dataLoaded.current = true
		}
	}, [data, showPermissions])

	const handleAccess = (e) => {
		const { value, checked } = e.target
		let policy = { [value]: { allow: [], deny: [] } }

		if (checked) {
			policy[value].allow = '*'
			policy[value].deny = []
		} else {
			policy[value].allow = []
			policy[value].deny = '*'
		}
		setHasChanged(true)
		setData((prev) => ({ ...prev, ...policy }))
	}

	const handleConfirm = () => {
		accessService.update({ policy: data }, orgId, member.id)
		.then( res => {
			setShowPermissions(false)
			setHasChanged(false)
			onUpdate()
		})
	}

	function handleChange(e) {
		var member_id = e.target.getAttribute('data-user')
		var role = e.target.value
		var body = { role }

		memberService
			.update(body, member_id, orgId)
			.then((response) => {
				onUpdate()
			})
			.catch((error) => {
				console.error('Unable to update role', error)
				if ('detail' in error) {
					setErrors({ ...errors, [member_id]: error.detail })
				}
			})
	}

	const handleShowPermissions = () => {
		showPermissions && setHasChanged(false)
		setShowPermissions(!showPermissions)
	}

	return (
		<>
		<tr>
			<td colSpan={5} className={showPermissions ? 'wcc-info wcc-open' : 'wcc-info'}>
				<table className='table wcc-tablerow'>
					<tbody>
						<tr>
							<td className='wcc-tdicons is-light-svg'>
								<IconButton
									aria-label='details'
									onClick={handleShowPermissions}
								>
									{showPermissions ? <AiOutlineUpCircle /> : <AiOutlineRightCircle />}
								</IconButton>
							</td>

							<td className='wcc-tdname'>
								<span className='wcc-gimg'>
									{member?.profile?.profile_pic ? (
										<img src={member?.profile?.profile_pic} />
									) : (
										<TiUser />
									)}
							 
								</span>
								<div className='wcc-name'>
									<Link to={`/profile/${member.user.cognito_id}/${orgId}`} className='text is-bold'>
										{member.user.public_name}
									</Link>

									<select
										data-user={member.id}
										className='text is-light no-border'
										value={member.role}
										onChange={handleChange}
									>
										{roles.map((opt) => (
											<option key={opt.value} value={opt.value}>
												{opt.name}
											</option>
										))}
									</select>
								</div>

								{member.id in errors && <p className='text error-message'>{errors[member.id]}</p>}
							</td>

							<td className='text is-light'>{member.user.email}</td>

							<td className='text is-light' />

							<td className='text right wcc-tdicons'>
								{canDrop && (
									<FontAwesomeIcon
										onClick={() => setToDelete(member)}
										className={'is-light cursor'}
										icon={faTrashCan}
									/>
								)}
							</td>
						</tr>

						{showPermissions && access && (
							<tr className='wcc-permissions'>
								<td colSpan={5}>
									{access.length &&
										access.map((val) => {
											const { name, action } = val
											const checked = member?.permission_policy?.policy[action]?.allow === '*'
											return (
												<FormControlLabel
													key={action}
													control={
														<Switch
															defaultChecked={checked}
															inputProps={{ value: action }}
															onChange={(e) => {
																handleAccess(e)
															}}
															size='small'
														/>
													}
													label={name}
												/>
											)
										})}
								</td>
							</tr>
						)}
					</tbody>
				</table>
						{	showPermissions && hasChanged ?
							(<div className='save-btn'>
							<Button text="Save Changes" class=" orange-btn subheading-light" type="button" onClick={handleConfirm} />
						  </div>) 
							: null}
			</td>
		</tr>
							</>
	)
}

export default OrganizationMembersRow
