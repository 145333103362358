import styles from './sourceCategoryItemSkt.module.sass'

const SourceCategoryItemSkt = ({}) => (
	<article className={styles.skeleton}>
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
	</article>
)
export default SourceCategoryItemSkt
