import React from 'react'

// import AccountCard from './AccountCard'
import WebscraperCard from './WebscraperCard'

const WebscraperDashboard = (props) => {
  const { results, onRefresh } = props

  let display_cards = results && results.map( e =>
    <div className="my-20" key={ e.id }>
      <WebscraperCard data={ e } onRefresh={ onRefresh }/>
    </div>,
  )

  return (
    <>
      <div>
        {display_cards}
      </div>
    </>
  )
}

export default WebscraperDashboard
