import { useState, useEffect, useRef } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
// Components
import ChartCard from '../../components/Analytics/ChartCard'
import TableCard from '../../components/Analytics/TableCard'
import useOrgId from '../../hooks/useOrgId'
import SearchBar from '../../components/Elements/SearchBar'
import { PermissionWrapper } from 'components'

//API
import { analyticsService, projectTypesService, memberService, projectService } from '../../api'

function AnalyticsContent() {
	const [projectTypes, setProjectTypes] = useState([])
	const [analystsTable, setAnalystsTable] = useState(null)
	const [projectsStatus, setProjectsStatus] = useState(null)
	const [daysCategory, setDaysCategory] = useState(null)
	const [projectsActivity, setProjectsActivity] = useState(null)
	const [monthlyProjects, setMonthlyProjects] = useState(null)
	const [weeklyProjects, setWeeklyProjects] = useState(null)
	const [membersSearch, setMembersSearch] = useState([])
	const [projectSearch, setProjectSearch] = useState([])

	const [selectedTypes, setSelectedTypes] = useState([])
	const [selectedStatus, setSelectedStatus] = useState([])
	const [selectedProjects, setSelectedProjects] = useState({})
	const [selectedAnalysts, setSelectedAnalysts] = useState({})
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const [loading, setLoading] = useState(false)
	const [loadingProjects, setLoadingProjects] = useState(false)
	const [showResultAnalyst, setShowResultAnalyst] = useState(false)
	const [showResultProject, setShowResultProject] = useState(false)

	const [isFilterActive, setIsFilterActive] = useState(false)

	const orgId = useOrgId()

	const multiselectRef1 = useRef(null)
	const multiselectRef2 = useRef(null)

	if (multiselectRef1.current && !isFilterActive) {
		multiselectRef1.current.resetSelectedValues()
	}

	if (multiselectRef2.current && !isFilterActive) {
		multiselectRef2.current.resetSelectedValues()
	}

	function filterToUrl(filters) {
		let params = []
		for (const [key, value] of Object.entries(filters)) {
			if (Array.isArray(value)) {
				for (const newValue of value) {
					params.push([key, newValue])
				}
			} else if (value) {
				params.push([key, value])
			}
		}

		return new URLSearchParams(params).toString()
	}

	// Load project types
	useEffect(() => {
		projectTypesService
			.list(orgId)
			.then(({ results }) => {
				setProjectTypes(results)
			})
			.catch((error) => {
				console.error('Unable to get project types', error)
			})
	}, [orgId])

	// Load analytics
	useEffect(() => {
		const analysts = Object.keys(selectedAnalysts)
		const id_project = Object.keys(selectedProjects)
		let filters = {
			project: id_project.length ? id_project : null,
			analyst: analysts.length ? analysts : null,
			status: selectedStatus,
			type: selectedTypes,
			from_date: startDate,
			to_date: endDate,
		}

		filters = filterToUrl(filters)
		analyticsService
			.general_metrics(orgId, filters)
			.then((response) => {
				var chart1 = {
					labels: response.cases_by_status.labels,
					datasets: [
						{
							data: response.cases_by_status.data,
							backgroundColor: ['#F9C21B', '#DB9718', '#F19126', '#DB6418', '#F9511B', '#E3620E'],
						},
					],
				}
				var chart2 = {
					labels: response.days_per_category.labels,
					datasets: [{ data: response.days_per_category.data, backgroundColor: ['#F19126'] }],
				}
				var chart3 = {
					labels: response.cases_with_activity_by_status.labels,
					datasets: [
						{ data: response.cases_with_activity_by_status.data, backgroundColor: ['#F19126'] },
					],
				}
				var chart4 = {
					labels: response.monthly_cases.labels,
					datasets: [{ data: response.monthly_cases.data, backgroundColor: ['#F19126'] }],
				}
				var chart5 = {
					labels: response.weekly_cases.labels,
					datasets: [{ data: response.weekly_cases.data, backgroundColor: ['#F19126'] }],
				}
				setProjectsStatus(chart1)
				setDaysCategory(chart2)
				setProjectsActivity(chart3)
				setMonthlyProjects(chart4)
				setWeeklyProjects(chart5)
			})
			.catch((error) => {
				console.error('Unable to get general metrics', error)
			})

		analyticsService
			.analyst_metrics(orgId, filters)
			.then((response) => {
				setAnalystsTable(response)
			})
			.catch((error) => {
				console.error('Unable to get analytics metrics', error)
			})
	}, [
		selectedTypes,
		selectedStatus,
		selectedAnalysts,
		selectedProjects,
		startDate,
		endDate,
		isFilterActive,
		orgId,
	])

	const project_status = [
		{ display_name: 'Available', id: 1, value: 'AVAILABLE' },
		{ display_name: 'Discover', id: 2, value: 'DISCOVER' },
		{ display_name: 'Develop', id: 2, value: 'DEVELOP' },
		{ display_name: 'Awaiting Review', id: 3, value: 'IN_REVIEW' },
		{ display_name: 'Monitoring', id: 4, value: 'MONITORING' },
		{ display_name: 'Closed', id: 5, value: 'CLOSE' },
		{ display_name: 'Unpublished', id: 6, value: 'UNPUBLISHED' },
	]

	// Multiselect function
	function handleSelectType(selected) {
		let type = selected.map((e) => e.id)
		setSelectedTypes(type)
		setIsFilterActive(true)
		if (selectedTypes === []) {
			setSelectedTypes(null)
		}
	}

	function handleSelectStatus(selected) {
		let type = selected.map((e) => e.value)
		setSelectedStatus(type)
		setIsFilterActive(true)

		if (selectedTypes === []) {
			setSelectedStatus(null)
		}
	}

	// Search by Analyst
	function handleAnalystSearch(e) {
		if (e.length >= 2) {
			setLoading(true)
			memberService
				.search(e, orgId)
				.then((response) => {
					setMembersSearch(response)
				})
				.then(() => {
					setShowResultAnalyst(true)
					setLoading(false)
					setIsFilterActive(true)
				})
				.catch((error) => {
					console.error('Analyst not found', error)
				})
		} else {
			setMembersSearch([])
			setLoading(false)
			setShowResultAnalyst(false)
		}
	}

	function handleClick(analystEmail, analystName, analystId) {
		setSelectedAnalysts({
			...selectedAnalysts,
			[analystId]: { name: analystName, email: analystEmail },
		})
		setShowResultAnalyst(false)

		var show_analyst = document.getElementById('analyst')
		show_analyst.classList.toggle('hidden')
		show_analyst.classList.toggle('shown')
	}

	function handleDeleteAnalyst(analyst) {
		let new_analysts = selectedAnalysts
		delete new_analysts[analyst]
		setSelectedAnalysts({ ...new_analysts })
	}

	var analysts = []
	for (const analyst of membersSearch) {
		analysts.push(
			<div
				className='flexer flexer-vcenter simple-profile__sections'
				onClick={() =>
					handleClick(analyst.user.email, analyst.user.public_name, analyst.user.cognito_id)
				}
				key={`analyst_${analyst.id}`}
			>
				<p className='heading is-light line-margin-0 ml-20 cursor'>
					{analyst.user.public_name}{' '}
					<span className='text is-light line-margin-0'>{analyst.user.email} </span>{' '}
				</p>
			</div>
		)
	}

	var selected_analyst =
		selectedAnalysts &&
		Object.entries(selectedAnalysts).map((e) => (
			<div className='simple-profile__sections'>
				<p className='text is-light is-bold' key={e[0]}>
					{' '}
					{e[1].name}{' '}
					<i className='is-light fa fa-close cursor' onClick={() => handleDeleteAnalyst(e[0])} />
				</p>
				<p className='text is-light mr-10 line-margin-0'>{e[1].email} </p>
			</div>
		))

	// Search by project
	function handleProjectSearch(e) {
		if (e.length >= 2) {
			projectService
				.search(e, orgId)
				.then((response) => {
					setProjectSearch(response)
				})
				.then(() => {
					setLoadingProjects(false)
					setShowResultProject(true)
					setIsFilterActive(true)
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			setProjectSearch([])
			setLoadingProjects(false)
			setShowResultProject(false)
		}
	}

	function handleClickProject(project_id, project_caseId, subject_name) {
		setSelectedProjects({
			...selectedProjects,
			[project_id]: [project_caseId, subject_name],
		})
		setShowResultProject(false)

		var show_project = document.getElementById('project')
		show_project.classList.toggle('hidden')
		show_project.classList.toggle('shown')
	}

	function handleDeleteProject(project) {
		let new_project = selectedProjects
		delete new_project[project]
		setSelectedProjects({ ...new_project })
	}

	var projects_ = []
	for (const project_ of projectSearch) {
		projects_.push(
			<div
				className='flexer flexer-vcenter simple-profile__sections'
				onClick={() =>
					handleClickProject(project_.id, project_.case_id, project_.subject.display_name)
				}
				key={`project_${project_.id}`}
			>
				<p className='heading is-light line-margin-0 ml-20 cursor'>
					{`${project_.case_id}`}{' '}
					<span className='text is-light line-margin-0'>{project_.subject.display_name} </span>
				</p>
			</div>
		)
	}

	var selected_project =
		selectedProjects &&
		Object.entries(selectedProjects).map((e) => (
			<div className='simple-profile__sections'>
				<p className='text is-light is-bold' key={e[0]}>
					{' '}
					{e[1][0]}{' '}
					<i className='is-light fa fa-close cursor' onClick={() => handleDeleteProject(e[0])} />
				</p>
				<p className='text is-light mr-10 line-margin-0'>{e[1][1]} </p>
			</div>
		))

	// Reset
	var show_reset = false
	if (
		Object.keys(selectedAnalysts).length ||
		Object.keys(selectedProjects).length ||
		selectedTypes.length !== 0 ||
		selectedStatus.length !== 0 ||
		startDate ||
		endDate
	) {
		show_reset = true
	}

	function handleReset() {
		setSelectedProjects({})
		setSelectedAnalysts({})
		setSelectedTypes([])
		setSelectedStatus([])
		setStartDate('')
		setEndDate('')
		setIsFilterActive(false)
	}

	return (
		<div className='flexer max-screen__bottom  flexer-movile'>
			<div className='flexer-2 project-sides '>
				<div className='flexer flexer-spaced'>
					<p className='title-3 is-light ml-20 mt-30'>Filters</p>
					{show_reset ? (
						<div className='cursor' onClick={(e) => handleReset(e)}>
							<p className='text is-light mt-30 mr-20'>
								{' '}
								Reset Filters
								<i className='is-light fa fa-trash cursor mx-10' />
							</p>
						</div>
					) : null}
				</div>
				<div className='simple-profile__sections'>
					<p className='text is-light left'>Project Types</p>
					<div className='filters-multi'>
						<Multiselect
							options={projectTypes ?? []}
							displayValue='name'
							showCheckbox={true}
							avoidHighlightFirstOption={true}
							placeholder='Select projects types'
							hidePlaceholder={true}
							name='selected_types'
							onSelect={handleSelectType}
							onRemove={handleSelectType}
							ref={multiselectRef1}
							isFilterActive={isFilterActive}
						/>
					</div>
				</div>
				<div className='simple-profile__sections'>
					<p className='text is-light left'>Project Status</p>
					<div className='filters-multi'>
						<Multiselect
							options={project_status}
							displayValue='display_name'
							showCheckbox={true}
							avoidHighlightFirstOption={true}
							placeholder='Select project status'
							hidePlaceholder={true}
							name='selected_status'
							onSelect={handleSelectStatus}
							onRemove={handleSelectStatus}
							ref={multiselectRef2}
							isFilterActive={isFilterActive}
						/>
					</div>
				</div>
				<div className='simple-profile__sections'>
					<PermissionWrapper requiredPermissions={'torch::manage_analytics'}>
						<p className='text is-light left'>By Analyst</p>
						<SearchBar onChange={handleAnalystSearch} placeholder='Enter a name...' />
						{showResultAnalyst ? (
							<div className='max-screen__bottom shown' id='analyst'>
								{loading ? 'Loading analysts...' : analysts}
							</div>
						) : null}
						{selected_analyst.length ? (
							<div className='simple-profile__sections my-10'>
								<p className='text is-light is-bold left'>Analysts selected</p>
								{selected_analyst}
							</div>
						) : null}
					</PermissionWrapper>
					<p className='text  is-light left'>By Project</p>
					<SearchBar onChange={handleProjectSearch} placeholder='Enter a Project ID...' />
					{showResultProject ? (
						<div className='max-screen__bottom' id='project'>
							{loadingProjects ? 'Loading projects...' : projects_}
						</div>
					) : null}
					{selected_project.length ? (
						<div className='simple-profile__sections'>
							<p className='text is-light is-bold left'>Projects selected</p>
							{selected_project}
						</div>
					) : null}
				</div>
				<div className='simple-profile__sections'>
					<p className='text  is-light left'>By Date</p>
					<div className='input-field'>
						<label className='input-field__label'>From:</label>
						<input
							className='input-field__input'
							value={startDate}
							type='date'
							name='start'
							label='From'
							onChange={(e) => setStartDate(e.target.value)}
						/>
					</div>
					<div className='input-field'>
						<label className='input-field__label mt-10'>To:</label>
						<input
							className='input-field__input'
							value={endDate}
							type='date'
							name='end'
							label='To'
							onChange={(e) => setEndDate(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className='remaining-width center'>
				<div className='row'>
					<div className='dashboard-wrapper col-12'>
						<h1 className='title-2 left is-dark'>Analytics</h1>
						<div className='flexer flexer-spaced flexer-mobile'>
							<div className='dashboard-wrapper__card flexer-5'>
								{projectsStatus ? (
									<ChartCard
										data={projectsStatus}
										type='doughnut'
										title='Number of projects by status'
									/>
								) : null}
							</div>
							<div className='dashboard-wrapper__card flexer-7'>
								{daysCategory ? (
									<ChartCard
										data={daysCategory}
										type='bar'
										title='Average Time (in days) for projects to sit in each status category'
										legendDisplay={false}
									/>
								) : null}
							</div>
						</div>
						<div className='flexer flexer-spaced flexer-mobile'>
							<div className='dashboard-wrapper__card flexer-4'>
								{projectsActivity ? (
									<ChartCard
										data={projectsActivity}
										type='bar'
										title='Average number of projects receiving activity by status'
										legendDisplay={false}
									/>
								) : null}
							</div>
							<div className='dashboard-wrapper__card flexer-4'>
								{monthlyProjects ? (
									<ChartCard
										data={monthlyProjects}
										type='bar'
										title='Average number of new projects and closed projects by week'
										legendDisplay={false}
									/>
								) : null}
							</div>
							<div className='dashboard-wrapper__card flexer-4'>
								{weeklyProjects ? (
									<ChartCard
										data={weeklyProjects}
										type='bar'
										title='Average number of new projects and closed projects by month'
										legendDisplay={false}
									/>
								) : null}
							</div>
						</div>
						<div className='dashboard-wrapper__card'>
							<TableCard title='Metrics by Analyst' type='Analyst' analysts={analystsTable} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AnalyticsContent
