import { groupOrganization } from 'api'
import axios from 'axios'
import { useOrgId } from 'hooks'
import React, { useEffect, useState } from 'react'
import { BiTrashAlt } from 'react-icons/bi'
import { MdGroups, MdOutlineClose, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { TiGroup } from 'react-icons/ti'
import { async } from 'rxjs'

import './grouplist.scss'

const GroupList = ({ changeData, showSelector, defaultData }) => {
	const orgId = useOrgId()

	const [data, setData] = useState([])
	const [show, setShow] = useState(false)
	const [keyword, setKeyword] = useState('')
	const [remoteData, setRemoteData] = useState([])

	const handleChange = (e) => {
		const { value, checked } = e.target

		if (checked) {
			// Take the completed object from remote data
			const found = remoteData.filter((obj) => obj.id_group === value)[0]
			setData((prev) => [...prev, found])
		} else {
			handleRemove(value)
		}
	}

	const hdlSearch = async () => {
		try {
			const { data } = await axios(
				`/chat/organization/${orgId}/chat-channels/search_groups/?name=${keyword}`
			)

			console.log(data)
			return data
		} catch (err) {
			console.log(err)
		}
	}

	const handleRemove = (id_group) => {
		setData((prev) => prev.filter((prev) => prev.id_group !== id_group))
	}

	useEffect(() => {
		if (keyword.length > 1) {
			hdlSearch().then((resp) => setRemoteData(resp))
		}

		// groupOrganization.search(keyword.toLowerCase(), orgId).then((res) => {
		// 	if (keyword.length === 0) {
		// 		setRemoteData(res.slice(0, 4))
		// 	} else {
		// 		setRemoteData(res)
		// 	}
		// })

		if (!showSelector) {
			setShow(true)
		}
	}, [keyword])

	// Suport initial data to keep checked on search groups
	useEffect(() => {
		setData(defaultData)
	}, [defaultData])

	// Set data for parent component, that going to send the request
	useEffect(() => {
		changeData(data)
	}, [data])

	return (
		<div className='wcc-groups'>
			{showSelector && (
				<div className='wcc-gbutton'>
					<MdGroups className='wcc-gicon' />
					<button type='button' onClick={() => setShow(!show)}>
						Choose one or more groups
						{show ? <MdOutlineClose /> : <MdOutlineKeyboardArrowDown />}
					</button>
				</div>
			)}

			{show && (
				<div className='wcc-gresult'>
					<input
						type='text'
						onChange={(e) => setKeyword(e.target.value)}
						value={keyword}
						className='wcc-search'
						placeholder='Search by keywords...'
					/>

					{remoteData?.length ? (
						<ul className='wcc-remote'>
							{remoteData.map(({ id_group, group_pic, name }) => {
								//  { id, name, id_group, image }
								// const  = val
								const ide = `group-check-${id_group}-${showSelector}`
								const checked = data.find((obj) => obj.id_group === id_group)

								return (
									<li key={id_group}>
										<label htmlFor={ide} className='wcc-gname'>
											<span className='wcc-gimg'>
												{group_pic ? <img src={group_pic} alt={name} /> : <TiGroup />}
											</span>
											<span className='wcc-gname'>{name}</span>
										</label>

										<input
											id={ide}
											type='checkbox'
											value={id_group}
											onClick={handleChange}
											defaultChecked={checked}
											maxLength={20}
										/>
									</li>
								)
							})}
						</ul>
					) : (
						<div className='wcc-noresult'>No result found</div>
					)}
				</div>
			)}

			{showSelector && (
				<ul className='wcc-selected'>
					{data.map((val, idx) => {
						const { id, name, image, id_group } = val
						const idr = `group-${idx}-${id}`

						return (
							<li key={idr}>
								<label htmlFor={idr} className='wcc-gname'>
									<span className='wcc-gimg'>
										{image ? <img src={image} alt={name} /> : <TiGroup />}
									</span>
									<span>{name}</span>
								</label>

								<button className='wcc-gremove' onClick={() => handleRemove(id_group)}>
									<BiTrashAlt />
								</button>
							</li>
						)
					})}
				</ul>
			)}
		</div>
	)
}

GroupList.defaultProps = {
	showSelector: true,
	defaultData: [],
}

export default GroupList
