/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const accessService = {
  list,
  update,
  listMemberAccess,
}


async function list() {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/access_management/actions/`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

async function update(body, org_id, member_id) {
  body = fixBody(body)

  const requestOptions = {
    method: 'PATCH',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }

  return fetch(`${config.apiUrl}/organization/${org_id}/member_permissions/${member_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function listMemberAccess(org_id, member_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/member_permissions/${member_id}/`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

