import { MdGroups, MdOutlineClose, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { BiTrashAlt } from 'react-icons/bi'
import { useEffect, useRef, useState } from 'react'
import { TiUser } from 'react-icons/ti'

import 'components/CreateProject/Forms/GroupList/grouplist.scss'
import defaultImage from './group-icon.svg'
import { memberService } from 'api'
import { useOrgId } from 'hooks'

const UserList = ({ changeData, showSelector, defaultData }) => {
	const [data, setData] = useState([])
	const [memberList, setMemberList] = useState([])
	const [show, setShow] = useState(false)
	const [search, setSearch] = useState('')
	const orgId = useOrgId()

	useEffect(() => {
		if (search?.length >= 1) {
			memberService.search(search, orgId).then((resp) => {
				const members = resp.map(({ user }) => {
					return user
				})
				setMemberList(members)
			})
		} else {
			memberService.list(orgId, 0, 999).then(({ results }) => {
				const members = results.map(({ user }) => {
					return user
				})

				setMemberList(members)
			})
		}

		if (!showSelector) {
			setShow(true)
		}
	}, [search])

	// Suport initial data to keep checked on search groups
	useEffect(() => {
		setData(defaultData)
	}, [defaultData])

	// Set data for parent component, that going to send the request
	// useEffect(() => {
	// 	if (initRender.current) {
	// 		initRender.current = false
	// 	} else {
	// 	}
	// }, [onCheck, !onCheck])

	const handleChange = ({ target }) => {
		const { value, checked } = target

		if (checked) {
			// Take the completed object from remote data
			const found = memberList.filter((obj) => {
				return obj.cognito_id === value
			})

			const newData = [...found, ...data]
			setData(newData)
			changeData(newData)
		} else {
			handleRemove(value)
		}
	}

	const handleRemove = (user_id) => {
		const newData = data.filter((obj) => obj.cognito_id !== user_id)
		setData(newData)
		changeData(newData)
	}

	return (
		<div className='wcc-groups'>
			{showSelector && (
				<div className='wcc-gbutton'>
					<MdGroups className='wcc-gicon' />
					<button type='button' onClick={() => setShow(!show)}>
						Choose one or few users
						{show ? <MdOutlineClose /> : <MdOutlineKeyboardArrowDown />}
					</button>
				</div>
			)}

			{show && (
				<div className='wcc-gresult'>
					<input
						type='text'
						onChange={({ target }) => setSearch(target.value)}
						value={search}
						className='wcc-search'
						placeholder='Search by keywords...'
					/>

					{memberList?.length ? (
						<ul className='wcc-remote'>
							{memberList?.map((val) => {
								const { public_name, cognito_id, profile } = val
								const ide = `group-check-${cognito_id}`
								const checked = data.find((obj) => obj.cognito_id === cognito_id)

								return (
									<li key={cognito_id}>
										<label htmlFor={ide} className='wcc-gname'>
											<span className='wcc-gimg'>
												{profile?.profile_pic ? (
													<img src={profile.profile_pic} alt={public_name} />
												) : (
													<TiUser />
												)}
											</span>
											<span className='wcc-gname'>{public_name}</span>
										</label>
										<input
											id={ide}
											type='checkbox'
											value={cognito_id}
											onClick={handleChange}
											defaultChecked={checked}
											maxLength={20}
										/>
									</li>
								)
							})}
						</ul>
					) : (
						<div className='wcc-noresult'>No result found</div>
					)}
				</div>
			)}

			{showSelector && (
				<ul className='wcc-selected'>
					{data.map((val, idx) => {
						const { id, name, image, id_group } = val
						const idr = `group-${idx}-${id}`
						return (
							<li key={idr}>
								<label htmlFor={idr} className='wcc-gname'>
									<span className='wcc-gimg'>
										<img src={image ?? defaultImage} alt={name} />
									</span>
									<span>{name}</span>
								</label>
								<button className='wcc-gremove' onClick={() => handleRemove(id_group)}>
									<BiTrashAlt />
								</button>
							</li>
						)
					})}
				</ul>
			)}
		</div>
	)
}

UserList.defaultProps = {
	showSelector: true,
	defaultData: [],
}

export default UserList
