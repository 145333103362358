import React from 'react'

function ExperienceItem(props) {
    return (
        <div className="experience-item">
            <p className="subtitle is-dark">{props.title}{props.emp_type ? <span className="subtitle is-dark mx-15">•</span> : null}{props.emp_type}</p>
            <p className="text is-light">{props.info1}<span className="text is-light mx-15">•</span>{props.info2}</p>
            <p className="subheading is-light">{props.text}</p>
        </div>
    )
}

export default ExperienceItem