import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const docsService = {
  create,
  remove,
}

async function create(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/docs/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function remove(org_id, doc_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/docs/${doc_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
