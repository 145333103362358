import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

//API
import { ticketService } from '../../api'

// Components
import { Button, MenuTabItem, Modal, Pagination } from 'components'
import CreateTicket from '../../components/Tickets/CreateTicket'

// Hooks
import { UserContext } from '../../hooks/UserContext'
import useWebSocket from 'hooks/useWebSocket'

const TicketsContent = () => {
	const { currentOrganizations, currentUser } = useContext(UserContext)

	const [isActive, setIsActive] = useState('all-tickets')
	const [openCreateTicket, setOpenCreateTicket] = useState(false)
	const [refresh, setRefresh] = useState(0)
	const [allTickets, setAllTickets] = useState([])

	//pagination
	const [paginator, setPaginator] = useState({})

	let user_organizations = Object.values(currentOrganizations).map((org) => {
		return { name: org.name, id: org.id }
	})

	const is_support = currentUser.role === 'SUPPORT' ? true : false

	useEffect(() => {
		if (isActive === 'Opened' || isActive === 'Closed') {
			ticketService
				.filters({ status: isActive }, paginator.offset, paginator.limit)
				.then((response) => {
					setAllTickets(response)
				})
				.catch((error) => {
					console.error('Unable to get tickets', error)
				})
		} else {
			ticketService
				.list(paginator.offset, paginator.limit)
				.then((response) => {
					setAllTickets(response)
				})
				.catch((error) => {
					console.error('Unable to get tickets', error)
				})
		}
	}, [refresh, paginator.offset, isActive])

	/* WEBSOCKET SECTION */
	const { WebSocketOpen, WebSocketClient } = useWebSocket('tickets')

	if (WebSocketOpen) {
		WebSocketClient.current.onmessage = (e) => {
			const data = JSON.parse(e.data)
			console.log(data, 'webS') ///////////////////
			if (data.type === 'NEW_TICKET') {
				setRefresh(refresh + 1)
			} else if (data.type === 'UPDATED_TICKET') {
				// handleNewMessage(data.payload.id)
				setRefresh(refresh + 1)
			}
		}
	}

	/* END WEBSOCKET SECTION */

	const handlePaginator = (e, page) => {
		e.preventDefault()

		if (page > 1) {
			setPaginator({ ...paginator, offset: paginator.limit * (page - 1) })
		} else {
			setPaginator({ ...paginator, offset: 0, showing: paginator.limit })
		}
	}

	const handleCloseCreateTicket = () => {
		setRefresh(refresh + 1)
		setOpenCreateTicket(false)
	}

	const handleNewMessage = (ticket_id) => {
		let body = is_support ? { unseen_messages_support: 1 } : { unseen_messages_user: 1 }
		ticketService
			.update(body, ticket_id)
			.then((response) => {
				console.log('Message updated')
			})
			.catch((error) => {
				console.error('Unable to update message', error)
			})
	}

	let tickets_table =
		allTickets?.count &&
		allTickets?.results.map((tkt) => {
			let issue_type
			if (tkt.type === 'ACCOUNT') {
				issue_type = 'Account Issue'
			}
			if (tkt.type === 'ORGANIZATION') {
				issue_type = 'Organization Issue'
			}
			if (tkt.type === 'ORGANIZATION_MEMBER') {
				issue_type = 'Organization Member Issue'
			}
			if (tkt.type === 'PROJECT') {
				issue_type = 'Organization Project Issue'
			}
			if (tkt.type === 'OTHER') {
				issue_type = 'Other'
			}

			let has_unread =
				(is_support && tkt.unseen_messages_support) || (!is_support && tkt.unseen_messages_user)
					? true
					: false

			return (
				<>
					<tr className='table-rows flexer' key={tkt.id}>
						<td className='text is-light flexer-3 left side-margin-0'>
							<Link to={`/tickets/detail/${tkt.id}`}>{tkt.title}</Link>
						</td>
						<td className='text is-light flexer-3 left side-margin-0'>{issue_type}</td>
						<td className='text is-light flexer-3 left side-margin-0'>
							{tkt?.ticket_messages[0]?.proper_created}
						</td>
						{tkt.status === 'CLOSED' && (
							<td className='text is-red flexer-2 left side-margin-0'>Closed</td>
						)}
						{tkt.status === 'OPENED' && (
							<td className='text is-green flexer-2 left side-margin-0'>Opened</td>
						)}

						<td className='text remaining-width right side-margin-0'>
							<Link to={`/tickets/detail/${tkt.id}`}>
								{has_unread ? (
									<i className='fa fa-envelope is-light mr-10 cursor'>
										<span className='title is-red is-bold  line-margin-0 '>•</span>
									</i>
								) : null}
							</Link>
						</td>
					</tr>
				</>
			)
		})

	return (
		<>
			<div className='tickets-dashboard'>
				<div className='tickets-dashboard__header'>
					<div className='flexer  flexer-vcenter center-mobile tickets-dashboard__border'>
						<div className='flexer mr-20'>
							<p className='title-3 is-bold is-dark line-margin-0'>My tickets</p>
						</div>
						<div className='flexer flexer-spaced remaining-width'>
							<div className='flexer'>
								<MenuTabItem
									text='All tickets'
									active={isActive === 'all-tickets'}
									onClick={() => {
										setIsActive('all-tickets')
									}}
								/>
								<MenuTabItem
									text='Opened tickets'
									active={isActive === 'Opened'}
									onClick={() => {
										setIsActive('Opened')
									}}
								/>
								<MenuTabItem
									text='Closed tickets'
									active={isActive === 'Closed'}
									onClick={() => {
										setIsActive('Closed')
									}}
								/>
							</div>
							<div className=''>
								<Button
									text={'Create Ticket'}
									class={'orange-btn'}
									icon={'fas fa-plus'}
									onClick={() => setOpenCreateTicket(true)}
								/>
							</div>
						</div>
					</div>
				</div>
				{allTickets.results?.length ? (
					<div className=''>
						<table className='table' cellSpacing='0'>
							<thead>
								<tr className='table-header flexer mt-15'>
									<th className='text is-dark is-bold flexer-3 left side-margin-0'>Ticket title</th>
									<th className='text is-dark is-bold flexer-3 left side-margin-0'>Issue type</th>
									<th className='text is-dark is-bold flexer-3 left side-margin-0'>Date</th>
									<th className='text is-dark is-bold flexer-2 left side-margin-0'>Status</th>
									<th className='text is-dark remaining-width left side-margin-0'></th>
								</tr>
							</thead>
							<tbody>{tickets_table}</tbody>
						</table>
						<Pagination
							paginator={paginator}
							setPages={setPaginator}
							data={allTickets}
							limit={10}
						/>
						<br />
					</div>
				) : (
					<div className='wcc-nodata'> No tickets found </div>
				)}
			</div>
			<Modal isOpen={openCreateTicket} handleClose={() => setOpenCreateTicket(false)}>
				<CreateTicket onClose={handleCloseCreateTicket} organizations={user_organizations} />
			</Modal>
		</>
	)
}

export default TicketsContent
