import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const resourceService = {
  create,
  list,
  listByUrl,
  remove,
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body: body }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/resource/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/resource/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function listByUrl(url) {
  // await checkToken()
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function remove(org_id, doc_id) {

  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/resource/${doc_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
