import { useState } from 'react'

// Components
import Button from '../Elements/Button'
import Dropdown from '../Elements/Dropdown'

// Hooks
import { useUserRole } from '../../hooks'
// API
import { applicationService } from '../../api/index'

function ApplicationForm(props) {
	const [submitting, setSubmitting] = useState(false)

	const currentRole = useUserRole()

	function handleSubmit(e) {
		e.preventDefault()
		setSubmitting(true)

		var form_element = document.getElementById('application-form')
		var form_data = new FormData(form_element)

		var body = {}
		form_data.forEach((value, key) => {
			if (value) {
				body[key] = value
			}
		})

		applicationService
			.accept(form_data, props.org, props.id)
			.then((response) => {
				props.onClose(true)
				setSubmitting(false)
			})
			.catch((error) => {
				if (Array.isArray(error)) {
					error = { non_field_errors: error[0] }
				}
				setSubmitting(false)
			})
	}

	return (
		<form id='application-form' onSubmit={handleSubmit}>
			<h1 className='title is-dark mb-30'>Accept Membership Request</h1>
			<p className='text is-light '>
				Please select a role to <span className='is-dark is-bold'> {props.name}</span>
			</p>

			<Dropdown label='' class='line-margin-xs' name='role'>
				<option value='' disabled>
					Select an option
				</option>
				{currentRole === 'ADMIN' || currentRole === 'SUPER_ADMIN' ? (
					<option value='ADMIN'>Admin</option>
				) : null}
				<option value='CASE_MANAGER'>Project Manager</option>
				<option value='ANALYST'>Analyst</option>
			</Dropdown>
			<div className='right center-mobile'>
				<Button
					text='Cancel'
					class='mt-30 mx-15 white-btn subheading is-dark'
					onClick={props.onClose}
				/>
				<Button
					text='Confirm Application'
					class='orange-btn mt-30 subheading is-white'
					type='submit'
					disabled={submitting}
				/>
			</div>
		</form>
	)
}

export default ApplicationForm
