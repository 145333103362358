import React from 'react'

function Dropdown(props) {
    var selectClass = props.selectClass ? props.selectClass : ''
    var className = props.class ? props.class : ''
    
    return (
        <div className={`${className} input-field`}>
            <label className="input-field__label" >{props.label}</label>
            <select className={`${selectClass} input-field__input`} name={props.name} onChange={props.onChange} required={props.required? "required" : false}>
                {props.children}
            </select>
            <p className="error-message">{props.error}</p>
        </div>
    )
}

export default Dropdown