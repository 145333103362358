import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

//API
import { organizationService, projectTypesService } from '../../api'

// Components
import OrganizationSettingsMenu from '../../components/Organization/OrganizationSettingsMenu'
import ProfileInfo from '../../components/Organization/ProfileInfo'
import ProjectTypes from '../../components/Organization/ProjectTypes/ProjectTypes'
import EditProfile from '../../components/Organization/EditProfile'
import OrganizationMembers from '../../components/Organization/OrganizationMembers'
import OrganizationPayment from '../../components/Organization/OrganizationPayment'
import OrganizationPlan from '../../components/Organization/OrganizationPlan'
import PendingVerification from '../../components/Organization/PendingVerification'
import PendingInvitation from '../../components/Organization/PendingInvitation'
import ReportTemplate from '../../components/Organization/ReportTemplate'
import SourceCategory from '../../components/Organization/SourceCategory/SourceCategory'
import useOrgId from '../../hooks/useOrgId'

// Hooks
import { useUserRole } from 'hooks'

const OrganizationSettingsContent = () => {
	const orgId = useOrgId()
	const currentRole = useUserRole()
	const [org, setOrg] = useState({})
	const location = useLocation()

	const [isActive, setIsActive] = useState('profile')
	const [refresh, setRefresh] = useState(1)

	const [projectTypes, setProjectTypes] = useState()

	let allow_edit = currentRole === 'ADMIN' || currentRole === 'SUPER_ADMIN'

	useEffect(() => {
		organizationService
			.retrieve(orgId)
			.then((org) => {
				setOrg(org)
			})
			.catch((error) => {
				console.log('Error loading organization data', error)
			})

		projectTypesService
			.list(orgId)
			.then((response) => {
				setProjectTypes(response)
			})
			.catch((error) => {
				console.error('Unable to get project types', error)
			})
	}, [orgId, refresh])

	useEffect(() => {
		if (location?.state?.isActive) {
			setIsActive(location.state.isActive)
		}

	}, [location.state])
	

	const handleActive = (active_menu) => {
		setIsActive(active_menu)
		setRefresh(refresh + 1)
	}

	return (
		<section className='flexer flexer-mobile'>
			{/*<div className='flexer-3 center'>*/}
			<OrganizationSettingsMenu isActive={isActive} handleActive={handleActive} />
			{/*</div>*/}
			<article className='flexer-9 center'>
				{isActive === 'profile' && <ProfileInfo org={org} projectTypes={projectTypes} />}
				{isActive === 'edit-profile' && <EditProfile org={org} onUpdate={handleActive} />}
				{isActive === 'active-members' && (
					<OrganizationMembers memberStatus={'active'} onUpdate={() => setRefresh(refresh + 1)} />
				)}
				{isActive === 'verification' && <PendingVerification />}
				{isActive === 'invitations' && (
					<PendingInvitation onUpdate={() => setRefresh(refresh + 1)} />
				)}
				{isActive === 'types' && <ProjectTypes allow_edit={allow_edit} orgId={orgId} />}
				{isActive === 'source-categories' && <SourceCategory orgId={orgId} />}
				{isActive === 'templates' && <ReportTemplate orgId={orgId} org={org}/>}
				{isActive === 'plan' && <OrganizationPlan />}
				{isActive === 'payment' && <OrganizationPayment />}
			</article>
		</section>
	)
}

export default OrganizationSettingsContent
