import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import axios from 'axios'
import {toast} from 'react-toastify'

const LocationCard = ( { account, orgId, setRefresh, setEditedLocation, setOpenEditLocation } ) => {
	const hdlEdit = () => {
		setOpenEditLocation( true )
		setEditedLocation( account )
	}

	console.log(account)

	const hdlDelete = async () => {
		try {
			const resp = await axios.delete( `/torch/organization/${ orgId }/locations/${ account.id }/`, account )

			toast.success( 'Location Deleted' )
			setRefresh( prevState => prevState + 1 )
			return resp
		} catch ( { message } ) {

		}
	}

	console.log(account)

	return <LocationWrapperStyled>
		<LocationHeaderStyled>
			<small className='is-light'>Location: <strong className='is-bold is-orange' style={{textTransform:'capitalize'}}>{ account?.category?.replaceAll('_', ' ')?.toLowerCase() ?? '' }</strong></small>
			<small className='is-light'>Added: <strong className='is-bold'>{ account?.proper_created ?? '' }</strong></small>
		</LocationHeaderStyled>
		<LocationContentStyled>
			<LocationListItem title={ 'Category:' } content={ account?.category?.replaceAll('_', ' ')?.toLowerCase() }/>
			<LocationListItem title={ 'Address Line 1:' } content={ account?.street_address?.content }/>
			<LocationListItem title={ 'Address Line 2:' } content={ account?.street_address_2?.content }/>
			<LocationListItem title={ 'City:' } content={ account?.city?.content }/>
			<LocationListItem title={ 'State:' } content={ account?.state_region?.content }/>
			<LocationListItem title={ 'Zip/Postal Code:' } content={ account?.zip?.content }/>
			<LocationListItem title={ 'Country:' } content={ account?.country?.content }/>
		</LocationContentStyled>
		<LocationFooterStyled>
			<FooterCreatorStyled>
				<img
					src={ account?.created_by?.profile?.profile_pic ?? 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png' }
					alt={ `${ account?.created_by?.public_name ?? '' } Avatar` }
					title={ `${ account?.created_by?.public_name ?? '' } Avatar` }
				/>
				<figcaption>{ account?.created_by?.public_name }</figcaption>
			</FooterCreatorStyled>
			<FooterNavStyled>
				{/*<span>*/ }
				{/*	<FontAwesomeIcon icon={ faPlus } className="mr-5"/>*/ }
				{/*	Add comment</span>*/ }
				<span onClick={ hdlEdit }>
					<FontAwesomeIcon icon={ faPencil } className="mr-5"/>
					Edit location</span>
				<span onClick={ hdlDelete }>
					<FontAwesomeIcon icon={ faTrashAlt } className="mr-5"/>
					Delete location</span>
			</FooterNavStyled>
		</LocationFooterStyled>
	</LocationWrapperStyled>
}

const LocationWrapperStyled = styled.article`
  margin: 2rem;
  border-radius: .25rem;
  background: var(--card-bg);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
`

const LocationHeaderStyled = styled.header`
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
`

const LocationContentStyled = styled.section`
  display: grid;
  row-gap: 1rem;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    display: inline-grid;
    grid-template-columns: 25% 1fr;

    strong {
      font-weight: bold;
    }
		
		span {
			text-transform: capitalize;
		}
  }
`

const LocationFooterStyled = styled.footer`
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
  flex-direction: row;
`

const FooterCreatorStyled = styled.figure`
  margin: 0;
  padding: 0;
	display: flex;
	column-gap: .5rem;
	align-items: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`

const FooterNavStyled = styled.nav`
  font-size: .85rem;
  column-gap: 1rem;
  display: inline-flex;

  span {
    cursor: pointer;
    align-items: center;
    display: inline-flex;
    color: var(--link) !important;

    svg {
      path {
        fill: var(--link) !important;
        stroke: var(--link) !important;
      }
    }

    &:last-child {
      color: var(--error) !important;

      svg {
        path {
          fill: var(--error) !important;
          stroke: var(--error) !important;
        }
      }
    }
  }
`

const LocationListItem = ( { title, content } ) => <p>
	<strong>{ title } </strong>
	<span>{ content }</span>
</p>

export default LocationCard
