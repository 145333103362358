import { useState } from "react";
import styled from "styled-components";

// API
import { userService } from "../../api";

// Components
import Button from "../Elements/Button";
import Modal from "../Elements/Modal";
import SearchBar from "../Elements/SearchBar";

const CreateSuperAdmin = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [usersList, setUsersList] = useState([])
	const [superAdmin, setSuperAdmin] = useState({})

	const handleSearchChange = (e) => {
		if (e.length >= 1) {
			setLoading(true)
			userService
				.search_super(e)
				.then((response) => {
					setUsersList(response)
				})
				.then(() => {
					setLoading(false)
				})
				.catch((error) => {
					console.error('It was not possible to obtain a list of users', error)
				})
		} else {
			setUsersList([])
			setLoading(false)
		}
	}

	const handleAccept = (user_id) => {
		const body = {
			role: 'SUPER_ADMIN',
		}

		userService
			.update(body, user_id)
			.then((response) => {
				setIsOpen(false)
			})
			.catch((error) => {
				console.error('It was not possible to create a super-admin', error)
			})
	}

	const handleClick = (userId, userName) => {
		setSuperAdmin({ id: userId, name: userName })
		setIsOpen(true)
	}

	return (
		<section style={{ overflow: 'hidden' }}>
			<h1
				className='is-dark'
				style={{ fontWeight: 'bold', fontSize: '24px', padding: 0, margin: 0 }}
			>
				Create a SuperAdmin
			</h1>
			<p className='heading is-dark mt-15 mb-15'>User browser</p>
			<div className=''>
				<SearchBar onChange={handleSearchChange} placeholder='Find a user by its name...' />
				{loading ? null : <p className='text is-light left mb-0 mt-20'>{usersList.length} users</p>}
			</div>
			<UserListWrapper>
				{loading
					? 'Loading users...'
					: usersList.map(({ cognito_id, public_name, email, profile: { profile_pic } }) => (
							<UserListItemStyled
								key={cognito_id}
								onClick={() => handleClick(cognito_id, public_name)}
							>
								<img
									src={
										profile_pic ??
										'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'
									}
									alt={`${public_name} Avatar`}
									title={`${public_name} Avatar`}
								/>
								<aside>
									<p>{public_name}</p>
									<small>{email}</small>
								</aside>
							</UserListItemStyled>
					  ))}
			</UserListWrapper>
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				<div className='center'>
					<h1 className='title is-dark mb-30'>Confirm SuperAdmin</h1>
					<p className='text is-light mx-30'>
						Are you sure that you want to give SuperAdmin role to <br />{' '}
						<span className='is-dark is-bold'>{superAdmin.name}</span>?
					</p>
					<Button
						text='Confirm'
						class='mt-30 one-third-width orange-btn subheading-light'
						type='button'
						onClick={() => handleAccept(superAdmin.id)}
					/>
				</div>
			</Modal>
		</section>
	)
}

const UserListWrapper = styled.section`
	margin: 0;
	overflow-y: auto;
	overflow-x: hidden;
`

const UserListItemStyled = styled.article`
	display: flex;
	cursor: pointer;
	column-gap: 1rem;
	padding: 1rem 0.5rem;
	color: var(--main-text);
	border-bottom: 1px solid var(--border);

	&:last-child {
		border: none;
	}

	img {
		width: 2.5rem;
		height: 2.5rem;
		object-fit: cover;
		border-radius: 50%;
		background-color: var(--naranja-btn);
	}

	aside {
		display: grid;

		p {
			margin: 0;
			font-weight: bold;
			line-height: 1rem;
		}

		small {
			font-weight: lighter;
		}
	}
`

export default CreateSuperAdmin
