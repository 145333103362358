import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const reportTemplateService = {
  list,
  update,
  remove,
}
	
	async function list(org_id) {
		const requestOptions = { method: 'GET', credentials: 'include' }
		
		return fetch(`${config.apiUrl}/torch/organization/${org_id}/report_template/`, requestOptions)
    .then(handleResponse)
    .then(reports => {
			return reports
    })
	}
	
	async function update(body, org_id, template_id) {
		body = fixBody(body)
		const requestOptions = { method: 'PATCH', credentials: 'include', body }
		
		return fetch(`${config.apiUrl}/torch/organization/${org_id}/report_template/${template_id}/`, requestOptions)
			.then(handleResponse)
			.then(user => {
				return user
		})
	}	

async function remove(org_id, template_id) {

  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/report_template/${template_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
	}