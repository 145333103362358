import React from 'react'
import Header from '../containers/Header'
import Content from '../containers/Content'
import SuperAdminSettingsContent from 'containers/Content/SuperAdminSettingsContent'

const SuperAdminSettings = () => {
	return (
		<>
			<Header />
			<Content>
				<SuperAdminSettingsContent />
			</Content>
		</>
	)
}

export default SuperAdminSettings
