import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const applicationService = {
  create,
  list,
  listByUrl,
  accept,
  reject,
}

async function create(org_id) { // by user
  const requestOptions = { method: 'POST', credentials: 'include' }
  return fetch(`${config.apiUrl}/organization/${org_id}/application/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/organization/${org_id}/application/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function accept(body, org_id, app_id) {  // by org
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body }
  return fetch(`${config.apiUrl}/organization/${org_id}/application/${app_id}/accept/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function reject(org_id, app_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }
  return fetch(`${config.apiUrl}/organization/${org_id}/application/${app_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
