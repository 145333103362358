import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { AiOutlineRightCircle, AiOutlineUpCircle } from 'react-icons/ai'
import { FaPencilAlt, FaUserCog } from 'react-icons/fa'
import LogoOrg from '../../assets/images/globe_international_icon.png'
import Styles from './OrganizationRow.module.sass'
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'

import SwitchButton from 'react-switch'
import { organizationService } from 'api'

const OrganizationRow = ({ org, onUpdate }) => {
	const [showDetailsOrg, setShowDetailsOrg] = useState(false)
	const [isSuspended, setIsSuspended] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		org?.status && org?.status === 'suspended' ? setIsSuspended(true) : setIsSuspended(false)
	}, [org?.status])

	const handleStatus = () => {
		let newStatus = isSuspended ? 'active' : 'suspended'

		organizationService
			.updateStatus({ status: newStatus }, org?.id)
			.then((res) => {
				onUpdate()
			})
			.catch((error) => {
				console.log('Error updating status', error)
			})
		setIsOpen(false)
		window.location.reload()
	}

	return (
		<>
			<article className={`${Styles.item} ${showDetailsOrg && Styles.item__open}`}>
				<header className={Styles.item_header}>
					<IconButton
						lassName={Styles.item_header_icons}
						aria-label='details'
						onClick={() => setShowDetailsOrg(!showDetailsOrg)}
					>
						{showDetailsOrg ? <AiOutlineUpCircle /> : <AiOutlineRightCircle />}
					</IconButton>
					<figure className={Styles.item_header_name}>
						<img
							className={Styles.item_header_name_img}
							src={org?.profile_pic ?? LogoOrg}
							alt='ProfilePic'
						/>
						<figcaption>
							<Link to={`/organization/${org?.id}/organization-settings`}>{org?.name}</Link>
						</figcaption>
					</figure>
					<p
						className={`${Styles.item_header_status} ${
							org?.status === 'suspended' && Styles.item_header_status__suspended
						}`}
					>
						{org?.status ?? 'No data available'}
					</p>

					<Link
						to={`/organization/${org?.id}/organization-settings`}
						className={Styles.item_header_members}
					>
						<IconButton className={Styles.item_header_members_iconMember} aria-label='details'>
							<FaUserCog size={20} />
						</IconButton>
						Manage members
					</Link>

					<IconButton className={Styles.item_header_icons} aria-label='details'>
						<Link to={`/organization/${org?.id}/organization-settings`}>
							<FaPencilAlt size={20} />
						</Link>
					</IconButton>
				</header>

				{showDetailsOrg && (
					<section className={Styles.item_details}>
						<span className={Styles.item_details_toggle}>
							Suspend or enable this organization
							<span className={Styles.item_details_toggle_switch}>
								<SwitchButton
									onChange={() => setIsOpen(true)}
									checked={isSuspended}
									height={20}
									width={40}
									handleDiameter={15}
									onColor='#F69221'
								/>
								Suspended
							</span>
						</span>
					</section>
				)}
			</article>
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				<div className='center'>
					<h1 className='title is-dark mb-30'>
						{org?.status && org?.status === 'active'
							? 'Suspend Organization'
							: 'Enable Organization'}
					</h1>
					<p className='text is-light mx-30'>
						{org?.status && org?.status === 'active'
							? 'Are you sure that you want to suspend'
							: 'Are you sure that you want to enable'}
						<br /> <span className='is-dark is-bold'>{org?.name}</span>?
					</p>
					<Button
						text='Confirm'
						class='mt-30 one-third-width orange-btn subheading-light'
						type='button'
						onClick={handleStatus}
					/>
				</div>
			</Modal>
		</>
	)
}

export default OrganizationRow
