import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers';

export const confidenceService = {
  update,
  list,
  retrieve,
  filter,
  remove,
}

async function update( body, org_id, data_id ) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', body }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/data_points/${data_id}/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}

async function list( org_id ) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/data_point_history/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}
async function retrieve( org_id, data_id ) {
  const requestOptions = { method: 'GET', credentials: 'include' } 

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/data_point_history/${data_id}/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}
async function filter( body, org_id ) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body } 

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/data_point_history/filters/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}

async function remove( org_id, data_id ) {
  const requestOptions = { method: 'DELETE', credentials: 'include' } 

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/data_point_history/${data_id}/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}
