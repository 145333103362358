import styled from 'styled-components'
import PropTypes from 'prop-types'

const FormLabel = ( { name, className, isRequired, errMsg, onErr } ) => (
	<FormLabelStyled className={ className }>
		<small>
			{ name }
		</small>
		{ isRequired &&
			<strong style={ { color: '#f69221', fontWeight: 'bold' } }>*</strong> }
		{ onErr && <small><small style={ {
			color: 'RGB(190,18,60)', fontWeight: 'bold', marginLeft: '.5rem',
		} }>{ errMsg }</small></small> }
	</FormLabelStyled> )

FormLabel.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	isRequired: PropTypes.bool,
}

const FormLabelStyled = styled.label`
  small {
    color: var(--secondary-text)
  }
`

export default FormLabel
