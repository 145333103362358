import { BiChevronDownCircle, BiChevronRightCircle, BiEditAlt, FiTrash } from 'react-icons/all'
import { IconButton } from '@mui/material'
import { useState } from 'react'

import LogoOrg from '../../../../assets/images/globe_international_icon.png'
import styles from './sourceCategoryItem.module.sass'

const SourceCategoryItem = ({ category, setToEdit, setToDelete }) => {
	const [showSourceList, setShowSourceList] = useState(false)

	return (
		<section className={`${styles.item} ${showSourceList && styles.item__open}`}>
			<header className={styles.item_header}>
				<IconButton
					className={`${styles.item_header_ic} ${styles.item_header_ic__outline}`}
					aria-label='details'
					onClick={() => setShowSourceList(!showSourceList)}
				>
					{showSourceList ? <BiChevronDownCircle size={20} /> : <BiChevronRightCircle size={20} />}
				</IconButton>
				<span className={styles.item_header_name}>{category.name}</span>
				{!category.default && (
					<>
						<IconButton
							className={`${styles.item_header_ic} ${styles.item_header_ic__outline}`}
							aria-label='Edit'
							onClick={() => {
								setToEdit({
									showModal: true,
									sourceSites: category.source_sites,
									name: category.name,
									id: category.id,
								})
							}}
						>
							<BiEditAlt size={20} />
						</IconButton>

						<IconButton
							onClick={() => setToDelete({ showModal: true, name: category.name, id: category.id })}
							className={`${styles.item_header_ic} ${styles.item_header_ic__solid}`}
							aria-label='Delete'
						>
							<FiTrash size={20} />
						</IconButton>
					</>
				)}
			</header>
			{showSourceList && (
				<article className={styles.item_categories}>
					<h1 className={styles.item_categories_title}>Sources</h1>
					<section className={styles.item_categories_sources}>
						{category.source_sites.map(({ id, name, img }) => (
							<figure className={styles.item_categories_sources_card} key={id}>
								<img
									className={styles.item_categories_sources_card_img}
									src={(img === '' || !img) && LogoOrg}
									alt={name}
								/>
								<figcaption className={styles.item_categories_sources_card_name}>{name}</figcaption>
							</figure>
						))}
					</section>
				</article>
			)}
		</section>
	)
}

export default SourceCategoryItem
