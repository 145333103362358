import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'

import CommentArticle from '../../components/RFI/CommentArticle'
import CommentBubble from '../../components/RFI/CommentBubble'
import {useOrgId} from '../../hooks'

const RFI = ({rfi}) => {
	const [commentsBubble, setCommentsBubble] = useState(false)
	const [rfiComments, setRfiComments] = useState([])

	const orgId = useOrgId()

	useEffect(() => {
		setRfiComments(rfi.comments)
	}, [rfi])

	let comments = []
	for (const [index, comment] of rfiComments.entries()) {
		comments.push(<CommentArticle
				articleType="secondComment"
				element={comment}
				showClick={index === rfiComments.length - 1 ? !commentsBubble : false}
				onClick={() => setCommentsBubble(!commentsBubble)}
		/>)
	}

	const handleSubmit = async (formData) => {
		try {
			const {data} = await axios.post(
					`/torch/organization/${orgId}/rfis/${rfi.id}/add_comment/`, formData)

			setRfiComments(prev => [
				...prev, data])
		} catch ({message}) {
			toast.error(message)
		}
	}

	return <>
		<CommentArticle
				articleType="initComment"
				element={rfi}
				showClick={!comments.length}
				onClick={() => setCommentsBubble(!commentsBubble)}
		/>
		<div className="subcomment-section flexer flexcer-vcenter">
			<div className="flexer-1"/>
			<div className="flexer-11">
				{comments}
				<div className="flexer add-comment-article">
					<div className="flexer-1"/>
					<div className="flexer-11 center">
						{ commentsBubble
							? ( <CommentBubble
								formStyle="responseComment"
								onClose={ () => setCommentsBubble( false ) }
								onSubmit={ handleSubmit }
								id={ `rfi-${ rfi.id }` }
							/> )
							: null }
					</div>
				</div>
			</div>
		</div>
	</>
}

export default RFI
