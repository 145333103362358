import { useState, useEffect } from 'react'

// Components
import Button from '../../Elements/Button'
import useOrgId from '../../../hooks/useOrgId'

// API
import { projectService } from '../../../api'

function EditCustomerForm(props) {
	const orgId = useOrgId()

	const [onEdit, setOnEdit] = useState(false)
	const [value, setValue] = useState('')

	useEffect(() => {
		setTimeout(() => {
			setValue(props.placeholder)
		}, 500)
	}, [props.placeholder])
	
	const hdlChange = ({target}) => {
		setValue(target.value)
	}

	function handleSubmit(e) {
		e.preventDefault()
		
		var form_element = document.getElementById('customer-form')
		var form_data = new FormData(form_element)

		projectService.update(form_data, orgId, props.projectID)
		.then(response => {
			props.onComplete()
			props.onClose()
		})
		.catch(error => {
			console.log(error)
		})
	}


	return (
		<form id="customer-form" onSubmit={handleSubmit}>
			<h1 className="title is-dark mb-30">Edit customer supported</h1>
			{/* <Textarea name="lea_supported" label="What customer will be supported with this project" placeholder={props.placeholder} /> */}
			<fieldset
						style={{
							padding: 0,
							border: 'none',
							borderRadius: '5px',
							gridColumn: '1 / 3',
							position: 'relative'
						}}
					>
						<textarea
							name="lea_supported"
							onChange={hdlChange}
							value={value}
							placeholder={'What customer will be supported with this project'}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
							style={{ width: '100%', height: '100%', backgroundColor: 'none', resize: 'none' }}
						/>

						{value?.length > 0 && (
							<i
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setOnEdit(false)
									setValue(' ')
								}}
								className='fa fa-times icon input-wrapper__icon-right search-icon'
							/>
						)}
					</fieldset>
			<div className="right center-mobile">
				<Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose}/>
				<Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
			</div>
		</form>
	)
}

export default EditCustomerForm