const SingleStep = (props) => {
	var circle = 'step fas fa-circle'
	var step = 'special-text is-light line-margin-0'
	var text = 'subheading is-light line-margin-0'

	if (parseInt(props.step) === props.current) {
		circle = 'step step-active fas fa-check is-orange'
		step = 'special-text is-orange line-margin-0'
		text = 'subheading is-dark line-margin-0'
	} else if (parseInt(props.step) < props.current) {
		circle = 'step step-past fas fa-check'
		step = 'special-text is-orange line-margin-0'
		text = 'subheading is-dark line-margin-0'
	}

	var line = 'line'
	if (props.last) {
		line = 'hidden'
	}

	return (
		<div className='flexer-5'>
			<div className='flexer flexer-vcenter'>
				<p className={circle} />
				<span className={line} />
			</div>
			<p className={step}>Step {props.step}</p>
			<p className={text}>{props.text}</p>
		</div>
	)
}

export default SingleStep
