import React from 'react'
import { Header } from 'containers'
import TicketDetailContent from 'containers/Content/TicketDetailContent'

const TicketDetail = () => {
  return (
    <>
      <Header />
      <TicketDetailContent />
    </>
  )
}

export default TicketDetail
