/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const invitationService = {
  create,
  listByOrg,
  listByUrl,
  listByUser,
  accept,
  remove,
  search,
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/organization/${org_id}/invitation/`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response
    })
}

async function listByOrg(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/invitation/`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function listByUser() {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/users/invitations/`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response
    })
}

async function accept(org_id, inv_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/invitation/${inv_id}/accept/`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response
    })
}

async function remove(org_id, inv_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/invitation/${inv_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function search(keyword, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/invitation/search/?keyword=${keyword}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
