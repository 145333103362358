import { Doughnut, Bar } from 'react-chartjs-2'

function ChartCard(props) {
	var chart
	var options
	if (props.type === 'doughnut') {
		options = {
			plugins: { legend: { position: 'right' } },
			maintainAspectRatio: false,
			response: true,
		}
		chart = (
			<div style={{ maxHeight: '500px' }}>
				<Doughnut data={props.data} options={options} width='90%' />
			</div>
		)
	} else if (props.type === 'bar') {
		options = {
			plugins: { legend: { display: props.legendDisplay } },
			maintainAspectRatio: false,
			response: true,
		}
		chart = (
			<div style={{ maxHeight: '500px' }}>
				<Bar data={props.data} options={options} width={'90%'} />
			</div>
		)
	}

	return (
		<div className='dashboard-wrapper__content center'>
			<h1 className='heading is-dark'>{props.title}</h1>
			{chart}
		</div>
	)
}

export default ChartCard
