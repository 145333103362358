import React from 'react'

// Components
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'
import useOrgId from '../../../hooks/useOrgId'

// API
import { webscraperService } from '../../../api'

function AddNoteScraperForm(props) {
    const { id, type, onClose } = props
    const orgId = useOrgId()
    
    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('note-form')
        var form_data = new FormData(form_element)
        
        let body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })

        let data_type = ''
        if(type === 'torch_alias') {
            data_type = 'aliases'
        }
        if(type === 'torch_accounts') {
            data_type = 'accounts'
        }
        if(type === 'torch_subject') {
            data_type = 'subjects'
        }
        if(type === 'torch_phone') {
            data_type = 'phones'
        }
        if(type === 'torch_link') {
            data_type = 'links'
        }
        if(type === 'torch_location') {
            data_type = 'location'
        }
        if(type === 'torch_datapoint') {
            data_type = 'data_points'
        }
        

        webscraperService.createNote(body, orgId, data_type, id)
        .then(response => {
            onClose()
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <form id="note-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Add notes</h1>
            <Textarea name="content" label="Notes"/>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
            </div>
        </form>
    )
}

export default AddNoteScraperForm
