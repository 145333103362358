const MenuButton = ({ project, icon, active, onClick, text }) => (
	<div
		className={`${active && 'list-menu__active'} ${project && 'menu-tab__project'} ${icon && 'flexer flexer-vcenter'} list-menu cursor`}
		onClick={onClick}
	>
		<p className='subheading is-light'>
			{icon && <i className={`${icon} text-icon`} />}
			{text}
		</p>
	</div>
)

export default MenuButton
