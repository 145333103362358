import React from 'react'

//Hooks
import usePermissions from 'hooks/usePermissions'

function PermissionWrapper(props) {
    const {requiredPermissions, checkAll, objectId, placeholder, children} = props
    
    return usePermissions(requiredPermissions, checkAll, objectId) ? children : placeholder ? placeholder : null
}

export default PermissionWrapper