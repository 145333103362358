import {toast} from 'react-toastify'
import {useState} from 'react'
import axios from 'axios'

import LocationForm from './LocationForm'
import {Modal} from '../../../Elements'

const EditLocationModal = ( { setOpenEditLocation, orgId, subject, editedLocation, setEditedLocation, isOpenEditLocation } ) => {
	const [ onLoading, setOnLoading ] = useState( false )

	const hdlSubmit = async ( { category, streetAddress, apartmentSuite, city, state, country, zip } ) => {
		setOnLoading( true )

		if ( streetAddress !== '' || apartmentSuite !== '' || city !== '' || state !== '' || country !== '' || zip !== '' ) {
			try {
				const req = await axios.patch( `/torch/organization/${ orgId }/locations/${ editedLocation.id }/`, {
					'street_address': {
						'content': streetAddress || 'Unknown',
					},
					'street_address_2': {
						'content': apartmentSuite || 'Unknown',
					},
					'city': {
						'content': city || 'Unknown',
					},
					'state_region': {
						'content': state || 'Unknown',
					},
					'zip': {
						'content': zip || 'Unknown',
					},
					'country': {
						'content': country || 'Unknown',
					},
					'category': parseInt(category),
					'subject': subject.id,
				} )

				toast.success( 'Location has been updated!' )
				setEditedLocation( {} )
				setOpenEditLocation( false )

				return req
			} catch ( { message } ) {
				toast.error( message )
			} finally {
				setOnLoading( false )
			}
		} else {
			setOnLoading( false )
			toast.error( 'Please fill in at least one field' )
		}
	}

	return (
		<Modal
			isOpen={ isOpenEditLocation }
			handleClose={ () => {
				setOpenEditLocation( false )
			} }
		>
			<LocationForm
				formType={'Edit' }
				onLoading={ onLoading }
				hdlSubmit={ hdlSubmit }
				subjectType={ subject.type }
				editedLocation={ editedLocation }
				cta={ () => setOpenEditLocation( false ) }
			/>
		</Modal>
	)
}

export default EditLocationModal
