import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PermissionWrapper } from 'components'
import { toast } from 'react-toastify'

// Components
import ImageContent from '../Elements/ImageContent'
import Modal from '../Elements/Modal'
import AddLinkForm from './Forms/AddLinkForm'
import EditLinkForm from './Forms/EditLinkForm'
import ConfidenceIcon from '../Elements/ConfidenceIcon'
import EditProfileForm from './Forms/EditProfileForm'
import { Button } from 'components'

// API
import { linkService } from '../../api'

function LinksSection(props) {
   const [isOpen, setIsOpen] = useState(false)
   const [modalForm, setModalForm] = useState(null)

   var links = []

   function isNotDuplicatedSubject(projectId) {
      const link = links.find(l => l.key === projectId)
      if (link) return false
      return true
   }

   if (props.project.subject) {
      for (const link of props.project.subject.links) {
         let profilePic = false  // hipster icon now inside ImageContent

         let first_name
         let middle_name
         let last_name

         let organization_name
         let event_name
         let relationship_label;

         if (link.linked_subject.type === 'PERSON') {

            first_name = {
               content: link.linked_subject.first_name ? link.linked_subject.first_name.content : null,
               confidence: link.linked_subject.first_name ? link.linked_subject.first_name.confidence : null,
               id: link.linked_subject.first_name ? link.linked_subject.first_name.id : null
            }

            middle_name = {
               content: link.linked_subject.middle_name ? link.linked_subject.middle_name.content : null,
               confidence: link.linked_subject.middle_name ? link.linked_subject.middle_name.confidence : null,
               id: link.linked_subject.middle_name ? link.linked_subject.middle_name.id : null
            }

            last_name = {
               content: link.linked_subject.last_name ? link.linked_subject.last_name.content : null,
               confidence: link.linked_subject.last_name ? link.linked_subject.last_name.confidence : null,
               id: link.linked_subject.last_name ? link.linked_subject.last_name.id : null
            }

            relationship_label = link.proper_relationship;
         }

         if (link.linked_subject.type === 'ORGANIZATION') {

            organization_name = {
               content: link.linked_subject.organization_name ? link.linked_subject.organization_name.content : null,
               confidence: link.linked_subject.organization_name ? link.linked_subject.organization_name.confidence : null,
               id: link.linked_subject.organization_name ? link.linked_subject.organization_name.id : null
            }

            relationship_label = "Organization";
         }

         if (link.linked_subject.type === 'EVENT') {

            event_name = {
               content: link.linked_subject.event_name ? link.linked_subject.event_name.content : null,
               confidence: link.linked_subject.event_name ? link.linked_subject.event_name.confidence : null,
               id: link.linked_subject.event_name ? link.linked_subject.event_name.id : null
            }

            relationship_label = "Event";
         }

         profilePic = {
            content: link.linked_subject.profile_pic.content ? link.linked_subject.profile_pic.content : profilePic,
            confidence: link.linked_subject.profile_pic ? link.linked_subject.profile_pic.confidence : null,
            id: link.linked_subject.profile_pic ? link.linked_subject.profile_pic.id : null
         }

         var project_id = link.linked_subject.cases[0]

         if (isNotDuplicatedSubject(project_id)) {
            links.push(
               <div className="project-links flexer flexer-spaced flexer-vcenter" key={project_id}>
                  <div className="flexer left">
                     <div className='flexer flexer-vcenter center wcc-hasconfidence'>
                        <Link to={`/organization/${props.orgId}/project/${project_id}`} >
                           <ImageContent className="profile-menu__image" src={profilePic.content} alt="pic" />
                        </Link>
                        {profilePic.confidence && (
                           <ConfidenceIcon confidence={profilePic.confidence} orgId={props.orgId} dataId={profilePic.id} onClose={props.onClose} />
                        )}
                     </div>
                     <div className="mx-15">
                        {link.linked_subject.type === 'PERSON' &&
                           <div className='flexer'>
                              {first_name?.content && (
                                 <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                    <p className="subtitle is-dark line-margin-s">{first_name.content}</p>
                                    <ConfidenceIcon confidence={first_name.confidence} orgId={props.orgId} dataId={first_name.id} onClose={props.onClose} />
                                 </div>
                              )}
                              {middle_name?.content && (
                                 <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                    <p className="subtitle is-dark line-margin-s">{middle_name.content}</p>
                                    <ConfidenceIcon confidence={middle_name.confidence} orgId={props.orgId} dataId={middle_name.id} onClose={props.onClose} />
                                 </div>
                              )}
                              {last_name?.content && (
                                 <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                    <p className="subtitle is-dark line-margin-s">{last_name.content}</p>
                                    <ConfidenceIcon confidence={last_name.confidence} orgId={props.orgId} dataId={last_name.id} onClose={props.onClose} />
                                 </div>
                              )}

                           </div>}
                        {link.linked_subject.type === 'ORGANIZATION' && <div>
                           {organization_name?.content && (
                              <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                 <p className="subtitle is-dark line-margin-s">{organization_name.content}</p>
                                 <ConfidenceIcon confidence={organization_name.confidence} orgId={props.orgId} dataId={organization_name.id} onClose={props.onClose} />
                              </div>
                           )}
                        </div>}
                        {link.linked_subject.type === 'EVENT' && <div>
                           {event_name?.content && (
                              <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                 <p className="subtitle is-dark line-margin-s">{event_name.content}</p>
                                 <ConfidenceIcon confidence={event_name.confidence} orgId={props.orgId} dataId={event_name.id} onClose={props.onClose} />
                              </div>
                           )}
                        </div>}
                        <p className="subheading is-dark line-margin-0">{relationship_label}</p>
                     </div>
                  </div>
                  <div >
                     <i className="fa fa-pencil is-light cursor mx-5" onClick={() => { handleEditSubject(link.linked_subject) }}></i>
                     {(props.project.subject.type === 'PERSON' && link.linked_subject.type === 'PERSON') &&
                        <i className="fa fa-people-arrows is-light cursor mx-5" onClick={() => { handleEditLink(link.id) }}></i>
                     }
                     <i className='fa fa-trash is-light cursor mx-5' onClick={() => { handleShowDeleteModal(link) }}></i>
                  </div>
               </div>
            )
         }

      }
   }

   let mainSubject = props.project.subject ? props.project.subject.id : ''
   let mainSubjectType = props.project.subject ? props.project.subject.type : ''

   function handleEditLink(linkId) {
      setIsOpen(true)
      setModalForm(<EditLinkForm onComplete={props.onClose} onClose={() => { setIsOpen(false) }} linkId={linkId} />)
   }

   function handleNewLink() {
      setIsOpen(true)
      setModalForm(<AddLinkForm onComplete={props.onClose} onClose={() => { setIsOpen(false) }} mainSubject={mainSubject} mainSubjectType={mainSubjectType} />)
   }

   function handleEditSubject(linkedSubject = null) {
      if (linkedSubject) {
         setIsOpen(true)
         setModalForm(
            <EditProfileForm
               onClose={() => {
                  setIsOpen(false)
               }}
               onComplete={props.onClose}
               subject={linkedSubject}
            />
         )
      }

   }

   function handleDelete(linkId = null) {
      if (linkId) {
         linkService.remove(props.orgId, linkId)
            .then(() => {
               setIsOpen(false)
               props.onClose()
            })
            .catch(error => {
               toast.error('Error deleting link')
               console.error('Error deleting link', error);
               setIsOpen(false)
            })
      }
   }

   function handleShowDeleteModal(link = null) {
      if (link) {
         setIsOpen(true)
         setModalForm(
            <div className="center">
               <h1 className="title is-dark mb-30">Delete Project Link</h1>
               <p className="text is-light mx-30">Are you sure that you want to delete the Project link <br></br>
                  <span className="is-dark is-bold">
                     {link.linked_subject.display_name}
                  </span> ?
               </p>
               <Button
                  type="button"
                  text="Delete"
                  class="mt-30 one-third-width orange-btn subheading-light"
                  onClick={() => { handleDelete(link.id) }}
               />
            </div>
         )
      }
   }

   return (
      <React.Fragment>
         <div className="simple-profile__sections center">
            <div className="flexer flexer-spaced flexer-vcenter">
               <p className="subtitle is-dark is-bold">Project Links:</p>
               {props.closed ? null :
                  <p className="cursor link" onClick={handleNewLink}>+ Add Link</p>
               }
            </div>
            {links}
         </div>
         <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
            {modalForm}
         </Modal>
      </React.Fragment>
   )
}

export default LinksSection
