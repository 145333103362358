import Header from '../containers/Header'
import Content from '../containers/Content'
import CaseManagerResourcesContent from '../containers/Content/CaseManagerResourcesContent'

function CaseManagerResources() {
	return (
		<>
			<Header />
			<Content>
				<CaseManagerResourcesContent />
			</Content>
		</>
	)
}

export default CaseManagerResources
