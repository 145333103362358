import axios from 'axios'

const getCategories = async (orgId) => {
	try {
		const { data } = await axios(`/torch/organization/${orgId}/categories/`)

		return data
	} catch (err) {
		console.log(err)
	}
}

export default getCategories
