import { useEffect, useState } from 'react'

const SearchBar = ( props ) => {

	const [searchTerm, setSearchTerm] = useState('')
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
		  props.onChange(searchTerm)
		}, 700)

		return () => clearTimeout(delayDebounceFn)
	  }, [searchTerm])

	useEffect(() => {
		if(props.value) {
			setSearchTerm(props.value)
		}
	}, [props.value])

	if ( props.noBg ) {
		return (
			<div className="input-wrapper">
				<input
					className="search-input-2 text is-dark"
					type="text"
					id={ props.id }
					placeholder={ props.placeholder }
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</div>
		)
	}

	if ( props.whiteBg ) {
		return (
			<div className="input-wrapper">
				<i className="fa fa-search icon input-wrapper__icon-left search-icon"/>
				<input
					className="search-input-3 subheading is-light"
					type="text"
					id={ props.id }
					placeholder={ props.placeholder }
					onChange={ (e) => setSearchTerm(e.target.value) }
				/>
			</div>
		)
	}

	return (
		<div className="input-wrapper">
			<i className="fa fa-search icon input-wrapper__icon-left search-icon"/>
			<input
				className="search-input subheading is-light"
				type="text"
				id={ props.id }
				placeholder={ props.placeholder }
				onChange={ (e) => setSearchTerm(e.target.value) }
			/>
		</div>
	)
}

export default SearchBar
