import { useState} from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import AppsIcon from '@mui/icons-material/Apps'
import IconButton from '@mui/material/IconButton'
import {userService} from '../../api'
import usePermissions from '../../hooks/usePermissions'
import {toast} from 'react-toastify'

// API
import config from '../../api/config'

const CidTorchMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const [goToLMS, setGoToLMS]=useState(false)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleCaseManager = () => {
		handleClose()
		// Torch redirect
		const link = `${config.torch}/organizations`
		window.location.assign(link)
	}

    const handleToolKit = () => {
	handleClose()
	// CID redirect
	const link = `${config.cid}/tools`
	window.location.assign(link)
	}

	const handleHome = () => {
		handleClose()
		// CID redirect
		const link = `${config.home}/home`
		window.location.assign(link)
	}
	function handleAcademy() {
		setAnchorEl(null)
		setGoToLMS(true)
		userService.get_lms_url().then((res) => {
			window.open(res.url)
			setGoToLMS(false)
		})		
	}

	function sendStore(){
		toast("Please go to the store to purchase a subscription")
		//redirect if needed
	}

	return (
		<>
			<IconButton
				aria-label='more'
				id='long-button'
				aria-controls='long-menu'
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
				className='MuiButtonBase-root-MuiIconButton-root'
				disabled={goToLMS}
			>
				<AppsIcon />
			</IconButton >
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{ 'aria-labelledby': 'basic-button' }}
				className='MuiList-root-MuiMenu-list'
			>
	            <MenuItem className='MenuItem' onClick={handleHome}>
					HOME
				</MenuItem>
				<MenuItem className='MenuItemLms' onClick= {usePermissions('torch::web_access',false) ? () => handleAcademy() : () => sendStore()} >
					ACADEMY
				</MenuItem>
				<MenuItem className='MenuItem' onClick= {usePermissions('cid::web_access',false) ? () => handleToolKit() : () => sendStore()} >
					TOOLKIT
				</MenuItem> 
				<MenuItem className='MenuItem' disabled onClick= {usePermissions('torch::web_access',false) ? () => handleCaseManager() : () => sendStore()} >
					CASE MANAGER
				</MenuItem>
			</Menu>
		</>
	)
}

export default CidTorchMenu
