import { useEffect, useState } from 'react'
import { docsService, projectService, projectTypesService, subjectService } from '../../../api/'
import { useOrgId } from '../../../hooks/'
import { Button, Dropdown, InputField, Checkbox } from '../../Elements'

function PriorityOfProjectForm(props) {
	const [projectTypes, setProjectTypes] = useState(null)
	// const [projectTemplate, setProjectTemplate] = useState(null)
	const orgId = useOrgId()

	useEffect(() => {
		projectTypesService.list(orgId, 999).then((response) => {
			setProjectTypes(response)
		})

		// projectTemplateService.list(orgId).then((response) => {
		// 	setProjectTemplate(response)
		// })
	}, [orgId])

	const handleSubmit = (e) => {
		e.preventDefault()

		// Create Subject
		var subject_data = props.data.subject_data
		var body = { alias: [], phones: [] }

		for (var [key, value] of Object.entries(subject_data)) {
			if (value) {
				if (key.includes('phones')) {
					value = subject_data['calling_code'] + value
				} else if (key === 'calling_code') {
					continue
				}

				if (key.includes('__')) {
					key = key.split('__')
					var subelement = {}
					subelement[key[1]] = { content: value }
					body[key[0]].push(subelement)
				} else if (key === 'type') {
					body[key] = value
				} else {
					body[key] = { content: value }
				}
			}
		}


		subjectService
			.create(body, orgId)
			.then((response) => {
				//Create project
				var form_element = document.getElementById('create-project-form')
				var form_data = new FormData(form_element)

				var body = props.data
				delete body.subject_data
				form_data.forEach((value, key) => {
					if (value) {
						if (key === 'number') {
							body[key] = parseInt(value)
						} else {
							body[key] = value
						}
					}
				})
				body['subject'] = response.id
				console.table(body)
				projectService
					.create(body, orgId)
					.then(async (case_) => {
						if (props.data.docs_attached) {
							let promises = []
							for (const file of props.data.docs_attached) {
								let formData = new FormData()
								formData.append('doc', file)
								formData.append('case', case_.id)
								promises.push(docsService.create(formData, orgId))
							}
							await Promise.allSettled(promises)
						}
						return case_
					})
					.then((case_) => {
						props.onUpdate({ case: case_.id })
					})
					.then(() => {
						props.onNext()
					})
			})
			.catch((error) => {
				console.error('Error creating project', error)
			})

		return
	}

	const handleClickPrevious = () => {
		var form_element = document.getElementById('create-project-form')
		var form_data = new FormData(form_element)

		var body = props.data
		form_data.forEach((value, key) => {
			if (value) {
				body[key] = value
			}
		})

		props.onUpdate({ ...body })
		props.onPrevious()
	}

	const options = !projectTypes?.results
		? ['No aoptions available']
		: projectTypes.results.map((type, idx) => (
				<option id={idx} value={type.id} selected={!!type.code}>
					{type.name}
				</option>
		  ))

	return (
		<div className='half-width center'>
			<form id='create-project-form' className='left mt-60' onSubmit={handleSubmit}>
				<div className='flexer'>
					<i className='form-icon fas fa-user-alt' />
					<Dropdown name='case_type' class='full-width line-margin-xs' label='Project type'>
						{options}
					</Dropdown>
				</div>

				<div className='flexer'>
					<i className='form-icon fa fa-level-up' />
					<Dropdown
						name='priority'
						class='full-width line-margin-xs'
						label='Priority of the project'
					>
						<option value='high' selected={props.data.priority === 'high'}>
							High Priority
						</option>
						<option value='standard' selected={props.data.priority === 'standard'}>
							Standard Priority
						</option>
					</Dropdown>
				</div>
				<div className='flexer'>

					<Checkbox
						name='collaborative'
						class='full-width line-margin-xs'
						label='Collaborative project'
					/>

				</div>

				{/*<div className='flexer'>*/}
				{/*	<i className='form-icon fa fa-file-text-o' />*/}
				{/*	<Dropdown*/}
				{/*		name='template'*/}
				{/*		class='full-width line-margin-xs'*/}
				{/*		label='Choose a project template'*/}
				{/*	>*/}
				{/*		/!* {projectTypes.map((type, idx) =>*/}
				{/*      <option id={idx} value={type.id} selected={type.code ? true : false}>{type.name}</option>*/}
				{/*    )} *!/*/}
				{/*	</Dropdown>*/}
				{/*</div>*/}

				<div className='flexer flexer-vcenter'>
					<i className='form-icon fa fa-clock' />
					<Dropdown label='Set Time Units' name='time_type' class='mr-20 half-width wcc-fieldtime'>
						<option value='' selected disabled>
							Select Time Units
						</option>
						<option value='D' selected={props.data.time_type === 'D'}>
							Days
						</option>
						<option value='H' selected={props.data.time_type === 'H'}>
							Hours
						</option>
					</Dropdown>

					<InputField
						name='number'
						type='number'
						label='Time for project'
						class='full-width wcc-fieldtime'
						value={props.data.number && props.data.number}
					/>
				</div>
				<div className='flexer flexer-spaced center-mobile'>
					<Button
						text='&larr; Previous step'
						class='mt-15 white-btn is-orange subheading is-light'
						type='button'
						onClick={handleClickPrevious}
					/>
					<Button text='Create Project &#43;' class='mt-15 orange-btn subheading is-light' />
				</div>
			</form>
		</div>
	)
}

export default PriorityOfProjectForm
