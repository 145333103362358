import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'components'
import LogoTorch from '../../assets/images/logo__torch.png'
import torchLogoWhite from '../../assets/images/logo__torch--white.png'
 
import SubscriptionCard from './SubscriptionCard'
import { organizationService, subscriptionService } from 'api'
import useOrgId from '../../hooks/useOrgId'
import config from '../../api/config'
import { toggleService } from '../Elements/ThemeToggle'

const OrganizationPlan = () => {

	const [isOpenCancel, setIsOpenCancel] = useState(false)
	const [subscription, setSubscription] = useState({})
  const [plan, setPlan] = useState([])
	const [refresh, setRefresh] = useState(1)
	const [isCancelled, setIsCancelled] = useState(false)
	
	const orgId = useOrgId()


	useEffect(() => {
		organizationService.listSubscription(orgId)
		.then(res => {
	    setSubscription(res[0])
      setPlan(res[0].plans[0])
		})
		.catch(error => {
		  console.log("Error loading organization subscriptions", error)
		})
	}, [orgId, refresh])

	const [mode, setMode] = useState(toggleService.currentModeValue)
    toggleService.currentModeSubject.subscribe((value)=>{
        if(value !== mode && value){
            setMode(value)
        }
    })

 
	var modeClass = mode ? mode : "light"

	if (modeClass === "light") {
        var logo = LogoTorch
    } 
	else {
        var logo = torchLogoWhite
	}

     

	const handleCancel = () => {
    subscriptionService.cancelSubscription(subscription.id)
		.then(res => {
			setIsCancelled(true)
			console.log('Subscription canceled')
			setRefresh( refresh + 1)
			setIsOpenCancel(false)
		})
		.catch(error => {
      console.log("Error canceling subscription", error)
		})
  }

	const handleGoToStore = () => {
		window.location.assign(`${config.home}/store/${orgId}`)
	}

	return (
		<>
			<section className='dashboard-section'>
				<div className='simple-profile__sections'>
					<p className='text is-light is-bold left'>Your Plan</p>
          {
						(isCancelled || subscription?.is_cancelled ) &&
            <div className='flexer flexer-vcenter subscription-alert'>
              <i className='fa fa-exclamation-triangle mx-10 is-red is-bold'/>
              <p className='text  is-red line-margin-0'>
              {`You have canceled your subscription, however you will have the platform enabled until your last payment cycle date is met in ${subscription.current_period_end}`}
              </p>
            </div>
          }
					<div className='flexer'>
						<div className='flexer-4 my-20'>
						{ (plan && subscription) &&
            
							<SubscriptionCard
								name={plan.name}
								logo={plan.logo ?? logo}
								description={plan.description}
								price={subscription.prices && subscription.prices[0].price }
								benefits={plan.plan_benefits}
							/>
						}
						</div>
					</div>
					<div className='flexer'>
						<div className='flexer-8'></div>
						<div className='flexer-4 my-20'>
							<Button text='See other Plans' class='list-menu' onClick={handleGoToStore} />
						</div>
					</div>
				</div>
          { (subscription && !subscription.is_cancelled) &&
          <>
				<div className='simple-profile__sections'>
					<p className='text is-light is-bold left'>Cancel your 
            <span className='text is-orange is-bold ml-5'>{plan && plan.name}</span> subscription.
					</p>
					<div className='flexer'>
						<div className='flexer-8'></div>
						<div className='flexer-4'>
							<Button
								text='Cancel your plan'
								class='list-menu my-30'
								onClick={() => {
									setIsOpenCancel(true)
								}}
							/>
						</div>
					</div>
				</div>
          </>
          }
			</section>
			<Modal
				isOpen={isOpenCancel}
				handleClose={() => {
					setIsOpenCancel(false)
				}}
			>
				<div>
					<h1 className='title is-dark mb-30'>Cancel subscription</h1>
					<p className='text is-light is-bold left'>You are cancelling your 
          <span className='text is-orange is-bold ml-5'>{plan && plan.name}</span> subscription.</p>
          <p className='text is-light is-bold left'>You will still have access to the platform until the end of your last payment cycle on 
          <span className='text is-orange is-bold ml-5'>{subscription && subscription.current_period_end}</span>.</p>

					<div className='right center-mobile'>
						<Button
							text='Cancel'
							class='mt-30 mx-15 white-btn subheading is-dark'
							onClick={() => {
								setIsOpenCancel(false)
							}}
						/>
						<Button
							text='Save changes'
							class='orange-btn mt-30 subheading is-white'
							onClick={handleCancel}
						/>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default OrganizationPlan
