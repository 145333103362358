import { MentionsInput, Mention } from 'react-mentions'
import { cloneElement, Children } from 'react'

const Mentions = (props) => {
	// console.log(props)

	const handleUserDisplay = (id, display) => `@${display}`

	const handleProjectDisplay = (id, display) => `$${display}`

	const handleBroadcastDisplay = (id, display) => `#${display}`

	const handleRenderSuggestion = (entry, search, highlightedDisplay, index, focused) => {
		if (!focused) {
			return highlightedDisplay
		}

		const children = Children.map(highlightedDisplay.props.children, (child) => {
			if (!(typeof child == 'string')) {
				return cloneElement(child, {
					className: 'is-orange is-text is-dark',
				})
			} else {
				return child
			}
		})

		const newhighlightedDisplay = cloneElement(highlightedDisplay, { className: '' }, children)
		return newhighlightedDisplay
	}

	var mentions = []
	if (props.channelUsers) {
		mentions.push(
			<Mention
				trigger='@'
				data={props.channelUsers}
				displayTransform={handleUserDisplay}
				appendSpaceOnAdd={true}
				onAdd={props.handleAddUser}
				renderSuggestion={handleRenderSuggestion}
			/>
		)
	}
	if (props.channelProjects) {
		mentions.push(
			<Mention
				trigger='$'
				data={props.channelProjects}
				displayTransform={handleProjectDisplay}
				appendSpaceOnAdd={true}
				onAdd={props.handleAddProject}
				renderSuggestion={handleRenderSuggestion}
			/>
		)
	}
	if (props.broadcastOptions) {
		mentions.push(
			<Mention
				trigger='#'
				data={props.broadcastOptions}
				displayTransform={handleBroadcastDisplay}
				appendSpaceOnAdd={true}
				onAdd={props.handleAddBroadcast}
			/>
		)
	}
	var meessage_field = (
		<MentionsInput
			id='chat-input-text'
			name='message'
			value={props.textValue}
			onChange={props.handleChange}
			className='mentions'
			placeholder='Enter your message...'
			allowSuggestionsAboveCursor={true}
			allowSpaceInQuery={true}
		>
			{mentions}
		</MentionsInput>
	)

	return meessage_field
}

export default Mentions
