/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const ticketService = {
  list,
  retrieve,
  create,
  filters,
  update,
  addMessage,
}

async function list(offset = 0, limit = 10) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/support/tickets/?limit=${limit}&offset=${offset}`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}

async function retrieve(ticket_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/support/tickets/${ticket_id}/`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}

async function create(body) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/support/tickets/`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}

async function filters(body, offset = 0, limit = 10) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/support/tickets/filters/?limit=${limit}&offset=${offset}`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}

async function update(body, ticket_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/support/tickets/${ticket_id}/`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}

async function addMessage(body, ticket_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body }

  return fetch(`${config.apiUrl}/support/tickets/${ticket_id}/add_message/`, requestOptions)
    .then(handleResponse)
    .then(ticket => {
      return ticket
    })
}
