import { useState, useEffect } from 'react'

// Components
import Button from '../../Elements/Button'
import Dropdown from '../../Elements/Dropdown'
import InputField from '../../Elements/InputField'
import ReactFlagsSelect from 'react-flags-select'
import Tooltip from '../../Elements/Tooltip'

const StartPointForm = (props) => {
	const [calling_code, setCallingCode] = useState('+1')
	const [errors, setErrors] = useState({})
	const country_calling_codes = { US: { primary: '+1' } }
	const [projectType, setProjectType] = useState('PERSON')

	function changeCountryCallingCode(value) {
		setCallingCode(country_calling_codes[value]['primary'])
	}

	function handleSubmit(e) {
		e.preventDefault()

		var form_element = document.getElementById('project-start-form')
		var form_data = new FormData(form_element)

		var subject_data = {}
		var empty = true
		var errors_ = {}
		form_data.forEach((value, key) => {
			if (value) {
				empty = false

				if (key.includes('phones')) {
					subject_data['calling_code'] = calling_code
					if (!/^[0-9]+$/.test(value)) {
						errors_[key] = 'This field can only contain numbers.'
					}
				}
				subject_data[key] = value
			}
		})

		if (Object.keys(errors_).length > 0) {
			setErrors(errors_)
			return
		}

		if (empty) {
			setErrors({ non_field_errors: 'You must fill at least one value' })
		} else {
			props.onUpdate({ subject_data })
			props.onNext()
		}
	}

	useEffect(() => {
		props.data.subject_data?.type && setProjectType(props.data.subject_data.type)
	}, [props.data.subject_data])

	const handleChange = (e) => {
		let selected_type = e.target.value
		setProjectType(selected_type)
	}

	let subject_data = props.data ? props.data.subject_data : null

	let input_email = (
		<>
			<div className='flexer'>
				<i className='form-icon fa fa-envelope' />
				<Tooltip content='example@example.com' direction='top' class='full-width'>
					<InputField
						class='full-width line-margin-xs'
						type='email'
						label='Email'
						name='email'
						value={subject_data?.email ? subject_data.email : null}
						error={errors.email}
					/>
				</Tooltip>
			</div>
		</>
	)

	let input_website = (
		<>
			<div className='flexer'>
				<i className='form-icon fa fa-globe' />
				<Tooltip
					content='http://www.example.com or https://www.example.com'
					direction='top'
					class='full-width'
				>
					<InputField
						class='full-width line-margin-xs'
						type='url'
						label='Website'
						name='website'
						value={subject_data?.website ? subject_data.website : null}
						error={errors.non_field_errors || errors.website}
					/>
				</Tooltip>
			</div>
		</>
	)

	let input_phone = (
		<>
			<div className='flexer'>
				<i className='form-icon fa fa-phone' />
				<ReactFlagsSelect
					selected={'US'}
					countries={['US']}
					customLabels={country_calling_codes}
					onSelect={changeCountryCallingCode}
				/>
				<Tooltip content='55555555' direction='top' class='full-width'>
					<InputField
						type='tel'
						class='full-width line-margin-xs'
						name='phones__phone_number'
						value={subject_data?.phones__phone_number ? subject_data.phones__phone_number : null}
						error={errors.phones__phone_number}
					/>
				</Tooltip>
			</div>
		</>
	)

	let input_fields
	if (projectType === 'PERSON') {
		input_fields = (
			<>
				<div className='flexer mt-30'>
					<i className='form-icon fas fa-user-alt' />
					<InputField
						class='full-width line-margin-xs'
						label='First name'
						name='first_name'
						value={subject_data?.first_name ? subject_data.first_name : null}
						error={errors.first_name}
					/>
				</div>
				<div className='flexer'>
					<i className='form-icon fas fa-user-alt' />
					<InputField
						class='full-width line-margin-xs'
						label='Middle name'
						name='middle_name'
						value={subject_data?.middle_name ? subject_data.middle_name : null}
						error={errors.middle_name}
					/>
				</div>
				<div className='flexer'>
					<i className='form-icon fas fa-user-alt' />
					<InputField
						class='full-width line-margin-xs'
						label='Last name'
						name='last_name'
						value={subject_data?.last_name ? subject_data.last_name : null}
						error={errors.last_name}
					/>
				</div>
				<div className='flexer'>
					<i className='form-icon fas fa-user-alt' />
					<InputField
						class='full-width line-margin-xs'
						label='Alias'
						name='alias__first_name'
						value={subject_data?.alias__first_name ? subject_data.alias__first_name : null}
						error={errors.alias__first_name}
					/>
				</div>
				{input_email}
				{input_phone}
				<div className='flexer'>
					<i className='form-icon fa fa-globe' />
					<Tooltip
						content='http://www.example.com or https://www.example.com'
						direction='top'
						class='full-width'
					>
						<InputField
							class='full-width line-margin-xs'
							type='url'
							label='Profile URL'
							name='profile_page'
							value={subject_data ? subject_data.profile_page : null}
							error={errors.non_field_errors || errors.profile_page}
						/>
					</Tooltip>
				</div>
			</>
		)
	} else if (projectType === 'ORGANIZATION') {
		input_fields = (
			<>
				<div className='flexer mt-30'>
					<i className='form-icon fa fa-building' />
					<InputField
						class='full-width line-margin-xs'
						label='Organization name'
						name='organization_name'
						value={subject_data?.organization_name ? subject_data.organization_name : null}
						error={errors.organization_name}
					/>
				</div>
				{input_email}
				{input_phone}
				{input_website}
			</>
		)
	} else {
		input_fields = (
			<>
				<div className='flexer mt-30'>
					<i className='form-icon fa fa-calendar-check-o' />
					<InputField
						class='full-width line-margin-xs'
						label='Event name'
						name='event_name'
						value={subject_data?.event_name ? subject_data.event_name : null}
						error={errors.event_name}
					/>
				</div>
				<div className='flexer mt-30'>
					<i className='form-icon fas fa-user-alt' />
					<InputField
						class='full-width line-margin-xs'
						label='Organizer name'
						name='organizer_name'
						value={subject_data?.organizer_name ? subject_data.organizer_name : null}
						error={errors.organizer_name}
					/>
				</div>
				{input_email}
				{input_phone}
				{input_website}
			</>
		)
	}

	return (
		<div className='half-width center'>
			<form id='project-start-form' className='left mt-60' onSubmit={handleSubmit}>
				<Dropdown
					name='type'
					label='Subject Type'
					type='text'
					class='line-margin-xs mb-30'
					onChange={handleChange}
				>
					<option value='PERSON' selected={subject_data?.type === 'PERSON'}>
						Person
					</option>
					<option value='ORGANIZATION' selected={subject_data?.type === 'ORGANIZATION'}>
						Organization
					</option>
					<option value='EVENT' selected={subject_data?.type === 'EVENT'}>
						Event
					</option>
				</Dropdown>
				{input_fields}
				<div className='right center-mobile'>
					<Button text='Next step &rarr;' class='mt-15 orange-btn subheading is-light' />
				</div>
			</form>
		</div>
	)
}

export default StartPointForm
