import config from './config';
import { handleResponse } from './handle-response';
// import { fixBody } from './helpers'

export const eventService = {
	list,
  mark_as_read,
}

async function list( org_id, limit, offset) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/events/?unseen=True&limit=${limit}&offset=${offset}`, requestOptions)
    .then(handleResponse)
    .then(event => {
      return event
    })
}
async function mark_as_read( org_id, event_id) {
  const requestOptions = { method: 'POST', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/events/${event_id}/mark_as_read/`, requestOptions)
    .then(handleResponse)
    .then(event => {
      return event
    })
}