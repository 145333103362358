import MenuButton from '../Elements/MenuButton'
import PermissionWrapper from '../Permissions/PermissionWrapper'

const OrganizationSettingsMenu = ({ handleActive, isActive }) => (
	<aside className='simple-profile flexer-3 center'>
		<article className='simple-profile__sections'>
			<p className='text is-light left'>Profile</p>
			<MenuButton
				text='Profile Info'
				onClick={() => handleActive('profile')}
				active={isActive === 'profile'}
			/>
			<PermissionWrapper
				requiredPermissions={['organizations::manage_organization']}
				checkAll={false}
			>
				<MenuButton
					text='Edit Profile'
					onClick={() => handleActive('edit-profile')}
					active={isActive === 'edit-profile'}
				/>
			</PermissionWrapper>
		</article>

		<article className='simple-profile__sections'>
			<p className='text is-light left'>Members</p>
			<PermissionWrapper
				requiredPermissions={['users::manage_users', 'organizations::manage_roles']}
				checkAll={false}
			>
				<MenuButton
					text='Active Members'
					onClick={() => handleActive('active-members')}
					active={isActive === 'active-members'}
				/>
			</PermissionWrapper>

			<PermissionWrapper requiredPermissions={['organizations::add_user']} checkAll={false}>
				<MenuButton
					text='Pending Verification'
					onClick={() => handleActive('verification')}
					active={isActive === 'verification'}
				/>
			</PermissionWrapper>

			<PermissionWrapper requiredPermissions={['organizations::add_user']} checkAll={false}>
				<MenuButton
					text='Pending Invitations'
					onClick={() => handleActive('invitations')}
					active={isActive === 'invitations'}
				/>
			</PermissionWrapper>
		</article>

		<PermissionWrapper requiredPermissions={['torch::manage_project_types', 'torch::manage_report_template']} checkAll={false}>
			<article className='simple-profile__sections'>
				<p className='text is-light left'>Projects</p>
				{/* <MenuButton
				text='Source Categories'
				onClick={() => handleActive('source-categories')}
				active={isActive === 'source-categories'}
			/> */}
			<PermissionWrapper requiredPermissions={['torch::manage_project_types']} checkAll={false}>
				<MenuButton
					text='Project Types'
					onClick={() => handleActive('types')}
					active={isActive === 'types'}
				/>
					</PermissionWrapper>
			<PermissionWrapper requiredPermissions={['torch::manage_report_template']} checkAll={false}>
				<MenuButton
					text='Report Templates'
					onClick={() => handleActive('templates')}
					active={isActive === 'templates'}
				/>
		</PermissionWrapper>
			</article>
		</PermissionWrapper>

		<PermissionWrapper requiredPermissions={['torch::manage_subscription']} checkAll={false}>
			<article className='simple-profile__sections'>
				<p className='text is-light left'>Membership</p>

				<MenuButton
					text='Your Plan'
					onClick={() => handleActive('plan')}
					active={isActive === 'plan'}
				/>

				<MenuButton
					text='Billing and Payment'
					onClick={() => handleActive('payment')}
					active={isActive === 'payment'}
				/>
			</article>
		</PermissionWrapper>
	</aside>
)

export default OrganizationSettingsMenu
