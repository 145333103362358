import React from 'react'

// Components
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'
import useOrgId from '../../../hooks/useOrgId'

// API
import { webscraperService } from '../../../api'

function EditNoteScraperForm(props) {
    const { data, onClose } = props
    const orgId = useOrgId()
    
    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('note-form')
        var form_data = new FormData(form_element)
        
        let body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })

        webscraperService.updateNote(body, orgId, data.id)
        .then(response => {
            onClose()
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <form id="note-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Edit note</h1>
            <Textarea name="content" label="Notes" placeholder={data.content}/>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
            </div>
        </form>
    )
}

export default EditNoteScraperForm
