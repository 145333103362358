import React, {useState} from 'react'

// Components
import InputField from '../../Elements/InputField'
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'
import Dropdown from '../../Elements/Dropdown'
// API
import {profileService} from '../../../api'

function ExperienceForm(props) {
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)

    function handleSubmit(e){
        e.preventDefault()

        setSubmitting(true)

        var form_element = document.getElementById('signup-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })
        profileService.create_info(body, "experience")
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
            setSubmitting(false)
        })

    }

    return (
        <form id="signup-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Add Experience</h1>
            <InputField name="title" label="Title" type="text" required={true} error={errors.title}/>
            <InputField name="company" label="Company" type="text" required={true} error={errors.company}/>
            <Dropdown name="emp_type" label="Employment type" required={true} error={errors.emp_type}>
                <option value="full_time">Full time</option>
                <option value="part_option">Part time</option>
                <option value="self_employed">Self employed</option>
                <option value="freelance">Freelance</option>
                <option value="contract">Contract</option>
                <option value="internship">Internship</option>
                <option value="apprenticeship">Apprenticeship</option>
            </Dropdown>
            <div className="flexer flexer-spaced mt-15">
                <InputField name="start_date" label="Start date" type="date" class="flexer-6" required={true} error={errors.start_date}/>
                <InputField name="end_date" label="Finish date" type="date" class="flexer-6" error={errors.end_date}/>
            </div>
            <Textarea name="description" label="Description" error={errors.description || errors.non_field_errors}/>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" disabled={submitting}/>
            </div>
        </form>
    )
}

export default ExperienceForm