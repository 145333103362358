import SourceCategoryItem from 'components/Organization/SourceCategory/Item/SourceCategoryItem'
import SourceCategoryItemSkt from 'components/Organization/SourceCategory/Item/SourceCategoryItemSkt'

import styles from './sourceCategory.module.sass'

const SourceCategoryList = ({ onLoading, setToEdit, setToDelete, categoryList }) =>
	onLoading ? (
		<>
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
			<SourceCategoryItemSkt />
		</>
	) : categoryList?.length > 0 ? (
		categoryList.map((category) => (
			<SourceCategoryItem
				key={category.id}
				category={category}
				setToEdit={setToEdit}
				setToDelete={setToDelete}
			/>
		))
	) : (
		<h3 className={styles.body_empty}>No categories found</h3>
	)

export default SourceCategoryList
