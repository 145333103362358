import React, { useState } from "react";

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
        setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    if (props.noBg) {
        return (
            <div className={`tooltip-wrapper ${props.class}`} onMouseEnter={showTip} onMouseLeave={hideTip}>
                {props.children}
                {active && (
                    <div className={`tooltip-tip noBg ${props.direction || "top"}`}>
                        {props.content}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={`tooltip-wrapper ${props.class}`} onMouseEnter={showTip} onMouseLeave={hideTip}>
            {props.children}
            {active && (
                <div className={`tooltip-tip ${props.direction || "top"}`}>
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;