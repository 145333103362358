// eslint-disable-next-line no-unused-vars
import { useContext } from 'react'

import useOrgId from './useOrgId'
import { UserContext } from './UserContext'

export default function useUserRole(onlyMember=false) {
  const { currentUser, currentOrganizations } = useContext(UserContext)
  const orgId = useOrgId()

  if (currentUser.role === 'SUPER_ADMIN' && !onlyMember){
    return currentUser.role
  }
  
  return currentOrganizations?.[orgId]?.role

}