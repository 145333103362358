import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {useForm} from 'react-hook-form'
import {LoadingButton} from '@mui/lab'
import styled from 'styled-components'
import {useEffect, useState} from 'react'

import {getPostalCode} from '../../../../helpers/index.helpers'
import {FormFieldset} from '../../../molecules/index.molecules'
import {FormInput, FormSelect} from '../../../atoms/index.atoms'
import config from '../../../../api/config'
import {Button} from '@mui/material'

const LocationForm = ( { hdlSubmit, onLoading, cta, subjectType, editedLocation, formType } ) => {
	const [ categories, setCategories ] = useState( [] )

	const { register, handleSubmit, reset } = useForm()

	const hdlSelectedLocation = async ( data ) => {
		const { terms, place_id } = data.value
		const fullAddress = terms.reverse()

		const address = {}

		for ( let i = 0; i < fullAddress.length; ) {
			switch ( i ) {
				case 0:
					address.country = fullAddress[ i ].value
					break
				case 1:
					address.state = fullAddress[ i ].value
					break
				case 2:
					address.city = fullAddress[ i ].value
					break
				case 3:
					address.apartmentSuite = fullAddress[ i ].value
					address.streetAddress = fullAddress[ i ].value
					break
				case 4:
					address.streetAddress = fullAddress[ i ].value
					break
				default:
					break
			}

			if ( i === fullAddress.length - 1 ) {
				address.zip = await getPostalCode( place_id )
			}

			i++
		}

		reset( address )
	}

	const hdlProps = () => {
		const address = {
			'country': editedLocation?.country?.content || '',
			'state': editedLocation?.state_region?.content || '',
			'city': editedLocation?.city?.content || '',
			'streetAddress': editedLocation?.street_address?.content || '',
			'apartmentSuite': editedLocation?.street_address_2?.content || '',
			'zip': editedLocation?.zip?.content || '',
			'category': editedLocation?.category === 'OTHER'
				? 1
				: editedLocation?.category === 'LAST_KNOWN_ADDRESS'
					? 2
					: editedLocation?.category === 'OCCUPATION'
						? 3
						: editedLocation?.category === 'RESIDENCE'
							? 4
							: '',
		}
		reset( address )
	}

	useEffect( () => {
		if ( editedLocation ) {
			hdlProps()
		}

		if ( subjectType === 'PERSON' ) {
			setCategories( [
				{
					value: 1,
					label: 'Other',
				},
				{
					value: 2,
					label: 'Last Known Address',
				},
				{
					value: 3,
					label: 'Occupation',
				},
				{
					value: 4,
					label: 'Residence',
				},
			] )
		} else {
			setCategories( [
				{
					value: 1,
					label: 'Other',
				},
				{
					value: 2,
					label: 'Last Known Address',
				},
			] )
		}
	}, [] )

	return (
		<>
			<h1 className="title is-dark mb-30">{ formType } Location</h1>
			<FormStyled onSubmit={ handleSubmit( hdlSubmit ) }>
				<FormFieldset name="Category">
					<FormSelect customAttribute={ { ...register( 'category' ) } } placeholder=' '>
						{ categories.map( ( { label, value } ) => (
							<option value={ value }>{ label }</option>
						) ) }
					</FormSelect>
				</FormFieldset>
				<FormFieldset name='Search an Address'>
					<GPAWrapper>
						<GooglePlacesAutocomplete
							apiKey={ config.googleKey }
							selectProps={ {
								onChange: hdlSelectedLocation,
								placeholder: '',
							} }/>
					</GPAWrapper>
				</FormFieldset>
				<FormFieldset name="Street Address">
					<FormInput customAttributes={ { ...register( 'streetAddress' ) } }/>
				</FormFieldset>
				<FormFieldset name="Apartment, Suite, etc.">
					<FormInput customAttributes={ { ...register( 'apartmentSuite' ) } }/>
				</FormFieldset>
				<FormFieldset name="City">
					<FormInput customAttributes={ { ...register( 'city' ) } }/>
				</FormFieldset>
				<FormFieldset name="State">
					<FormInput customAttributes={ { ...register( 'state' ) } }/>
				</FormFieldset>
				<FormFieldset name="Country">
					<FormInput customAttributes={ { ...register( 'country' ) } }/>
				</FormFieldset>
				<FormFieldset name="ZIP Code">
					<FormInput customAttributes={ { ...register( 'zip' ) } }/>
				</FormFieldset>
				<FooterStyled>
					<Button
						onClick={ cta }
						size={ 'large' }
						disableElevation
						type={ 'button' }
						color={ 'inherit' }
						variant={ 'contained' }
						style={ {
							color: 'var(--secondary-text)',
							border: '1px solid rgba(39, 39, 39, 0.1)',
							backgroundColor: 'var(--secondary-btn-text)',
						} }
					>Cancel</Button>
					<LoadingButton
						size={ 'large' }
						disableElevation
						type={ 'submit' }
						color={ 'primary' }
						loading={ onLoading }
						variant={ 'contained' }
					>Save changes</LoadingButton>
				</FooterStyled>
			</FormStyled>
		</>
	)
}

const FormStyled = styled.form`
  display: grid;
  row-gap: 1rem;
  padding: 1rem 0;
`

const FooterStyled = styled.footer`
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
`

const GPAWrapper = styled.article`
  div {
    color: var(--main-text) !important;

    :nth-child(2) {
      border-radius: 0;
      border: none !important;
      box-shadow: none !important;
      outline: transparent !important;
      background-color: transparent !important;
      border-bottom: 1px solid var(--border) !important;

      :focus {
        border-bottom-color: #f69221 !important;
      }

      div {
        border: none !important;
      }
    }

    :nth-child(3) {
      background-color: var(--main-bg) !important;

      div {
        div {
          :hover {
            color: var(--main-text) !important;
            background-color: var(--card-bg) !important;
          }
        }
      }
    }
  }
`

export default LocationForm
