import React from 'react'

// Components
import MultiStep from '../../components/CreateProject/MultiSteps'
import UploadAttachmentsForm from '../../components/CreateProject/Forms/UploadAttachmentsForm'

function UploadAttachments(props) {
    return (
        <React.Fragment>
            <h1 className="title-2 is-dark line-margin-0">Project Generation</h1>
            <MultiStep current={4} />
            <UploadAttachmentsForm onNext={props.onNext} onPrevious={props.onPrevious} onUpdate={props.onUpdate} data={props.data}/>
        </React.Fragment>
    )
}

export default UploadAttachments