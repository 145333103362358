import React, { useEffect, useState } from 'react'
import { organizationService } from 'api'
import { Button, Modal, CreditCard } from 'components'
import { useOrgId } from 'hooks'

export const OrganizationPayment = () => {

	const [isOpen, setIsOpen] = useState(false)
	const [showAllCards, setShowAllCards] = useState(false)
	const [payment, setPayment] = useState(null)
	const [paymentMethods, setPaymentMethods] = useState([])
	const [subscription, setSubscription] = useState(null)
	const orgId = useOrgId()

	useEffect(() => {

		organizationService.listSubscription(orgId)
			.then(res => {
				setSubscription(res[0])
				return res[0]
			})
			.then(subs => {
				organizationService.paymentMethods(orgId)
				.then((res) => {
					setPaymentMethods(res)
					if(subs.default_payment_method){
						let paymentMethod = res.find(pm => pm.id === subs.default_payment_method)
						setPayment(paymentMethod)
					}	
					else {
						setPayment(res[0])
					}

					if(res.length > 1){
						setShowAllCards(true)
					}
				})
				.catch((error) => {
					console.log('Error loading organization subscriptions', error)
				})
			})
			.catch(error => {
				console.log("Error loading organization subscriptions", error)
			})
	}, [])

	function handleDelete(e){
		let cardId = e.currentTarget.getAttribute('id')
		let body = {
			payment_method: cardId,
			subscription: subscription.stripe_subscription_id
		}
		organizationService.deletePaymentMethod(orgId, body)
		.then(
			setPaymentMethods(
				paymentMethods.filter(card => card.id !== cardId)
			)
		)
		.catch(e => {
			console.error("It was not possible to delete payment method", e)
		})
	}

	function handleSelect(e){
		let cardId = e.currentTarget.getAttribute('id')
		let body = {
			payment_method: cardId,
			subscription: subscription.stripe_subscription_id
		}
		organizationService.setDefaultPaymentMethod(orgId, body)
		.then(
			setPayment(
				paymentMethods.find(card => card.id === cardId)
			)
		)
		.then(()=>{
			setIsOpen(false)
		})
		.catch(e => {
			console.error("It was not possible to set payment method as default", e)
		})
	}

	let cards 
	if (paymentMethods.length > 0){
		cards = paymentMethods.filter(card => card.id !== payment?.id).map(card => {
				return (
					<tr className="table-rows flexer">
						<td className="text is-light full-width left side-margin-0">...{card.last4}</td>
						<td className="text is-light full-width left side-margin-0">{card.brand.toUpperCase()}</td>
						<td className="text is-light full-width left side-margin-0">{card.exp_month}/{card.exp_year}</td>
						<td className="text flexer-1 right side-margin-0 cursor">
							<i className="fa fa-check mr-10 is-light" id={card.id} onClick={handleSelect}></i>
						</td>
						<td className="text flexer-1 right side-margin-0 cursor">
							<i className="fa fa-trash mr-10 is-light" id={card.id} onClick={handleDelete}></i>
						</td>
					</tr>
				)
		})
	}

	return (
		<React.Fragment>
		<section className='dashboard-section'>
			<p className='text is-light is-bold left mb-30'>Credit Card Details</p>
			<div className='two-third-width'>
				<div className='flexer flexer-spaced one-third-width my-10'>
				<p className='text is-dark line-margin-xs'>Number:</p>
				<p className='text is-light is-bold line-margin-xs'>{payment && ` ... ${payment.last4}`}</p>
				</div>
				<div className='flexer flexer-spaced one-third-width my-10'>
				<p className='text is-dark line-margin-xs'>Type:</p>
				<p className='text is-light is-bold line-margin-xs'>{payment && payment.brand.toUpperCase()}</p>
				</div>
				<div className='flexer flexer-spaced one-third-width my-10'>
				<p className='text is-dark line-margin-xs'>Expiry date::</p>
				<p className='text is-light is-bold line-margin-xs'>
					{payment && `${payment.exp_month}/${payment.exp_year}`}
				</p>
				</div>
			</div>
			<div className='flexer'>
				<div className='flexer-8'></div>
				<div className='flexer-4'>
					<Button
						text='Change payment method'
						class='list-menu mt-30'
						onClick={() => {
							setIsOpen(true)
						}}
					/>
				</div>
			</div>
		</section>
		<Modal
			isOpen={isOpen}
			handleClose={() => {
				setIsOpen(false)
			}}
		>
				<div>
					<h1 className='title is-dark mb-30'>Change Payment Method</h1>
					{
						showAllCards ?
						<>
							<p className='text is-light is-bold left'>Select a payment method <span className='text is-orange cursor is-link left' onClick={()=>{setShowAllCards(false)}}> or create a new one</span></p> 
							<table className="table" cellSpacing="0">
								<thead>
								<tr className="table-header flexer mt-15">
									<th className="text is-light full-width left side-margin-0">Card</th>
									<th className="text is-light full-width left side-margin-0">Type</th>
									<th className="text is-light full-width left side-margin-0">Exp. Date</th>
									<th className="text is-light flexer-1 left side-margin-0"></th>
									<th className="text is-light flexer-1 side-margin-0"></th>
								</tr>
								</thead>
								<tbody>
									{cards}
								</tbody>
							</table>
						</>
						:
						<>
							<p className='text is-light is-bold left'>Create a payment method<span className='text is-orange cursor is-link left' onClick={()=>{setShowAllCards(true)}}> or select one</span></p> 
							<CreditCard subscription={subscription} onClose={() => {setIsOpen(false)}}/>	
						</>
					}
				</div>
			</Modal>
		</React.Fragment>
	)
}

export default OrganizationPayment
