import React, { useState, useEffect } from 'react'

// Components
import ImageContent from '../../Elements/ImageContent'
import InputField from '../../Elements/InputField'
import Button from '../../Elements/Button'
import ReactFlagsSelect from 'react-flags-select'
import useOrgId from '../../../hooks/useOrgId'
import Tooltip from '../../Elements/Tooltip'

// API
import { subjectService } from '../../../api'

function EditProfileForm(props) {
	const { subject, onComplete, onClose } = props
	const [calling_code, setCallingCode] = useState('+1')
	const [aliasesList, setAliasesList] = useState([])
	const [phonesList, setPhonesList] = useState([])

	const orgId = useOrgId()

	const [inputValue, setInputValue] = useState({
		first_name: '',
		middle_name: '',
		last_name: '',
		email: '',
		dob: '',
		organization_name: '',
		website: '',
		event_name: '',
		organizer_name: '',
	})

	const [aliasValue, setAliasValue] = useState([])
	const [toDeleteAlias, setToDeleteAlias] = useState([])
	const [canDeleteAlias, setCanDeleteAlias] = useState(false)

	const [phonesValue, setPhonesValue] = useState([])
	const [toDeletePhones, setToDeletePhones] = useState([])
	const [canDeletePhones, setCanDeletePhones] = useState(false)

	const [onEdit, setOnEdit] = useState(false)

	const [profileImage, setProfileImage] = useState(null)
	const [hasNewImage, setHasNewImage] = useState(false)

	if (!profileImage && subject?.profile_pic?.content) {
		setProfileImage(subject.profile_pic.content)
	}

	useEffect(() => {
		let aliasObj
		if (subject?.alias?.length) {
			aliasObj = subject.alias.map((item) => {
				return { id: item.id, content: item.display_alias }
			})
		}
		setAliasValue(aliasObj)
	}, [subject.alias])

	useEffect(() => {
		let phoneObj
		if (subject?.phones?.length) {
			phoneObj = subject.phones.map((item) => {
				return { id: item.id, content: item?.phone_number?.content }
			})
		}
		setPhonesValue(phoneObj)
	}, [subject.phones])

	useEffect(() => {}, [profileImage])

	useEffect(() => {
		if (subject) {
			setTimeout(() => {
				setInputValue({
					first_name: subject?.first_name?.content,
					middle_name: subject?.middle_name?.content,
					last_name: subject?.last_name?.content,
					email: subject?.email?.content,
					dob: subject?.dob?.content?.split(' ')[0],
					// dob: subject?.proper_dob,
					organization_name: subject?.organization_name?.content,
					website: subject?.website?.content,
					event_name: subject?.event_name?.content,
					organizer_name: subject?.organizer_name?.content,
				})
			}, 200)
		}
	}, [subject])

	const country_calling_codes = { US: { primary: '+1' } }
	function changeCountryCallingCode(value) {
		setCallingCode(country_calling_codes[value]['primary'])
	}

	const hdlChange = (e, field) => {
		setInputValue({ ...inputValue, [field]: e.target.value })
	}

	const hdlChangeAlias = (e, id) => {

		let aliasArray = aliasValue
		aliasArray = aliasArray.filter((item) => item.id !== id)
		setAliasValue([...aliasArray, { id: id, content: e.target.value }])
	}
	const hdlChangePhone = (e, id) => {

		let phonesArray = phonesValue
		phonesArray = phonesArray.filter((item) => item.id !== id)
		setPhonesValue([...phonesArray, { id: id, content: e.target.value }])
	}

	function handleSubmit(e) {
		e.preventDefault()

		var form_element = document.getElementById('subject-form')
		var form_data = new FormData(form_element)

		var body = { alias: [], phones: [] }
		let body_subject = {}
		let body_new = { alias: [], phones: [] }

		form_data.forEach((value, key) => {
			// if (value) {
			if (key.includes('phones') && !value.includes('+')) {
				value = calling_code + value
			}

			if (key.includes('__')) {
				key = key.split('__')
				var subelement = {}
				if (key.length > 2) {
					subelement[key[2]] = { [key[1]]: { content: value } }
					body[key[0]].push(subelement)
				} else {
					subelement[key[1]] = { content: value }
					body_new[key[0]].push(subelement)
				}
			} else {
				body_subject[key] = { content: value }
			}
			// }
		})

		Object.entries(body_subject).forEach((key) => {

			if (key[1]?.content === subject[key[0]]?.content) { // delete the key if no change
				delete body_subject[key[0]]
			}
			if (key[1]?.content === '' && (!subject[key[0]]?.content)) {
				delete body_subject[key[0]]
			}
			if (key[0] === 'dob') {
				if (key[1]?.content === subject.dob?.content?.split(' ')[0]) {
					delete body_subject.dob
				}
			}

		})

		Object.keys(body_subject)?.length && subjectService
			.update(body_subject, orgId, props.subject.id)
			.then((response) => {
				props.onComplete()
				props.onClose()
			})
			.catch((error) => {
				console.log('Error updating subject profile', error)
			})

		body.alias.forEach((e) => {
			subjectService
				.updateAlias(Object.values(e)[0], orgId, Object.keys(e)[0])
				.then((response) => console.log('alias updated'))
				.then(() => {
					props.onComplete()
					props.onClose()
				})
				.catch((error) => {
					console.log('Error updating subject aliase', error)
				})
		})

		body.phones.forEach((e) => {
			subjectService
				.updatePhone(Object.values(e)[0], orgId, Object.keys(e)[0])
				.then((response) => console.log('phone updated'))
				.then(() => {
					props.onComplete()
					props.onClose()
				})
				.catch((error) => {
					console.log('Error updating subject phone', error)
				})

		})

		body_new.alias.forEach((e) => {
			if (e?.first_name?.content?.length) { // avoid create an empty alias
				subjectService
					.createAlias({ ...e, subject: props.subject.id }, orgId)
					.then((response) => console.log('alias created'))
					.then(() => {
						props.onComplete()
						props.onClose()
					})
					.catch((error) => {
						console.log('Error creating subject alias', error)
					})
			}
		})

		body_new.phones.forEach((e) => {
			if (e?.phone_number?.content?.length > 2) { // avoid create a +1 phone
				console.log(e)
				subjectService
					.createPhone({ ...e, subject: props.subject.id }, orgId)
					.then((response) => console.log('phone created'))
					.then(() => {
						props.onComplete()
						props.onClose()
					})
					.catch((error) => {
						console.log('Error creating subject phone', error)
					})
			}
		})

		var form_element_pic = document.getElementById('subject-image-form')
		var form_data_pic = new FormData(form_element_pic)
		var UUIDFile, currentFile, uniqueName
		
		for (var pair of form_data_pic.entries()) {			
			UUIDFile=pair[1]
			currentFile=pair[0]
			uniqueName = crypto.randomUUID() + '-' + pair[1].name
		}
		form_data_pic.set(currentFile,UUIDFile,uniqueName)

		hasNewImage &&
			subjectService
				.updateImage(form_data_pic, orgId, props.subject.id)
				.then((response) => {
					props.onComplete()
					props.onClose()
					setHasNewImage(false)
				})
				.catch((error) => {
					console.log('Error updating subject profile picture', error)
				})

		canDeleteAlias && deleteAlias()

        canDeletePhones && deletePhones()
	}

    // Profile pic
	function handleSubmitImage({ target }) {
		if (target.files && target.files[0]) { 
			var ext =  target.files[0].name.split('.').pop().toLowerCase();
             const allowedExtensions = ["png", "jpeg", "gif", "jpg"];
             if(allowedExtensions.includes(ext)){
				setProfileImage(URL.createObjectURL(target.files[0]))
				setHasNewImage(true)
			 }
		}
	}

	function handleFileLoader() {
		var element = document.getElementById('file-loader-profile')
		element.click()
	}

	// Alias
	function addAlias() {
		setAliasesList([...aliasesList, { first_name: null }])
	}

	const hdlDeleteAlias = (aliasItem) => {
		let alias = aliasValue.filter((item) => item.id !== aliasItem.id)
		setAliasValue(alias)
		setToDeleteAlias((prevState) => [...prevState, aliasItem])
		setCanDeleteAlias(true)
	}

	const deleteAlias = () => {
		toDeleteAlias.map(({ id }) =>
			subjectService.deleteAlias(id, orgId).then((res) => {
				console.log('Alias deleted')
                onComplete()
                onClose()
			})
		)
		setCanDeleteAlias(false)
	}

	// Phones
    function addPhone() {
		setPhonesList([...phonesList, { phone_number: null }])
	}

	const hdlDeletePhones = (phoneItem) => {
		let phone = phonesValue.filter((item) => item.id !== phoneItem.id)
		setPhonesValue(phone)
		setToDeletePhones((prevState) => [...prevState, phoneItem])
		setCanDeletePhones(true)
	}

	const deletePhones = () => {
		toDeletePhones.map(({ id }) =>
			subjectService.deletePhone(id, orgId).then((res) => {
				console.log('Phone deleted')
                onComplete()
                onClose()
			})
		)
		setCanDeletePhones(false)
	}

    // Alias Inputs
	let aliases = []
	aliases = aliasValue?.length ?
		aliasValue.map((item) => (
			<div className='flexer mt-30' key={item.id}>
				<i className='form-icon fas fa-user-alt line-margin-0'></i>
				<fieldset
					style={{
						padding: 0,
						border: 'none',
						width: '100%',
					}}
				>
					<label className='input-field__label'>{'Alias'}</label>
                    <div className='flexer'>
					<input
						name={`alias__first_name__${item.id}`}
						onChange={(e) => hdlChangeAlias(e, item.id)}
						value={
							aliasValue?.length &&
							aliasValue.filter((element) => item.id === element.id)[0].content
						}
						onFocus={() => setOnEdit(true)}
						className={'input-field__input'}
					/>
					<i
						className='fa fa-trash-o is-light cursor'
						onClick={() => hdlDeleteAlias(item)}
					/>
                    </div>
				</fieldset>
			</div>
		)) : []

	for (const alias of aliasesList) {
		aliases.push(
			<div className='flexer mt-30'>
				<i className='form-icon fas fa-user-alt line-margin-0'></i>
				<InputField
					class='full-width line-margin-xs'
					label='Alias'
					name='alias__first_name'
					placeholder={alias.first_name?.content ? alias.first_name.content : ''}
				/>
			</div>
		)
	}

	// Phones Inputs
	let phones = []
	phones = phonesValue?.length ?
		phonesValue.map((phone) => (
			<div className='flexer' key={phone.id}>
				<i className='form-icon fa fa-phone'></i>
				<Tooltip content='+155555555' direction='bottom' class='full-width'>
					<fieldset
						style={{
							padding: '1rem 0',
							border: 'none',
							width: '100%',
						}}
					>
						<label className='input-field__label'>{'Phone'}</label>
                        <div className='flexer flexer-spaced'>
						<input
							name={`phones__phone_number__${phone.id}`}
							onChange={(e) => hdlChangePhone(e, phone.id)}
							value={
								phonesValue?.length &&
								phonesValue.filter((element) => phone.id === element.id)[0].content
							}
							onFocus={() => setOnEdit(true)}
							className={'input-field__input'}
							type='tel'
						/>
						<i
							className='fa fa-trash-o is-light cursor'
							onClick={() => hdlDeletePhones(phone)}
						/>
                        </div>
					</fieldset>
				</Tooltip>
			</div>
		)) : []

	for (const phone of phonesList) {
		phones.push(
			<div className='flexer'>
				<i className='form-icon fa fa-phone'></i>
				<ReactFlagsSelect
					selected={'US'}
					countries={['US']}
					customLabels={country_calling_codes}
					onSelect={changeCountryCallingCode}
				/>
				<Tooltip content='55555555' direction='bottom' class='full-width'>
					<InputField
						type='tel'
						class='full-width line-margin-xs'
						name='phones__phone_number'
						placeholder={phone.phone_number?.content ? phone.phone_number.content : ''}
					/>
				</Tooltip>
			</div>
		)
	}

	const subjectNames = [
		{ name: 'first_name', placeholder: 'First name' },
		{ name: 'middle_name', placeholder: 'Middle name' },
		{ name: 'last_name', placeholder: 'Last name' },
	].map((item) => (
		<div className='flexer mt-30'>
			<i className='form-icon fas fa-user-alt line-margin-0'></i>
			<fieldset
				style={{
					padding: 0,
					border: 'none',
					width: '100%',
				}}
			>
				<label className='input-field__label'>{item.placeholder}</label>
				<input
					name={item.name}
					onChange={(e) => hdlChange(e, item.name)}
					value={inputValue[item.name]}
					onFocus={() => setOnEdit(true)}
					className={'input-field__input'}
				/>
			</fieldset>
		</div>
	))

	return (
		<React.Fragment>
			<form id='subject-image-form' onSubmit={handleSubmitImage}>
				<h1 className='title is-dark mb-30'>Edit Profile</h1>
				<div className='flexer flexer-vcenter'>
					{hasNewImage ? (
						<img className={'edit-profile__picture'} src={profileImage} alt='profile' />
					) : (
						<ImageContent className={'edit-profile__picture'} src={profileImage} alt='profile' />
					)}
					<input
						id='file-loader-profile'
						name='profile_pic'
						type='file'
						accept='.png, .jpeg, .jpg, .gif'
						className='file-input'
						onChange={handleSubmitImage}
					/>
					<Button
						type='button'
						text='Upload photo'
						class='mx-15 orange-btn subheading is-white'
						onClick={handleFileLoader}
					/>
				</div>
			</form>
			<form id='subject-form' onSubmit={handleSubmit}>
				{subject && subject.type === 'PERSON' && (
					<>
						{subjectNames}

						<div className='flexer mt-10'>
							<i className='form-icon fa fa-calendar line-margin-0'></i>
							<fieldset
								style={{
									padding: 0,
									border: 'none',
									width: '100%',
								}}
							>
								<label className='input-field__label'>{'DOB'}</label>
								<input
									name={'dob'}
									onChange={(e) => hdlChange(e, 'dob')}
									value={inputValue.dob}
									onFocus={() => setOnEdit(true)}
									className={'input-field__input full-width line-margin-0'}
									type='date'
								/>
							</fieldset>
						</div>
					</>
				)}
				{subject && subject.type === 'ORGANIZATION' && (
					<>
						<div className='flexer mt-30'>
							<i className='form-icon fas fa-building line-margin-0'></i>
							<fieldset
								style={{
									padding: 0,
									border: 'none',
									width: '100%',
								}}
							>
								<label className='input-field__label'>{'Organization name'}</label>
								<input
									name={'organization_name'}
									onChange={(e) => hdlChange(e, 'organization_name')}
									value={inputValue.organization_name}
									onFocus={() => setOnEdit(true)}
									className={'input-field__input full-width line-margin-0'}
								/>
							</fieldset>
						</div>

						<div className='flexer mt-10'>
							<i className='form-icon fa fa-globe line-margin-0'></i>
							<Tooltip content='http://example.com' direction='bottom' class='full-width'>
								<fieldset
									style={{
										padding: 0,
										border: 'none',
										width: '100%',
									}}
								>
									<label className='input-field__label'>{'Website'}</label>
									<input
										name={'website'}
										onChange={(e) => hdlChange(e, 'website')}
										value={inputValue.website}
										onFocus={() => setOnEdit(true)}
										className={'input-field__input full-width line-margin-0'}
									/>
								</fieldset>
							</Tooltip>
						</div>
					</>
				)}
				{subject && subject.type === 'EVENT' && (
					<>
						<div className='flexer mt-30'>
							<i className='form-icon fa fa-calendar-check-o line-margin-0'></i>
							<fieldset
								style={{
									padding: 0,
									border: 'none',
									width: '100%',
								}}
							>
								<label className='input-field__label'>{'Event name'}</label>
								<input
									name={'event_name'}
									onChange={(e) => hdlChange(e, 'event_name')}
									value={inputValue.event_name}
									onFocus={() => setOnEdit(true)}
									className={'input-field__input full-width line-margin-0'}
								/>
							</fieldset>
						</div>
						<div className='flexer mt-30'>
							<i className='form-icon fas fa-user-alt line-margin-0'></i>
							<fieldset
								style={{
									padding: 0,
									border: 'none',
									width: '100%',
								}}
							>
								<label className='input-field__label'>{'Organizer name'}</label>
								<input
									name={'organizer_name'}
									onChange={(e) => hdlChange(e, 'organizer_name')}
									value={inputValue.organizer_name}
									onFocus={() => setOnEdit(true)}
									className={'input-field__input full-width line-margin-0'}
								/>
							</fieldset>
						</div>

						<div className='flexer mt-10'>
							<i className='form-icon fa fa-globe line-margin-0'></i>
							<Tooltip content='http://example.com' direction='bottom' class='full-width'>
								<fieldset
									style={{
										padding: 0,
										border: 'none',
										width: '100%',
									}}
								>
									<label className='input-field__label'>{'Website'}</label>
									<input
										name={'website'}
										onChange={(e) => hdlChange(e, 'website')}
										value={inputValue.website}
										onFocus={() => setOnEdit(true)}
										className={'input-field__input full-width line-margin-0'}
									/>
								</fieldset>
							</Tooltip>
						</div>
					</>
				)}
				<div className='flexer mt-10'>
					<i className='form-icon fa fa-envelope line-margin-0'></i>
					<Tooltip content='example@example.com' direction='bottom' class='full-width'>
						<fieldset
							style={{
								padding: 0,
								border: 'none',
							}}
						>
							<label className='input-field__label'>{'Email'}</label>
							<input
								name='email'
								onChange={(e) => hdlChange(e, 'email')}
								value={inputValue.email}
								onFocus={() => setOnEdit(true)}
								className={'input-field__input'}
								type='email'
							/>
						</fieldset>
					</Tooltip>
				</div>
				{phones}
				<div className='flexer flexer-vcenter mt-10 cursor' onClick={addPhone}>
					<i className='form-icon fas fa-plus is-orange line-margin-0'></i>
					<p className='link'>Add phone</p>
				</div>
				{subject && subject.type === 'PERSON' && aliases}
				{subject && subject.type === 'PERSON' && (
					<div className='flexer flexer-vcenter mt-10 cursor' onClick={addAlias}>
						<i className='form-icon fas fa-plus is-orange line-margin-0'></i>
						<p className='link'>Add alias</p>
					</div>
				)}
				<div className='right center-mobile'>
					<Button
						text='Cancel'
						class='mt-30 mx-15 white-btn subheading is-dark'
						type='button'
						onClick={props.onClose}
					/>
					<Button text='Save changes' class='orange-btn mt-30 subheading is-white' type='submit' />
				</div>
			</form>
		</React.Fragment>
	)
}

export default EditProfileForm
