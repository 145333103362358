import styled from 'styled-components'

const FormSelect = ( { children, customAttribute, placeholder, isRequired } ) => {
	return (
		<StyledSelect
			{ ...customAttribute }
			required={ isRequired }
		>
			{ placeholder && <option selected disabled>{ placeholder }</option> }
			{ children }
		</StyledSelect>
	)
}

const StyledSelect = styled.select`
  outline: none;
  border: none;
  border-bottom-width: 1px;
  padding: 0.25rem 0.125rem;
  border-bottom-style: solid;
  background-color: transparent;
  border-bottom-color: var(--border);
  transition: border 500ms ease-in-out;

  :focus {
    border-bottom-color: #f69221;
  }
`

export default FormSelect
