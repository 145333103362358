import MenuButton from '../Elements/MenuButton'

const SuperAdminSettingsMenu = (props) => {
	const { handleActive, isActive } = props

	return (
		<>
			<div className='simple-profile'>
				<div className='simple-profile__sections'>
					<p className='text is-light left'>Dashboards</p>
					<MenuButton
						text='Users'
						icon=''
						onClick={() => handleActive('users')}
						active={isActive === 'users'}
					/>
					<MenuButton
						text='Organizations'
						icon=''
						onClick={() => handleActive('organizations')}
						active={isActive === 'organizations'}
					/>
				</div>
			</div>
		</>
	)
}

export default SuperAdminSettingsMenu
