import React, {useState} from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'

// API
import {  applicationService } from '../../api'

function AddOrganizationForm (props) {
	const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)

	function handleSubmit(e){
        e.preventDefault()
        setSubmitting(true)

        var form_element = document.getElementById('signup-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })
        applicationService.create(body.organization) 
        // memberService.create_member(body.organization) 
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            else if(!error.hasOwnProperty('organization') && !error.hasOwnProperty('non_field_errors')){
                error = {organization: "Wrong organization id"}
            }
            
            setErrors({...error})
            setSubmitting(false)
        })
    }

	return (
        <form id="signup-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Request Organization Membership</h1> 
            <InputField name="organization"  label='Organization ID' type="text" required={true} error={errors.organization}/>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Send Request" class="orange-btn mt-30 subheading is-white" type="submit" disabled={submitting}/>
            </div>
		</form>
	)
}

export default AddOrganizationForm