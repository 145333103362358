import { IoOptions } from 'react-icons/io5';
import Avatar from '../Elements/Avatar'
import config from '../../api/config'
// Utilities 
import parse from 'html-react-parser'

function CommentArticle(props) {
    const {element, articleType, showClick} = props

    let articleOptions = showClick ?
            [<span className='is-orange flexer-2 cursor' onClick={props.onClick}>
                <i className="fa fa-plus-square-o is-orange" ></i>
                Add comment
            </span>] : []

    // if (articleType == "initComment") {
    //     articleOptions.push(
    //         <span className='is-orange flexer-2 cursor' onClick={props.onLinkProject}>
    //             <i className="fa fa-folder-open-o is-orange " ></i>
    //             Link a project
    //         </span>)
    // }

    var attachment = null
    if (element.attachment){
        var attachment_name = element.attachment.split('/')
        attachment_name = attachment_name[attachment_name.length - 1]

        let attachment_link = element.attachment
        if (!attachment_link.includes('//')){
            const slash = attachment_link.startsWith('/') ? '' : '/'
            attachment_link = `${config.mediaUri}${slash}${attachment_link}`
        }
        attachment = <a className="link" href={attachment_link} target="_blank" rel="noopener noreferrer">{attachment_name}</a>
    }

    return (
        <article className="comment-article mt-30">

            <div className="flexer flexer-vcenter">
                <div className='flexer-1 center'>
                    <Avatar userImage={element?.created_by?.profile?.profile_pic}/>
                </div>
                <div className="flexer-12">
                    <div className="comment-headers">
                        <b>{element?.created_by?.public_name}</b> <span className="comment-timing">left a message - {element?.proper_created}</span>
                    </div>
                </div>
            </div>

            <div className="flexer flexer-vcenter content-article">
                <div className='flexer-1 center'></div>
                <div className="flexer-12">
                    <div className="comment-content">
                        <p className='text is-light line-margin-xs'>
                            {element?.comment ? parse(element?.comment) : null} 
                            {/* <a> <b className="is-orange">@User_name</b></a> */}
                        </p>
                        {attachment}
                    </div>
                </div>
            </div>

            <div className="flexer flexer-vcenter options-article">
                <div className='flexer-1 center'></div>
                <div className="flexer-12">
                    <div className="comment-options flexer">
                        {articleOptions}
                    </div>
                </div>
            </div>
        </article>
    )
}

export default CommentArticle
