import Header from '../containers/Header'
import Content from '../containers/Content'
import UserResourcesContent from '../containers/Content/UserResourcesContent'

function UserResources() {
	return (
		<>
			<Header />
			<Content>
				<UserResourcesContent />
			</Content>
		</>
	)
}

export default UserResources
