import { useState, useEffect } from 'react'

// API
import { userService, authenticationService, organizationService } from '../api'

export default function useFindUser() {
  const [currentUser, setCurrentUser] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [currentOrganizations, setCurrentOrganizations] = useState(null)

  useEffect(() => {
    async function findUser() {
      await userService.details()
        .then(user => {
          setCurrentUser(user)
          // setLoading(false)
        })
        .then(async () => {
          await organizationService.listByUser()
            .then(organizations => {
              let organ = {}
              organizations.map(org => organ[org.id] = org)
              setCurrentOrganizations(organ)
              setLoading(false)
            })
        })
        .catch(async () => {
          await authenticationService.refreshToken()
            .then(user => {
              setCurrentUser(user)
              // setLoading(false)
            })
            .then(async () => {
              await organizationService.listByUser()
                .then(organizations => {
                  let organ = {}
                  organizations.map(org => organ[org.id] = org)
                  setCurrentOrganizations(organ)
                  setLoading(false)
                })
            })
            .catch(() => {
              authenticationService.logout() // TODO: Validar cómo hacer este logount borrando la cookie
            })
          setLoading(false)
        })
    }

    findUser()
  }, [])

  return { currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading }
}
