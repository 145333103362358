/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const projectTypesService = {
  list,
  listByUrl,
  create,
  update_template,
  remove,
}

async function list(org_id, limit, offset) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/?limit=${limit}&offset=${offset}`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function create(org_id, body) {
  const fixedBody = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fixedBody) }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function update_template(body, org_id, type_id) {
  const fixedBody = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fixedBody) }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/${type_id}/assign_project_type_template/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })

}
async function remove(org_id, type_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/${type_id}/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })

}
