const TableCard = (props) => {
	var rows = []
	if (props.analysts) {
		for (const analyst of Object.values(props.analysts)) {
			var singleAnalyst = `${((analyst.close_by_himself / analyst.close_cases) * 100)
				.toFixed(1)
				.toString()}%`
			var submissionsAccepted = analyst.submissions
				? `${((analyst.accepted / analyst.submissions) * 100).toFixed(1).toString()}%`
				: '0%'
			rows.push(
				<tr className='table-rows flexer'>
					<td className='text is-dark remaining-width left side-margin-0'>{analyst.public_name}</td>
					<td className='text is-dark flexer-2 center side-margin-0'>{analyst.total_cases}</td>
					<td className='text is-dark flexer-2 center side-margin-0'>{singleAnalyst}</td>
					<td className='text is-dark flexer-2 center side-margin-0'>{submissionsAccepted}</td>
					<td className='text is-dark flexer-2 center side-margin-0'>{analyst.close_cases}</td>
				</tr>
			)
		}
	}

	return (
		<div className='dashboard-wrapper__content'>
			<h1 className='heading is-dark'>{props.title}</h1>
			<table className='table' cellSpacing='0'>
				<thead>
					<tr className='table-header flexer'>
						<th className='text is-light remaining-width left side-margin-0'>{props.type}</th>
						<th className='text is-light flexer-2 center side-margin-0'>
							Total number of projects <br />
							contributed to
						</th>
						<th className='text is-light flexer-2 center side-margin-0'>
							Projects contributed by <br />a single analyst (%)
						</th>
						<th className='text is-light flexer-2 center side-margin-0'>
							Submissions accepted <br />
							into final report (%)
						</th>
						<th className='text is-light flexer-2 center side-margin-0'>
							Total number of closed <br />
							projects contributed to
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	)
}

export default TableCard
