import React, { useEffect, useState } from 'react'

import {invitationService} from '../../api'  

// Components
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'

function InvitationsPending (props) {
	const [invitationList, setInvitationList] = useState([])
    const [toReject, setToReject] = useState({})
	const [toAccept, setToAccept] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenReject, setIsOpenReject] = useState(false)
	const [refresh, setRefresh] = useState(false)
console.log(invitationList)
    useEffect(()=> {
        invitationService.listByUser()
        .then(response => {
            setInvitationList(response)
        })
        .catch((error) => {
            console.error("Unable to get invitation list", error)
        })
    }, [refresh])

	function handleClickAccept(orgId, invId, orgName) {
        setIsOpen(true)
        setToAccept({org: orgId, id: invId, name: orgName})
    }

	function handleAccept() {
        invitationService.accept(toAccept.org, toAccept.id)
        .then(response => {
            let inv = invitationList
            delete inv[toAccept.id]
            setInvitationList(inv)
        })
        .then(() => {
            setIsOpen(false)
			setRefresh(true)
        })
    }

	function handleClick(orgId, invId, orgName) {
        setIsOpenReject(true)
        setToReject({ org: orgId, id: invId, name: orgName })
    }
	
	function handleReject() {  
        invitationService.remove(toReject.org, toReject.id)
        .then(response => {
            let inv = invitationList
            delete inv[toReject.id]
            setInvitationList(inv)
        })
        .then(() => {
            setIsOpenReject(false)
			setRefresh(true)
        })
    }

    let invitations
	invitations = invitationList
	.map((inv, index) => (
		<tr className="table-rows flexer" id={index}>
		<td className="text is-light flexer-1 left side-margin-0"><img className="org-icon__picture" src={inv.organization.profile_pic?inv.organization.profile_pic:OrganizationLogo} alt="Organization Logo" /></td>
		<td className="text is-light flexer-3 left side-margin-0 mt-5">{inv.organization.name}</td>
		<td className="text is-light flexer-6 left side-margin-0 mt-5">{inv.organization.description}</td>
		<td className="text flexer-1 center side-margin-0 cursor">
		<Button type="button" text={'Accept'} class={"orange-btn"} onClick={()=>handleClickAccept(inv.organization.id, inv.id, inv.organization.name)} /></td>
		<td className="text remaining-width right side-margin-0 cursor">
		<Button type="button" text={'Reject'} class={"white-btn "} onClick={()=>handleClick(inv.organization.id, inv.id, inv.organization.name)} /></td>
		</tr>))

	return (
        <div className="dashboard-user-section ">
			{  !invitationList.length 
			?
			<p className="text is-light left">You have no pending invitations</p> 
			:
				(<table className="table" cellSpacing="0">
				<thead>
                    <tr className="table-header flexer full-width mt-15">
                        <th className="text is-light flexer-4 left side-margin-0">Organization</th>
                        <th className="text is-light remaining-width left side-margin-0">Description</th>
                    </tr>
                </thead>
				<tbody>
					{invitations}
				</tbody>
        </table>)}
		<Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
			<div className="center">
				<h1 className="title is-dark mb-30">Accept Invitation</h1>
				<p className="text is-light mx-30">Are you sure that you want to be member of <br></br> <span className="is-dark is-bold">{toAccept.name}</span>?</p>
				<Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleAccept} />
			</div>
		</Modal>
		<Modal isOpen={isOpenReject} handleClose={()=>{setIsOpenReject(false)}}>
			<div className="center">
				<h1 className="title is-dark mb-30">Reject Invitation</h1>
				<p className="text is-light mx-30">Are you sure that you want to reject the invitation from <br></br> <span className="is-dark is-bold">{toReject.name}</span>?</p>
				<Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleReject} />
			</div>
		</Modal>
		</div>
	)

}

export default InvitationsPending