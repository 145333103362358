import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import {
	AdditionalInformation,
	Customer,
	PriorityOfProject,
	StartPoint,
	TeamAssignment,
	UploadAttachments,
} from './'
import { Modal } from '../components/Elements'
import { useOrgId } from '../hooks'

const CreateProject = (props) => {
	const { isOpen, handleClose } = props

	const [stage, setStage] = useState('start')
	const [data, setData] = useState({})
	const orgId = useOrgId()

	const changeData = (new_data) => {
		setData({ ...data, ...new_data })
	}

	useEffect(() => {
		if (stage === 'completed') handleClose()
	}, [stage])

	return (
		<Modal isOpen={isOpen} fullScreen={true} handleClose={handleClose}>
			{stage === 'start' && (
				<StartPoint onNext={() => setStage('additionalInfo')} onUpdate={changeData} data={data} />
			)}

			{stage === 'additionalInfo' && (
				<AdditionalInformation
					onNext={() => setStage('customer')}
					onPrevious={() => setStage('start')}
					onUpdate={changeData}
					data={data}
				/>
			)}

			{stage === 'customer' && (
				<Customer
					onNext={() => setStage('uploadAttachments')}
					onPrevious={() => setStage('additionalInfo')}
					onUpdate={changeData}
					data={data}
				/>
			)}

			{stage === 'uploadAttachments' && (
				<UploadAttachments
					onNext={() => setStage('priorityOfProject')}
					// onNext={() => setStage('teamAssignment')}
					onPrevious={() => setStage('additionalInfo')}
					onUpdate={changeData}
					data={data}
				/>
			)}

			{/* {stage === 'teamAssignment' && (
				<TeamAssignment
					onNext={() => setStage('priorityOfProject')}
					onPrevious={() => setStage('uploadAttachments')}
					onUpdate={changeData}
					data={data}
					/>
				)} */}

			{stage === 'priorityOfProject' && (
				<PriorityOfProject
					onNext={() => setStage('completed')}
					onPrevious={() => setStage('uploadAttachments')}
					// onPrevious={() => setStage('teamAssignment')}
					onUpdate={changeData}
					data={data}
				/>
			)}

			{stage === 'completed' && (
				<Redirect
					to={{
						pathname: `/organization/${orgId}/project/${data.case}`,
						state: { first: true },
						key: Math.random(),
					}}
				></Redirect>
			)}
		</Modal>
	)
}

export default CreateProject
