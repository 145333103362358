import {useEffect, useState} from 'react'
import {accountService, commentsService} from '../../api'
import ConfidenceIcon from '../Elements/ConfidenceIcon'
import FullSize from '../Elements/FullSize'
import useOrgId from '../../hooks/useOrgId'
import { toast } from 'react-toastify'

function FacebookFields( props ) {
	var images = []
	for ( const image of props.account.images ) {
		let image_src = image.image.content
		images.push(
			<div className="wcc-acimages" key={ image.id }>
				<FullSize key={ image.id } image={ image_src }/>
				<ConfidenceIcon confidence={ image.image.confidence } orgId={ props.orgId } dataId={ image.image.id } onClose={ props.onClose }/>
			</div>,
		)
	}

	var shortProfilePage = null
	if ( props.account.profile_page.content ) {
		if ( props.account.profile_page.content.length > 50 ) {
			shortProfilePage = `${ props.account.profile_page.content.substring( 0, 50 ) }...`
		} else {
			shortProfilePage = props.account.profile_page.content
		}
	}

	return (
		<>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">User ID</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.user_id.content }</p>
					{ props.account.user_id.content && (
						<ConfidenceIcon confidence={ props.account.user_id.confidence } orgId={ props.orgId } dataId={ props.account.user_id.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Display name</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.display_name.content }</p>
					{ props.account.display_name.content && (
						<ConfidenceIcon confidence={ props.account.display_name.confidence } orgId={ props.orgId } dataId={ props.account.display_name.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Profile page</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<a href={ props.account.profile_page.content } className="text is-orange line-margin-xs" target="_blank" rel="noreferrer">{ shortProfilePage }</a>
					{ props.account.profile_page.content && (
						<ConfidenceIcon confidence={ props.account.profile_page.confidence } orgId={ props.orgId } dataId={ props.account.profile_page.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Last active</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark  line-margin-xs">{ props.account.last_active?.content }</p>
					{ props.account.last_active?.content && (
						<ConfidenceIcon confidence={ props.account.last_active.confidence } orgId={ props.orgId } dataId={ props.account.last_active.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Uploaded images</p>
				<div className="wcc-contimages">
					{ images }
				</div>
			</div>
		</>
	)
}

function StreetViewFields( props ) {

	var images = []

	for ( const image of props.account.images ) {
		let image_src = image.image.content

		images.push(
			<div className="wcc-acimages" key={ image.id }>
				<FullSize key={ image.id } image={ image_src }/>
				<ConfidenceIcon confidence={ image.image.confidence } orgId={ props.orgId } dataId={ image.image.id } onClose={ props.onClose }/>
			</div>,
		)
	}

	var shortProfilePage = null
	if ( props.account.profile_page.content ) {
		if ( props.account.profile_page.content.length > 50 ) {
			shortProfilePage = `${ props.account.profile_page.content.substring( 0, 50 ) }...`
		} else {
			shortProfilePage = props.account.profile_page.content
		}
	}

	return (
		<>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Location</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.display_name.content }</p>
					{ props.account.display_name.content && (
						<ConfidenceIcon confidence={ props.account.display_name.confidence } orgId={ props.orgId } dataId={ props.account.display_name.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Street View URL</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<a href={ props.account.profile_page.content } className="text is-orange line-margin-xs" target="_blank" rel="noreferrer">{ shortProfilePage }</a>
					{ props.account.profile_page.content && (
						<ConfidenceIcon confidence={ props.account.profile_page.confidence } orgId={ props.orgId } dataId={ props.account.profile_page.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Uploaded images</p>
				<div className="wcc-contimages">
					{ images }
				</div>
			</div>
		</>
	)
}

function TwitterFields( props ) {

	var images = []
	for ( const image of props.account.images ) {
		let image_src = image.image.content

		images.push(
			<div className="wcc-acimages" key={ image.id }>
				<FullSize key={ image.id } image={ image_src }/>
				<ConfidenceIcon confidence={ image.image.confidence } orgId={ props.orgId } dataId={ image.image.id } onClose={ props.onClose }/>
			</div>,
		)
	}

	var shortProfilePage = null
	if ( props.account.profile_page.content ) {
		if ( props.account.profile_page.content.length > 50 ) {
			shortProfilePage = `${ props.account.profile_page.content.substring( 0, 50 ) }...`
		} else {
			shortProfilePage = props.account.profile_page.content
		}
	}

	return (
		<>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">User ID</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.user_id.content }</p>
					{ props.account.user_id.content && (
						<ConfidenceIcon confidence={ props.account.user_id.confidence } orgId={ props.orgId } dataId={ props.account.user_id.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Display name</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.display_name.content }</p>
					{ props.account.display_name.content && (
						<ConfidenceIcon confidence={ props.account.display_name.confidence } orgId={ props.orgId } dataId={ props.account.display_name.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Profile page</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<a href={ props.account.profile_page.content } className="text is-orange line-margin-xs" target="_blank" rel="noreferrer">{ shortProfilePage }</a>
					{ props.account.profile_page.content && (
						<ConfidenceIcon confidence={ props.account.profile_page.confidence } orgId={ props.orgId } dataId={ props.account.profile_page.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Last active</p>
				<div className="flexer  flexer-9 flexer-vcenter wcc-hasconfidence">
					<p className="text is-dark  line-margin-xs">{ props.account.last_active.content }</p>
					{ props.account.last_active.content && (
						<ConfidenceIcon confidence={ props.account.last_active.confidence } orgId={ props.orgId } dataId={ props.account.last_active.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Handle</p>
				<div className="flexer  flexer-9 flexer-vcenter wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.handle.content }</p>
					{ props.account.handle.content && (
						<ConfidenceIcon confidence={ props.account.handle.confidence } orgId={ props.orgId } dataId={ props.account.handle.id } onClose={ props.onClose }/>
					) }
				</div>
			</div>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Uploaded images</p>
				<div className="wcc-contimages">
					{ images }
				</div>
			</div>
		</>
	)
}

function LinkedInFields( props ) {

	var images = []
	for ( const image of props.account.images ) {

		let image_src = image.image.content
		images.push(
			<div className="wcc-acimages" key={ image.id }>
				<FullSize key={ image.id } image={ image_src }/>
				<ConfidenceIcon confidence={ image.image.confidence } orgId={ props.orgId } dataId={ image.image.id } onClose={ props.onClose }/>
			</div>,
		)
	}

	var shortProfilePage = null
	if ( props.account.profile_page.content ) {
		if ( props.account.profile_page.content.length > 50 ) {
			shortProfilePage = `${ props.account.profile_page.content.substring( 0, 50 ) }...`
		} else {
			shortProfilePage = props.account.profile_page.content
		}
	}

	return (
		<>
			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">User ID</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.user_id.content }</p>
					{ props.account.user_id.content && (
						<ConfidenceIcon confidence={ props.account.user_id.confidence } orgId={ props.orgId } dataId={ props.account.user_id.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Display name</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<p className="text is-dark line-margin-xs">{ props.account.display_name.content }</p>
					{ props.account.display_name.content && (
						<ConfidenceIcon confidence={ props.account.display_name.confidence } orgId={ props.orgId } dataId={ props.account.display_name.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Profile page</p>
				<div className="flexer flexer-vcenter flexer-9 wcc-hasconfidence">
					<a href={ props.account.profile_page.content } className="text is-orange line-margin-xs" target="_blank" rel="noreferrer">{ shortProfilePage }</a>
					{ props.account.profile_page.content && (
						<ConfidenceIcon confidence={ props.account.profile_page.confidence } orgId={ props.orgId } dataId={ props.account.profile_page.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Last active</p>
				<div className="flexer  flexer-9 flexer-vcenter wcc-hasconfidence">
					<p className="text is-dark  line-margin-xs">{ props.account.last_active.content }</p>
					{ props.account.last_active.content && (
						<ConfidenceIcon confidence={ props.account.last_active.confidence } orgId={ props.orgId } dataId={ props.account.last_active.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Job Title</p>
				<div className="flexer  flexer-9 flexer-vcenter wcc-hasconfidence">
					<p className="text is-dark  line-margin-xs">{ props.account.job_title.content }</p>
					{ props.account.job_title.content && (
						<ConfidenceIcon confidence={ props.account.job_title.confidence } orgId={ props.orgId } dataId={ props.account.job_title.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Employer</p>
				<div className="flexer  flexer-9 flexer-vcenter wcc-hasconfidence">
					<p className="text is-dark  line-margin-xs">{ props.account.employer.content }</p>
					{ props.account.employer.content && (
						<ConfidenceIcon confidence={ props.account.employer.confidence } orgId={ props.orgId } dataId={ props.account.employer.id }
														onClose={ props.onClose }/>
					) }
				</div>
			</div>

			<div className="flexer">
				<p className="text is-dark is-bold flexer-3 line-margin-xs">Uploaded images</p>
				<div className="wcc-contimages">
					{ images }
				</div>
			</div>
		</>
	)
}

const AccountCard = ( props ) => {
	const [ commentsList, setComments ] = useState( {} )
	const [ showComments, setShowComments ] = useState( false )
	const [ showBubble, setShowBubble ] = useState( false )
	const orgId = useOrgId()

	useEffect( () => {

		var commentDict = {}
		for ( const comment of props.account.comments ) {
			commentDict[ comment.id ] = comment
		}
		setComments( { ...commentDict } )

	}, [ props.account.comments ] )

	function handleCommentSubmit( e ) {
		e.preventDefault()
		var form_element = document.getElementById( 'comment-form' )
		var form_data = new FormData( form_element )

		commentsService.create( form_data, orgId )
			.then( response => {
				var new_comment = {}
				new_comment[ response.id ] = response
				setComments( { ...commentsList, ...new_comment } )
			} )
			.catch( error => {
				console.log( error )
			} )

		form_element.reset()
	}

	function handleDeleteComment( comment_id ) {

		commentsService.remove( orgId, comment_id )
			.then( () => {
				var new_comments = commentsList
				delete new_comments[ comment_id ]
				setComments( { ...new_comments } )
			} )
			.catch( error => {
				console.log( error )
			} )

	}

	function toastMessage(type = '', accountDisplayName = '') {
		// type could be 'error' or 'success'
		return `Account ${accountDisplayName} ${type === 'error' ? 'not' : ''} deleted successfully`
	}

	function handleDeleteAccount() {
		accountService.remove( orgId, props.account.id )
			.then( () => {
				props.onDelete()
				toast.success(toastMessage('success', props.account.display_name.content))
			} )
			.catch( error => {
				toast.error(toastMessage('error'))
				console.log( 'Error deleting account', error )
			} )
	}

	var profilePic = 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

	var extra_fields
	if ( props.account.proper_type === 'Twitter' ) {
		extra_fields = <TwitterFields account={ props.account } orgId={ orgId } onClose={ props.onClose }/>
	} else if ( props.account.proper_type === 'LinkedIn' ) {
		extra_fields = <LinkedInFields account={ props.account } orgId={ orgId } onClose={ props.onClose }/>
	} else if ( props.account.proper_type === 'Street View' ) {
		extra_fields = <StreetViewFields account={ props.account } orgId={ orgId } onClose={ props.onClose }/>
	} else {
		extra_fields = <FacebookFields account={ props.account } orgId={ orgId } onClose={ props.onClose }/>
	}

	var comments = []
	if ( showComments ) {
		for ( const comment of Object.values( commentsList ) ) {
			comments.push(
				<div className="flexer">
					<p className="text is-dark is-bold flexer-3 line-margin-xs"/>
					<div className="full-width">
						<div className="bubble">
							<p className="subheading is-light line-margin-xs">{ comment.comment }</p>
						</div>
						<div className="flexer flexer-spaced flexer-vcenter mt-15">
							<div className="flexer flexer-vcenter">
								<img className="profile-menu__image" src={ comment.created_by.profile.profile_pic
									? comment.created_by.profile.profile_pic
									: profilePic } alt="profile"/>
								<p className="text is-dark line-margin-0 mx-15 is-bold">{ comment.created_by.public_name } <span className="text is-light">left a note</span>
								</p>
								<span className="text is-light">•</span>
								<p className="text is-light mx-15">{ comment.proper_created }</p>
							</div>
							{ !props.closed
								? <i className="fa fa-trash-o is-red cursor" c onClick={ () => {
									handleDeleteComment( comment.id )
								} }/>
								: null }
						</div>
					</div>
				</div>,
			)
		}
	}

	var commentBubble = null
	if ( showBubble ) {
		commentBubble = <div className="comment-bubble mt-30">
			<form className="flexer flexer-vcenter" id="comment-form" onSubmit={ handleCommentSubmit }>
				<input name="comment" className="flexer-10 text is-dark" type="text" placeholder="Enter your comment..."/>
				<input type="hidden" name="account" value={ props.account.id }/>
				<i className="fa fa-paper-plane flexer-1 is-orange cursor" onClick={ handleCommentSubmit }/>
				<i className="fa fa-close flexer-1 is-light cursor" onClick={ () => {
					setShowBubble( false )
				} }/>
			</form>
		</div>
	}

	// Set account created by user and profile pic
	var public_name = props.account.created_by
		? props.account.created_by.public_name
		: 'Unknown'
	var profile_pic = profilePic
	if ( props.account.created_by ) {
		if ( props.account.created_by.profile?.profile_pic ) {
			profile_pic = props.account.created_by.profile.profile_pic
		}
	}

	return (
		<div className="content-wrapper mx-30">
			<div className="simple-profile__sections">
				<div className="flexer flexer-spaced">
					<div>
						<p className="text is-light line-margin-0">Source: <span className="text is-bold is-orange">{ props.account.proper_type }</span></p>
					</div>
					<p className="text is-dark line-margin-0">Added: <span className="text is-dark is-bold">{ props.account.proper_created }</span></p>
				</div>
				<div className="mt-30">

					{ extra_fields }
					<div className="flexer">
						<p className="text is-dark is-bold flexer-3 line-margin-xs">Note</p>
						<p className="text is-dark flexer-9 line-margin-xs">{ props.account.note }</p>
					</div>
					<div className="flexer">
						<p className="text is-dark is-bold flexer-3 line-margin-xs">Comments ({ Object.values( commentsList ).length })</p>
						{ showComments
							? <p className="text flexer-9 line-margin-xs is-orange cursor" onClick={ () => {
								setShowComments( false )
							} }>Hide comments <i className="fas fa-angle-up is-orange"/></p>
							: <p className="text flexer-9 line-margin-xs is-orange cursor" onClick={ () => {
								setShowComments( true )
							} }>Show comments <i className="fas fa-angle-down is-orange"/></p> }
					</div>

					{ comments }
				</div>
				{ commentBubble }

			</div>

			<div className="simple-profile__sections">
				<div className="flexer flexer-spaced flexer-vcenter">
					<div className="flexer left flexer-vcenter">
						<img className="profile-menu__image" src={ profile_pic } alt="profile"/>
						<div className="mx-15">
							<p className="subheading is-dark line-margin-0">{ public_name }</p>
						</div>
					</div>
					<div className="flexer">
						{ !props.closed &&
							<p className="link ml-20 line-margin-0 cursor" onClick={ () => setShowBubble( true ) }>+ Add comment</p>
						}
						{ !props.closed &&
							<p className="link ml-20 line-margin-0 cursor" onClick={ () => props.onEdit( props.account ) }><i className="fa fa-pencil is-orange mr-10"/>Edit
								account</p>
						}
						{ !props.closed &&
							<p className="subheading ml-20 line-margin-0 is-red cursor" onClick={ handleDeleteAccount }><i className="fa fa-trash-o is-red mr-10"/>Delete
								account</p>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AccountCard
