import Header from '../containers/Header'
import Content from '../containers/Content'
import UserOrganizationsContent from '../containers/Content/UserOrganizationsContent'

function UserOrganizations() {
	return (
		<>
			<Header />
			<Content>
				<UserOrganizationsContent />
			</Content>
		</>
	)
}

export default UserOrganizations
