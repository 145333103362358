import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = (props) => {
	if (props.icon) {
		var icon = <i className={`${props.icon} ${props.class} mx-5`} />
	}
	const disabled = props.disabled || props.loading
	var className = props.class ? props.class : ''

	if (props.type === 'submit') {
		return (
			<button
				type='submit'
				form={props.form}
				className={`button ${className}`}
				onClick={props.onClick}
				value={props.text}
				disabled={disabled}
			>
				{props.loading ? (
					<FontAwesomeIcon icon={faSpinner} spin={true} className={'text-white mx-10'} />
				) :
					props.text
				}
			</button>
		)
	} else {
		return (
			<button
				type={props.type}
				className={`button ${className}`}
				onClick={props.onClick}
				disabled={disabled}
			>
				{icon} {props.text}
			</button>
		)
	}
}

export default Button
