import { MdOutlineCheckCircleOutline, MdRadioButtonUnchecked } from 'react-icons/md'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IconButton } from '@mui/material'
import { useEffect } from 'react'

import LogoOrg from '../../../../assets/images/globe_international_icon.png'

import styles from './sourceCategoryModalCreate.module.sass'

const SourceCategoryModalEdit = ({
	toEdit,
	hdlEdit,
	setToEdit,
	sourceList,
	hdlSelectAll,
	categoryName,
	hdlToggleItem,
	selectedItems,
	hdlDeselectAll,
	hdlSelectedItem,
	setCategoryName,
	setSelectedItems,
}) => {
	useEffect(() => {
		setCategoryName(toEdit.name)
		setSelectedItems(toEdit.sourceSites)
	}, [toEdit.showModal])

	return (
		<>
			<dialog className={styles.dialog}>
				<header className={styles.dialog_header}>
					<h1 className={styles.dialog_header_title}>Custom Category</h1>
					<IconButton
						className={styles.dialog_header_close}
						onClick={() =>
							setToEdit({
								showModal: false,
								name: '',
								sourceSites: [],
								id: '',
							})
						}
					>
						<AiOutlineCloseCircle />
					</IconButton>
				</header>
				<article className={styles.dialog_sources}>
					<header className={styles.dialog_sources_header}>
						<fieldset>
							<input
								type='text'
								value={categoryName}
								placeholder={'Category Name'}
								onChange={(e) => setCategoryName(e.target.value)}
							/>
						</fieldset>
						<h4 className={styles.dialog_sources_header_title}>Sources</h4>
						<span className={styles.dialog_sources_header_link} onClick={hdlSelectAll}>
							Select All
						</span>
						<span className={styles.dialog_sources_header_link} onClick={hdlDeselectAll}>
							Deselect All
						</span>
					</header>
					<section className={styles.dialog_sources_container}>
						{sourceList.map((sourceProduct) => (
							<figure
								key={sourceProduct.id}
								onClick={() => hdlToggleItem(sourceProduct)}
								className={styles.dialog_sources_container_card}
							>
								<img
									className={styles.dialog_sources_container_card_img}
									src={(sourceProduct.logo === '' || !sourceProduct.logo) && LogoOrg}
									alt={sourceProduct.name}
								/>
								<figcaption className={styles.dialog_sources_container_card_name}>
									{sourceProduct.name}
								</figcaption>
								{hdlSelectedItem(sourceProduct) ? (
									<MdOutlineCheckCircleOutline
										className={styles.dialog_sources_container_card_check}
									/>
								) : (
									<MdRadioButtonUnchecked className={styles.dialog_sources_container_card_check} />
								)}
							</figure>
						))}
					</section>
				</article>
				<footer className={styles.dialog_footer}>
					<button
						onClick={hdlEdit}
						className={styles.dialog_footer_btn}
						disabled={categoryName === '' || selectedItems.length === 0}
					>
						Save changes
					</button>
				</footer>
			</dialog>
			<main
				style={{
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 40,
					position: 'fixed',
					backgroundColor: 'rgba(0,0,0,.25)',
				}}
			/>
		</>
	)
}
export default SourceCategoryModalEdit
