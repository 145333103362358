import { useEffect, useState } from 'react'
import { ClickAwayListener } from '@mui/material'

const Notifications = ({ handleShowMsg, events, handleMarkAsRead, handleAllRead}) => {
	const [items, setItems] = useState([])

	useEffect(() => {
		if (events?.length) {
			setItems(events)
		}
	}, [events.length])

	return (
		<ClickAwayListener onClickAway={handleShowMsg}>
			<section className='notification-panel show y-scroll'>
				<h1 className='title-4 is-orange is-bold line-margin-0'>Notifications</h1>
				{items?.length > 0 && <p className='text is-orange is-light line-margin-0 right cursor' onClick={handleAllRead} >Mark all as read</p>}

				{items?.length ?
					items.map((event) =>
						event.type === 'RFICommentMention' || 
						event.type === 'RFIMention' ? (
							<p
								className='notification-item text is-light line-margin-0 cursor'
								key={event.id}
								onClick={() => handleMarkAsRead(event.id, `rfi`, 'rfi')}
							>
								{event.message} {' '}
								<span className='text is-light is-bold'>
									{event?.created_by?.public_name}{' '}
								</span>{' '}
							</p>
						) : event.type === 'ProjectAssigned' ||
						  event.type === 'ChangeStatusProjectAvailable' ? (
							<p
								className='notification-item text is-light line-margin-0 cursor'
								key={event.id}
								onClick={() => handleMarkAsRead(event.id, `project/${event?.case.id}`, 'dashboard')}
							>
								<span className='text is-light is-bold'>{event?.case.case_id} </span>{' '}
								{event.message}
							</p>
						) : event.type === 'DirectMessage' ||
						  event.type === 'BroadcastMessage' ||
						  event.type === 'GroupMessage' ? (
							<p
								className='notification-item text is-light line-margin-0 cursor'
								key={event.id}
								onClick={() => handleMarkAsRead(event.id, 'chats', 'chat')}
							>
								{event.message}{' '}
								<span className='text is-light is-bold'>
									{event?.created_by?.public_name}{' '}
								</span>{' '}
							</p>
						) : (
							event.type === 'InvitationAccepted' && (
								<p
									className='notification-item text is-light line-margin-0 cursor'
									key={event.id}
									onClick={() => handleMarkAsRead(event.id, 'organization-settings', 'orgSettings')}
								>
									<span className='text is-light is-bold'>{event?.member?.public_name} </span>
									{event.message}
								</p>
							)
						)
					)
				: <p className='text is-light is bold'>No new notifications</p> }
			</section>
		</ClickAwayListener>
	)
}

export default Notifications
