import config from './config'
import { handleResponse } from './handle-response'
import { fixBody } from './helpers'

export const userService = {
	list,
	get_lms_url,
	search_user,
	details,
	search,
	search_super,
	update,
	accept_terms,
	delete_user,
	request_org,
	create_feedback,
	verify_attribute,
}

async function list(offset = 0, limit) {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/?limit=${limit}&offset=${offset}`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
async function get_lms_url () {
	const requestOptions = {
		method     : 'GET',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/users/lsm_url/`,
		requestOptions
	)
		.then(handleResponse)
		.then((url) => {
			return url
		})
}
async function search_user(keyword) {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/search/?keyword=${keyword}`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

function details(keyword, org_id) {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/details/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function search(keyword, org_id) {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(
		`${config.apiUrl}/organization/${org_id}/users/search/?keyword=${keyword}`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function search_super(keyword) {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/search/?keyword=${keyword}`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function update(body, user_id) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(bodyFixed),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

function accept_terms(user_id) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/${user_id}/accept_terms/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function delete_user(user_id) {
	const requestOptions = {
		method: 'DELETE',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function request_org(body) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'POST',
		bodyFixed,
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/invitations/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function create_feedback(body) {
	let object = {}
	body.forEach((value, key) => (object[key] = value))

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(object),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/jira/create_issue/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function verify_attribute(body) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(bodyFixed),
	}

	return fetch(`${config.apiUrl}/users/verify_user_attribute/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
