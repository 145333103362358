import { ClickAwayListener, Tooltip } from '@mui/material'
import { projectService } from 'api'
import { Button, GroupList, Modal, UserList } from 'components'

import { useOrgId, UserContext, useUserRole } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { TiGroup, TiUser } from 'react-icons/ti'
import './ProjectAssigner.scss'

const ProjectAssigner = ({ AssignedUsers, AssignedGroups, projectId, setRefresh }) => {
	const [view, setView] = useState(false)
	const [showClaim, setShowClaim] = useState(false)
	const [showSearch, setShowSearch] = useState(false)
	const [dataGroup, setDataGroup] = useState([])
	const [dataUser, setDataUser] = useState([])
	const [isCollaborator, setIsCollaborator] = useState(false)

	const [initialDataGroup, setInitialDataGroup] = useState([])
	const [initialDataUser, setInitialDataUser] = useState([])
	const { currentUser } = useContext(UserContext)

	const org_id = useOrgId()
	const currentRole = useUserRole()

	const hdlClickAway = () => {
		setShowSearch(false)
	}

	useEffect(() => {
		setRefresh((prev) => prev + 1)
		
	}, [showSearch]);

	useEffect(() => {
		setInitialDataGroup(AssignedGroups)
		setDataGroup(AssignedGroups)
	}, [AssignedGroups])

	// Set initial assigned collaborators
	useEffect(() => {
		setInitialDataUser(AssignedUsers)
		setDataUser(AssignedUsers)
	}, [AssignedUsers])

	// Now if the current user is already as a collaborator
	useEffect(() => {
		const user = dataUser.find((u) => u.cognito_id === currentUser.cognito_id)

		if (user) {
			setIsCollaborator(true)
		} else {
			setIsCollaborator(false)
		}
	}, [dataUser])

	const handleChangeGroup = (data) => {
		const payload = { id_group: data.map((g) => g.id_group) }

		projectService.updateAssigned(payload, org_id, projectId).catch((err) => console.error(err))

		setDataGroup(data)
	}

	const handleChangeUsers = (data) => {
		const payload = { collaborators: data.map((g) => g.cognito_id) }

		projectService.updateAssigned(payload, org_id, projectId).catch((err) => console.error(err))

		setDataUser(data)
	}

	const handleClaim = () => {
		const type = isCollaborator ? 'unclaim_project' : 'claim_project'

		projectService
			.claim(org_id, projectId, type)
			.then(() => {
				setShowClaim(false)

				if (type === 'unclaim_project') {
					setDataUser(dataUser.filter((u) => u.cognito_id !== currentUser.cognito_id))
					setIsCollaborator(false)
				} else {
					setDataUser([...dataUser, currentUser])
					setIsCollaborator(true)
				}
			})
			.catch((err) => console.error(err))
	}

	return (
		<div className='wcc-assigned simple-profile__sections'>
			<div className='wcc-top'>
				<div className='subtitle is-dark is-bold' style={{ flexGrow: 2 }}>
					Assigned:
				</div>
				{currentRole === 'ANALYST' && (
					<a className='cursor link' onClick={() => setShowClaim(!showClaim)}>
						{isCollaborator ? '- Unclaim' : '+ Claim'}
					</a>
				)}

				{(currentRole === 'ADMIN' ||
					currentRole === 'CASE_MANAGER' ||
					currentRole === 'SUPER_ADMIN') && (
					<span className='cursor link' onClick={() => setShowSearch(!showSearch)}>
						{showSearch ? '- Assign' : '+ Assign'}
					</span>
				)}
				<div className='wcc-showAssigner'>
					{showSearch && (
						<ClickAwayListener onClickAway={hdlClickAway}>
							<div className='wcc-modal'>
								<div className='wcc-tabs'>
									<button className={!view ? 'wcc-active' : ''} onClick={() => setView(false)}>
										Users
									</button>
									{/* <button className={view ? 'wcc-active' : ''} onClick={() => setView(true)}>
										Teams
									</button> */}
								</div>
								{view ? (
									<GroupList
										changeData={handleChangeGroup}
										showSelector={false}
										defaultData={initialDataGroup}
										key={projectId}
									/>
								) : (
									<UserList
										changeData={handleChangeUsers}
										showSelector={false}
										defaultData={initialDataUser}
									/>
								)}
							</div>
						</ClickAwayListener>
					)}
				</div>
			</div>

			<div className='wcc-assignedlist'>
				<div className='wcc-asgroups'>
					{dataGroup.map((group) => {
						const { name, group_pic, id_group } = group

						return (
							<Tooltip title={name} key={id_group} arrow>
								<div className='wcc-asimage'>
									{group_pic ? <img src={group_pic} alt={name} /> : <TiGroup />}
								</div>
							</Tooltip>
						)
					})}
				</div>

				<div className='wcc-asusers'>
					{dataUser.map((user) => {
						const { public_name, profile, cognito_id } = user

						return (
							<Tooltip title={public_name} key={cognito_id} arrow>
								<div className='wcc-asimage'>
									{profile?.profile_pic ? (
										<img src={profile?.profile_pic} alt={profile.public_name} />
									) : (
										<TiUser />
									)}
								</div>
							</Tooltip>
						)
					})}
				</div>
			</div>

			<Modal
				isOpen={showClaim}
				handleClose={() => {
					setShowClaim(false)
				}}
			>
				<div className='center'>
					{isCollaborator && (
						<>
							<h1 className='title is-dark mb-30'>Remove me from this project</h1>
							<p className='text is-light mx-30'>
								Are you sure that you want to remove yourself from this project?
							</p>
						</>
					)}

					{!isCollaborator && (
						<>
							<h1 className='title is-dark mb-30'>Claim this project</h1>
							<p className='text is-light mx-30'>
								Are you sure that you want to claim this project?
							</p>
						</>
					)}

					<Button
						text='Cancel'
						class='mt-30 mr-20 one-third-width black-btn subheading-light'
						type='button'
						onClick={() => {
							setShowClaim(false)
						}}
					/>
					<Button
						text='Accept'
						class='mt-30 one-third-width orange-btn subheading-light'
						type='button'
						onClick={handleClaim}
					/>
				</div>
			</Modal>
		</div>
	)
}

ProjectAssigner.defaultProps = {
	AssignedUsers: [],
	AssignedGroups: [],
}

export default ProjectAssigner
