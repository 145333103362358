import React from 'react'

// API
import config from '../../api/config'

function Thumbnail(props) {
    var profilePic = 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'
    if (props.user) {
        if (props.user.profile) {
            profilePic = props.user.profile.profile_pic ? props.user.profile.profile_pic : profilePic
        }
    }

    if (!profilePic.includes('//')){
        const slash = profilePic.startsWith('/') ? '' : '/'
        profilePic = `${config.mediaUri}${slash}${profilePic}`
    }

    return (
        <React.Fragment>
            <img className="profile-menu__image" alt="profile" src={profilePic}  />
        </React.Fragment>
    )
}

export default Thumbnail