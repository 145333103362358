import ProjectTypesTableItem from './Item/ProjectTypesTableItem'
import ProjectTypesTableItemSkt from './Item/ProjectTypesTableItemSkt'

import styles from './projectTypesTable.module.sass'

const ProjectTypesTable = ({ projectTypes, onLoading, setToDelete, setToEdit }) => {
	
	return (
	<section className={styles.table}>
		<header className={styles.table_header}>
			<strong>Name</strong>
			<strong>Short Name</strong>
			<strong>Report Template</strong>
			<strong></strong>
		</header>
		{onLoading ? (
			<>
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
				<ProjectTypesTableItemSkt />
			</>
		) : projectTypes?.length > 0 ? (
			projectTypes.map(({ id, name, code, categories, project_type_template }) => {
				return (
				<ProjectTypesTableItem
					id={id}
					key={id}
					name={name}
					code={code}
					setToEdit={setToEdit}
					categories={categories}
					setToDelete={setToDelete}
					reportTemplate={project_type_template}
				/>
			)})
		) : (
			<h3>No project types found</h3>
		)}
	</section>
)}

export default ProjectTypesTable
