import React, { useEffect, useState } from 'react'

// Components
import InputField from '../Elements/InputField'
import Textarea from '../Elements/Textarea'
import { Multiselect } from 'multiselect-react-dropdown'
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import useOrgId from '../../hooks/useOrgId'

// API
import { chatService, projectService } from '../../api'

function CreateBroadcast(props) {
  const [linkedSubject, setLinkedSubject] = useState(null)
  const [title, setTitle] = useState("")
  const [options, setOptions] = useState([])
  const [searchResults, setSearchResults] = useState(null)
  const [Project, setProject] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)

  const [data, setData] = useState({})

  const orgId = useOrgId()

  useEffect(() => {
    if (props?.message?.id) {
      setData(props.message)
    }

  }, [props.message])
  

  useEffect(() => {
    if (props.statusChange) {
      // var statusChangeTitle = `Project ${props.statusChange.case_id}: Status updated to "${props.statusChange.status}"`
      var statusChangeTitle = `Project ${props.statusChange.project_id}: Status updated to "${props.statusChange.status}"`
      setTitle(statusChangeTitle)
      setProject(props.statusChange.id)
    }
  }, [props.statusChange])

  useEffect(() => {
    chatService.get_group_channels(orgId)
      .then(response => {
        setOptions(response)
        for (const option of response) {
          if (option.name === "General") {
            setLinkedSubject(option.id)
            break
          }
        }
      })
      .catch(error => {
        console.error("Unable to retrieve group list", error)
      })
  }, [orgId])

  function handleSendMessage(e) {
    e.preventDefault()

    var form_element = document.getElementById('create-broadcast-form')
    var form_data = new FormData(form_element)

    chatService.send_broadcast_message(form_data, orgId)
      .then(response => {
        props.onSubmit(response)
      })
      .catch(error => {
        console.error("It was not possible to create the broadcast message", error)
      })
  }

  function handleEditMessage(e) {
    e.preventDefault()

    var form_element = document.getElementById('edit-broadcast-form')
    var form_data = new FormData(form_element)

    chatService.edit_broadcast(form_data, orgId, props.message.id)
      .then(response => {
        props.onEdit(response)
      })
      .catch(error => {
        console.error("It was not possible to edit the broadcast message", error)
      })
  }

  function handleDeleteMessage(e) {
    e.preventDefault()

    chatService.delete_broadcast(props.message.id, orgId)
      .then(response => {
        props.onDelete(props.message.id)
      })
      .catch(error => {
        console.error("It was not possile to delete the broadcast message", error)
      })
  }

  function handleSearchProject(e) {
    function selectProject(project_id, project_name) {
      setProject(project_id)
      setSearchResults([])
      setSelectedProject(project_name)
    }

    var results = []
    if (e.length >= 2) {
      projectService.search(e, orgId)
        .then(response => {
          for (const r of response) {
            results.push(
              <div className="search-results cursor" onClick={() => { selectProject(r.id, `${r.case_id} (${r.subject.display_name})`) }}>
                <div className="flexer">
                  <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
                  <p className="text is-light line-margin-0">{`${r.case_id} (${r.subject.display_name})`}</p>
                </div>
              </div>
            )
          }
          setSearchResults(results)
        })
        .catch(error => {
          console.error(error)
          setSearchResults([])
        })
    } else {
      setSearchResults([])
    }
  }

  function handleSelect(selected) {
    let type = selected.map(e => e.id)
    setLinkedSubject(type)
  }

  var projectDiv = null
  if (!props.statusChange) {
    projectDiv =
      <div className="simple-profile__sections chats-multi">
        <SearchBar placeholder={"Find a project..."} onChange={handleSearchProject} noBg={true} value={selectedProject ?? undefined}/>
        <div className="absolute full-width on-top">
          {searchResults}
        </div>
      </div>
  }

  let selected_channel = options && options.filter(option => option.id === props?.message?.channel)

  if (props.message) {
    return (
      <>
      <form id="edit-broadcast-form" className="max-screen__bottom chats-max-screen" onSubmit={handleEditMessage}>
        <div className="max-screen__bottom chats-max-screen">
          <div className="simple-profile__sections flexer flexer-spaced flexer-vcenter">
            <div className="flexer flexer-spaced flexer-vcenter full-width">
              <div className='flexer flexer-vcenter'>
              <p className="heading is-dark line-margin-0">Edit Broadcast message</p>
              </div>
              <div>

            <Button type="button" class="white-btn mr-10" text="Delete message" onClick={handleDeleteMessage} />
            <Button type="button" class="orange-btn mr-10" text="Save changes" onClick={handleEditMessage} />
              </div>
            </div>
          </div>
          <div className="simple-profile__sections">
              <InputField  class="chats-input left" name="title" value={data.title} label='Title' />
          </div>
          <div className="simple-profile__sections filters-multi">
            {/* <Multiselect options={options} displayValue="name" selectionLimit={1} selectedValues={options} onSelect={(selected) => { setLinkedSubject(selected[0]['id']) }} /> */}
            <Multiselect options={options} displayValue="name" selectionLimit={1} selectedValues={selected_channel}  onSelect={handleSelect} avoidHighlightFirstOption={true}/>
          </div>
          {Project ? <input id="case-input" name="case" value={data.case} type="hidden" /> : null}
          <div className="simple-profile__sections">
            <InputField type="number" value={data.expiration_days} class="chats-input left" name="expiration_days" label='Expiration time' />
          </div>
          <div className="simple-profile__sections max-screen__bottom">
            <Textarea value={data.message} class="chats-input full-height left" name="message" label='Message' />
          </div>
        </div>
        </form>
      </>
    )
  } else {
    return (
      <React.Fragment>
        <form id="create-broadcast-form" className="max-screen__bottom chats-max-screen" onSubmit={handleSendMessage}>
          <div className="simple-profile__sections flexer flexer-spaced flexer-vcenter">
            <p className="heading is-dark line-margin-0">Create Broadcast Message</p>
            <Button type="submit" class="orange-btn" text="Create broadcast" />
          </div>
          <div className="simple-profile__sections">
            <InputField placeholder="Message title..." class="chats-input" name="title" value={title} />
          </div>
          <div className="simple-profile__sections filters-multi">
            {/* <Multiselect options={options} displayValue="name" selectionLimit={1} selectedValues={options} onSelect={(selected) => { console.log(selected); setLinkedSubject(selected[0]['id']) }} /> */}
            <Multiselect options={options} displayValue="name" selectionLimit={1} onSelect={handleSelect} avoidHighlightFirstOption={true} />
            <input type="hidden" name="channel" value={linkedSubject} />
          </div>
          {projectDiv}
          {Project ? <input id="case-input" name="case" value={Project} type="hidden" /> : null}
          <div className="simple-profile__sections">
            <InputField type="number" placeholder="Enter an expiration time in days..." class="chats-input" name="expiration_days" />
          </div>
          <div className="simple-profile__sections max-screen__bottom">
            <Textarea placeholder="Enter your message..." class="chats-input full-height" name="message" />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default CreateBroadcast