import { Button } from '@mui/material'

import { Modal } from '../../../Elements'

const SourceCategoryModalDelete = ({ toDelete, setToDelete, hdlDelete }) => (
	<Modal
		isOpen={toDelete.showModal}
		handleClose={() =>
			setToDelete({
				showModal: false,
				name: '',
				code: '',
				id: '',
			})
		}
	>
		<h1 className='title is-dark mx-5 mb-15'>Delete Category</h1>
		<p className='text is-light mx-5 mb-15'>
			Are you sure that you want to delete the category
			<span className='is-dark is-bold mx-5'>{toDelete.name}</span>?
		</p>
		<Button variant={'contained'} onClick={hdlDelete}>
			Delete
		</Button>
	</Modal>
)

export default SourceCategoryModalDelete
