import React from 'react'
import OrganizationLogo from '../../assets/images/globe_international_icon.png'

const ProfileInfo = (props) => {
	const { org } = props

	return (
		<>
			<div className='dashboard-section'>
				<p className='text is-light is-bold left mb-30'>Organization Profile</p>

				<div className='flexer my-30'>
					<div className='flexer-3 left'>
						<img
							className='simple-org__picture'
							src={org.profile_pic ?? OrganizationLogo}
							alt='profile'
						/>
					</div>
					<div className='flexer-6 left'>
						<p className='subtitle is-dark line-margin-s my-10'>
							{org.name ?? 'Organization Name not available'}
						</p>
						<p className='text is-light line-margin-s my-10'>
							EAG ID: {org.eag_id ?? 'EAG ID not available'}
						</p>
						<p className='text is-light line-margin-s my-10'>
							{org.description ?? 'Description not available'}
						</p>
						<p className='text is-light line-margin-s my-10'>{org.id && org.id}</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProfileInfo
