import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const customTable = {
  get,
  create,
  update,
  remove,
}

async function get(org_id) {
  const options = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/custom_table/`, options)
    .then(handleResponse)
    .then(table => {
      // Force to always return 
      return table
    })
}

async function create(org_id, body) {
  body = fixBody(body)
  const options = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/custom_table/`, options)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function update(org_id, table_id, body) {
  body = fixBody(body)
  const options = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/custom_table/${table_id}/`, options)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function remove(org_id, table_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/custom_table/${table_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
