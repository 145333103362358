import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'

function CustomerForm(props) {
	function handleClick() {
		var lea_supported = document.getElementsByName('lea_supported')[0].value
		props.onUpdate({ lea_supported })
		props.onNext()
	}

	return (
		<div className='half-width center'>
			<div className='left mt-60'>
				<div className='flexer'>
					<i className='form-icon far fa-list-alt' />
					<Textarea
						name='lea_supported'
						class='full-width line-margin-xs'
						label='What customer will be supported with this project'
					/>
				</div>
				<div className='flexer flexer-spaced center-mobile'>
					<Button
						text='&larr; Previous step'
						class='mt-15 white-btn is-orange subheading is-light'
						type='button'
						onClick={props.onPrevious}
					/>
					<Button
						text='Next step &rarr;'
						class='mt-15 orange-btn subheading is-light'
						onClick={handleClick}
					/>
				</div>
			</div>
		</div>
	)
}

export default CustomerForm
