import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import config from '../api/config'

import GeneralHeader from '../components/Header/GeneralHeader'
import OrganizationHeader from '../components/Header/OrganizationHeader'

// Hooks
import { useOrgId, UserContext } from '../hooks'

function Header() {
	const { currentOrganizations, currentUser } = useContext(UserContext) // global context
	const orgId = useOrgId()
	const orgMemberInfo = currentOrganizations
	const homeLogin = `${config.home}/login`

	if (!orgId) {
		// If the user has only one organization, use that one
		if (
			orgMemberInfo &&
			Object.values(orgMemberInfo).length === 1 &&
			currentUser.role !== 'SUPER_ADMIN'
		) {
			return <OrganizationHeader orgId={Object.values(orgMemberInfo)[0].id} />
		} else {
			// If we don't know what organization to use
			return <GeneralHeader />
		}
	} else {
		if (!orgMemberInfo[orgId] && currentUser.role !== 'SUPER_ADMIN') {
			// User does to have access to the organization
			return <Redirect to={homeLogin} />
		}
		// User has access to organization
		return <OrganizationHeader orgId={orgId} />
	}
}

export default Header
