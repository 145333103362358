import config from './config';
import { handleResponse } from './handle-response';

export const analyticsService = {
  general_metrics,
  analyst_metrics,
}

async function general_metrics(org_id, filters) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/analytics/general_stats/?${filters}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function analyst_metrics(org_id, filters) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/analytics/analysts_stats/?${filters}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
