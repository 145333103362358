/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const accountService = {
  create,
  update,
  remove,
}

async function create(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/accounts/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function update(body, org_id, account_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/accounts/${account_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function remove(org_id, account_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/accounts/${account_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
