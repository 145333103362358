import React, {useState} from 'react'

// Components
import Title from '../../components/Elements/Title'
import TermsOfServiceContent from '../../components/TermsOfServiceContent'
import Button from '../../components/Elements/Button'
import Modal from '../../components/Elements/Modal'

// API
import {userService} from '../../api'


function TermsOfServiceCard(props) {

    const [errors, setErrors] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)

    function handleAccept(){
        
        // var user_body = {
        //     accept_terms_of_service: true
        // }
        // console.log("id", props.signup_info.user_id)
        
        userService.accept_terms(props.signup_info.user_id)
        .then((response) => {
            props.onChange("onboarding", {})
        })
        .catch(error => {
            console.error("Error", error)
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })
        
    }
    console.log("id", props.signup_info.user_id)

    var errors_ = errors.non_field_errors | errors.accept_terms_of_service
    errors_ = errors_ ? errors_ : null


    return (
        <div className={`regist-card ${props.logged ? null : "col-8"}`} >
            <div className="left">
                <Title text="Torch™ Project Management Portal Terms and Conditions" />
            </div>
            <div className="left">
                <TermsOfServiceContent />
            </div>
            <div className={props.logged ? "flexer" : null}>
                <Button text="I read and accept terms of service" class={`orange-btn mt-15 ${props.logged ? "mr-10" : null} full-width`} onClick={handleAccept}/>
                {errors_}
                <Button text="I do not agree with terms of service" class={`white-btn mt-15 ${props.logged ? "ml-10" : null} full-width`} onClick={()=>{setIsModalOpen(true)}}/>
            </div>
            <Modal isOpen={isModalOpen} handleClose={()=>{setIsModalOpen(false)}}>
                <div className="center">
                    <p className="text is-dark mx-30">You need to accept the terms of service in order to be able to use the Echo platform.</p>
                </div>
            </Modal>
        </div>
    )
}

export default TermsOfServiceCard