import {Children, cloneElement, useEffect, useState} from 'react'
import {Mention, MentionsInput} from 'react-mentions'
import {toast} from 'react-toastify'
import axios from 'axios'

import {useOrgId} from 'hooks'
import {memberService} from 'api'
import Avatar from '../Elements/Avatar'
import defaultStyle from './defaultStyle'

function CommentBubble({formStyle, id, onSubmit, onClose}) {
	const orgId = useOrgId()

	const [ subCommentFile, setSubCommentFile ] = useState( {} )

	const initComment = formStyle === 'initComment'

	const [ attachedFile, setAttachedFile ] = useState( '' )

	const handleFileLoader = () => {
		var element = document.getElementById( `file-loader-attachment-${ id }` )
		element.click()
	}

	const handleAttachment = (e) => {
		var filename = e.target.value.split( '\\' )
		filename = filename[ filename.length - 1 ]

		setSubCommentFile( e.target.files[ 0 ] )
		setAttachedFile( filename )
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (formStyle === 'initComment') {
			let input_text_element = document.getElementById('rfi-input-text').value
			input_text_element = replaceMentions(mentions, input_text_element)

			const formElement = document.getElementById(`comment-form-${id}`)
			const formData = new FormData(formElement)

			formData.delete('comment')
			formData.append('comment', input_text_element)

			for (const mention of mentions) {
				if (mention.type === 'case' && mention.id.length === 36) {
					formData.append('case_mentions', mention.id)
				}
			}

			onSubmit(formData)
		} else {

			if ( attachedFile === '' ) {
				const formElement = document.getElementById( `comment-form-${ id }` )[ 0 ].value

				onSubmit( {
					comment: replaceMentions( mentions, formElement ),
				} )
			} else {
				const formData = new FormData()

				const formElement = document.getElementById( `comment-form-${ id }` )[ 0 ].value

				console.log( formElement )

				if ( formElement.length >= 1 ) {
					formData.append( 'comment', formElement )
				}

				formData.append( 'attachment', subCommentFile )

				onSubmit( formData )
			}

		}

		setMentionValue( '' )
		setAttachedFile( '' )
		setSubCommentFile( {} )

		var element = document.getElementById( `file-loader-attachment-${ id }` )
		element.value = ''
	}

	// Start Mentions Config
	const [mentionValue, setMentionValue] = useState('')
	const [userList, setUserList] = useState([])
	const [projectList, setProjectList] = useState([])
	const [mentions, setMentions] = useState([])

	const getProjects = async () => {
		try {
			const {data} = await axios(`/torch/organization/${orgId}/projects/`)
			return data
		} catch ({message}) {
			toast.error(message)
		}
	}

	useEffect(() => {
		memberService.list(orgId,0,998).then(({results}) => hdlUserList(results))
		getProjects().then(({results}) => hdlProjectList(results))
	}, [])

	const hdlDisplayUser = (id, display) => `@${display}`

	const hdlDisplayProject = (id, display) => `$${display}`

	const hdlUserList = (results) => {
		const users = results.map(({user}) => {
			return {
				id: user?.cognito_id, display: `${user?.first_name} ${user?.last_name}`,

			}
		})

		setUserList(users)
	}

	const hdlProjectList = (results) => {
		const projects = results.map(({id, case_id, subject}) => {
			return {
				id: id, display: `${case_id} (${subject?.display_name})`,
			}
		})

		setProjectList(projects)
	}

	const hdlAddUserMention = (id, display, startPos, endPos) => {
		endPos = startPos + display.length + 1
		display = `@${display}`
		setMentions(
			(prev) => [...prev, {type: 'user', startPos, endPos, display, id}])
	}

	const hdlAddProjectMention = (id, display, startPos, endPos) => {
		endPos = startPos + display.length + 1
		display = `$${display}`
		setMentions(
			(prev) => [...prev, {type: 'case', startPos, endPos, display, id}])
	}

	const replaceMentions = (mentions, input_text_element) => {
		mentions.sort((a, b) => b.startPos - a.startPos)

		for (const mention of mentions) {
			let subText = input_text_element.slice(mention.startPos, mention.endPos)

			if (subText !== mention.display) {
				continue
			}

			if (mention.type === 'case') {
				subText = `<a href="/organization/${orgId}/project/${mention.id}" id="project:${mention.id}"><span class="text is-dark is-bold cursor">${subText}</span></a>`
			}
			if (mention.type === 'user') {
				subText = `<span class="text is-dark is-bold" id="user:${mention.id}">${subText}</span>`
			}

			const iniText = input_text_element.slice(0, mention.startPos)
			const finalText = input_text_element.slice(mention.endPos,
				input_text_element.length)

			input_text_element = iniText + subText + finalText
		}

		return input_text_element
	}

	const hdlOnChange = (e, newValue, newPlainTextValue, mentions) => {
		setMentionValue(newPlainTextValue)
	}

	const hdlRenderSuggestion = (
		entry, search, highlightedDisplay, index, focused) => {

		const children = Children.map(highlightedDisplay.props.children,
			(child) => {
				if (!(typeof child == 'string')) {
					return cloneElement(child, {
						className: 'is-text is-dark',
					})
				} else {
					return child
				}
			})

		return cloneElement(highlightedDisplay, {className: 'is-black'}, children)
	}
	// End Mentions Config

	return <form
		className={`flexer flexer-vcenter py-4 ${initComment
			? 'comment-bubble mt-30'
			: 'comment-bubble mt-10'}`}
		id={`comment-form-${id}`}
		onSubmit={handleSubmit}
	>
		<Avatar className={'ml-20'}/>
		<MentionsInput
			id={'rfi-input-text'}
			style={defaultStyle}
			value={mentionValue}
			onChange={hdlOnChange}
			allowSpaceInQuery={true}
			allowSuggestionsAboveCursor={true}
			className={'flexer-8 text is-dark borderless'}
			placeholder={initComment ? 'Add a request...' : 'Add a comment...'}
		>
			<Mention
				trigger="@"
				data={userList}
				appendSpaceOnAdd={true}
				onAdd={hdlAddUserMention}
				displayTransform={hdlDisplayUser}
				renderSuggestion={hdlRenderSuggestion}
			/>
			<Mention
				trigger="$"
				data={projectList}
				appendSpaceOnAdd={true}
				onAdd={hdlAddProjectMention}
				displayTransform={hdlDisplayProject}
				renderSuggestion={hdlRenderSuggestion}
			/>
		</MentionsInput>

		<p
			className="text is-light line-margin-0 left"
			id="attached-file"
			style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
		>
			{attachedFile}
		</p>

		<i className="fa fa-paperclip mr-10 center cursor"
			 onClick={handleFileLoader}/>
		<input
			type="file"
			name="attachment"
			className="file-input"
			onChange={handleAttachment}
			id={`file-loader-attachment-${id}`}
		/>

		<i
			className={`fa fa-paper-plane center cursor mr-${initComment
				? '20'
				: '10'}`}
			onClick={handleSubmit}
		/>

		{!initComment && <i className={'fa fa-close mr-20 center cursor'}
												onClick={onClose}/>}
	</form>
}

export default CommentBubble
