import React from 'react'

function MenuTabItem(props) {
	var ClassName = 'menu-tab cursor'
	if (props.active) {
		ClassName = 'menu-tab menu-tab__active cursor'
	}

	var projectClass = props.project ? 'menu-tab__project' : ''

	if (props.icon) {
		return (
			<div className={`${ClassName} ${projectClass} flexer flexer-vcenter`} onClick={props.onClick}>

				{props.alert > 0 ? 
				<p className='subheading is-light'>
						<i className={`${props.icon} text-icon`}/>
						<i className='fa fa-circle alert-icon' />
					{props.text}
				</p>
				: 
				<p className='subheading is-light'>
					<i className={`${props.icon} text-icon`} />
					{props.text}
				</p>
				}
			</div>
		)
	}

	return (
		<div className={`${ClassName} ${projectClass}`} onClick={props.onClick}>
			<p className='subheading is-light'>{props.text}</p>
		</div>
	)
}

export default MenuTabItem
