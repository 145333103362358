import React, {useState} from 'react'

// Component
import Modal from '../components/Elements/Modal'
import AdditionalInformation from './ConvertProject/AdditionalInformation'
import Customer from './ConvertProject/Customer'
import PriorityOfProject from './ConvertProject/PriorityOfProject'

function ConvertProject(props) {
    const [stage, setStage] = useState("additionalInfo")
    const [data, setData] = useState({})

    function changeData(new_data){
        setData({...data, ...new_data})
    }

    var stageComponent
    if (stage === "additionalInfo"){
        stageComponent = <AdditionalInformation 
                            onNext={()=>setStage("customer")} 
                            onUpdate={changeData} 
                            data={data}/>
    }
    else if (stage === "customer"){
        stageComponent = <Customer 
                            onNext={()=>setStage("priorityOfProject")} 
                            onPrevious={()=>setStage("additionalInfo")} 
                            onUpdate={changeData} 
                            data={data}/>
    }
    else if (stage === "priorityOfProject"){
        stageComponent = <PriorityOfProject 
                            onNext={()=>setStage("completed")} 
                            onPrevious={()=>setStage("customer")} 
                            data={data}/>
    }
    else if (stage === "completed"){
        props.onClose()
        setStage("closed")
    }

    return (
        <Modal isOpen={props.isOpen} fullScreen={true} handleClose={props.onCancel} >
            {stageComponent}
        </Modal>
    )
}

export default ConvertProject