import React from 'react'
import ReactDOM from 'react-dom'


function Modal(props) {

	var body = document.getElementById('body-wrapper')
	if (!props.isOpen){
		body.classList.remove('modal-open')
		return null
	} else {
		body.classList.add('modal-open')
	}

	var className = 'Modal__container screen-center'
	if (props.fullScreen) {
		className = 'Modal__container Modal__full-screen-container'
	}
	
	return (
		ReactDOM.createPortal(
			<div className="Modal">
				<div className={className}>
					{props.handleClose ? <i className="fa fa-close Modal__close cursor is-dark" onClick={props.handleClose}></i> : null}
					{props.children}
				</div>
			</div>,
	        document.getElementById('modal')
	    )
    )

}

export default Modal