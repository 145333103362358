import React from 'react'

const Avatar = ({ userImage, className }) => (
	<img
		alt='profile'
		className={`profile-menu__image ${className}`}
		src={
			userImage ?? 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'
		}
	/>
)

export default Avatar
