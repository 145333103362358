import { authenticationService } from './'
import config from './config'
import { handleResponse } from './handle-response'
import { fixBody } from './helpers'

export const organizationService = {
	list,
	update,
	updateStatus,
	retrieve,
	listByUser,
	create,
	listSubscription,
	paymentMethods,
	attachPaymentMethods,
	setDefaultPaymentMethod,
	deletePaymentMethod,
}

async function list(value, limit, offset) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(
		`${config.apiUrl}/organization/?all=${value}&limit=${limit}&offset=${offset}`,
		requestOptions
	)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function listByUser() {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/`, requestOptions)
		.then(handleResponse)
		.then((organizations) => {
			const organizationsObj = {}
			for (const org of organizations) {
				organizationsObj[org.id] = org
			}
			authenticationService.updateOrgMember(organizationsObj)
			return organizations
		})
}

async function update(body, org_id) {
	body = fixBody(body)
	const requestOptions = { method: 'PATCH', credentials: 'include', body }

	return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function updateStatus(body, org_id) {
	body = fixBody(body)
	const requestOptions = {
		method: 'PATCH',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function retrieve(org_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function create(body) {
	body = fixBody(body)
	const requestOptions = { method: 'POST', credentials: 'include', body }

	return fetch(`${config.apiUrl}/organization/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function listSubscription(org_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/${org_id}/subscriptions/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}
async function paymentMethods(org_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/${org_id}/payment_methods/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function attachPaymentMethods(org_id, body) {
	body = fixBody(body)
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(body),
		headers: { 'Content-Type': 'application/json' },
	}

	return fetch(`${config.apiUrl}/organization/${org_id}/attach_payment_method/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function setDefaultPaymentMethod(org_id, body) {
	body = fixBody(body)
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(body),
		headers: { 'Content-Type': 'application/json' },
	}

	return fetch(`${config.apiUrl}/organization/${org_id}/set_default_payment_method/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function deletePaymentMethod(org_id, body) {
	body = fixBody(body)
	const requestOptions = {
		method: 'DELETE',
		credentials: 'include',
		body: JSON.stringify(body),
		headers: { 'Content-Type': 'application/json' },
	}

	return fetch(`${config.apiUrl}/organization/${org_id}/delete_payment_method/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}
