import { useState } from 'react'
import Modal from './Modal'
import config from '../../api/config'

const FullSize = (props) => {
  const { image } = props
  const [isOpen, setIsOpen] = useState(false)

  function handleImgClick() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="wcc-acimage">
        <img alt="full-size" src={ `${config.mediaUri}/${image}`  } onClick={handleImgClick} />
      </div>

      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <img className="full-size-image full-width full-height" alt="full-size" src={`${config.mediaUri}/${image}`} />
      </Modal>
    </>
  )
}

export default FullSize
