import {useEffect, useState} from 'react'
import {Tooltip} from '@mui/material'
import styled from 'styled-components'

const CustomTooltip = ( { collaborators, currentUser , onLoading, case_id, events } ) => {
	const [ collaboratorList, setCollaboratorList ] = useState( [] )

	const findMe = () => {
		for ( let i = 0; i < collaborators.length; i++ ) {
			if ( collaborators[ i ].cognito_id === currentUser.cognito_id ) {
				setCollaboratorList( prevState => [ collaborators[ i ], ...prevState ] )

			} else {
				setCollaboratorList( prevState => [ ...prevState, collaborators[ i ] ] )
			}
		}
	}

	useEffect( () => {
		setCollaboratorList([])
		findMe()
	}, [ onLoading] )

	let eventInPlace = Array.isArray(events) ? events.includes(case_id) : true

	return collaboratorList.length > 1 && (
		<>
			<CollaboratorNameStyled itsMe={ collaboratorList[ 0 ].cognito_id === currentUser.cognito_id && eventInPlace}>{ collaboratorList[ 0 ].public_name }</CollaboratorNameStyled>
			< Tooltip
				title={
					collaboratorList.slice( 1 ).map( ( collaborator ) => <>
						<span style={{color: 'white'}} key={ collaborator.cognito_id }>{ collaborator.public_name }</span>
						<br/>
					</> )
				}
				placement="right"
				className="ml-10"
				children={ <CollaboratorsBadgeStyled>+ { collaboratorList.length - 1 }</CollaboratorsBadgeStyled> }
				arrow
			/>
		</>
	)

}

const CollaboratorNameStyled = styled.span`
  color: ${ ( props ) => props.itsMe && 'var(--error)' };
  font-weight: ${ ( props ) => props.itsMe && '700' };
`

const CollaboratorsBadgeStyled = styled.small`
  line-height: 1;
  cursor: pointer;
  padding: .35rem;
  border-radius: 100%;
  color: var(--secondary-btn-text);
  background-color: var(--secondary-btn-bg);
`

export default CustomTooltip
