import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import OrganizationSettingsContent from 'containers/Content/OrganizationSettingsContent'

function OrganizationSettings() {
    return (
        <>
            <Header />
            <Content>
                <OrganizationSettingsContent />
            </Content>
        </>
    )
}

export default OrganizationSettings
