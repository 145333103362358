import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { UserContext } from './hooks/UserContext'
import useFindUser from './hooks/useFindUser'
import PrivateRoute from './components/PrivateRoute'
import ThemeMode from './components/ThemeMode'
import Profile from './pages/Profile'
import UserResources from './pages/UserResources'
import Dashboard from './pages/Dashboard'
import Project from './pages/Project'
import Projects from './pages/Projects'
import Chats from './pages/Chats'
import Analytics from './pages/Analytics'
import OrganizationSettings from './pages/OrganizationSettings'
import Tickets from 'pages/Tickets'
import TicketDetail from 'pages/TicketDetail'
import RFI from './pages/RFI'
import UserOrganizations from './pages/UserOrganizations'
import SuperadminSettings from 'pages/SuperadminSettings'
import Home from './pages/Home'
import CaseManagerResources from './pages/CaseManagerResources'
import './assets/styles/grid/simple-grid.min.css'
import './assets/styles/variables.scss'
import './assets/styles/main.scss'

import AwsIsDown from 'pages/AwsIsDown'

function App() {
	const { currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading } =
		useFindUser()

	return (
		<ThemeMode>
			<BrowserRouter>
				<UserContext.Provider
					value={{
						currentUser,
						setCurrentUser,
						currentOrganizations,
						setCurrentOrganizations,
						isLoading,
					}}
				>
					<Switch>
						{/* <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          
          <Route exact path="/verify" component={VerifyEmail} />
          <Route exact path="/recover-password" component={RecoverPassword} /> */}
						<Route exact path='/aws' component={AwsIsDown} />
						<PrivateRoute exact path='/profile/:user_id' component={Profile} />
						<PrivateRoute exact path='/profile/:user_id/:org_id' component={Profile} />
						<PrivateRoute exact path='/tickets/:user_id' component={Tickets} />
						<PrivateRoute exact path='/tickets/detail/:ticket_id' component={TicketDetail} />
						<PrivateRoute
							exact
							path='/organization/:org_id/user-resources'
							component={UserResources}
						/>
						<PrivateRoute
							exact
							path='/organization/:org_id/case-manager-resources'
							component={CaseManagerResources}
						/>
						<PrivateRoute exact path='/organization/:org_id' component={Dashboard} />
						<PrivateRoute
							exact
							path='/organization/:org_id/project/:project_id'
							component={Project}
						/>
						<PrivateRoute
							exact
							path='/organization/:org_id/projects/:project_type'
							component={Projects}
						/>
						<PrivateRoute exact path='/organization/:org_id/chats' component={Chats} />
						<PrivateRoute
							exact
							path='/organization/:org_id/chats/thread/:thread_id'
							component={Chats}
						/>
						<PrivateRoute exact path='/organization/:org_id/analytics' component={Analytics} />
						<PrivateRoute
							exact
							path='/organization/:org_id/organization-settings'
							component={OrganizationSettings}
						/>
						<PrivateRoute exact path='/organization/:org_id/rfi' component={RFI} />
						<PrivateRoute exact path='/organizations' component={UserOrganizations} />
						<PrivateRoute exact path='/superadmin-settings' component={SuperadminSettings} />
						<PrivateRoute exact path='' component={Home} />
					</Switch>
				</UserContext.Provider>
			</BrowserRouter>
		</ThemeMode>
	)
}

export default App
