import { Pagination } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { organizationService } from '../../api/index'
import Button from '../Elements/Button'

import Modal from '../Elements/Modal'
import { PermissionWrapper } from '../index'
import CreateOrganizationForm from '../UserOrganizations/CreateOrganizationForm'
import OrganizationRow from './OrganizationRow'
import OrganizationRowSkt from './OrganizationRowSkt'
import { makeStyles } from '@mui/styles'

import Styles from './OrganizationsDashboard.module.sass'

const OrganizationsDashboard = () => {
	const [limit] = useState(12)
	const [orgs, setOrgs] = useState([])
	const [count, setCount] = useState(0)
	const [offset, setOffset] = useState(0)
	const [refresh, setRefresh] = useState(1)
	const [onSearch, setOnSearch] = useState(false)
	const [onLoading, setOnLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [searchValue, setSearchValue] = useState('')
	const [isCreateOpen, setIsCreateOpen] = useState(false)

	const hdlSearch = ({ target }) => {
		setSearchValue(target.value)
	}

	const getSearchOrganization = async (keyword) => {
		setOnLoading(true)
		try {
			const { data } = await axios(`/organization/search/?keyword=${keyword}`)

			return data
		} catch ({ message }) {
			toast.error(message)
		}
	}

	const hdlUpdate = () => {
		setRefresh(refresh + 1)
	}

	const hdlPaginator = (event, value) => {
		setCurrentPage(value)
		setOnLoading(true)
		setOffset(limit * (value - 1))
	}

	useEffect(() => {
		if (searchValue.length >= 2) {
			getSearchOrganization(searchValue).then((results) => {
				setOrgs(results)

				setTimeout(() => {
					setOnLoading(false)
				}, 250)
			})
		}
	}, [searchValue])

	useEffect(() => {
		if (!onSearch) {
			organizationService
				.list(true, limit, offset)
				.then(({ results, count }) => {
					setOrgs(results)
					setCount(count)
					setOnLoading(false)
				})
				.catch((error) => {
					console.log('Error loading organizations', error)
				})
		}
	}, [offset, onSearch, refresh, isCreateOpen])

	const useStyles = makeStyles(() =>({
		ul: {
			"& .MuiPaginationItem-root": {
				
				color: 'var(--secondary-text)'
			},
			"& .Mui-selected": {
				backgroundColor: 'var(--naranja-btn) !important',
				color: '#FFF'
			}
	 
	}}),
  );

  const classes = useStyles();

	return (
		<>
			<main className='dashboard-section'>
				<header
					className='full-width'
					style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)' }}
				>
					<h1 className='text is-light is-bold left'>Organizations Dashboard</h1>
					<fieldset
						className={'input-wrapper'}
						style={{
							padding: 0,
							border: 'none',
							borderRadius: '5px',
							gridColumn: '1 / 3',
							backgroundColor: 'var(--main-bg)',
						}}
					>
						<i className='fa fa-search icon input-wrapper__icon-left search-icon' />
						<input
							type='search'
							onChange={hdlSearch}
							placeholder={'Find a company...'}
							onFocus={() => setOnSearch(true)}
							className={'search-input subheading is-light'}
							style={{ width: '100%', height: '100%', backgroundColor: 'none' }}
						/>

						{onSearch && (
							<i
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setOnLoading(true)
									setOnSearch(false)
									setSearchValue('')
								}}
								className='fa fa-times icon input-wrapper__icon-right search-icon'
							/>
						)}
					</fieldset>
					<PermissionWrapper requiredPermissions={''}>
						<Button
							text={'Create Organization'}
							class={'black-btn mx-10'}
							icon={'fas fa-plus'}
							onClick={() => setIsCreateOpen(true)}
						/>
					</PermissionWrapper>
				</header>

				<section className={Styles.container}>
					<header className={Styles.container_header}>
						<span />
						<span className={Styles.container_header_span}>Name</span>
						<span className={Styles.container_header_span}>Status</span>
						<span />
						<span />
					</header>
					{onLoading ? (
						<>
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
							<OrganizationRowSkt />
						</>
					) : orgs?.length ? (
						orgs.map((org) => <OrganizationRow org={org} key={org.id} onUpdate={hdlUpdate} />)
					) : (
						<span className='text is-light is-bold'> No organization found</span>
					)}
				</section>
				{!onSearch && (
					<footer className={Styles.container_footer}>
						<span>
							Showing {currentPage === 1 ? currentPage : (currentPage - 1) * limit} -{' '}
							{currentPage === Math.ceil(count / limit) ? count : currentPage * limit} of {count}
						</span>
						<Pagination
							count={Math.ceil(count / limit)}
							shape='rounded'
							classes={{ul: classes.ul }}
							onChange={hdlPaginator}
						/>
					</footer>
				)}
			</main>
			<Modal
				isOpen={isCreateOpen}
				handleClose={() => {
					setIsCreateOpen(false)
				}}
			>
				<CreateOrganizationForm onClose={() => setIsCreateOpen(false)} />
			</Modal>
		</>
	)
}

export default OrganizationsDashboard
