import React from 'react'

// Assets
import GroupIcon from '../../assets/images/group-icon.png'

//Components
import Thumbnail from '../Elements/Thumbnail'
import PermissionWrapper from '../Permissions/PermissionWrapper'

function ChatsList(props) {
	var items = []
	for (let [key, value] of Object.entries(props.items)) {
		var activeClass = 'chats__inactive'
		if (key === props.active) {
			activeClass = 'chats__active'
		}

		if (props.type === 'direct') {
			items.push(
				<div
					className={`${activeClass} chats__item flexer flexer-vcenter flexer-spaced cursor`}
					onClick={() => {
						props.onClick(value)
					}}
				>
					<div className='flexer flexer-vcenter mx-30'>
						<Thumbnail user={value.channel_profile} />
						<p className='text is-dark ml-10'>{value.channel_name}</p>
					</div>
					{value.number_unseen_messages > 0 ? (
						<p className='chats__unread line-margin-0 mx-10'>{value.number_unseen_messages}</p>
					) : (
						''
					)}
				</div>
			)
		} else if (props.type === 'groups') {
			items.push(
				<div
					className={`${activeClass} chats__item flexer flexer-vcenter flexer-spaced cursor`}
					onClick={() => {
						props.onClick(value)
					}}
				>
					<div className='flexer flexer-vcenter mx-30'>
						<img style={{ width: '40px' }} alt='profile' src={GroupIcon} />
						<p className='text is-dark ml-10'>{value.channel_name}</p>
					</div>
					{value.number_unseen_messages > 0 ? (
						<p className='chats__unread line-margin-0 mx-10'>{value.number_unseen_messages}</p>
					) : (
						''
					)}
				</div>
			)
		} else if (props.type === 'broadcast') {
			items.push(
				<div
					className={`${activeClass} chats__item flexer flexer-vcenter flexer-spaced cursor`}
					onClick={() => {
						props.onClick(value)
					}}
				>
					<div className='flexer mx-30'>
						<p className='text is-dark line-margin-xs mr-10'>#</p>
						<p className='text is-dark left line-margin-xs'>{value.title}</p>
					</div>
				</div>
			)
		}
	}

	function handleClick() {
		var arrow = document.getElementById(`arrow-${props.pos}`)
		arrow.classList.toggle('fa-angle-down')
		arrow.classList.toggle('fa-angle-right')

		var items = document.getElementById(`items-${props.pos}`)
		items.classList.toggle('shown')
		items.classList.toggle('hidden')
	}

	return (
		<div className='simple-profile__sections unpadify-x'>
			<div className='flexer flexer-vcenter flexer-spaced mx-15'>
				<h1 className='heading is-dark cursor' onClick={handleClick}>
					<i id={`arrow-${props.pos}`} className='fas fa-angle-down mr-10 is-light'></i>{' '}
					{props.title}
				</h1>
				{props.type === 'direct' ? (
					<i className='fa fa-plus cursor is-light' onClick={props.onNew}></i>
				) : (
					''
				)}
				{props.type === 'broadcast' && (
					<PermissionWrapper
						requiredPermissions={['chat::manage_broadcast_messaging']}
						checkAll={false}
					>
						<i className='fa fa-plus cursor is-light' onClick={props.onNew}></i>{' '}
					</PermissionWrapper>
				)}
			</div>
			<div id={`items-${props.pos}`} className='shown'>
				{items}
			</div>
		</div>
	)
}

export default ChatsList
