import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const webscraperService = {
  list,
  createNote,
  updateNote,
  updateRelevance,
  addNewInfo,
}

async function list(org_id, project_id, filters) {
  const requestOptions = { method: 'GET', credentials: 'include'  }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/scraper_results/${project_id}/?${filters}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response
    })
}

async function createNote(body, org_id, data_type, data_id ) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include' , headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/${data_type}/${data_id}/note/`, requestOptions)
    .then(handleResponse)
    .then(note => {
      return note
    })
}


async function updateNote(body, org_id, note_id) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include' , headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/result_notes/${note_id}/`, requestOptions)
    .then(handleResponse)
    .then(note => {
      return note
    })
}

async function updateRelevance(body, org_id, data_type, data_id ) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include' , headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/${data_type}/${data_id}/`, requestOptions)
    .then(handleResponse)
    .then(note => {
      return note
    })
}

async function addNewInfo(body) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include' , headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }

  return fetch(`${config.apiUrl}/cid/tools/scrapers/`, requestOptions)
    .then(handleResponse)
    .then(note => {
      return note
    })
}
