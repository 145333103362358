import React from 'react'

function Title(props){
    return (
        <React.Fragment>
            <h1 className="title is-dark">{props.text}</h1>
        </React.Fragment>
    )
}

export default Title