import React, { useState } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import SortIcon from '@mui/icons-material/Sort'


const WebscraperFilters = (props) => {
  const { options, handleSelect, handleCategories, handleSortFilter } = props

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleSort = (value) => {
    handleSortFilter(value)
    handleClose()
  }

  let sort = <>
      <IconButton
        onClick={handleClick}
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className='MuiButtonBase-root-MuiIconButton-root'
        >
        <SortIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        className='MuiList-root-MuiMenu-list'
      >
        <MenuItem disabled >Sort by... </MenuItem>
        <MenuItem className='MenuItem' onClick={() =>handleSort(['created', 'reverse'])}>Date ascending  </MenuItem>
        <MenuItem className='MenuItem' onClick={() =>handleSort(['created'])}>Date descending</MenuItem>
        <MenuItem className='MenuItem' onClick={() =>handleSort(['relevance'])}>Relevance ascending</MenuItem> 
        <MenuItem className='MenuItem' onClick={() =>handleSort(['relevance', 'reverse'])}>Relevance descending</MenuItem> 
      </Menu>
    </>

  return (
    <>
      <div className='flexer flexer-spaced flexer-vcenter my-30 mx-30'>
        <div className='flexer-6 filters-multi'>
          <Multiselect
            options={options}
            displayValue="name"
            avoidHighlightFirstOption={true}
            placeholder="Select tag"
            hidePlaceholder={true}
            name="selected_tags"
            onSelect={handleSelect}
            onRemove={handleSelect}
          />
        </div>
        <div className='flexer-3 center'>
          <select  className="full-width subheading is-light no-border filter__border-bottom background-color" onChange={handleCategories}>
            <option value='' >Select category</option>
            <option value='RELEVANT'>Relevant result</option>
            <option value='IRRELEVANT'>Irrelevant result</option>
            <option value='REVIEW'>For review</option>
          </select>
        </div>
        <div className='flexer-1'>
          {sort}
        </div>
      </div>
    </>
  )
}

export default WebscraperFilters
