import {toast} from 'react-toastify'
import {useState} from 'react'
import axios from 'axios'

import LocationForm from './LocationForm'
import {Modal} from '../../../Elements'

const AddLocationModal = ( { isOpenAddLocation, setOpenAddLocation, orgId, subject } ) => {
	const [ onLoading, setOnLoading ] = useState( false )

	const hdlSubmit = async ( { category, streetAddress, apartmentSuite, city, state, country, zip } ) => {
		setOnLoading( true )

		if ( streetAddress !== '' || apartmentSuite !== '' || city !== '' || state !== '' || country !== '' || zip !== '' ) {
			try {
				const req = await axios.post( `/torch/organization/${ orgId }/locations/`, {
					'street_address': {
						'content': streetAddress || 'Unknown',
					},
					'street_address_2': {
						'content': apartmentSuite || 'Unknown',
					},
					'city': {
						'content': city || 'Unknown',
					},
					'state_region': {
						'content': state || 'Unknown',
					},
					'zip': {
						'content': zip || 'Unknown',
					},
					'country': {
						'content': country || 'Unknown',
					},
					'category': parseInt(category),
					'subject': subject.id,
				} )

				toast.success( 'Location has been created!' )
				setOpenAddLocation( false )

				return req
			} catch ( { message } ) {
				toast.error( message )
			} finally {
				setOnLoading( false )
			}
		} else {
			setOnLoading( false )
			toast.error( 'Please fill in at least one field' )
		}
	}

	return (
		<Modal
			isOpen={ isOpenAddLocation }
			handleClose={ () => {
				setOpenAddLocation( false )
			} }
		>
			<LocationForm
				hdlSubmit={ hdlSubmit }
				onLoading={ onLoading }
				cta={ () => setOpenAddLocation( false ) }
				subjectType={subject.type}
				formType={'Add'}
			/>
		</Modal>
	)
}

export default AddLocationModal
