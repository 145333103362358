import React, { useState, useEffect } from 'react'
import DataConfidence from './DataConfidence'

const ConfidenceIcon = (props) => {
  const { confidence, orgId, dataId } = props

  const [iconColor, setIconColor] = useState(null)
  const [confidenceData, setConfidenceData] = useState('')
  const [dataConfidence, setDataConfidence] = useState({ show: false, color: iconColor, confidence: confidenceData })
  const [isActive, setIsActive] = useState(false)
  const [activeClass, setActiveClass] = useState('confidence')

  useEffect(() => {
    if (isActive) {
      setActiveClass('confidence__active')
    }

    if (confidence === 'Created by bot') {
      setIconColor('confidence-0')
      setConfidenceData('')
    }

    if (confidence === 'Unknown') {
      setIconColor('confidence-1')
      setConfidenceData('Unknown')
    }

    if (confidence === 'Low') {
      setIconColor('confidence-2')
      setConfidenceData('Low Confidence')
    }

    if (confidence === 'Medium') {
      setIconColor('confidence-3')
      setConfidenceData('Medium Confidence')
    }

    if (confidence === 'High') {
      setIconColor('confidence-4')
      setConfidenceData('High Confidence')
    }

    if (confidence === 'Verified') {
      setIconColor('confidence-5')
      setConfidenceData('Verified')
    }
  }, [confidence, isActive])


  const handleDataConfidence = () => {
    setIsActive(!isActive)
    setDataConfidence({
      show: !dataConfidence.show,
      color: iconColor,
      confidence: confidenceData
    })
  }
  const handleLeaveMouse = () => {
    setIsActive(false)
    setDataConfidence({
      show: false,
      color: iconColor,
      confidence: confidenceData
    })
  }

  const handleClose = () => {
    setIsActive(false)
    props.onClose()
    setDataConfidence({
      ...dataConfidence,
      show: !dataConfidence.show,
    })
  }

  return (
    <>
      <div className={`wcc-confidence ${activeClass}`} onMouseLeave={handleLeaveMouse}>
        <i className={`fa fa-tachometer ml-5 mr-10 ${iconColor}`} onClick={handleDataConfidence} />

        {dataConfidence.show && (
          <DataConfidence
            confidence={dataConfidence.confidence}
            color={dataConfidence.color}
            orgId={orgId}
            dataId={dataId}
            onClose={handleClose}
          />
        )}
      </div>
    </>
  )
}

export default ConfidenceIcon
