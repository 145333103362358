import { Pagination as MaterialPagination } from '@mui/material'
import { useEffect } from 'react'

import './pagination.scss'

const Pagination = ({ data, paginator, setPages, limit = 9 }) => {
	useEffect(() => {
		const fromValue = paginator.offset + 1
		const toValue = fromValue + data.results.length - 1
		const showing = fromValue === toValue ? `${fromValue}` : `${fromValue} - ${toValue}`

		if (!paginator.offset) {
			setPages((prev) => ({ ...prev, offset: 0 }))
		}

		setPages((prev) => ({ ...prev, showing: showing, limit: limit }))
	}, [data])

	const handlePaginator = (e, page) => {
		e.preventDefault()

		if (page > 1) {
			setPages((prev) => ({ ...prev, offset: limit * (page - 1) }))
		} else {
			setPages((prev) => ({ ...prev, offset: 0, showing: limit }))
		}
	}

	return (
		<div className='wcc-pagination'>
			<div className='wcc-info'>
				Showing {paginator.showing} of {data.count}
			</div>

			<MaterialPagination
				count={Math.ceil(data.count / limit)}
				onChange={handlePaginator}
				disabled={data.count <= 9 }
				shape='rounded'
				size='small'
			/>
		</div>
	)
}

export default Pagination
