import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Modal } from '../../../Elements/index'

const ProjectTypesModalEdit = ({ toEdit, setToEdit, hdlEdit, categories, onErr, reportTemplates }) => {
	const [defaultData, setDefaultData] = useState({})

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		setTimeout(() => {
			setDefaultData({
				project_name: toEdit.name,
				short_name: toEdit.code,
				template:toEdit?.template,
				// category: [],
				// category: toEdit.category,
			})
		}, 500)
	}, [toEdit])

	useEffect(() => {
		reset(defaultData)
	}, [defaultData])

	return (
		<Modal
			isOpen={toEdit.showModal}
			handleClose={
				() =>
					setToEdit({
						id: '',
						name: '',
						code: '',
						showModal: false,
						template: '',
					})
				// category: '',
			}
		>
			<h1 className='title is-dark mx-5 mb-15'>Edit Project Type</h1>
			<form
				onSubmit={handleSubmit(hdlEdit)}
				style={{
					display: 'grid',
					rowGap: '.75rem',
				}}
			>
				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Project type name </small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{onErr && 'This field is required and must be unique.'}
							{errors.project_name && 'This field is required'}
						</span>
					</label>
					<input
						style={{
							border: `1px solid var(${errors.project_name ? '--error' : '--border'})`,
							padding: '.5rem',
							transition: 'all .5rem ease-in-out',
						}}
						placeholder={'Example: Research'}
						type={'text'}
						{...register('project_name', { required: true })}
					/>
				</fieldset>
				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>
							Short name (3 Uppercase letters)
						</small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{onErr && 'This field is required and must be unique.'}
							{errors.short_name && 'This field is required'}
						</span>
					</label>
					<input
						style={{
							border: `1px solid var(${errors.short_name ? '--error' : '--border'})`,
							padding: '.5rem',
							transition: 'all .5rem ease-in-out',
						}}
						maxLength={3}
						placeholder={'Example: RES'}
						type='text'
						{...register('short_name', { required: true })}
					/>
				</fieldset>
				<fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Custom report template</small>
					</label>
					<select
						style={{ border: '1px solid var(--border)', padding: '.5rem' }}
						{...register('template')}
					>
						<option disabled selected value={''}>
							Select template
						</option>
						{reportTemplates.length > 0 ? (
							reportTemplates.map(({ name, id }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))
						) : (
							<option disabled>No report templates found </option>
						)}
					</select>
				</fieldset>
				{/* <fieldset
					style={{
						display: 'flex',
						flexDirection: 'column',
						border: 'none',
						margin: 0,
						padding: 0,
					}}
				>
					<label>
						<small style={{ color: 'var(--secondary-text)' }}>Category</small>
						<strong style={{ color: 'var(--link)' }}>*</strong>
						<span
							style={{
								color: 'var(--error)',
								fontSize: '.6rem',
								marginLeft: '.4rem',
							}}
						>
							{errors.category && 'This field is required'}
						</span>
					</label>
					<select
						style={{
							border: '1px solid var(--border)',
							padding: '.5rem',
						}}
						{...register('category')}
					>
						{categories.length > 0 ? (
							categories.map(({ name, id }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))
						) : (
							<option disabled hidden>
								No categories found
							</option>
						)}
					</select>
				</fieldset> */}
				<footer className='right center-mobile'>
					<Button
						type={'button'}
						className={'mr-10'}
						variant={'outlined'}
						onClick={() => {
							setToEdit({
								showModal: false,
								name: '',
								code: '',
								// category: '',
								template: '',
							})
						}}
					>
						Cancel
					</Button>
					<Button variant={'contained'} type={'submit'}>
						Save changes
					</Button>
				</footer>
			</form>
		</Modal>
	)
}
export default ProjectTypesModalEdit
