import { Link } from 'react-router-dom'

const Hyperlink = ({ iconClass, text, className, linkClass, link }) => {
  return (
    <div className={className}>
      <Link className={`${linkClass} link`} to={link}>
        {iconClass && <i className={`${iconClass}`} />}
        {text}
      </Link>
    </div>
  )
}

export default Hyperlink;
