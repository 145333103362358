import { useState } from 'react'

// Stripe
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// Config
import config from 'api/config'
// Components
import InputField from './InputField'
import Button from './Button'

import { organizationService } from '../../api/index'
// Hooks
import { useOrgId } from 'hooks'

function CreditCard(props) {
	const stripePromise = loadStripe(config.stripeClientSecret)
	return (
		<Elements stripe={stripePromise}>
			<CreditCardInfo {...props} />
		</Elements>
	)
}

function CreditCardInfo(props) {
	const [billingDetails, setBillingDetails] = useState({ phone: '', name: '' })
	const [error, setError] = useState(null)

	const orgId = useOrgId()

	let theme = localStorage.getItem('themeMode')
	let color =  theme === 'dark' ? '#ffff' : '#272727'
	let color_placehoder =  theme === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#272727B2'


	const CARD_OPTIONS = {
		iconStyle: 'solid',
		style: {
			base: {
				colorPrimary: '#0570de',
				colorBackground: '#ffffff',
				color: color,
				colorDanger: '#df1b41',
				fontFamily: 'Ideal Sans, system-ui, sans-serif',
				spacingUnit: '2px',
				borderRadius: '4px',
				'::placeholder': {
					color: color_placehoder,
				},
			},
			invalid: {
				iconColor: 'red',
				color: 'red',
			},
		},
	}

	const stripe = useStripe()
	const elements = useElements()
	if (!stripe || !elements) {
		return ''
	}
	async function handleSubmit(e) {
		e.preventDefault()

		setError(null)

		const cardElement = elements.getElement(CardElement)

		let { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: billingDetails.name,
			},
		})

		if (error) {
			setError(error.message)
			console.error(error)
		} else {
			organizationService
				.attachPaymentMethods(orgId, {
					payment_method: paymentMethod.id,
					subscription: props.subscription.stripe_subscription_id,
				})
				.then((response) => {
					console.log(response)
				})
				.then(() => {
					props.onClose()
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}



	return (
		<>
			<form className='Form' onSubmit={handleSubmit}>
				<InputField
					class='mb-20'
					name='full_name'
					type='text'
					placeholder='Full Name'
					value={billingDetails.name}
					onChange={(e) => {
						setBillingDetails({ ...billingDetails, name: e.target.value })
					}}
					/>
				<InputField
					class='mb-20'
					name='phone'
					type='number'
					placeholder='Phone'
					value={billingDetails.phone}
					onChange={(e) => {
						setBillingDetails({ ...billingDetails, phone: e.target.value })
					}}
				/>
				<div style={{ borderBottom: '1px solid var(--lines)', marginTop: '50px' }}>
					<CardElement options={CARD_OPTIONS} />
				<div style={{borderBottom: 'solid 0.5px var(--border)', margin: '10px 0 30px 0'}} />
				</div>
				{error ? (
					<p className='error left mb-20'>
						<i className='fa fa-warning mr-10'></i>
						{error}
					</p>
				) : null}
				<Button type='submit' text='Create Card' class='orange-btn mt-30 subheading is-white' />
			</form>
		</>
	)
}

export default CreditCard
