import React, { Fragment } from 'react'
import { CustomDashboard, Header } from '../containers'

function Dashboard() {
  return (
    <Fragment>
      <Header />
      <CustomDashboard />
    </Fragment>
  )
}

export default Dashboard
