import { Button, Pagination } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { projectTypesService } from '../../../api/'
import getCategories from '../../../helpers/getCategories'

import styles from './projectTypes.module.sass'
import ProjectTypesModalCreate from './Modal/ProjectTypesModalCreate'
import ProjectTypesModalDelete from './Modal/ProjectTypesModalDelete'
import ProjectTypesModalEdit from './Modal/ProjectTypesModalEdit'
import ProjectTypesTable from './ProjectTypesTable'

const ProjectTypes = ({ orgId }) => {
	const [limit] = useState(12)
	const [count, setCount] = useState(0)
	const [offset, setOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)

	const [categories, setCategories] = useState([])
	const [onLoading, setOnLoading] = useState(true)
	const [onErr, setOnErr] = useState(false)
	const [projectTypes, setProjectTypes] = useState([])
	const [showAddModal, setShowAddModal] = useState(false)
	const [reportTemplates, setReportTemplates] = useState([])
	const [toEdit, setToEdit] = useState({
		id: '',
		name: '',
		code: '',
		// category: '',
		showModal: false,
		template: '',
	})
	const [toDelete, setToDelete] = useState({
		code: '',
		id: '',
		name: '',
		showModal: false,
	})
	const [refresh, setRefresh] = useState(0)

	const getReportTemplates = async () => {
		try {
			const { data } = await axios(`/torch/organization/${orgId}/project_type_template/?limit=999`)

			return data
		} catch (err) {
			toast.error(err)
		}
	}

	const hdlCreate = async ({ short_name, project_name, category, template }) => {
		try {
			await axios.post(`/torch/organization/${orgId}/project_type/`, {
				code: short_name,
				name: project_name,
				// categories: [category],
				project_type_template: parseInt(template),
			})
			toast.success(`The Project Type: ${project_name} has been created`)
			setShowAddModal(false)
			setOnErr(false)
			setOnLoading(true)
		} catch ({message}) {
			setOnErr(true)
			toast.error({message})
		}
	}

	const hdlEdit = async ({ short_name, project_name, category, template }) => {
		try {
			await axios.patch(`/torch/organization/${orgId}/project_type/${toEdit.id}/`, {
				code: short_name,
				name: project_name,
				// categories: category === '' ? toEdit.category : [category],
				// project_type_templates: template,
			})

			template &&
				projectTypesService
					.update_template({ project_type_template: [template] }, orgId, toEdit.id)
					.then((res) => {
						console.log(res)
						setRefresh(refresh + 1)
					})

			toast.success(`The Project Types: ${toEdit.name} has been updated.`)
			setOnErr(false)
			setToEdit({
				id: '',
				name: '',
				code: '',
				// category: '',
				showModal: false,
				template: '',
			})
			setOnLoading(true)
		} catch ({message}) {
			setOnErr(true)
			toast.error(message)
		} 
	}

	const hdlDelete = async () => {
		setOnLoading(true)

		try {
			await axios.delete(`/torch/organization/${orgId}/project_type/${toDelete.id}/`)

			toast.success(`The Project Type: ${toDelete.name} was deleted,`)
			setToDelete({
				showModal: false,
				name: '',
				code: '',
				id: '',
			})
			setOnLoading(true)
		} catch (err) {
			toast.error(err)
		}
	}

	const hdlPaginator = (event, value) => {
		setCurrentPage(value)
		setOnLoading(true)
		setOffset(limit * (value - 1))
	}

	useEffect(() => {
		if (showAddModal || toEdit.showModal) {
			getCategories(orgId).then(({ results }) => setCategories(results))
			getReportTemplates().then(({ results }) => setReportTemplates(results))
		} else {
			setCategories([])
			setReportTemplates([])
		}

		projectTypesService
			.list(orgId, limit, offset)
			.then(({ results, count }) => {
				setProjectTypes(results)
				setCount(count)
				setTimeout(() => {
					setOnLoading(false)
				}, 500)
			})
			.catch((error) => {
				console.error('Unable to get project types', error)
			})
	}, [showAddModal, toDelete.showModal, toEdit.showModal, refresh, offset])


	return (
		<section className={'dashboard-section'}>
			<main className={styles.main}>
				<header className={styles.main_header}>
					<h1 className={`${styles.main_header_title} text is-light is-bold left mb-30`}>
						Project Types
					</h1>
					<Button
						size={'large'}
						color={'primary'}
						// startIcon={<Add sx={{color: ['white']}}/>	}
						variant={'contained'}
						onClick={() => setShowAddModal(true)}
					>
						Add Type
					</Button>
				</header>
				<ProjectTypesTable
					hdlEdit={hdlEdit}
					onLoading={onLoading}
					setToEdit={setToEdit}
					setToDelete={setToDelete}
					projectTypes={projectTypes}
				/>
				<footer className={styles.main_footer}>
					<span>
						Showing {currentPage === 1 ? currentPage : (currentPage - 1) * limit} -{' '}
						{currentPage === Math.ceil(count / limit) ? count : currentPage * limit} of {count}
					</span>
					<Pagination
						count={Math.ceil(count / limit)}
						shape='rounded'
						color={'primary'}
						onChange={hdlPaginator}
					/>
				</footer>
			</main>
			<ProjectTypesModalCreate
				onErr={onErr}
				hdlCreate={hdlCreate}
				categories={categories}
				showAddModal={showAddModal}
				setShowAddModal={setShowAddModal}
				reportTemplates={reportTemplates}
			/>
			{toEdit.showModal && (
				<ProjectTypesModalEdit
					onErr={onErr}
					toEdit={toEdit}
					hdlEdit={hdlEdit}
					setToEdit={setToEdit}
					categories={categories}
					reportTemplates={reportTemplates}
				/>
			)}
			<ProjectTypesModalDelete
				toDelete={toDelete}
				hdlDelete={hdlDelete}
				setToDelete={setToDelete}
			/>
		</section>
	)
}

export default ProjectTypes
