import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'

const AdditionalInformationForm = (props) => {
	const handleClick = () => {
		var additional_content = document.getElementsByName('additional_content')[0].value
		props.onUpdate({ additional_content })
		props.onNext()
	}

	const handleClickPrevious = () => {
		var additional_content = document.getElementsByName('additional_content')[0].value
		props.onUpdate({ additional_content })
		props.onPrevious()
	}

	return (
		<div className='half-width center'>
			<div className='left mt-60'>
				<div className='flexer'>
					<i className='form-icon far fa-list-alt'></i>
					<Textarea
						name='additional_content'
						class='full-width line-margin-xs'
						label='Additional information'
						value={props.data.additional_content}
					/>
				</div>
				<div className='flexer flexer-spaced center-mobile'>
					<Button
						text='&larr; Previous step'
						class='mt-15 white-btn is-orange subheading is-light'
						type='button'
						onClick={handleClickPrevious}
					/>
					<Button
						text='Next step &rarr;'
						class='mt-15 orange-btn subheading is-light'
						onClick={handleClick}
					/>
				</div>
			</div>
		</div>
	)
}

export default AdditionalInformationForm
