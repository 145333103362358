import React from 'react'

// Containers
import Content from '../containers/Content'
import WebScraperSection from 'components/Project/WebScraperSection'

function AwsIsDown() {
	return (
		<React.Fragment>
			<Content>
				<div className="flexer flexer-spaced max-screen__bottom flexer-mobile">
					<div className="flexer-3 project-sides"/>
					<div className="flexer-6">
						<div className="advanced-profile"/>
						<WebScraperSection/>
					</div>
					<div className="flexer-3 project-sides"/>
				</div>
			</Content>
		</React.Fragment>
	)
}

export default AwsIsDown
