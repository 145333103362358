import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { AiOutlineRightCircle, AiOutlineUpCircle } from 'react-icons/ai'
import { FaPencilAlt, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import SwitchButton from 'react-switch'

import LogoOrg from '../../assets/images/globe_international_icon.png'
import { userService } from '../../api'
import Button from '../Elements/Button'
import Modal from '../Elements/Modal'

import Styles from './UserRow.module.sass'

const UserRow = ({ user, onUpdate }) => {
	const [showDetails, setShowDetails] = useState(false)
	const [isSuspended, setIsSuspended] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		user?.status && user.status === 'suspended' ? setIsSuspended(true) : setIsSuspended(false)
	}, [user.status])

	const handleStatus = () => {
		let newStatus = isSuspended ? 'active' : 'suspended'

		userService
			.update({ status: newStatus }, user.cognito_id)
			.then((res) => {
				onUpdate()
			})
			.catch((error) => {
				console.error('Unable to update user status', error)
			})
		setIsOpen(false)
		window.location.reload()
	}

	return (
		<>
			<article className={`${Styles.item} ${showDetails && Styles.item__open}`}>
				<header className={Styles.item_header}>
					<IconButton
						className={Styles.item_header_icons}
						aria-label='details'
						onClick={() => setShowDetails(!showDetails)}
					>
						{showDetails ? <AiOutlineUpCircle /> : <AiOutlineRightCircle />}
					</IconButton>

					<figure className={Styles.item_header_name}>
						{user?.profile?.profile_pic ? (
							<img
								className={Styles.item_header_name_img}
								src={user.profile.profile_pic}
								alt={user?.public_name}
							/>
						) : (
							<FaUser className={Styles.item_header_name_img} />
						)}
						<figcaption className={Styles.item_header_name_data}>
							<strong>
								<Link to={`/profile/${user.cognito_id}/`}>{user.public_name}</Link>
							</strong>
							<small
								className={Styles.item_header_name_data_role}
								style={{ textTransform: 'capitalize' }}
							>
								<strong>
									{user.role === 'SUPER_ADMIN' && user.role.replace('_', ' ').toLowerCase()}
									{user.role === 'SUPPORT' && user.role.toLowerCase()}
								</strong>
							</small>
						</figcaption>
					</figure>

					<p className={Styles.item_header_email}>{user.email}</p>
					<p
						className={`${Styles.item_header_status} ${
							user?.status === 'suspended' && Styles.item_header_status__suspended
						}`}
					>
						{user.status ?? 'No data available'}
					</p>
					<IconButton className={Styles.item_header_icons} aria-label='details'>
						<Link to={`/profile/${user.cognito_id}`}>
							<FaPencilAlt size={20} />
						</Link>
					</IconButton>
				</header>

				{showDetails && (
					<section className={Styles.item_details}>
						<span className={Styles.item_details_toggle}>
							Suspend or enable this user
							<span className={Styles.item_details_toggle_switch}>
								<SwitchButton
									onChange={() => setIsOpen(true)}
									checked={isSuspended}
									height={20}
									width={40}
									handleDiameter={15}
									onColor='#F69221'
								/>
								Suspended
							</span>
						</span>

						{user?.memberships?.length ? (
							<article className={Styles.item_details_orgs}>
								{user?.memberships?.map(({ organization: { name, profile_pic, id } }) => (
									<figure className={Styles.item_details_orgs_card} key={id}>
										<img
											className={Styles.item_details_orgs_card_img}
											src={profile_pic ?? LogoOrg}
											alt={name}
										/>
										<figcaption>{name}</figcaption>
									</figure>
								))}
							</article>
						) : (
							<p>No memberships available</p>
						)}
					</section>
				)}
			</article>
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				<div className='center'>
					<h1 className='title is-dark mb-30'>
						{user?.status === 'active' ? 'Suspend User' : 'Enable User'}
					</h1>
					<p className='text is-light mx-30'>
						{user?.status === 'active'
							? 'Are you sure that you want to suspend'
							: 'Are you sure that you want to enable'}
						<br /> <span className='is-dark is-bold'>{user?.public_name}</span>?
					</p>
					<Button
						text='Confirm'
						class='mt-30 one-third-width orange-btn subheading-light'
						type='button'
						onClick={handleStatus}
					/>
				</div>
			</Modal>
		</>
	)
}

export default UserRow
