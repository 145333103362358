
const SubscriptionCard = ( {
	logo,
	name,
	price,
	benefits,
	description,
} ) => {
	return (
		<article
			style={ {
				padding        : '1.5rem',
				backgroundColor: 'var(--card-bg',
				borderRadius   : '.5rem',
        border         : '1px solid var(--border)',
				rowGap         : '1rem',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				boxShadow      : '0px 2px 6px var(--shadow)'
			} }
		>
			<span
				style={ {
					borderWidth  : '1px',
					borderStyle  : 'solid',
					color        : 'var(--link)',
					borderColor  : 'var(--link)',
					padding      : '.625rem',
					textTransform: 'capitalize',
					borderRadius : '1.5rem',
					textAlign    : 'center',
					fontWeight   : 'bold',
					width        : '15rem',
				} }
			>
				{ name }
			</span>
			<figure
				style={ {
					margin       : 0,
					padding      : 0,
					textAlign    : 'center',
					borderBottom : '1px solid var(--border)',
					paddingBottom: '1rem',
					width        : '100%',
				} }
			>
				<img style={ { height: '4rem' } } src={ logo } alt='' />
			</figure>
			<ul style={ { width: '100%', margin: 0, padding: 0, flexGrow: 2 } }>
				{ benefits &&
					benefits?.map( ( benefit, i ) => <li
						style={ { listStyleType: 'none' } }
						key={ `item-${ benefit.trim }-${ i }` }
					>{ benefit }</li> ) }
			</ul>
			<p style={ { textAlign: 'justify', lineHeight: '1.2rem', margin: 0, color:'var(--main-text)' } }>{ description }</p>
      <strong style={{fontSize:'2rem', color:'var(--main-text)'}}>{ price !== 0 ? `$ ${price}` : `FREE` }</strong>
		</article>
	)
}

export default SubscriptionCard
