import config from '../../api/config'
import caseFolder from '../../assets/images/caseFolder.png'

const ImageContent = ({ className, src, alt }) => {
	return (
		<img
			className={className}
			src={
				src
					? `${config.mediaUri}/${src}`
					: caseFolder
			}
			alt={alt}
		/>
	)
}

export default ImageContent
