import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-regular-svg-icons'
import {useEffect, useRef, useState} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import styled from 'styled-components'
import {Prompt} from 'react-router'

import {timeService} from 'api'
import {useOrgId} from 'hooks'
import './countdown.scss'

const CountDown = (props) => {
	const orgId = useOrgId()
	const {
		overTime, projectId,
	} = props
	const [start, setStart] = useState(false)
	const [seconds, setSeconds] = useState(0)
	const [data, setData] = useState({
		case: '', start_time: '', end_time: '',
	})
	const componentReady = useRef(false)

	const secondsToDisplay = seconds % 60
	const minutesRemaining = (seconds - secondsToDisplay) / 60
	const minutesToDisplay = minutesRemaining % 60
	const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

	useEffect(() => {
		if (projectId) {
			timeService.getSpendTime(orgId, projectId).then((res) => setSeconds(res))
		}
	}, [projectId, orgId])

	useEffect(() => {
		if (componentReady.current && projectId) {
			if (start) {
				setData({
					case: projectId, start_time: new Date(), end_time: '',
				})
			} else {
				const lastData = {
					...data, end_time: new Date(),
				}
				setData(lastData)

				// Cada vez que el tiempo termine, se deben enviar los datos al server
				timeService.create(orgId, lastData).
					then((res) => console.log('Update time spend'))
			}
		}

		componentReady.current = true
	}, [start])

	useInterval(() => {
			setSeconds(seconds + 1)
		}, start === true ? 1000 : null, // passing null stops the interval
	)

	return (<div className="wcc-countdown">
		{hoursToDisplay >= overTime && (<TimeExceededStyled>
			<FontAwesomeIcon icon={faExclamationTriangle}/>
			You've exceeded the estimated time for this project.
		</TimeExceededStyled>)}
		<div className="wcc-counter">
			<SpendTimeStyled>
				<FontAwesomeIcon icon={faClock}/>
				Time Spent
			</SpendTimeStyled>
			<div className="wcc-tright">
				<h3>
					{twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:{twoDigits(
					secondsToDisplay)}
				</h3>
				<button onClick={() => setStart(!start)} type="button">
					<i className={start ? `fa fa-stop-circle` : `fa fa-play-circle`}
						 aria-hidden="true"></i>
					{start && (<Prompt
						when={true}
						message="If you leave the page you will lose the time tracked in the project, are you sure you want to leave the page?"
					/>)}
				</button>
			</div>
		</div>
	</div>)
}

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
	const savedCallback = useRef()

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current()
		}

		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

//  https://stackoverflow.com/a/2998874/1673761
const twoDigits = (num) => String(num).padStart(2, '0')

const TimeExceededStyled = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(227, 30, 40, 0.1);
  border: solid 1px rgba(227, 30, 40, 0.3);
  color: var(--error);
  font-size: 16px;
  height: 56px;
  padding: 0 10px;
  border-radius: 5px;

  & svg {
    margin-right: 0.5rem;

    & path {
      fill: var(--error) !important;
    }
  }
`

const SpendTimeStyled = styled.span`
  & svg {
    margin-right: 0.5rem;
  }
`

export default CountDown
