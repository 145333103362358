import MultiStep from '../../components/CreateProject/MultiSteps'
import StartPointForm from '../../components/CreateProject/Forms/StartPointForm'

const StartPoint = ({ onNext, onUpdate, data }) => {
	return (
		<>
			<h1 className='title-2 is-dark line-margin-0'>Project Generation</h1>
			<MultiStep current={1} />
			<StartPointForm onNext={onNext} onUpdate={onUpdate} data={data} />
		</>
	)
}

export default StartPoint
