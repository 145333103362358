import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Title = ( { text, className } ) => (
  <StyledTitle className={ className }>{ text }</StyledTitle>
)

const StyledTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.15px;
`

Title.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Title
