import { faFilePdf, faFilePowerpoint, faFileWord } from '@fortawesome/free-regular-svg-icons'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClickAwayListener, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import config from '../../api/config'
import { projectService } from '../../api/index'
import ConvertProject from '../../containers/ConvertProject'
import { useUserRole } from '../../hooks/index'
import useOrgId from '../../hooks/useOrgId'
import Button from '../Elements/Button'
import Modal from '../Elements/Modal'
import PermissionWrapper from '../Permissions/PermissionWrapper'

import AddNewInformationForm from './Forms/AddNewInformationForm'
import CloseProjectForm from './Forms/CloseProjectForm'

const ProjectInfoSection = (props) => {
	const [status, setStatus]                         = useState(null)
	const [isOpen, setIsOpen]                         = useState(false)
	const [priority, setPriority]                     = useState(null)
	const [redirect, setRedirect]                     = useState(null)
	const [statusState, setStatusState]               = useState(null)
	const [closedReason, setClosedReason]             = useState(null)
	const [isOpenConvert, setIsOpenConvert]           = useState(false)
	const [isOpenNewInfo, setIsOpenNewInfo]           = useState(false)
	const [showReportOptions, setShowReportOptions]   = useState(false)
	const [isOpenCloseProject, setIsOpenCloseProject] = useState(false)

	const orgId       = useOrgId()
	const currentRole = useUserRole()
	const memberRole  = useUserRole(true)

	const [anchorEl, setAnchorEl] = useState(null)
	const open                    = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	if (props.project.status === 'CLOSE') {
		if (!closedReason) {
			// setClosedReason(props.project.close_other_reason ? props.project.close_other_reason : props.project.proper_close_options)

			setClosedReason(
				props.project.close_options !== 'OTHER'
					? props.project.proper_close_options
					: props.project.close_other_reason
			)
		}
	}

	const hdlShowReportOptions = () => {
		setShowReportOptions(false)
	}

	function TogglePriorityMenu (e) {
		var element = document.getElementById('priority-list')
		if (element.classList.contains('hidden')) {
			element.classList.remove('hidden')
			element.classList.add('shown')
		} else {
			element.classList.add('hidden')
			element.classList.remove('shown')
		}
	}

	function ToggleStatusMenu (e) {
		var element = document.getElementById('status-list')
		if (element.classList.contains('hidden')) {
			element.classList.remove('hidden')
			element.classList.add('shown')
		} else {
			element.classList.add('hidden')
			element.classList.remove('shown')
		}
	}

	function updateProject (field, e) {
		var form_data = new FormData()
		form_data.append(field, e)
		projectService.update(form_data, orgId, props.project.id)
			.catch((error) => {
				console.log('It was not possible to update status', error)
			})
	}

	function handleConvert () {
		setIsOpenConvert(true)
		setStatus('Unpublished')
	}

	function changeStatus (e) {
		setStatusState(e.target.textContent)
		console.log(e.target.textContent)
		updateProject('status', e.target.getAttribute('value'))
		if (memberRole) {
			setIsOpen(true)
		}
		// setStatus(e.target.textContent)
		let value = e.target.textContent
		if (value === 'Discover') {
			value = 'Discovery'
		}
		if (value === 'Monitor') {
			value = 'Monitoring'
		}
		setStatus(value)
	}

	function handleReview () {
		setStatusState('In review')
		updateProject('status', 'IN_REVIEW')
		setStatus('In review')
	}

	function handleCloseProject () {
		setIsOpenCloseProject(true)
	}

	function handleUpdateClose (reason) {
		setStatusState('Closed')
		if (memberRole) {
			setIsOpen(true)
		}
		setStatus('Closed')
		setIsOpenCloseProject(false)
		setClosedReason(reason)
	}

	if (redirect) {
		return redirect
	}

	function changePriority (e) {
		setPriority(e.target.getAttribute('value'))
		updateProject('priority', e.target.getAttribute('value'))
	}

	const handleDownloadReport = (fileType) => {
		const file_type = fileType

		projectService.generate_report(orgId, props.project.id, file_type)
			.then((response) => {
				const link    = document.createElement('a')
				link.target   = '_blank'
				link.download = `report_${ props.project_id }.${ file_type }`
				link.href     = `${ config.mediaUri }/${ response.report }`
				link.click()
			})
			.catch((error) => {
				console.error('It was not possible to generate report', error)
			})
	}

	function closeAndRefresh () {
		setIsOpen(false)
		setRedirect(
			<Redirect
				to={ {
					pathname: `/organization/${ orgId }/chats`,
					state   : {
						status    : statusState,
						project_id: props.project.case_id,
						id        : props.project.id
					}
				} }
			/>
		)
	}

	var finalStatus   = status
		? status
		: props.project.proper_status
	var finalPriority = priority
		? priority
		: props.project.priority

	var priorityOptions
	if (props.closed || currentRole === 'ANALYST') {
		priorityOptions = <></>
	} else {
		priorityOptions = (
			<>
				<i className='fas fa-angle-down text-icon mx-5'/>
				<ul
					id='priority-list'
					className='profile-menu__list hidden subheading is-dark right'
				>
					<li
						className='profile-menu__item cursor'
						value='high'
						onClick={ changePriority }
					>
						High Priority
					</li>
					<li
						className='profile-menu__item cursor'
						value='standard'
						onClick={ changePriority }
					>
						Standard Priority
					</li>
				</ul>
			</>
		)
	}

	var priorityDrop = null
	if (finalStatus !== 'Temporary' && finalStatus !== 'Closed') {
		priorityDrop = (
			<div
				className={ `line-margin-0 mr-20 relative ${
					props.closed || currentRole === 'ANALYST'
						? null
						: 'cursor'
				} select-special select-red` }
				onClick={ props.closed || currentRole === 'ANALYST'
					? null
					: TogglePriorityMenu }
			>
				<span className='profile-menu__name subheading is-dark'>
					{ finalPriority === 'high'
						? 'High Priority'
						: 'Standard Priority' }
				</span>
				{ priorityOptions }
			</div>
		)
	}

	var statusOptions
	var allowToggle
	var allowCursor = null
	var reviewProject
	if (
		( finalStatus === 'Unpublished' ||
			finalStatus === 'Develop' ||
			finalStatus === 'Discovery' ||
			finalStatus === 'Monitoring' ||
			finalStatus === 'Available' ||
			finalStatus === 'In review' ||
			finalStatus === 'Closed' ) &&
		( currentRole === 'CASE_MANAGER' || currentRole === 'ADMIN' ||
			currentRole === 'SUPER_ADMIN' )
	) {
		allowToggle   = ToggleStatusMenu
		allowCursor   = 'cursor'
		statusOptions = (
			<>
				<i className='fas fa-angle-down text-icon mx-5'/>
				<ul
					id='status-list'
					className='profile-menu__list hidden subheading is-dark right'
				>
					{ finalStatus !== 'Unpublished' && (
						<li
							className='profile-menu__item cursor'
							value='UNPUBLISHED'
							onClick={ changeStatus }
						>
							Unpublished
						</li>
					) }
					{ finalStatus !== 'Available' && (
						<li
							className='profile-menu__item cursor'
							value='AVAILABLE'
							onClick={ changeStatus }
						>
							Available
						</li>
					) }
					{ finalStatus !== 'Discovery' && (
						<li
							className='profile-menu__item cursor'
							value='DISCOVER'
							onClick={ changeStatus }
						>
							Discover
						</li>
					) }
					{ finalStatus !== 'Develop' && (
						<li
							className='profile-menu__item cursor'
							value='DEVELOP'
							onClick={ changeStatus }
						>
							Develop
						</li>
					) }
					{ finalStatus !== 'Monitoring' && (
						<li
							className='profile-menu__item cursor'
							value='MONITORING'
							onClick={ changeStatus }
						>
							Monitor
						</li>
					) }
					{ finalStatus !== 'Closed' && (
						<li
							className='profile-menu__item cursor'
							value='CLOSE'
							onClick={ handleCloseProject }
						>
							Closed
						</li>
					) }
				</ul>
			</>
		)
	} else if (finalStatus !== 'Closed' && currentRole === 'ANALYST') {
		allowToggle   = ToggleStatusMenu
		allowCursor   = 'cursor'
		statusOptions = (
			<>
				<i className='fas fa-angle-down text-icon mx-5'/>
				<ul
					id='status-list'
					className='profile-menu__list hidden subheading is-dark right'
				>
					<li
						className='profile-menu__item cursor'
						value='AVAILABLE'
						onClick={ changeStatus }
					>
						Available
					</li>
					<li
						className='profile-menu__item cursor'
						value='DISCOVER'
						onClick={ changeStatus }
					>
						Discover
					</li>
					<li
						className='profile-menu__item cursor'
						value='DEVELOP'
						onClick={ changeStatus }
					>
						Develop
					</li>
					<li
						className='profile-menu__item cursor'
						value='MONITORING'
						onClick={ changeStatus }
					>
						Monitor
					</li>
				</ul>
			</>
		)
	}

	if (finalStatus === 'Develop' || finalStatus === 'Discovery' ||
		finalStatus === 'Monitoring') {
		reviewProject = (
			<PermissionWrapper
				requiredPermissions={ ['torch::submit_project_for_approval'] }
				checkAll={ false }
			>
				<Button
					class='orange-btn'
					text='Submit for Review'
					type='button'
					icon='fa fa-search'
					onClick={ handleReview }
				/>
			</PermissionWrapper>
		)
	}

	const handleClickNewInfo = () => {
		setIsOpenNewInfo(true)
		handleClose()
	}

	var convert

	if (finalStatus === 'Temporary') {
		convert = (
			<PermissionWrapper
				requiredPermissions={ ['torch::edit_project_data'] }
				checkAll={ false }
			>
				<Button
					class='orange-btn ml-10'
					text='Convert to project'
					type='button'
					icon='fas fa-share'
					onClick={ handleConvert }
				/>
			</PermissionWrapper>
		)
	}

	return (
		<>
			<div className='flexer flexer-spaced wcc-project-header'>
				<div className='inline'>
					<div className='flexer flexer-vcenter'>
						{ finalStatus === 'Temporary'
							? (
								''
							)
							: (
								<h1 className='title is-dark line-margin-0 mr-10'>{ props.project.case_id }</h1>
							) }
						{ priorityDrop }
						<div
							className={ `line-margin-0 relative ${ allowCursor } select-special select-orange` }
							onClick={ allowToggle }
						>
							<span className='profile-menu__name subheading is-dark'>
								{ finalStatus === 'Discovery'
									? 'Discover'
									: finalStatus === 'Monitoring'
										? 'Monitor'
										: finalStatus }
							</span>
							{ statusOptions }
						</div>
						{/*<PermissionWrapper
						 requiredPermissions={['torch::read_scraper_results']}
						 checkAll={false}
						 >
						 <div className={`line-margin-0 mx-10 relative select-special select-grey`}>
						 <span className='profile-menu__name subheading is-dark'>Non scraping</span>
						 </div>
						 </PermissionWrapper>*/ }
						{ !props.project?.collaborative && (
								<div className={ `line-margin-0 mx-10 relative select-special select-grey` }>
									<span className='profile-menu__name subheading is-dark'>Not Collaborative</span>
									<PermissionWrapper
										requiredPermissions={ ['torch::manage_projects'] }
										checkAll={ false }
									>
										<i
											className='fa fa-refresh mr-20 is-light cursor'
											onClick={ () => {
												props.onChangeCollaborative()
												updateProject('collaborative',
													!props.project?.collaborative)
											} }
										></i>
									</PermissionWrapper>
								</div>
							) ||
							(
								<div className={ `line-margin-0 mx-10 relative select-special select-grey` }>
									<span className='profile-menu__name subheading is-dark'>Collaborative</span>
									<PermissionWrapper
										requiredPermissions={ ['torch::manage_projects'] }
										checkAll={ false }
									>
										<i
											className='fa fa-refresh mr-20 is-light cursor'
											onClick={ () => {
												props.onChangeCollaborative()
												updateProject('collaborative',
													!props.project?.collaborative)
											} }
										></i>
									</PermissionWrapper>
								</div> )
						}
					</div>
					{ finalStatus === 'Closed' && (
						<p className='text is-dark line-margin-0'>{ `Close reason: ${ closedReason }` }</p>
					) }
					<div className='flexer flexer-vcenter mt-10'>
						<p className='text is-dark line-margin-0'>
							Start Date:{ ' ' }
							<span className='text is-dark is-bold'>{ props.project.proper_start_date }</span>
						</p>
						<span className='text is-light mx-15'>•</span>
						<p className='text is-dark line-margin-0'>
							Stop Date:{ ' ' }
							<span className='text is-dark is-bold'>
								{ props.project.proper_stop_date
									? props.project.proper_stop_date
									: '-' }
							</span>
						</p>
					</div>
				</div>
				{ finalStatus === 'Closed' && (
					<PermissionWrapper
						requiredPermissions={ ['torch::create_reports'] }
						checkAll={ false }
					>
						<ClickAwayListener onClickAway={ hdlShowReportOptions }>
							<nav
								style={ {
									position: 'relative',
									height  : 'fit-content'
								} }
							>
								<BtnReportTypesStyled
									onClick={ () => setShowReportOptions(true) }
									className={ 'button orange-btn' } type={ 'button' }
								>
									Generate Customer Report
									<FontAwesomeIcon className={ 'ml-10' } icon={ faEllipsisV }/>
								</BtnReportTypesStyled>
								{ showReportOptions && !['80dee24d-59aa-4cf1-9194-cd6f996cc98e', 'bac0e273-cd1d-4096-aa3a-18f7cc84795e', '6fb5fd76-33dd-4e19-a1d1-f5b967eaeee2'].includes(orgId) ? 
									<ul
										style={ {
											right          : 0,
											margin         : 0,
											padding        : 0,
											width          : '100%',
											top            : '100%',
											position       : 'absolute',
											listStyleType  : 'none',
											borderRadius   : '.25rem',
											border         : '1px solid var(--border)',
											backgroundColor: 'var(--main-bg)'
										} }
									>
										<li
											style={ {
												padding     : '.8rem 1rem',
												cursor      : 'pointer',
												fontWeight  : 'lighter',
												borderBottom: '1px solid var(--border)'
											} }
											onClick={ () => handleDownloadReport('docx') }
										>
											<FontAwesomeIcon
												icon={ faFileWord }
												style={ { marginRight: '.5rem' } }
											/>
											Download Word
										</li>
										<li
											style={ {
												padding     : '.8rem 1rem',
												cursor      : 'pointer',
												fontWeight  : 'lighter',
												borderBottom: '1px solid var(--border)'
											} }
											onClick={ () => handleDownloadReport('pdf') }
										>
											<FontAwesomeIcon
												icon={ faFilePdf }
												style={ { marginRight: '.5rem' } }
											/>
											Download PDF
										</li>
										<li
											style={ {
												padding   : '.8rem 1rem',
												cursor    : 'pointer',
												fontWeight: 'lighter'
											} }
											onClick={ () => handleDownloadReport('pptx') }
										>
											<FontAwesomeIcon
												icon={ faFilePowerpoint }
												style={ { marginRight: '.5rem' } }
											/>
											Download PPT
										</li>
									</ul> :
									showReportOptions ?
									<ul
									style={ {
										right          : 0,
										margin         : 0,
										padding        : 0,
										width          : '100%',
										top            : '100%',
										position       : 'absolute',
										listStyleType  : 'none',
										borderRadius   : '.25rem',
										border         : '1px solid var(--border)',
										backgroundColor: 'var(--main-bg)'
									} }
									>
										<li
											style={ {
												padding     : '.8rem 1rem',
												cursor      : 'pointer',
												fontWeight  : 'lighter',
												borderBottom: '1px solid var(--border)'
											} }
											onClick={ () => handleDownloadReport('lea_docx') }
										>
											<FontAwesomeIcon
												icon={ faFileWord }
												style={ { marginRight: '.5rem' } }
											/>
											Download Word
										</li>
									</ul>
									:
									null
								}
							</nav>
						</ClickAwayListener>
					</PermissionWrapper>
				) }
				{ reviewProject }
				{ convert }
				<div className='ml-5'>
					{/*<IconButton
					 aria-label="more"
					 id="long-button"
					 aria-controls="long-menu"
					 aria-expanded={open ? "true" : undefined}
					 aria-haspopup="true"
					 onClick={handleClick}
					 className="MuiButtonBase-root-MuiIconButton-root"
					 >
					 <MoreVertIcon />
					 </IconButton>*/ }
					<Menu
						id='basic-menu'
						anchorEl={ anchorEl }
						open={ open }
						onClose={ handleClose }
						MenuListProps={ { 'aria-labelledby': 'basic-button' } }
						className='MuiList-root-MuiMenu-list'
					>
						<MenuItem
							className='MenuItem'
							onClick={ () => handleClickNewInfo() }
						>
							Add new information
						</MenuItem>
					</Menu>
				</div>
			</div>
			<Modal
				isOpen={ isOpen }
				handleClose={ () => {
					setIsOpen(false)
				} }
			>
				<div className='center'>
					<h1 className='title is-dark mb-30'>Status Changed</h1>
					<p className='text is-dark mx-30'>Send a broadcast message to let everyone know.</p>
					<Button
						text='OK'
						class='mt-30 one-third-width orange-btn subheading is-light'
						type='button'
						onClick={ closeAndRefresh }
					/>
				</div>
			</Modal>
			<Modal
				isOpen={ isOpenCloseProject }
				handleClose={ () => {
					setIsOpenCloseProject(false)
				} }
			>
				<CloseProjectForm
					onClose={ () => {
						setIsOpenCloseProject(false)
					} }
					project={ props.project.id }
					onSubmit={ handleUpdateClose }
				/>
			</Modal>
			<Modal
				isOpen={ isOpenNewInfo }
				handleClose={ () => {
					setIsOpenNewInfo(false)
				} }
			>
				<AddNewInformationForm
					onClose={ () => {
						setIsOpenNewInfo(false)
					} }
					projectId={ props.project.id }
					accounts={ props.accounts }
				/>
			</Modal>
			<ConvertProject
				isOpen={ isOpenConvert }
				onCancel={ () => {
					setIsOpenConvert(false)
				} }
				onClose={ () => {
					setIsOpenConvert(false)
					props.onClose()
					props.onChange(true)
				} }
			/>
		</>
	)
}

const BtnReportTypesStyled = styled.button`
	svg {
		path {
			fill: white !important;
		}
	}
`

export default ProjectInfoSection
