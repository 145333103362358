const getPlacesPostCodeById = async placeId =>
	new Promise((resolve, reject) => {
		if (!placeId) reject("placeId not provided")

		try {
			new window.google.maps.places.PlacesService(
				document.createElement("div")
			).getDetails(
				{
					placeId,
					fields: ["address_components"],
				},
				details => {
					let postcode = null
					details?.address_components?.forEach(entry => {
						if (entry.types?.[0] === "postal_code") {
							postcode = entry.long_name
						}
					})
					return resolve(postcode)
				}
			)
		} catch (e) {
			reject(e)
		}
	})

const getPostalCode = async ( placeId ) => {
	try {
		return await getPlacesPostCodeById( placeId )
	} catch ( err ) {
		console.log( err )
	}
}

export default getPostalCode
