import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BtnSolid = ( { name, cta, style, type, className, isDisabled, onLoad } ) => (
	<StyledButton
		title={ name }
		onClick={ cta }
		className={ `${ className }` }
		type={ type }
		style={ style }
		disabled={ isDisabled }
		isDisabled
	>
		{ onLoad
			? <FontAwesomeIcon icon={ faSpinner } spin={ true } className={ 'text-white' }/>
			: name }
	</StyledButton>
)

BtnSolid.propTypes = {
	name: PropTypes.string,
	onLoad: PropTypes.bool,
	isDisabled: PropTypes.bool,
	className: PropTypes.string,
	cta: PropTypes.func,
	type: PropTypes.oneOf( [ 'submit', 'button', 'reset' ] ).isRequired,
}

BtnSolid.defaultProps = {
	onLoad: false,
	type: 'button',
	isDisabled: false,
}

const StyledButton = styled.button`
	color: #fff;
	cursor: pointer;
	font-size: 1rem;
	min-width: 9rem;
	border-width: 1px;
	border-style: solid;
	border-radius: 0.25rem;
	padding: 0.5rem 1.25rem;
	transition: all 500ms ease-in-out;
	border-color: ${ ( props ) => props.isDisabled
	? 'var(--orange-btn)'
	: '#bdbdbd' };
	background-color: ${ ( props ) => props.isDisabled
	? 'var(--orange-btn)'
	: '#bdbdbd' };

	:hover {
		border-color: #d47810;
		background-color: #d47810;
	}
`

export default BtnSolid
