import React from 'react'

// Components
import Button from '../../Elements/Button'
import useOrgId from '../../../hooks/useOrgId'

// API
import { webscraperService } from '../../../api'

const EditRelevanceScraperForm = (props) => {
  const { id, type, newRelevance, oldRelevance, onClose } = props
  const orgId = useOrgId()
  
  const handleRelevance = () => {
    let data_type = ''
    if(type === 'torch_alias') {
      data_type = 'aliases'
    }
    if(type === 'torch_accounts') {
      data_type = 'accounts'
    }
    if(type === 'torch_subject') {
        data_type = 'subjects'
    }
    if(type === 'torch_phone') {
      data_type = 'phones'
    }
    if(type === 'torch_link') {
      data_type = 'links'
    }
    if(type === 'torch_location') {
      data_type = 'location'
    }
    if(type === 'torch_datapoint') {
      data_type = 'data_points'
    }
      
    let body = {
      relevance: newRelevance && newRelevance
    }

    webscraperService.updateRelevance(body, orgId, data_type, id) 
      .then(response => {
          onClose()
      })
      .catch(error => {
          console.log(error)
      })
  }

  return (
    <>
        <div className="center">
          <h1 className="title is-dark mb-30">Change Relevance</h1>
          <input type="hidden" name="relevance" value={newRelevance && newRelevance}/>
          <p className="text is-light mx-30">Are you sure that you want to change the relevance from <br></br>
            <span className="is-dark is-bold mx-5">{oldRelevance}</span> to 
            <span className="is-dark is-bold mx-5">{newRelevance}</span> for 
            <span className="is-dark is-bold mx-5">{`Scraper result  #${id && id}`}</span>?
          </p>
          <div className="right center-mobile">
            <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={onClose}/>
            <Button text="Save changes" class="orange-btn mt-30 subheading is-white" onClick={handleRelevance} />
          </div>
        </div>
      </>
  )
}

export default EditRelevanceScraperForm
