import styles from './userRowSkt.module.sass'

const OrganizationRowSkt = () => (
	<article className={styles.skeleton}>
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<span className={`${styles.skeleton_item} skeleton-animation`} />
		<aside className={styles.skeleton_actions}>
			<span className={`${styles.skeleton_actions_item} skeleton-animation`} />
			<span className={`${styles.skeleton_actions_item} skeleton-animation`} />
		</aside>
	</article>
)
export default OrganizationRowSkt
