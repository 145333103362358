import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const rfisService = {
  create,
  list,
  create_comment,
}

async function create(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body}
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/rfis/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/rfis/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function create_comment(body, org_id, rfiId) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body}
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/rfis/${rfiId}/add_comment/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
