// API
import { rfisService } from 'api/rfis.service'
// Hooks
import { useOrgId } from 'hooks'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
// Components
import CommentBubble from '../../components/RFI/CommentBubble'
import RFI from '../../components/RFI/RFI'

const RFIContent = () => {
	const orgId = useOrgId()

	const [msgList, setMsgList] = useState([])
	const [onLoading, setOnLoading] = useState(false)

	useEffect(() => {
		rfisService.list(orgId)
			.then(({results}) => {
				setMsgList(results)
			})
	}, [onLoading])

	const hdlSubmit = (formData) => {
		setOnLoading(true)

		rfisService
			.create(formData, orgId)
			.then(() => {
				setOnLoading(false)
				toast.success('RFI created successfully')
			})
			.catch(({ message }) => {
				toast.error(message)
			})
	}

	return (
		<section className="rfi-wrapper">
			<CommentBubble formStyle="initComment" onSubmit={hdlSubmit} id={'rfi'}/>
			<article className="comments-section">{msgList.map((item) => <RFI key={item.id} rfi={item}/>)}</article>
		</section>
	)
}

export default RFIContent
