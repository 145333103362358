import React from 'react'

function Content(props) {
    return (
        <div className="row">
            <div className="content-wrapper col-10">
                {props.children}
            </div>
        </div>
    )
}

export default Content