export default {
	flexGrow: 2,
	border: 'none',
	margin: '0 1rem',

	control: {
		fontSize: 14,
		fontWeight: 'normal',
	},

	'&multiLine': {
		control: {
			fontFamily: 'monospace',
		},
		highlighter: {
			padding: 9,
			border: '1px solid transparent',
			 
		},
		input: {
			border: 'none',
			padding: '.5rem',
			lineHeight: '1.8rem',
		},
	},

	'&singleLine': {
		display: 'inline-block',
		width: 180,

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},
		input: {
			padding: 1,
			border: '2px inset',
		},
	},

	suggestions: {
		list: {
			fontSize: 14,
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			 
			'&focused': {
				backgroundColor: 'var(--naranja-btn)',
				
			},
		},
	},
}
