import React, { useState, useEffect, useContext } from 'react'
import { PermissionWrapper } from 'components'

// Components
import ChatsList from '../../components/Chats/ChatsList'
import CreateBroadcast from '../../components/Chats/CreateBroadcast'
import UserSearch from '../../components/Chats/UserSearch'
import ViewChat from '../../components/Chats/ViewChat'
import ViewThread from '../../components/Chats/ViewThread'
import useOrgId from '../../hooks/useOrgId'


//API
import { chatService } from '../../api'

// Hooks
import { useUserRole, useWebSocket } from 'hooks'

function ChatsContent(props) {

  const [refresh, setRefresh] = useState(0)
  const [broadcastObj, setBroadcastObj] = useState({})
  const [groupObj, setGroupsObj] = useState({})
  const [directObj, setDirectObj] = useState({})
  const [selectedChannel, selectChannel] = useState(null)
  const [selectedThread, setSelectedThread] = useState(null)
  const [webSocketMessages, setWebSocketMessages] = useState([])
  const [webSocketBroadcastMessages, setwebSocketBroadcastMessages] = useState([])
  const [rightView, setRightView] = useState(null)
  const [activeChannel, setActiveChannel] = useState('')
  const [broadcastMessage, setBroadcastMessage] = useState(null)
  const [statusChange, setStatusChange] = useState(null)
  const [first, setFirst] = useState(true)

  // Edit or delete 
  const [idsDeleted, setIdsDeleted] = useState([])
  const [msgUpdated, setMsgUpdated] = useState({})



  const orgId = useOrgId()
  const currentRole = useUserRole()

  useEffect(() => {
    if (props.statusChange && first) {
      setRightView("new-broadcast")
      setStatusChange(props.statusChange)
      setFirst(false)
    }

    if (props.viewThread && first) {
      handleSelectThread(props.viewThread.id)
      setFirst(false)
    }
  }, [first, props.statusChange, props.viewThread])

  /* WEBSOCKET SECTION */
  const { WebSocketOpen, WebSocketClient } = useWebSocket('chat')
  if (WebSocketOpen) {
    WebSocketClient.current.onmessage = e => {
      const data = JSON.parse(e.data);
      if (data.type === "CHAT_MESSAGE") {
        handleNewMessage(data.payload, "chat")
      }
      if (data.type === "UPDATED_CHAT_MESSAGE") {
        handleUpdatedMessage(data.payload, "chat")
      }
      if (data.type === "DELETED_CHAT_MESSAGE") {
        handleDeleteMessage(data.payload, "chat")
      }
      else if (data.type === "BROADCAST_MESSAGE") {
        handleNewMessage(data.payload, "broadcast")
      }
      else if (data.type === "THREAD_MESSAGE") {
        handleNewThread(data.payload)
      }
      else if (data.type === "UPDATED_THREAD_MESSAGE") {
        handleUpdatedThread(data.payload)
      }
      else if (data.type === "DELETED_THREAD_MESSAGE") {
        handleDeletedThread(data.payload)
      }
      else if (data.type === "CONVERSATION") {
        handleConversation(data.payload)
      }
      else if (data.type === "NEW_CONVERSATION") {
        handleNewConversation(data.payload)
      }
    }
  }

  /* END WEBSOCKET SECTION */

  function handleNewThread(message) {
    if (selectedThread) {
      if (message.parent_message === selectedThread) {
        if (!(message.id in webSocketMessages)) {
          var webMessages = webSocketMessages
          webMessages.unshift(message)
          setWebSocketMessages([...webMessages])
          markIfVisible()
          setRefresh(count => count + 1)
        }
      }
    }
  }
  function handleUpdatedThread(message) {
    if (selectedThread) {
      if (message.parent_message === selectedThread) {
        let webMessages = []
          webMessages = webSocketMessages.map(msg => (msg.id !== message.id) ? msg : (message))
          setWebSocketMessages([...webMessages])
          markIfVisible()
          setRefresh(count => count + 1)
          setMsgUpdated((prevState) => {return {...prevState, [message.id]: message}})
      }
    }
  }
  function handleDeletedThread(message) {
    if (selectedThread) {
      if (message.parent_message === selectedThread) {
          let webMessages = webSocketMessages.filter(msg => msg.id !== message.id)
          setWebSocketMessages([...webMessages])
          markIfVisible()
          setRefresh(count => count + 1)
          setIdsDeleted((prevState) => [...prevState, message.id])
      }
    }
  }

  function handleNewMessage(message, type_) {
    if (selectedChannel) {
      if (message.channel === selectedChannel.channel.id) {
        if (type_ === "chat") {
          if (!(message.id in webSocketMessages)) {
            var webMessages = webSocketMessages
            webMessages.unshift(message)
            setWebSocketMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
          }
        }
        else if (type_ === "broadcast") {
          if (!(message.id in webSocketBroadcastMessages)) {
            webMessages = webSocketBroadcastMessages
            webMessages.unshift(message)
            setwebSocketBroadcastMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
          }
        }
      }
    }
  }

  function handleUpdatedMessage(message, type_) {
    if (selectedChannel) {
      if (message.channel === selectedChannel.channel.id) {
        let webMessages = []
        if (type_ === "chat") {
            webMessages = webSocketMessages.map(msg => (msg.id !== message.id) ? msg : (message))
            setWebSocketMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
            setMsgUpdated((prevState) => {return {...prevState, [message.id]: message}})

          }
          else if (type_ === "broadcast") {
            webMessages = webSocketBroadcastMessages.map(msg => (msg.id !== message.id) ? msg : (message))
            setwebSocketBroadcastMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
            setMsgUpdated((prevState) => {return {...prevState, [message.id]: message}})

        }
      }
    }
  }

  function handleDeleteMessage(message, type_) {
    if (selectedChannel) {
      if (message.channel === selectedChannel.channel.id) {
        let webMessages = []
        if (type_ === "chat") {
            webMessages = webSocketMessages.filter(msg => msg.id !== message.id)
            setWebSocketMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
            setIdsDeleted((prevState) => [...prevState, message.id])
          }
          else if (type_ === "broadcast") {
            webMessages = webSocketBroadcastMessages.filter(msg => msg.id !== message.id)
            setwebSocketBroadcastMessages([...webMessages])
            markIfVisible()
            setRefresh(count => count + 1)
        }
      }
    }
  }

  const handleEditMessage = () => {
    setRefresh(refresh + 1)
  }

  function handleConversation(message) {
    if (!selectedChannel || message.channel.id !== selectedChannel.channel.id) {
      var newChannel = {}
      newChannel[`message_${message.channel.id}`] = message
      if (message.channel.type === "group") {
        setGroupsObj({ ...groupObj, ...newChannel })
      }
      else if (message.channel.type === "direct") {
        setDirectObj({ ...directObj, ...newChannel })
      }
      setRefresh(refresh + 1)
    }
  }

  function handleNewConversation(userChat) {

    var newChannel = {}
    newChannel[`message_${userChat.channel.id}`] = userChat
    if (userChat.channel.type === "group") {
      setGroupsObj({ ...groupObj, ...newChannel })
    }
    else if (userChat.channel.type === "direct") {
      setDirectObj({ ...directObj, ...newChannel })
    }

    WebSocketClient.current.close()

    selectChannel(() => userChat)
    setRightView(null)
    setRefresh(refresh + 1)

  }

  function markMessagesAsRead(userChatId) {
    chatService.mark_messages_as_read(userChatId, orgId)
      .then(response => {
      })
      .catch(error => {
        console.error("It was not possible to reset messages", error)
      })
  }

  function markBroadcastMessagesAsRead(channelId) {
    const userChatId = groupObj[`message_${channelId}`].id
    chatService.mark_broadcast_messages_as_read(userChatId, orgId)
      .then(response => {
      })
      .catch(error => {
        console.error("It was not possible to reset messages", error)
      })
  }

  function handleSelectChannel(channel) {
    channel.number_unseen_messages = 0
    markMessagesAsRead(channel.id)

    if (selectedThread) {
      setWebSocketMessages([])
      setwebSocketBroadcastMessages([])
    }
    else if (selectedChannel) {
      if (channel.channel.id !== selectedChannel.channel.id) {
        setWebSocketMessages([])
        setwebSocketBroadcastMessages([])
      }
    }

    selectChannel(() => channel)
    setRightView(null)
    setSelectedThread(null)
    setActiveChannel(`message_${channel.channel.id}`)
    setRefresh(count => count + 1)

  }

  function handleSelectBroadcast(broadcast) {
    setActiveChannel(`broadcast_${broadcast.id}`)
    setRightView("new-broadcast")
    setBroadcastMessage(broadcast)
    setSelectedThread(null)
  }

  function markIfVisible() {
    if (document.visibilityState === "visible" && !document.hidden) {
      if (selectedChannel) {
        markMessagesAsRead(selectedChannel.id)
        var channel = selectedChannel
        channel.number_unseen_messages = 0
        selectChannel(() => channel)
      }
    }
  }

  document.onvisibilitychange = (event) => {
    markIfVisible()
  }

  useEffect(() => {
    chatService.list_channels(orgId)
      .then(response => {
        var groups = {}
        var directs = {}
        for (const value of response) {
          if (value.channel.type === "direct") {
            directs[`message_${value.channel.id}`] = value
          } else if (value.channel.type === "group") {
            groups[`message_${value.channel.id}`] = value
          }
        }

        setDirectObj({ ...directs })
        setGroupsObj({ ...groups })
      })
      .catch(error => {
        console.error("It was not possible to get user channels", error)
      })
  }, [orgId, refresh])

  useEffect(() => {
    if (currentRole !== "ANALYST") {
      chatService.list_broadcasts(orgId)
        .then(response => {
          var broadcasts = {}
          for (const value of response.filter(msg => !msg.deleted)) {
            broadcasts[`broadcast_${value.id}`] = value
          }
          setBroadcastObj({ ...broadcasts })
        })
        .catch(error => {
          console.error("It was not possible to get the broadcast list", error)
        })
    }
  }, [currentRole, orgId, refresh])

  function handleNewBroadcast(broadcast) {
    var newBroadcast = {}
    newBroadcast[broadcast.id] = broadcast
    setBroadcastObj({ ...newBroadcast, ...broadcastObj })
    setRightView(null)
    selectChannel(null)
  }
  function handleEditBroadcast(broadcast) {
    setRightView(null)
    selectChannel(null)
    setRefresh(refresh + 1)
  }

  function handleDeleteBroadcast(broadcast_id) {
    var broadcast = broadcastObj
    delete broadcast[`broadcast_${broadcast_id}`]
    setBroadcastObj(broadcast)
    setRightView(null)
    selectChannel(null)
  }

  // Functions to handle threads
  function handleSelectThread(thread_id) {
    setWebSocketMessages([])
    setwebSocketBroadcastMessages([])
    setSelectedThread(thread_id)
    setRefresh(count => count + 1)
  }

  function handleRemoveThread() {
    setSelectedThread(null)
    setWebSocketMessages([])
    setwebSocketBroadcastMessages([])
    setRefresh(count => count + 1)
  }

  // Functions to change right view
  function handleChangeRightView(type_) {
    setRightView(type_)
    setActiveChannel('')
    setBroadcastMessage(null)
    setStatusChange(null)
    setwebSocketBroadcastMessages([])
    setWebSocketMessages([])
  }

  var rightPanel = null
  if (rightView === "new-chat") {
    rightPanel = <UserSearch />
  } else if (rightView === "new-broadcast") {
    rightPanel = <CreateBroadcast onSubmit={handleNewBroadcast} onEdit={handleEditBroadcast} onDelete={handleDeleteBroadcast} message={broadcastMessage} statusChange={statusChange} />
  } else if (selectedThread) {
    rightPanel = <ViewThread selectedThread={selectedThread} updatedMsg={msgUpdated} deletedMsg={idsDeleted} newMessage={webSocketMessages} onReturn={handleRemoveThread} />
  } else if (selectedChannel) {
    if (selectedChannel.channel.type === "group") {
      rightPanel = <ViewChat selectedChannel={selectedChannel} updatedMsg={msgUpdated} deletedMsg={idsDeleted} newMessage={webSocketMessages} newBroadcastMessage={webSocketBroadcastMessages} onMark={markBroadcastMessagesAsRead} onSelectThread={handleSelectThread} />
    } else if (selectedChannel.channel.type === "direct") {
      rightPanel = <ViewChat selectedChannel={selectedChannel} updatedMsg={msgUpdated} deletedMsg={idsDeleted} newMessage={webSocketMessages} newBroadcastMessage={webSocketBroadcastMessages} onMark={markBroadcastMessagesAsRead} onSelectThread={handleSelectThread} onEdit={handleEditMessage}/>
    }
  }

  return (
    <div className="max-screen__bottom__2 flexer">
      <div className="flexer flexer-mobile flexer-10 center chat-wrapper">
        <div className="flexer-3 simple-profile scroll">
          {/* {(currentRole !== "ANALYST") 
          ? <ChatsList title="Broadcast messaging" type="broadcast" pos={1} items={broadcastObj} onClick={handleSelectBroadcast} onNew={() => { handleChangeRightView("new-broadcast") }} active={activeChannel} /> 
          : null} */}
          <PermissionWrapper requiredPermissions={['chat::manage_broadcast_messaging']} checkAll={false} >
            <ChatsList 
              title="Broadcast Messaging" 
              type="broadcast" 
              pos={1} 
              items={broadcastObj} 
              onClick={handleSelectBroadcast} 
              onNew={() => { handleChangeRightView("new-broadcast") }} 
              active={activeChannel} /> 
          </PermissionWrapper>
          {/* <PermissionWrapper requiredPermissions={['chat::manage_messaging_groups']} checkAll={false} > */}
          <PermissionWrapper requiredPermissions={['chat::use_chats']} checkAll={false} >
            <ChatsList title="Groups" type="groups" pos={2} items={groupObj} onClick={handleSelectChannel} active={activeChannel} />
          </PermissionWrapper>
          <PermissionWrapper requiredPermissions={['chat::use_chats']} checkAll={false} >
            <ChatsList 
              title="Direct Messages" 
              type="direct" 
              pos={3} 
              items={directObj} 
              onClick={handleSelectChannel} 
              onNew={() => { handleChangeRightView("new-chat") }} 
              active={activeChannel} />
          </PermissionWrapper>
        </div>
        <div className="flexer-9 chats-max-screen">
          {rightPanel}
        </div>
      </div>
    </div>
  )
}

export default ChatsContent