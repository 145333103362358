// check-permissions.function.ts
export function checkPermissions(
  currentPermissionsObj,
  requiredPermissions,
  checkAll,
  objectId,
) {

  /** No permissions required, so it's cool, saves us the trouble
    and allows for early exit.
  */
  if (!requiredPermissions) {
    return true;
  }
  /** If there is only one required permission, wrap it in an array
    for further convenience.    
  */
  if (!Array.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }

  return evaluateObjectPermission(requiredPermissions, currentPermissionsObj, objectId, checkAll)

}

function evaluateObjectPermission(requiredPermissions, currentPermissions, objectId, checkAll) {

  let allPermissions = false
  for (const permissionName of requiredPermissions) {
    if (permissionName in currentPermissions) {
      // console.log(
      //   currentPermissions[permissionName].allow === '*',
      //   (Array.isArray(currentPermissions[permissionName].allow) && currentPermissions[permissionName].allow.includes(objectId)),
      //   currentPermissions[permissionName].deny === null,
      //   currentPermissions[permissionName].deny,
      //   Array.isArray(currentPermissions[permissionName].deny),
      //   (Array.isArray(currentPermissions[permissionName].deny) && !(currentPermissions[permissionName].deny.includes(objectId))))
      if (objectId &&
        (currentPermissions[permissionName].allow === '*' ||
          (Array.isArray(currentPermissions[permissionName].allow) && currentPermissions[permissionName].allow.includes(objectId))) &&
        (currentPermissions[permissionName].deny === null ||
          (Array.isArray(currentPermissions[permissionName].deny) && !(currentPermissions[permissionName].deny.includes(objectId))))) {
        if (!checkAll) {
          return true
        }
        allPermissions = true
      } else if (!objectId && currentPermissions[permissionName].allow === '*') {
        if (!checkAll) {
          return true
        }
        allPermissions = true
      } else {
        if (checkAll) {
          return false
        }
        allPermissions = false
      }
    }
  }
  return allPermissions
}
