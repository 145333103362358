import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from '../hooks/UserContext'
import Loading  from '../components/Loading/'
import config from '../api/config'


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser, isLoading } = useContext(UserContext)

  return (
    <Route {...rest} render={props => {
      if (isLoading) {
        return <Loading text="Loading" />
      } else if (!currentUser) {
        // not logged in so redirect to login page with the return url
        // return <Redirect to={{ pathname: `${config.home}/login`, state: { from: props.location } }} />
        window.location.assign(`${config.home}/login`)
        return false
      }

      // authorised so return component
      return <Component {...props} {...rest} />
    }} />)
}

export default PrivateRoute
