import React, {useEffect, useState} from 'react'
import {webscraperService} from 'api'

// Components
import WebscraperFilters from './WebscraperFilters'
import WebscraperDashboard from './WebscraperDashboard'

const WebScraperSection = ( props ) => {
  const { project, orgId } = props

  const [ filters, setFilters ] = useState( { result_type: [], relevance: [], sorting: null } )
  const [ results, setResults ] = useState( [] )
  const [ refresh, setRefresh ] = useState( 1 )

  function filterToUrl( filters ) {
    let params = []
    for ( const [ key, value ] of Object.entries( filters ) ) {
      if ( Array.isArray( value ) ) {
        for (const newValue of value) {
          params.push([key, newValue])
        }
      } else if (value) {
        params.push([key, value])
      }
    }
    return new URLSearchParams(params).toString()
  }


  useEffect(() => {
    let url_filters = filterToUrl(filters)
    webscraperService.list(orgId, project.id, url_filters)
    .then(response => {
      setResults(response.results)
    })
    .catch(error => {
      console.log("Error loading webscraper results", error)
    })

  },[filters, refresh])

  let options = [
                  {name:'Alias', id: 'torch_alias'},
                  {name:'Account', id: 'torch_accounts'},
                  {name:'Phone', id: 'torch_phone'},
                  {name:'Link', id: 'torch_link'},
                  {name:'Account image', id: 'torch_account_image'},
                  // {name:'Location', id: 'torch_location'},
                  {name:'Data point', id: 'torch_datapoint'},
              ]

  // multiselect
  function handleSelect(selected) {
    let tag = selected.map( e => e.id)
    setFilters({...filters, result_type: tag})
    setRefresh(refresh + 1)
  }

  // categories
  function handleCategories( e ) {
    setFilters( { ...filters, relevance: e.target.value } )
    setRefresh( refresh + 1 )
  }

  function handleSortFilter(order) {
    setFilters({...filters, sorting: order})
    setRefresh(refresh + 1)
  }

  function handleRefresh() {
    setRefresh(refresh +1)
  }

  return (
    <>
      <div className=''>
        <div className="mb-30">
          <WebscraperFilters
            options={ options }
            handleSelect={ handleSelect }
            handleCategories={ handleCategories }
            handleSortFilter={ handleSortFilter }
          />
        </div>
        <WebscraperDashboard
          results={ results }
          project={ project }
          onRefresh={ handleRefresh }/>
      </div>
    </>
  )

}

export default WebScraperSection
