import { PermissionWrapper } from 'components'
import { useState } from 'react'

// Components
import Button from '../Elements/Button'
import ConfidenceIcon from '../Elements/ConfidenceIcon'
import ImageContent from '../Elements/ImageContent'
import Modal from '../Elements/Modal'
import EditProfileForm from './Forms/EditProfileForm'

function ProfileSection(props) {
	const [isOpen, setIsOpen] = useState(false)
	const [subject, setSubject] = useState(null)

	// PERSON
	let aliases = []
	let dob
	let first_name
	let middle_name
	let last_name

	// ORG
	let organization_name

	// EVENT
	let event_name
	let organizer_name

	let phones = []
	let profilePic = false // hipster icon now inside ImageContent
	let email
	let website

	if (props.project.subject) {
		// ID
		if (!subject) {
			setSubject(props.project.subject)
		}

		if (props.project.subject.type === 'PERSON') {
			// Name PERSON
			first_name = {
				content: props.project.subject?.first_name
					? props.project.subject.first_name.content
					: null,
				confidence: props.project.subject?.first_name
					? props.project.subject.first_name.confidence
					: null,
				id: props.project.subject?.first_name ? props.project.subject.first_name.id : null,
			}

			middle_name = {
				content: props.project.subject?.middle_name
					? props.project.subject.middle_name.content
					: null,
				confidence: props.project.subject?.middle_name
					? props.project.subject.middle_name.confidence
					: null,
				id: props.project.subject?.middle_name ? props.project.subject.middle_name.id : null,
			}

			last_name = {
				content: props.project.subject?.last_name ? props.project.subject.last_name.content : null,
				confidence: props.project.subject?.last_name
					? props.project.subject.last_name.confidence
					: null,
				id: props.project.subject?.last_name ? props.project.subject.last_name.id : null,
			}

			// Get aliases
			for (const [index, alias] of props.project.subject.alias.entries()) {
				let alias_first_name
				let alias_middle_name
				let alias_last_name

				alias_first_name = {
					content: alias?.first_name ? alias.first_name.content : null,
					confidence: alias?.first_name ? alias.first_name.confidence : null,
					id: alias?.first_name ? alias.first_name.id : null,
				}

				alias_middle_name = {
					content: alias?.middle_name ? alias.middle_name.content : null,
					confidence: alias?.middle_name ? alias.middle_name.confidence : null,
					id: alias?.middle_name ? alias.middle_name.id : null,
				}

				alias_last_name = {
					content: alias?.last_name ? alias.last_name.content : null,
					confidence: alias?.last_name ? alias.last_name.confidence : null,
					id: alias?.last_name ? alias.last_name.id : null,
				}

				aliases.push(
					<div className='flexer flexer-spaced my-10' key={`alias_${index}`}>
						<p className='text is-dark is-bold line-margin-s'>Alias {index + 1}</p>

						<div className='flexer'>
							{alias_first_name?.content && (
								<div className='flexer flexer-vcenter wcc-hasconfidence'>
									<p className='text is-dark line-margin-s'>{alias_first_name.content}</p>
									<div className='center'>
										<ConfidenceIcon
											confidence={alias_first_name.confidence}
											orgId={props.orgId}
											dataId={alias_first_name.id}
											onClose={props.onClose}
										/>
									</div>
								</div>
							)}
							{alias_middle_name?.content && (
								<div className='flexer flexer-vcenter wcc-hasconfidence'>
									<p className='text is-dark line-margin-s'>{alias_middle_name.content}</p>
									<div className='center'>
										<ConfidenceIcon
											confidence={alias_middle_name.confidence}
											orgId={props.orgId}
											dataId={alias_middle_name.id}
											onClose={props.onClose}
										/>
									</div>
								</div>
							)}
							{alias_last_name?.content && (
								<div className='flexer flexer-vcenter wcc-hasconfidence'>
									<p className='text is-dark line-margin-s'>{alias_last_name.content}</p>
									<div className='center'>
										<ConfidenceIcon
											confidence={alias_last_name.confidence}
											orgId={props.orgId}
											dataId={alias_last_name.id}
											onClose={props.onClose}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				)
			}

			// DOB
			dob = {
				content: props.project.subject?.proper_dob ? props.project.subject.proper_dob : null,
				confidence: props.project.subject?.dob ? props.project.subject.dob.confidence : null,
				id: props.project.subject?.dob ? props.project.subject.dob.id : null,
			}
		} else if (props.project.subject.type === 'ORGANIZATION') {
			organization_name = {
				content: props.project.subject?.organization_name
					? props.project.subject.organization_name.content
					: null,
				confidence: props.project.subject?.organization_name
					? props.project.subject.organization_name.confidence
					: null,
				id: props.project.subject?.organization_name
					? props.project.subject.organization_name.id
					: null,
			}
			website = {
				content: props.project.subject?.website ? props.project.subject.website.content : null,
				confidence: props.project.subject?.website
					? props.project.subject.website.confidence
					: null,
				id: props.project.subject?.website ? props.project.subject.website.id : null,
			}
		} else if (props.project.subject.type === 'EVENT') {
			organizer_name = {
				content: props.project.subject?.organizer_name
					? props.project.subject.organizer_name.content
					: null,
				confidence: props.project.subject?.organizer_name
					? props.project.subject.organizer_name.confidence
					: null,
				id: props.project.subject?.organizer_name ? props.project.subject.organizer_name.id : null,
			}
			event_name = {
				content: props.project.subject?.event_name
					? props.project.subject.event_name.content
					: null,
				confidence: props.project.subject?.event_name
					? props.project.subject.event_name.confidence
					: null,
				id: props.project.subject?.event_name ? props.project.subject.event_name.id : null,
			}
			website = {
				content: props.project.subject?.website ? props.project.subject.website.content : null,
				confidence: props.project.subject?.website
					? props.project.subject.website.confidence
					: null,
				id: props.project.subject?.website ? props.project.subject.website.id : null,
			}
		}

		// Get phones

		for (const [index, phone] of props.project.subject.phones.entries()) {
			let phone_number = {
				content: phone?.phone_number ? phone.phone_number.content.trim() : null,
				confidence: phone?.phone_number ? phone.phone_number.confidence : null,
				id: phone?.phone_number ? phone.phone_number.id : null,
			}

			phones.push(
				<div className='flexer flexer-spaced my-10' key={`phone_${index}`}>
					<p className='text is-dark is-bold line-margin-s'>Phone number {index + 1}</p>
					<div className='flexer flexer-vcenter wcc-hasconfidence mv-profpic'>
						<p className='text is-dark line-margin-s'>{phone_number.content}</p>
						{phone_number.content && (
							<ConfidenceIcon
								confidence={phone_number.confidence}
								orgId={props.orgId}
								dataId={phone_number.id}
								onClose={props.onClose}
							/>
						)}
					</div>
				</div>
			)
		}

		// Email
		email = {
			content: props.project.subject?.email ? props.project.subject.email.content : null,
			confidence: props.project.subject?.email ? props.project.subject.email.confidence : null,
			id: props.project.subject?.email ? props.project.subject.email.id : null,
		}

		// Profile pic
		profilePic = {
			content: props.project.subject.profile_pic.content
				? props.project.subject.profile_pic.content
				: profilePic,
			confidence: props.project.subject?.profile_pic
				? props.project.subject.profile_pic.confidence
				: null,
			id: props.project.subject?.profile_pic ? props.project.subject.profile_pic.id : null,
		}
	}

	var profileElement = (
		<EditProfileForm
			onClose={() => {
				setIsOpen(false)
			}}
			onComplete={props.onClose}
			subject={props.project.subject}
		/>
	)

	return (
		<>
			<div className='simple-profile__sections center'>
				<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic'>
					<ImageContent
						className='simple-profile__picture one-fourth-width wcc-000'
						src={props.project?.subject?.profile_pic?.content}
						alt='profile'
					/>
					{profilePic.content && (
						<ConfidenceIcon
							confidence={profilePic.confidence}
							orgId={props.orgId}
							dataId={profilePic.id}
							onClose={props.onClose}
						/>
					)}
				</div>
				<div className='flexer center my-20'>
					{first_name?.content && (
						<div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
							<p className='subtitle is-dark line-margin-s'>{first_name.content}</p>
							<div className='center'>
								<ConfidenceIcon
									confidence={first_name.confidence}
									orgId={props.orgId}
									dataId={first_name.id}
									onClose={props.onClose}
								/>
							</div>
						</div>
					)}
					{middle_name?.content && (
						<div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
							<p className='subtitle is-dark line-margin-s'>{middle_name.content}</p>
							<div className='center'>
								<ConfidenceIcon
									confidence={middle_name.confidence}
									orgId={props.orgId}
									dataId={middle_name.id}
									onClose={props.onClose}
								/>
							</div>
						</div>
					)}
					{last_name?.content && (
						<div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
							<p className='subtitle is-dark line-margin-s'>{last_name.content}</p>
							<div className='center'>
								<ConfidenceIcon
									confidence={last_name.confidence}
									orgId={props.orgId}
									dataId={last_name.id}
									onClose={props.onClose}
								/>
							</div>
						</div>
					)}
				</div>
				{event_name?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='subtitle is-dark line-margin-s'>{event_name.content}</p>
						<ConfidenceIcon
							confidence={event_name.confidence}
							orgId={props.orgId}
							dataId={event_name.id}
							onClose={props.onClose}
						/>
					</div>
				)}
				{organization_name?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='subtitle is-dark line-margin-s'>{organization_name.content}</p>
						<ConfidenceIcon
							confidence={organization_name.confidence}
							orgId={props.orgId}
							dataId={organization_name.id}
							onClose={props.onClose}
						/>
					</div>
				)}
				{dob?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='text is-dark line-margin-s'>{dob.content}</p>
						<ConfidenceIcon
							confidence={dob.confidence}
							orgId={props.orgId}
							dataId={dob.id}
							onClose={props.onClose}
						/>
					</div>
				)}

				{organizer_name?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='text is-light line-margin-s icon-text'>
							<i className='fa fa-user mr-10 text-icon' />
							{organizer_name.content}
						</p>
						<ConfidenceIcon
							confidence={organizer_name.confidence}
							orgId={props.orgId}
							dataId={organizer_name.id}
							onClose={props.onClose}
						/>
					</div>
				)}
				{email?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='text is-light line-margin-s icon-text'>
							<i className='fa fa-envelope mr-10 text-icon' />
							{email.content}
						</p>
						<ConfidenceIcon
							confidence={email.confidence}
							orgId={props.orgId}
							dataId={email.id}
							onClose={props.onClose}
						/>
					</div>
				)}
				{website?.content && (
					<div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
						<p className='text is-light line-margin-s icon-text'>
							<i className='fa fa-globe mr-10 text-icon' />
							{website.content}
						</p>
						<ConfidenceIcon
							confidence={website.confidence}
							orgId={props.orgId}
							dataId={website.id}
							onClose={props.onClose}
						/>
					</div>
				)}
				<div className='mx-15'>
					{aliases}
					{phones}
				</div>
				{props.closed ? null : (
					// <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false}>
						<div className='mt-15'>
							<Button
								type='button'
								text='Edit Profile'
								class='orange-btn'
								icon='fa fa-pencil cursor'
								onClick={() => setIsOpen(true)}
							/>
						</div>
					// </PermissionWrapper>
				)}
			</div>
			<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
			>
				{profileElement}
			</Modal>
		</>
	)
}

export default ProfileSection
