import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

// API
import { authenticationService } from '../../api'
import { UserContext } from '../../hooks/UserContext'
import useWebSocket from '../../hooks/useWebSocket'
import ThemeToggle, { toggleService } from '../Elements/ThemeToggle'
import MenuTabItem from '../Header/MenuTabItem'

import ProfileMenu from '../Header/ProfileMenu'

import torchLogo from '../../assets/images/logo__torch.png'
import torchLogoWhite from '../../assets/images/logo__torch--white.png'
import { CidTorchMenu } from '../../components'

function GeneralHeader() {
	const [theme, setTheme] = useState(toggleService.currentModeValue)

	const { currentOrganizations, setCurrentOrganizations, currentUser } = useContext(UserContext)
	const orgMemberInfo = currentOrganizations

	let is_superadmin = currentUser && currentUser.role === 'SUPER_ADMIN'

	var path = window.location.pathname

	/* WEBSOCKET SECTION */
	const { WebSocketOpen, WebSocketClient } = useWebSocket('header')
	if (WebSocketOpen) {
		WebSocketClient.current.onmessage = (e) => {
			const data = JSON.parse(e.data)
			if (data.type === 'NEW_ORGANIZATION') {
				let newOrgId = data.payload.id
				orgMemberInfo[newOrgId] = data.payload
				setCurrentOrganizations(orgMemberInfo)
			} else if (data.type === 'DELETED_ORGANIZATION') {
				delete orgMemberInfo[data.payload.id]
				authenticationService.updateOrgMember(orgMemberInfo)
			}
		}
	}
	/* END WEBSOCKET SECTION */

	function handleExpandable() {
		var element = document.getElementById('expandable-menu')
		element.classList.toggle('mobile-hidden')
		element.classList.toggle('mobile-shown')
	}

	return (
		<header className='header'>
			<div className='row flexer flexer-vcenter'>
				{/*Commented out until both have subscriptions 2/18/2022 TD*/}
				<div className='ml-10'>
					<CidTorchMenu />
				</div>
				<div className='col-3 center-mobile'>
					<img
						src={theme === 'dark' ? torchLogoWhite : torchLogo}
						className='logo'
						alt='torch-logo'
					/>
				</div>
				<div className='col-6 relative' />
				<div className='col-3 flexer flexer-spaced flexer-vcenter'>
					<ThemeToggle onChange={setTheme} />
					<div className='hidden mobile-shown center-mobile' onClick={handleExpandable}>
						<p className='mx-10'>
							Menu
							<i className='fa fa-bars mx-10' />
						</p>
					</div>
					<ProfileMenu />
				</div>
			</div>
			<div className='flexer flexer-spaced header-tabs flexer-mobile'>
				<div id='expandable-menu' className='wcc-menu flexer flexer-mobile mobile-hidden'>
						<Link to={`/organizations`} className='line-margin-0'>
							<MenuTabItem
								text='Organizations'
								icon='fas fa-grip-horizontal'
								active={path === `/organizations`}
							/>
						</Link>
					{is_superadmin && (
						<Link to={`/superadmin-settings`} className='line-margin-0'>
							<MenuTabItem
								text='SuperAdmin Settings'
								icon='fas fa-cog'
								active={path === `/superadmin-settings`}
							/>
						</Link>
					)}
				</div>
			</div>
		</header>
	)
}

export default GeneralHeader
