import { useState, useEffect, useRef } from 'react'

import config from '../api/config'
import useOrgId from './useOrgId'
import useUserRole from './useUserRole'

export default function useWebSocket(WsType, additionalInfo = {}) {

  const closedCode = useRef(0)
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [numberOfAttempts, setNumberOfAttempts] = useState(0)

  const orgId = useOrgId()
  const memberRole = useUserRole(true)

  useEffect(() => {
    if (waitingToReconnect) {
      return
    }

    // Only set up the websocket once
    if (!clientRef.current) {
      if (closedCode.current === 4000) {
        // Refresh token
        //authenticationService.refreshToken()
        closedCode.current = 0
      }

      setNumberOfAttempts(numberOfAttempts + 1)
      const client = new WebSocket(`${config.WebSocketURL}`)
      clientRef.current = client

      //window.client = client;

      // eslint-disable-next-line no-console
      client.onerror = (e) => console.error(e)

      client.onopen = () => {
        setIsOpen(true)
        let body = {'type': WsType, ...additionalInfo}
        if (orgId && memberRole) {
          body.organization = orgId
        }
        client.send(JSON.stringify(body))
      }

      client.onclose = (e) => {
        closedCode.current = e.code

        if (!clientRef.current || waitingToReconnect) {
          return
          // Connection failed
          // console.log('ws closed by server', e.code)
        }


        // Parse event code and log
        setIsOpen(false)
        // console.log('ws closed')

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true)

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        if(numberOfAttempts < 10){
          setTimeout(() => setWaitingToReconnect(null), 2000)
        }
      }

      return () => {
        // console.log('Cleanup');
        // Dereference, so it will set up next time
        clientRef.current = null;
        client.close();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, waitingToReconnect, WsType])

  return { WebSocketOpen: isOpen, WebSocketClient: clientRef }
}
