import { useState } from 'react'

import MenuTabItem from '../../components/Header/MenuTabItem'
import InvitationsPending from '../../components/UserOrganizations/InvitationsPending'
import Organizations from '../../components/UserOrganizations/Organizations'

function UserOrganizationsContent() {
	const [section, setSection] = useState('organizations')

	let userSection
	section === 'organizations'
		? (userSection = <Organizations />)
		: (userSection = <InvitationsPending />)

	return (
		<div className='flexer flexer-mobile'>
			<div className='flexer-11 center'>
				<div className='advanced-profile flexer center-mobile'>
					<MenuTabItem
						text='Organizations'
						active={section === 'organizations'}
						onClick={() => {
							setSection('organizations')
						}}
					/>
					<MenuTabItem
						text='Pending Invitations'
						active={section === 'invitationsPending'}
						onClick={() => {
							setSection('invitationsPending')
						}}
					/>
				</div>
				{userSection}
			</div>
		</div>
	)
}

export default UserOrganizationsContent
