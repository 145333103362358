import { useEffect, useState } from 'react'
import { projectTemplateService , reportTemplateService } from '../../api'
import Button from '../Elements/Button'
import InputField from '../Elements/InputField'
import Modal from '../Elements/Modal'
import OrganizationLogo from '../../assets/images/globe_international_icon.png'
import { Pagination } from '@mui/material'
import styles from './ProjectTypes/projectTypes.module.sass'



const ReportTemplate = ({ orgId, org }) => {
	const [limit] = useState(12)
	const [count, setCount] = useState(0)
	const [offset, setOffset] = useState(0)
	const [currentPage, setCurrentPage] = useState(1) 

	const [templates, setTemplates] = useState([])
	const [toDelete, setToDelete] = useState({})
	const [toEdit, setToEdit] = useState({})
	const [isOpenDelete, setIsOpenDelete] = useState(false)
	const [isOpenCreate, setIsOpenCreate] = useState(false)
	const [refresh, setRefresh] = useState(0)
	const [errors, setErrors] = useState({})
	const [submitting, setSubmitting] = useState(false)
	const [templateImage, setTemplateImage] = useState(null)
	const [hasNewImg, setHasNewImg] = useState(false)

	useEffect(() => {
		projectTemplateService
			.list(orgId, limit, offset)
			.then(({ results, count }) => {
				setTemplates(results)
				setCount(count)
			})
			.catch((error) => {
				console.error('Unable to get report templates', error)
			})
	}, [orgId, refresh, offset])

	useEffect(() => {
		let logo = org?.profile_pic ?? OrganizationLogo
		if (toEdit?.id) {
			toEdit?.image ? setTemplateImage(toEdit?.image) : setTemplateImage(logo)
		} else {
			setTemplateImage(logo)
		}
	}, [org?.profile_pic, toEdit?.id, toEdit?.image, refresh])

	const hdlPaginator = (event, value) => {
		setCurrentPage(value)
		setOffset(limit * (value - 1))
	}

	const handleSetToDelete = (template_id, template_name) => {
		setToDelete({ id: template_id, name: template_name })
		setIsOpenDelete(true)
	}

	const handleConfirmDelete = () => {
		projectTemplateService
		.remove(orgId, toDelete.id).then((res) => {
			setToDelete({})
			setRefresh(refresh + 1)
			setIsOpenDelete(false)
		})
	}

	const handleEdit = (id, pic, name, template_id ) => {
		setToEdit({ id: id, image: pic, name: name, report_template_id: template_id })
		setIsOpenCreate(true)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setSubmitting(true)

		let form_element = document.getElementById('template-form')
		let form_data = new FormData(form_element)

		var UUIDFile, currentFile, uniqueName
		for (var pair of form_data.entries()) {			
		  UUIDFile=pair[1]
		  currentFile=pair[0]
		  uniqueName = crypto.randomUUID() + '-' + pair[1].name
		  break
		}
		form_data.set(currentFile,UUIDFile,uniqueName)
	

		if (toEdit?.id) {

			let body = {}
			form_data.forEach((value, key) => {
				if (!value) {
					form_data.delete(key)
				}
				if (key === 'name') {
					body[key] = value
					form_data.delete(key)
				}
			})

			body.name && projectTemplateService
				.update(body, orgId, toEdit.id)
				.then((response) => {
					setToEdit({})
					setRefresh(refresh + 1)
					setSubmitting(false)
					setIsOpenCreate(false)
				})
				.catch((error) => {
					if (Array.isArray(error)) {
						error = { non_field_errors: error[0] }
					}
					setErrors({ ...error })
					setSubmitting(false)
				})
				hasNewImg && reportTemplateService.update(form_data, orgId, toEdit.report_template_id)
				.then((response) => {
					setToEdit({})
					setRefresh(refresh + 1)
					setSubmitting(false)
					setIsOpenCreate(false)
				})

		} else {
			projectTemplateService
				.create(form_data, orgId)
				.then((response) => {
					setRefresh(refresh + 1)
					setSubmitting(false)
					setIsOpenCreate(false)
				})
				.catch((error) => {
					if (Array.isArray(error)) {
						error = { non_field_errors: error[0] }
					}
					setErrors({ ...error })
					setSubmitting(false)
				})
			}
		}
		
	const handleClose = () => {
		setIsOpenCreate(false)
		setIsOpenDelete(false)
		setToEdit({})
		setToDelete({})
		setHasNewImg(false)
		setErrors({})
		setRefresh(refresh + 1)
	}

	const handleFileLoader = () => {
		var element = document.getElementById('file-loader')
		element.click()
	}

	function handleChangeImage({ target }) {
		if (target.files && target.files[0]) {
					 	
			var ext =  target.files[0].name.split('.').pop().toLowerCase();
			const allowedExtensions = ["png", "jpeg", "gif", "jpg"];
			if(allowedExtensions.includes(ext)){
			setTemplateImage(URL.createObjectURL(target.files[0]))
			setHasNewImg(true)}
		}
	}

	let templates_list =
		templates &&
		templates.map((item) => (
			<tr className='table-rows flexer' key={item.id}>
				<td className='text is-light flexer-1 left side-margin-0 cursor'>
				<img
						className='org-icon__picture'
						src={item?.report_templates?.image ?? org?.profile_pic ?? OrganizationLogo }
						// src={item?.report_templates?.image ?? templateImage}
						alt='Template'
						// onClick={() => handleEdit(item.id, item.image, item?.name)}
					onClick={() => handleEdit(item.id, item?.report_templates?.image, item?.name, item?.report_templates?.id)}
					/>
				</td>
				<td className='text is-light full-width  side-margin-0 left'>{item.name}</td>
				<td className='text flexer-1 right side-margin-0 cursor'>
					<i
						className='fa fa-pencil mr-20 is-light'
						onClick={() => handleEdit(item.id, item?.report_templates?.image, item?.name, item?.report_templates?.id)}
					/>
					<i
						className='fa fa-trash mr-10 is-light'
						onClick={() => handleSetToDelete(item.id, item.name)}
					/>
				</td>
			</tr>
		))

	return (
		<>
			<section className='dashboard-section '>
				<div className='flexer flexer-spaced'>
					<p className='text is-light is-bold left mb-30'>Report Templates</p>
					<div className='mt-20'>
						<Button
							text={'Add Template'}
							class={'black-btn'}
							icon={'fas fa-plus'}
							onClick={() => setIsOpenCreate(true)}
						/>
					</div>
				</div>
				<table className='table' cellSpacing='0'>
					<thead>
						<tr className='table-header flexer mt-15'>
							<th className='text flexer-1 is-light full-width left side-margin-0 center'>Logo</th>
							<th className='text full-width  is-light full-width side-margin-0 left'> Name</th>
							<th className='text flexer-1 is-light  left side-margin-0'> </th>
						</tr>
					</thead>
					<tbody>{templates_list}</tbody>
				</table>
				<footer className={styles.main_footer}>
					<span>
						Showing {currentPage === 1 ? currentPage : (currentPage - 1) * limit} -{' '}
						{currentPage === Math.ceil(count / limit) ? count : currentPage * limit} of {count}
					</span>
					<Pagination
						count={Math.ceil(count / limit)}
						shape='rounded'
						color={'primary'}
						onChange={hdlPaginator}
					/>
				</footer>
			</section>
			<Modal isOpen={isOpenDelete} handleClose={handleClose}>
				<div className='center'>
					<h1 className='title is-dark mb-30'>Delete Report Template</h1>
					<p className='text is-light mx-30'>
						Are you sure that you want to delete the report template{' '}
						<span className='is-dark is-bold'>{toDelete.name}</span>?
					</p>
					<Button
						text='Delete'
						class='mt-30 one-third-width orange-btn subheading-light'
						type='button'
						onClick={handleConfirmDelete}
					/>
				</div>
			</Modal>

			<Modal isOpen={isOpenCreate} handleClose={handleClose}>
				<form id='template-form' onSubmit={handleSubmit}>
					<h1 className='title is-dark mb-30'>
						{toEdit?.id ? 'Edit Report Template' : 'Create Report Template'}
					</h1>
					<div className='flexer flexer-vcenter'>
						<input
							id='file-loader'
							name='image'
							type='file'
							accept='.png, .jpeg, .jpg, .gif'
							className='file-input'
							onChange={handleChangeImage}
						/>
						<img className='edit-org-profile__picture' src={templateImage} alt='logo' />
						<Button
							type='button'
							text='Upload logo'
							class='mx-15 orange-btn subheading is-white'
							onClick={handleFileLoader}
						/>
					</div>
					<InputField
						name='name'
						label='Name'
						type='text'
						placeholder={toEdit?.name ?? null}
						required={toEdit?.id ? false : true}
						error={errors.name}
					/>

					<div className='right center-mobile'>
						<Button
							text='Cancel'
							class='mt-30 mx-15 white-btn subheading is-dark'
							onClick={handleClose}
						/>
						<Button
							text='Save Changes'
							class='orange-btn mt-30 subheading is-white'
							type='submit'
							disabled={submitting}
						/>
					</div>
				</form>
			</Modal>
		</>
	)
}

export default ReportTemplate
