import React from 'react'

//Components
import StarRatings from 'react-star-ratings'

function ReviewsItem(props) {
    var profilePic = props.profile ? props.profile : null
    profilePic = profilePic ? profilePic : 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

    return (
        <div className="experience-item full-width">
            <div className="bubble">
                <p className="subheading is-light">{props.text}</p>
            </div>
            <div className="reviewer-wrapper flexer">
                <img className="profile-menu__image" src={profilePic} alt="profile"  />
                <div className="reviewer-wrapper__info">
                    <p className="heading is-dark line-margin-0" >{props.name}</p>
                    <StarRatings rating={props.rating} starDimension="15px" starSpacing="0px" starRatedColor="#44C568" />
                    <span className="rating-text mx-10">{props.rating.toString()}</span>
                </div>
            </div>
        </div>
    )
}

export default ReviewsItem