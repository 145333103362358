import  { useEffect, useState } from 'react'
import { Modal, Button, SearchBar, Pagination } from 'components'

import { memberService, accessService } from 'api'
import { useOrgId, useUserRole } from 'hooks'
import OrganizationMembersRow from './OrganizationMembersRow'
import '../../assets/styles/dashboard.scss'

function OrganizationMembers(props) {
  const [members, setMembers] = useState()
  const [memberList, setMemberList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [toDelete, setToDelete] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [paginator, setPaginator] = useState({ offset: 0 })
  const [accessList, setAccessList] = useState([])

  const orgId = useOrgId()
  const currentRole = useUserRole()
  const canDrop = (currentRole === 'SUPER_ADMIN' || currentRole === 'ADMIN' || currentRole === 'CASE_MANAGER')

  useEffect(() => {
    memberService.list(orgId, paginator.offset, paginator.limit)
      .then((res) => {
        setMembers(res)
        setMemberList(res)
      })
      .catch((error) => {
        console.error("Unable to get members list", error)
      })
    
    // Load all permissions avalible for users
    accessService.list().then((res => {
      setAccessList(res)
    }))
  }, [orgId, paginator.offset, refresh])


  useEffect(() => {
    if (toDelete.id) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [toDelete])

  const handleDelete = () => {
    memberService.delete_member(orgId, toDelete.id)
      .then(response => {
        let users = memberList
        delete users[toDelete.id]
        setMemberList(users)
      })
      .then(() => {
        setIsOpen(false)
        setRefresh(refresh + 1)
        props.onUpdate()
      })
      .catch(error => {
        console.error("Unable to delete user", error)
      })
  }

  const handleSearch = (e) => {

    if (e.length > 1) {
      memberService.search(e, orgId)
        .then(res => {
          setMembers({ results: res })
        })
        .catch(() => {
          setMembers(memberList)
        })
    } else if(memberList?.results?.length) {
      setMembers(memberList)
    }
  }

  return (
    <div className="dashboard-section wcc-members">
      <p className='text is-light is-bold left'>Active Members</p>
      <div className="full-width mt-30">
        <SearchBar placeholder={"Find a member..."} onChange={handleSearch} />
      </div>

      <table className="table wcc-members" cellSpacing="0">
        <thead>
          <tr className="table-header mt-15">
            <th className="text is-light wcc-tdicons"></th>
            <th className="text is-light">Name</th>
            <th className="text is-light">Email</th>
            <th className="text is-light">Last Active</th>
            <th className="wcc-tdicons"></th>
          </tr>
        </thead>

        <tbody>
          {members?.results?.length > 0 ?
            members.results.map(member => (member.role !== "UNASSIGNED") && (
              <OrganizationMembersRow
                member={member}
                orgId={orgId}
                currentRole={currentRole}
                canDrop={canDrop}
                setToDelete={setToDelete}
                key={member.id}
                accessList={accessList}
                onUpdate={() => setRefresh(refresh + 1)}
              />
            ))
            : <tr className="wcc-nodata"><td colSpan={5}>No member found</td></tr>
          }
        </tbody>
      </table>

      {/* If count not exist on the endpoint response, tha menas that result don't need to be paginated */}
      {members?.count > 0 && (
        <Pagination
          paginator={paginator}
          setPages={setPaginator}
          data={members}
 
        />
       
      )}

      <Modal isOpen={isOpen} handleClose={() => { setToDelete({}) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Delete Member</h1>
          <p className="text is-light mx-30">Are you sure that you want to delete the member <br></br>
            <span className="is-dark is-bold">{toDelete?.user?.public_name} - {toDelete?.user?.email}</span>?
          </p>

          <Button text="Delete" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDelete} />
        </div>
      </Modal>
    </div>
  )
}

export default OrganizationMembers
