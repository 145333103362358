import React from 'react'

// Containers
import Header from '../containers/Header'
import AnalyticsContent from '../containers/Content/AnalyticsContent'

function Analytics() {
	return (
		<>
			<Header />
			<AnalyticsContent />
		</>
	)
}

export default Analytics
