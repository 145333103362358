import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import getCategories from '../../../helpers/getCategories'
import { Button } from '../../index'
import SourceCategoryList from './SourceCategoryList'
import SourceCategoryModalCreate from './Modal/SourceCategoryModalCreate'
import SourceCategoryModalDelete from './Modal/SourceCategoryModalDelete'
import SourceCategoryModalEdit from './Modal/SourceCategoryModalEdit'

import styles from './sourceCategory.module.sass'

const SourceCategory = ({ orgId }) => {
	const [toCreate, setToCreate] = useState(false)
	const [onLoading, setOnLoading] = useState(true)
	const [sourceList, setSourceList] = useState([])
	const [selectedIds, setSelectedIds] = useState([])
	const [categoryName, setCategoryName] = useState('')
	const [categoryList, setCategoryList] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [toDelete, setToDelete] = useState({
		showModal: false,
		id: '',
		name: '',
	})
	const [toEdit, setToEdit] = useState({
		showModal: false,
		name: '',
		sourceSites: [],
		id: '',
	})

	const getSources = async () => {
		try {
			const { data } = await axios(`/torch/organization/${orgId}/source_sites/`)

			return data
		} catch (err) {
			toast.error(err)
		}
	}

	const hdlToggleItem = (sourceItem) => {
		if (selectedItems.length > 0) {
			if (selectedItems.includes(sourceItem)) {
				setSelectedItems(selectedItems.filter((item) => item.id !== sourceItem.id))
			} else {
				setSelectedItems([...selectedItems, ...sourceList.filter((item) => item === sourceItem)])
			}
		} else if (!selectedItems.includes(sourceItem)) {
			setSelectedItems([...selectedItems, ...sourceList.filter((item) => item === sourceItem)])
		}
	}

	const hdlSelectedItem = (sourceItem) => {
		return selectedItems.includes(sourceItem)
	}

	const hdlSelectAll = () => {
		setSelectedItems(sourceList)
	}

	const hdlDeselectAll = () => {
		setSelectedItems([])
	}

	const hdlCreate = async () => {
		try {
			await axios.post(`/torch/organization/${orgId}/categories/`, {
				name: categoryName,
				description: categoryName,
				source_sites: selectedIds,
			})

			setToCreate(false)
			setOnLoading(true)
			toast.success(`The category ${categoryName} has been created`)
		} catch (err) {
			toast.error(err)
		}
	}

	const hdlEdit = async () => {
		try {
			await axios.patch(`/torch/organization/${orgId}/categories/${toEdit.id}/`, {
				name: categoryName,
				source_sites: selectedIds,
			})

			toast.success(`The category ${toEdit.name} has been edited`)
			setToEdit({
				showModal: false,
				name: '',
				sourceSites: [],
				id: '',
			})
			setOnLoading(true)
		} catch (err) {
			toast.error(err)
		}
	}

	const hdlDelete = async () => {
		try {
			await axios.delete(`/torch/organization/${orgId}/categories/${toDelete.id}/`)

			toast.success(`The category ${toDelete.name} has been deleted`)
			setToDelete({
				showModal: false,
				name: '',
				id: '',
			})
			setOnLoading(true)
		} catch ({ message }) {
			toast.error(message)
			setOnLoading(true)
			setToDelete({
				showModal: false,
				name: '',
				id: '',
			})
		}
	}

	useEffect(() => {
		setSelectedIds([])

		if (selectedItems.length > 0) {
			selectedItems.map(({ id }) => setSelectedIds((prev) => [...prev, id]))
		}
	}, [selectedItems])

	useEffect(() => {
		if (toCreate || toEdit.showModal) {
			getSources().then(({ results }) => setSourceList(results))
		} else {
			setSourceList([])
			setSelectedIds([])
			setCategoryName('')
			setSelectedItems([])
		}

		getCategories(orgId).then(({ results }) => setCategoryList(results))
		setTimeout(() => {
			setOnLoading(false)
		}, 500)
	}, [toCreate, toEdit.showModal, toDelete.showModal])

	return (
		<section className={'dashboard-section'}>
			<header className={styles.header}>
				<h1 className={`${styles.header_title} text is-light is-bold left mb-30`}>My Categories</h1>
				<Button
					icon={'fas fa-plus'}
					text={'Create Category'}
					class={'black-btn'}
					onClick={() => setToCreate(true)}
				/>
			</header>
			<SourceCategoryList
				onLoading={onLoading}
				setToEdit={setToEdit}
				setToDelete={setToDelete}
				categoryList={categoryList}
				setCategoryName={setCategoryName}
				setSelectedItems={setSelectedItems}
			/>
			<SourceCategoryModalCreate
				toCreate={toCreate}
				hdlCreate={hdlCreate}
				sourceList={sourceList}
				setToCreate={setToCreate}
				hdlSelectAll={hdlSelectAll}
				categoryName={categoryName}
				selectedItems={selectedItems}
				hdlToggleItem={hdlToggleItem}
				hdlDeselectAll={hdlDeselectAll}
				setCategoryName={setCategoryName}
				hdlSelectedItem={hdlSelectedItem}
			/>
			{toEdit.showModal && (
				<SourceCategoryModalEdit
					toEdit={toEdit}
					hdlEdit={hdlEdit}
					setToEdit={setToEdit}
					sourceList={sourceList}
					hdlSelectAll={hdlSelectAll}
					categoryName={categoryName}
					selectedItems={selectedItems}
					hdlToggleItem={hdlToggleItem}
					hdlDeselectAll={hdlDeselectAll}
					setCategoryName={setCategoryName}
					hdlSelectedItem={hdlSelectedItem}
					setSelectedItems={setSelectedItems}
				/>
			)}
			<SourceCategoryModalDelete
				toDelete={toDelete}
				hdlDelete={hdlDelete}
				setToDelete={setToDelete}
				categoryName={categoryName}
			/>
		</section>
	)
}

export default SourceCategory
