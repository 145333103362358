import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import ProjectsContent from '../containers/Content/ProjectsContent'

function Projects() {
    return (
        <React.Fragment>
            <Header />
            <Content>
                <ProjectsContent />
            </Content>
        </React.Fragment>
    )
}

export default Projects