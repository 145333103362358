import React from 'react'

// Components
import MultiStep from '../../components/ConvertProject/MultiSteps'
import  PriorityOfProjectForm from '../../components/ConvertProject/Forms/PriorityOfProjectForm'

function PriorityOfProject(props) {
    return (
        <React.Fragment>
            <h1 className="title-2 is-dark line-margin-0">Project Generation</h1>
            <MultiStep current={3} />
            <PriorityOfProjectForm onNext={props.onNext} onPrevious={props.onPrevious} onUpdate={props.onUpdate} data={props.data}/>
        </React.Fragment>
    )
}

export default PriorityOfProject