import './loading.scss'

const Loading = ({ text }) => {
  return (
    <div className="wcc-loading">
      <div className="wcc-circle">
        <div className="wcc-ltext">{text}</div>
      </div>
    </div>);
};

export default Loading
