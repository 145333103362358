import React, { useState, useContext } from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import Dropdown from '../Elements/Dropdown'

// API
import  { invitationService } from '../../api'

// Hooks
import { useUserRole, useOrgId } from '../../hooks'

function InvitationForm (props) {
    const [errors, setErrors] = useState({})
    const [forceInvitation, setForceInvitation] = useState(false)
    const [submitting, setSubmitting] = useState(false)

	const currentRole = useUserRole()
    const orgId = useOrgId()

	function handleSubmit(e){
        e.preventDefault()
        setSubmitting(true)

        var form_element = document.getElementById('invitation-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })
        if (forceInvitation){
            body['force'] = true
        }

        invitationService.create(body, orgId)
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
            setForceInvitation(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }

            if(currentRole === 'SUPER_ADMIN' && error?.non_field_errors && error?.non_field_errors[0] === 'you have no available spaces for this role'){
                setForceInvitation(true)
                setErrors({non_field_errors: 'There are no more spaces available for this role. Do you want to proceed anyways?'})
            }
            else{
                setErrors({...error})
            }
            
            setSubmitting(false)
        })
    }

	return (
        <form id="invitation-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Send Invitation</h1> 
            <InputField name="email"  label='Email' type="email" required={true} error={errors.email}/>
            <Dropdown label="Role" class="line-margin-xs" name="role" error={errors.role || errors.non_field_errors}>
                    <option value="" disabled>Select an option</option>
                    <option value="ANALYST">Analyst</option>
                    <option value="CASE_MANAGER">Project Manager</option>
                    {currentRole === 'ADMIN' || currentRole === 'SUPER_ADMIN' ? <option value="ADMIN">Admin</option> : null}
            </Dropdown>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text={!forceInvitation ? "Send Invitation" : "Yes" } class="orange-btn mt-30 subheading is-white" type="submit" disabled={submitting}/>
            </div>
		</form>
	)
}

export default InvitationForm