import {ToastContainer} from 'react-toastify'
import {StrictMode} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'

import reportWebVitals from './reportWebVitals'
import config from './api/config'
import App from './App'

axios.defaults.baseURL = config.apiUrl
axios.defaults.withCredentials = true

ReactDOM.render(
	<StrictMode>
		<App />
		<ToastContainer
			hideProgressBar
			draggable={ true }
			autoClose={3000}
			theme={'colored'}
			closeOnClick={true}
			pauseOnHover={true}
			position={'bottom-right'}
			toastStyle={{ backgroundColor: "#F19126" }}
		/>
	</StrictMode>,
	document.getElementById('root')
)

reportWebVitals()
